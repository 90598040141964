import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAddWidget from "./useAddWidget";
import useElementInnerHtml from "./useElementInnerHtml";
import { widgetConfig } from "../components/Editor/editor_config";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  ANIMATION,
  ICON,
  ILLUSTRATION,
  STICKER,
  TEXT_FRAME,
  TYPE_INFOGRAPHIC,
  TYPE_PROPOSAL,
} from "../constants/editor";
import { RGBToHex, getSvgContentFromUrl } from "../_helpers/utils";

const useAddWidgetOnDocumentCreate = ({ blockNode }) => {
  const [assetType, setAssetType] = useState();
  const [dataParam, setDataParam] = useState();
  const [dropPosition, setDropPosition] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [widgetWidth, setWidgetWidth] = useState();
  const [widgetHeight, setWidgetHeight] = useState();
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);

  const { state: assetInfo } = useLocation();
  const { dimension, documentType } = useContext(EditorContext);

  const getNewWidgetObject = useAddWidget(
    addWidgetClicked,
    assetType,
    dataParam,
    dropPosition,
    widgetWidth,
    widgetHeight
  );

  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataParam,
    getNewWidgetObject
  );

  const addWidget = {
    meta: false,

    prepareDropPosition: () => {
      const { width: activeBlockWidth, height: activeBlockHeight } = blockNode?.getBoundingClientRect();
      const zoomFactor = 100 / parseFloat(dimension.zoom);
      const pos = {
        left: (activeBlockWidth / 2) * zoomFactor - widgetWidth / 2,
        top: (activeBlockHeight / 2) * zoomFactor - widgetHeight / 2,
      };
      setDropPosition([pos]);
    },

    prepareInnerHtmlAndDimension: async ({ asset, value }) => {
      if ([ILLUSTRATION, STICKER, ICON, TEXT_FRAME].includes(asset)) {
        const svgContent = await getSvgContentFromUrl(value);

        svgContent.querySelectorAll(`svg *`).forEach(node => {
          if (
            node.nodeName === "defs" ||
            node.parentNode.nodeName === "defs" ||
            node.parentNode.nodeName === "linearGradient"
          )
            return false;

          if (node.nodeName !== "g" && node.style && RGBToHex(node.style.fill) !== "") {
            const currentIconColor = RGBToHex(node.style.fill);
            node.dataset.class = `${currentIconColor}body`;
          }

          if (node.tagName === "title") node.remove(); // fremove title tag for uploaded svg
        });

        const svgRatio = svgContent?.viewBox?.baseVal?.width / svgContent?.viewBox?.baseVal?.height;
        setAssetInnerContent(svgContent);
        setWidgetWidth(widgetConfig[asset].width);
        setWidgetHeight(widgetConfig[asset].width / svgRatio);
      }

      //
      else if ([ANIMATION].includes(asset)) {
        setAssetInnerContent(value);
        setWidgetWidth(widgetConfig[asset].width);
        setWidgetHeight(widgetConfig[asset].height);
      }
    },

    prepareDataParam: ({ asset = "", scheme = "", duration = "" }) => {
      const paramObj = {};

      // get dynamic data params
      if ([ICON].includes(asset)) paramObj["data-scheme"] = scheme;
      if ([ANIMATION].includes(asset)) paramObj["data-duration"] = duration;

      paramObj["data-x-allignment"] = "middle";
      paramObj["data-y-allignment"] = "center";

      // merge dynamic and predefined data params
      setDataParam({ ...widgetConfig[asset].dataAttr, ...paramObj });
    },

    start: ({ asset_type: asset, thumb: value, scheme_type: scheme, duration }) => {
      setAssetType(asset);
      addWidget.prepareDataParam({ asset, scheme, duration });
      addWidget.prepareInnerHtmlAndDimension({ asset, value });
      sessionStorage.setItem("dhp.useDesignAsset.isActive", "true");
    },
  };

  // set dropPosition for widget INSERT
  useEffect(() => {
    if (!dropPosition && widgetWidth && widgetHeight) {
      addWidget.prepareDropPosition();
    }
  }, [dropPosition, widgetWidth, widgetHeight]);

  // trigger widget INSERT
  useEffect(() => {
    if (assetType && dataParam && dropPosition && assetInnerContent) {
      setAddWidgetClicked(true);
    }
  }, [assetType, dataParam, dropPosition, assetInnerContent]);

  // reset all local states when widget drag-drop action INSERT is completed
  useEffect(() => {
    if (!addWidgetClicked) {
      setAssetType();
      setDataParam();
      setDropPosition();
      setAssetInnerContent();
      setWidgetWidth();
      setWidgetHeight();
    }
  }, [addWidgetClicked]);

  useEffect(() => {
    let timeoutId;
    const delay = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? 100 : 0;

    if (assetInfo && sessionStorage.getItem("dhp.useDesignAsset.isActive") !== "true") {
      timeoutId = setTimeout(() => {
        addWidget.start({ ...assetInfo });
      }, delay);
    }

    return () => {
      clearTimeout(timeoutId);
      timeoutId = null;
      sessionStorage.removeItem("dhp.useDesignAsset.isActive");
    };
  }, [assetInfo]);
};

export default useAddWidgetOnDocumentCreate;
