import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { showLoading, hideLoading } from "react-redux-loading-bar";

import global from "../../scss/dhp.scss";

import EditorTopbar from "./EditorTopbar";
import EditorSidebar from "./EditorSidebar";
import EditorZoomNavigationPannel from "./EditorZoomNavigationPannel";
import EditorCanvasPanel from "./EditorCanvasPanel";
import { fetchDocumentDetails } from "../../store/actions/documentActions";
import { fetchTemplateTypes, fetchTemplatePageSizes } from "../../store/actions/templateActions";
import { calculateNewZoomValue, getClassListToString, loadAllDBFonts } from "../../_helpers/utils";
import { getResourceEntries } from "../../store/actions/resourcesActions";
import RightContextMenubar from "./RightContextMenubar";
import { fetchRegionList, fetchWidgetList, updateEditorReducer } from "../../store/actions/editorActions";
import { Icon } from "../../components/ui/icon";
import TableModalComponent from "../../components/Editor/Widgets/TableV2";
import { useContextualUpgrade } from "../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../constants/company";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL, VERSION } from "../../constants/editor";
import VideoTimeline from "./VideoTimeline";
import Modal from "../../components/ui/modal";
import useStateHistory from "../../hooks/useStateHistory";
import EditorLoader from "./EditorLoader";
import { DOCUMENT_NOT_FOUND } from "../../constants/document";
import PageResizeComponent from "../../components/Editor/PageResizeComponent";
import parserLoaderSvg from "../../assets/images/dh-loader.svg";
import useCustomFonts from "../../hooks/useCustomFonts";
import useDeletedCustomFonts from "../../hooks/useDeletedCustomFonts";
import Map from "../../components/Editor/Widgets/Map";
import EditorMapInfoTooltip from "./EditorMapInfoTooltip";
import { fetchBrands, fetchBrandDetils } from "../../store/actions/companyActions";
import { AppContext } from "../../contexts";
import useCollage from "../../hooks/useCollage";

let style = Object.assign({}, global);

export const EditorContext = React.createContext({});

const EditorLayout = () => {
  const editorMetadata = {
    pageController: {
      style: {
        top: 0,
      },
    },
    activeOutSideCanvasArea: false,
    activePageId: false,
    activePageIdx: 0,
    activeBlockId: false,
    activeBlockIdx: 0,
    activeWidgetId: false,
    activeWidgetType: false,
    activeWidgetIdx: 0,
    pageCount: 1,
    blockCount: 1,
  };
  const editorGrid = {
    enabled: false,
    size: 15,
    color: "black",
    strokeWidth: 0.5,
  };
  const editorPageMargin = {
    enabled: false,
    value: "",
    style: {
      zIndex: 1000,
      position: "absolute",
      pointerEvents: "none",
    },
  };
  const editorDimension = {
    dpi: 72,
    size: "a4",
    width: 0,
    height: 0,
    zoom: 100,
    prevZoom: 100,
    orientation: "portrait",
    unit: "px",
    label: "A4",
    displayUnit: "px",
  };
  const editorPageSorterList = {
    style: {},
  };

  const editorKeyboardEvent = {
    cut: false,
    copy: false,
    paste: false,
    clone: false,
  };
  const editorCopyStyle = {
    enable: false,
    widgetType: false,
    style: {},
    cssText: false,
    opacity: false,
    dataset: {},
    lineThicknessFlag: false,
    changeList: false,
  };

  const editorRightContextMenu = {
    enable: false,
    style: {},
  };

  const editorMapTooltip = {
    enable: false,
    style: {
      position: "fixed",
      padding: "5px",
      borderRadius: "5px",
      display: "inline-block",
    },
    content: {},
  };

  const collageEventData = {
    isActive: false,
    widgetId: false,
    prevState: false,
    curState: false,
  };

  const animationObjData = {
    animationActive: false,
    animationClassName: "",
    targetWidgets: false,
  };
  const findAndReplaceEventData = {
    isActive: false,
    origin: false,
    curState: false,
  };

  const pagePlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    pageTimer: null,
    pageProgress: 0,
    pageDurationMs: 0,
  };

  const documentPlayObj = {
    action: null,
    pageId: null,
    pageIdx: null,
    documentTimer: null,
    documentProgress: 0,
    documentDurationMs: 0,
    pageArray: [],
  };

  const [templateGroupName, setTemplateGroupName] = useState("");
  const [documentType, setDocumentType] = useState("");
  // const [pageNodes, setPageNodes] = useState([]);
  // const [blockNodes, setBlockNodes] = useState([]);
  // const [widgets, setWidgets] = useState([]);
  // const [backgroundColors, setBackgroundColors] = useState([]);
  // const [backgroundImages, setBackgroundImages] = useState([]);
  const [metadata, setmetadata] = useState(editorMetadata);
  const [grid, setgrid] = useState(editorGrid);
  const [pageMargin, setPageMargin] = useState(editorPageMargin);
  const [dimension, setdimension] = useState(editorDimension);
  const [fitToScreen, setFitToScreen] = useState(false);
  const [ignoreNextScrollEvent, setIgnoreNextScrollEvent] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [pageSorterList, setpageSorterList] = useState(editorPageSorterList);
  const [clipboardObj, setClipboardObj] = useState();
  const [keyBoardEvent, setKeyBoardEvent] = useState(editorKeyboardEvent);
  const [isProcessDone, setIsProcessDone] = useState(false);
  const [rightContextMenu, setRightContextMenu] = useState(editorRightContextMenu);
  const [transitionAppliedAllPages, setTransitionAppliedAllPages] = useState(false);
  const [widgetActionClicked, setWidgetActionClicked] = useState("*");
  const [returnFromAsset, setReturnFromAsset] = useState(false);
  const [activeAssetName, setActiveAssetName] = useState("");
  const [activeAssetSchemeType, setactiveAssetSchemeType] = useState("");
  const [activeAssetType, setActiveAssetType] = useState("");
  const [groupIndex, setGroupIndex] = useState();
  const [widgetHandler, setWidgetHandler] = useState({});
  const [doubleClickActive, setDoubleClickActive] = useState(false);
  const [copyStyle, setCopyStyle] = useState(editorCopyStyle);
  const [snapGrid, setSnapGrid] = useState([]);
  const [backgroundCloning, setBackgroundCloning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalFullScreen, setModalFullScreen] = useState(false);
  const [guideLine, setGuideLine] = useState();
  const [isDocumentSave, setIsDocumentSave] = useState(false);
  const [showPageResizeModal, setShowPageResizeModal] = useState(false);
  const [togglePlayButton, setTogglePlayButton] = useState();
  const [isUploadDone, setisUploadDone] = useState(true);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [socketState, setSocketState] = useState({ activeUsers: {} });
  const [documentName, setDocumentName] = useState();
  const [changeBySocket, setChangeBySocket] = useState(false);
  const [selectionRange, setSelectionRange] = useState(false);
  const [textSelectedAreaColor, setTextSelectedAreaColor] = useState(false);
  const [doubleClickSelctedareaColor, setDoubleClickSelctedareaColor] = useState();
  const [previousSelection, setPreviousSelection] = useState();
  const [activeColorThemePageIdx, setActiveColorThemePageIdx] = useState();
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapTooltip, setMapTooltip] = useState(editorMapTooltip);
  const [collageData, setCollageData] = useState(collageEventData);
  const [textFontSize, setTextFontSize] = useState("");
  const [isTimeLineViewOpen, setIsTimeLineViewOpen] = useState(false);
  const [animationObj, setAnimationObj] = useState(animationObjData);
  const [showFindAndReplace, setShowFindAndReplace] = useState(findAndReplaceEventData);
  const [activeWidgetFromTimeLine, setActiveWidgetFromTimeLine] = useState();
  const [uploadSchemeType, setUploadSchemeType] = useState(null);
  const [retainRotation, setRetainRotation] = useState(false);
  const [actionOnRotateModal, setActionOnRotateModal] = useState(false);
  const [whichPagePlaying, setWhichPagePlaying] = useState({});
  const [pagePlayData, setPagePlayData] = useState(pagePlayObj);
  const [documentPlayData, setDocumentPlayData] = useState(documentPlayObj);
  const [playingPageIdx, setPlayingPageIdx] = useState(null);
  const [isVideoTrimApplied, setIsVideoTrimApplied] = useState();

  // ToDo -- this detail will be fatched by previous page.. once privious form page done the code will be deleted and this value will pass by props
  const dispatch = useDispatch();
  const { id } = useParams();
  const redirectionHistory = useHistory();

  const fonts = useSelector(state => state?.resources?.fonts);
  const documentDetails = useSelector(state => state?.document?.documentDetails?.data);
  const documentErrorMsg = useSelector(state => state?.document?.error?.message);
  const availableTemplateGroups = useSelector(state => state?.template?.templateGroups);
  const availableTemplatePageSizes = useSelector(state => state?.template?.templatePageSizes);
  const availableWidgetLists = useSelector(state => state?.editor?.availableWidgetLists);
  const isCompSuperAdmin = useSelector(state => state.auth?.user?.company?.superadmin_company);
  const userInfo = useSelector(state => state?.auth?.user);
  const brandInfo = useSelector(state => state.company?.brandInfo);

  let { setBrandInfo } = useContext(AppContext);

  const isDocumentParsable = !(!documentDetails?.version?.rawdata || documentDetails?.version?.rawdata?.contexts);

  const { deactivateCollageEvent } = useCollage({ restrictHooks: true });

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  useCustomFonts(); // use active custom fonts
  useDeletedCustomFonts(); // use deleted custom fonts

  // Redirect to Templates page if document not found
  useEffect(() => {
    if (documentErrorMsg && documentErrorMsg === DOCUMENT_NOT_FOUND && isProcessDone) {
      redirectionHistory.push("/templates");
    }
  }, [documentErrorMsg, isProcessDone]);

  // Load all fonts
  useEffect(() => {
    if (!fonts) dispatch(getResourceEntries("fonts"));
    else loadAllDBFonts(fonts);
  }, [fonts]);

  useEffect(() => {
    if (documentDetails?.document_name) setDocumentName(documentDetails?.document_name);
  }, [documentDetails]);

  //Fetch api for the 1st type insert in the document
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          dispatch(showLoading());
          await dispatch(fetchDocumentDetails(id));
          await dispatch(fetchTemplateTypes(isCompSuperAdmin));
          await dispatch(fetchTemplatePageSizes());
          await dispatch(fetchWidgetList(isCompSuperAdmin));
          await dispatch(fetchRegionList()); // preload map modal with Regions thumb and World Map

          //Fetch BrandList with default Brand Fetch
          const response = await dispatch(fetchBrands());
          const defaultBrandId = sessionStorage.getItem("lastSelectedBrandId") || response?.results?.brands?.[0]?.id;
          if (defaultBrandId) {
            await dispatch(fetchBrandDetils(defaultBrandId));
          }
          setIsProcessDone(true);
          dispatch(hideLoading());
        }
      } catch {
        dispatch(hideLoading());
      }
    };

    fetchData();

    let mainElem = document.getElementsByTagName("main")[0];

    // add "editor-body" class and id when insert to the editor and remove 'hide-secondAside' class to prevent collapse sidebar when 1st insert in editor
    mainElem?.classList.add("editor-body");
    mainElem?.setAttribute("id", "editorBody");
    mainElem?.classList.remove("hide-secondAside");
    document.getElementsByTagName("body")[0]?.classList.add("open-editor");

    // reset widget and collage events to false on load
    localStorage.setItem("widget.event.isActive", "false");
    localStorage.setItem("collage.event.isActive", "false");
    localStorage.removeItem("documents");

    // get timeline status from session storage to hold the prafarence when refresh the page
    let timeline = sessionStorage.getItem("timeline-status");
    setIsTimeLineViewOpen(timeline === "false" || timeline === "null" ? false : timeline);

    return () => {
      sessionStorage.removeItem("timeline-status");
      sessionStorage.removeItem("dataObj");
      //Remove lastSelectedBrandId when leave from Editor
      sessionStorage.removeItem("lastSelectedBrandId");

      //remove pagetop width and icon size width
      window.sessionStorage.removeItem("page_Top_Option_Width");
      window.sessionStorage.removeItem("icon_Size_Width");

      //remove "editor-body" class and id when leave from the editor
      mainElem?.classList.remove("editor-body");
      mainElem?.removeAttribute("id");
      document.getElementsByTagName("body")[0]?.classList.remove("open-editor");

      // unfreeze editor on leaving (in-case freezed during AI writer action from toolbar)
      document.querySelectorAll(".pe-none").forEach(e => e.classList.remove("pe-none"));

      // reset widget and collage events to false on leave
      localStorage.setItem("widget.event.isActive", "false");
      localStorage.setItem("collage.event.isActive", "false");
    };
  }, []);

  // for editor loading time panel-toggle hide
  useEffect(() => {
    let mainElem = document.querySelector(".canvas-panel-area .panel-toggle");

    if (!parseInt(dimension?.width) || !parseInt(dimension?.height)) mainElem?.classList.add("d-none");
    else mainElem?.classList.remove("d-none");
  }, [dimension?.width, dimension?.height]);

  useEffect(() => {
    if (
      documentDetails &&
      availableTemplateGroups &&
      availableTemplatePageSizes &&
      isProcessDone &&
      documentDetails?.permission &&
      !isDocumentReady
    ) {
      if (documentDetails?.permission.toLowerCase() === "ro") redirectionHistory.push("/templates");
      else {
        setDocumentType(documentDetails?.template_info?.type?.toLowerCase());
        let templateDefaultSize;
        let templateGroupId = documentDetails.template_info.group_id;
        let templateTypeId = documentDetails.template_info.type_id;

        // set landscape orientation for certificate - set up for granular structure
        let specialCase = ["certificate", "brochure"].includes(documentDetails?.template_info?.type?.toLowerCase());
        const isBlankDocument = !documentDetails?.version?.rawdata;
        const documentDimension = documentDetails?.version?.rawdata?.metadata?.dimension;

        availableTemplateGroups.template_groups.map(availableTemplateGroup => {
          if (availableTemplateGroup.id === templateGroupId) {
            setTemplateGroupName(availableTemplateGroup.slug);

            availableTemplateGroup.type.map(templateType => {
              if (templateType.id === templateTypeId) {
                templateDefaultSize = templateType.default_size;
                if (templateDefaultSize === "custom") {
                  setdimension({
                    ...dimension,
                    width: !isBlankDocument
                      ? documentDimension?.displayUnit === "px"
                        ? parseInt(documentDimension.width)
                        : parseFloat(documentDimension.width)
                      : templateType.custom_width,
                    height: !isBlankDocument
                      ? documentDimension?.displayUnit === "px"
                        ? parseInt(documentDimension.height)
                        : parseFloat(documentDimension.height)
                      : templateType.custom_height,
                    label: !isBlankDocument ? documentDimension.label : templateDefaultSize,
                    size: !isBlankDocument ? documentDimension.size : templateDefaultSize,
                    displayUnit: !isBlankDocument
                      ? documentDimension?.displayUnit ?? documentDimension.unit
                      : dimension.unit,
                    zoom: !isBlankDocument
                      ? dimension.zoom
                      : calculateNewZoomValue(templateType.custom_width, templateType.custom_height, false, userInfo?.video_enabled),
                  });
                }
              }
            });
          }
        });

        availableTemplatePageSizes.map(template_page_size => {
          template_page_size.page.map(page => {
            if (page.value === templateDefaultSize && templateDefaultSize !== "custom") {
              setdimension({
                ...dimension,
                width: !isBlankDocument
                  ? documentDimension?.displayUnit === "px"
                    ? parseInt(documentDimension.width)
                    : parseFloat(documentDimension.width)
                  : parseInt(specialCase ? page.height : page.width),
                height: !isBlankDocument
                  ? documentDimension?.displayUnit === "px"
                    ? parseInt(documentDimension.height)
                    : parseFloat(documentDimension.height)
                  : parseInt(specialCase ? page.width : page.height),
                label: !isBlankDocument ? documentDimension.label : page?.label,
                size: !isBlankDocument ? documentDimension.size : page?.value,
                orientation: specialCase ? "landscape" : "portrait",
                displayUnit: !isBlankDocument
                  ? documentDimension?.displayUnit ?? documentDimension.unit
                  : dimension.unit,
                zoom: !isBlankDocument
                  ? dimension.zoom
                  : calculateNewZoomValue(
                      parseInt(specialCase ? page.height : page.width),
                      parseInt(specialCase ? page.width : page.height),
                      false, 
                      userInfo?.video_enabled
                    ),
              });
            }
          });
        });

        if (!templateDefaultSize && documentDimension) setdimension({ ...dimension, ...documentDimension });
      }
    }
  }, [documentDetails, availableTemplateGroups, availableTemplatePageSizes, isProcessDone, isDocumentReady]);
  // ---------- END section to delete -----------//

  const toggle = () => {
    document.getElementById("editorBody").classList.toggle("hide-secondAside");
    setIsOpen(!isOpen);
    if (![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
      setTimeout(() => {
        //Fit document to canvas
        setdimension({
          ...dimension,
          zoom: calculateNewZoomValue(dimension.width, dimension.height, isTimeLineViewOpen, false, userInfo?.video_enabled),
        });
      }, 500);
    }
  };

  // Widget click action
  const handleWidgetAction = (widgetSlug, widgetName, widgetType, index, returnFromAsset, groupslug, schemeType) => {
    if ("*" === widgetSlug) {
      setWidgetActionClicked("*");
      dispatch(updateEditorReducer({ availableassetLists: [], totalAssetCount: 0 }));
      if (returnFromAsset) setReturnFromAsset(true);
      if (index !== false) setGroupIndex(index);
    } else {
      // Show modal for table
      if (widgetSlug === "table") {
        toggleModal();
        return;
      }
      // Show modal for page-size
      if (widgetSlug === "page-size") {
        togglePageResizeModal();
        return;
      }
      // Show modal for map
      if (widgetSlug === "map") {
        toggleMapModal();
        return;
      }
      // Contextual Upgrade modal for Version
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && widgetSlug === VERSION && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contexttual upgrade modal based on your condition
        showUpgrade("versionHistory", { docPagesMeta: { ...contextData, dimension, documentType } });
        return;
      }

      let i = availableWidgetLists?.findIndex(availableWidgetList => availableWidgetList.slug === groupslug);
      let componentName = "";

      widgetSlug.split("-").forEach(widgetT => {
        componentName += widgetT.charAt(0).toUpperCase() + widgetT.slice(1);
      });

      setReturnFromAsset(false);
      setWidgetActionClicked(componentName);
      setActiveAssetName(widgetName);
      setActiveAssetType(widgetType);
      setGroupIndex(index ?? groupIndex);
      schemeType && setactiveAssetSchemeType(schemeType);

      let dataObj = {
        documentId: id,
        index: i,
        slug: widgetSlug,
        name: widgetName,
        type: widgetType,
        component: componentName,
        schemeType,
      };

      sessionStorage.setItem("dataObj", JSON.stringify(dataObj));
    }
  };

  // Toggle modal func
  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  // Toggle PageResize modal func
  const togglePageResizeModal = () => {
    setShowPageResizeModal(prevState => !prevState);
  };

  // Toggle Map modal func
  const toggleMapModal = () => {
    setShowMapModal(prevState => !prevState);
  };

  const [contextData, setContextData, { history, pointer, back, forward }] = useStateHistory(
    { pageNodes: [], blockNodes: [], widgets: [], backgroundColors: [], backgroundImages: [], audios: [] },
    { docType: documentType }
  );

  const updateContext = (
    key,
    value,
    optionalPageData = null,
    optionalBlockData,
    optionalBgcolorData,
    optionalBgImageData,
    optionalActiveBlockIndex,
    currentDimension,
    resizedStatus,
    currentDocumentType,
    optionalAudioData
  ) => {
    switch (key) {
      case "page":
        break;

      case "block":
        setContextData(() => {
          return {
            type: "blockNodes",
            value: value,
            optionalPageData: optionalPageData,
            optionalActiveBlockIndex: optionalActiveBlockIndex,
            currentDimension: currentDimension,
            currentDocumentType: currentDocumentType,
          };
        });

        break;

      case "widget":
        setContextData(() => {
          return {
            type: "widgets",
            value: value,
            optionalPageData: optionalPageData,
            optionalBlockData: optionalBlockData,
            optionalBgcolorData: optionalBgcolorData,
            optionalBgImageData: optionalBgImageData,
            optionalActiveBlockIndex: optionalActiveBlockIndex,
            currentDimension: currentDimension,
            resizedStatus: resizedStatus,
            currentDocumentType: currentDocumentType,
            optionalAudioData: optionalAudioData,
          };
        });

        break;

      case "bgcolor":
        setContextData(() => {
          return { type: "backgroundColors", value: value, currentDimension: currentDimension };
        });

        break;

      case "bgimage":
        setContextData(() => {
          return { type: "backgroundImages", value: value, currentDimension: currentDimension };
        });

        break;

      case "audio":
        setContextData(() => {
          return { type: "audios", value: value, currentDimension: currentDimension };
        });

        break;

      default:
        console.warn("key not in acceptable values");
        break;
    }
  };

  // Update brand inforamtion when brandInfo changes
  useEffect(() => {
    if (brandInfo) setBrandInfo(brandInfo);
  }, [brandInfo]);

  return (
    <EditorContext.Provider
      value={{
        templateGroupName,
        updateTemplateGroupName: data => setTemplateGroupName(data),
        documentType,
        updateDocumentType: data => setDocumentType(data),
        ...contextData,
        updatePageNodes: data => {
          updateContext("page", data);
        },
        updateBlockNodes: (blockData, pageData = null, activeBlockIdx) => {
          updateContext("block", blockData, pageData, false, false, false, activeBlockIdx, dimension);
        },
        updateWidgets: (
          widgetData,
          pageData,
          blockData,
          bgcolorData,
          bgImageData,
          activeBlockIdx,
          curdimension,
          resizedStatus,
          currentDocumentType,
          audioData
        ) => {
          let optionalDimension = curdimension ? curdimension : dimension;
          let docType = currentDocumentType ? currentDocumentType : documentType;
          updateContext(
            "widget",
            widgetData,
            pageData,
            blockData,
            bgcolorData,
            bgImageData,
            activeBlockIdx,
            optionalDimension,
            resizedStatus,
            docType,
            audioData
          );
        },
        updateBackgroundColors: data => {
          updateContext("bgcolor", data, false, false, false, false, false, dimension);
        },
        updateBackgroundImages: data => {
          updateContext("bgimage", data, false, false, false, false, false, dimension);
        },
        setAudios: audioData => {
          updateContext("audio", audioData, false, false, false, false, false, dimension);
        },
        metadata,
        updateMetadata: data => setmetadata(data),
        grid,
        updateGrid: data => setgrid(data),
        pageMargin,
        updatePageMargin: data => setPageMargin(data),
        dimension,
        updateDimension: data => setdimension(data),
        pageSorterList,
        updatePageSorterList: data => setpageSorterList(data),
        ignoreNextScrollEvent,
        updateScrollEvent: data => setIgnoreNextScrollEvent(data),
        clipboardObj,
        updateClipboardObj: data => setClipboardObj(data),
        keyBoardEvent,
        updateKeyBoardEvent: data => setKeyBoardEvent(data),
        widgetHandler,
        updateWidgetHandler: data => setWidgetHandler(data),
        rightContextMenu,
        updateRightContextMenu: data => setRightContextMenu(data),
        transitionAppliedAllPages,
        updateTransitionAppliedAllPages: data => setTransitionAppliedAllPages(data),
        doubleClickActive,
        updateDoubleClickActive: data => setDoubleClickActive(data),
        copyStyle,
        updateCopyStyle: data => setCopyStyle(data),
        snapGrid,
        updateSnapGrid: data => setSnapGrid(data),
        backgroundCloning,
        updateBackgroundCloning: data => setBackgroundCloning(data),
        guideLine,
        updateGuideLine: data => setGuideLine(data),
        isDocumentSave,
        setIsDocumentSave,
        togglePlayButton,
        updateTogglePlayButton: data => setTogglePlayButton(data),
        undo: back,
        redo: forward,
        HistoryList: history,
        currentPointerPos: pointer,
        handleWidgetAction: (p1, p2, p3, p4, p5, p6, p7) => handleWidgetAction(p1, p2, p3, p4, p5, p6, p7),
        isUploadDone,
        setisUploadDone: data => setisUploadDone(data),
        isDocumentReady,
        setIsDocumentReady,
        socketState,
        updateSocketState: data => setSocketState(data),
        documentName,
        updateDocumentName: data => setDocumentName(data),
        changeBySocket,
        setChangeBySocket,
        selectionRange,
        setSelectionRange,
        textSelectedAreaColor,
        setTextSelectedAreaColor,
        doubleClickSelctedareaColor,
        setDoubleClickSelctedareaColor,
        previousSelection,
        setPreviousSelection,
        activeColorThemePageIdx,
        setActiveColorThemePageIdx,
        mapTooltip,
        updateEditorMapTooltip: data => setMapTooltip(data),
        collageData,
        updateCollageData: data => setCollageData(data),
        textFontSize,
        setTextFontSize,
        isTimeLineViewOpen,
        setIsTimeLineViewOpen,
        animationObj,
        setAnimationObj,
        showFindAndReplace,
        setShowFindAndReplace,
        activeWidgetFromTimeLine,
        setActiveWidgetFromTimeLine,
        uploadSchemeType,
        setUploadSchemeType: data => setUploadSchemeType(data),
        retainRotation,
        setRetainRotation,
        actionOnRotateModal,
        setActionOnRotateModal,
        whichPagePlaying,
        setWhichPagePlaying,
        pagePlayData,
        setPagePlayData,
        documentPlayData,
        setDocumentPlayData,
        playingPageIdx,
        setPlayingPageIdx,
        isVideoTrimApplied,
        setIsVideoTrimApplied,
      }}>
      <>
        {parseInt(dimension?.width) > 0 && parseInt(dimension.height) > 0 && (
          <>
            <EditorSidebar
              isOpen={isOpen}
              handleWidgetAction={handleWidgetAction}
              widgetActionClicked={widgetActionClicked}
              returnFromAsset={returnFromAsset}
              activeAssetName={activeAssetName}
              activeAssetType={activeAssetType}
              groupIndex={groupIndex}
              activeAssetSchemeType={activeAssetSchemeType}
              setFitToScreen={setFitToScreen}
            />

            <div
              className={cx(style["dhp-contentArea"], style["clearfix"], style["overflow-hidden"], {
                [style["open-timeline-wrap"]]: isTimeLineViewOpen,
                [style["hide-timeline-wrap"]]: !isTimeLineViewOpen,
                [style["pe-none"]]: !isDocumentReady,
                [style["small-document-time-line"]]:
                  (contextData.audios?.length === 0 || !contextData.audios) &&
                  isTimeLineViewOpen === "document-timeline",
                [style["video-enable"]]:
                  userInfo.video_enabled && ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType),
              })}
              onMouseDown={e =>
                deactivateCollageEvent({
                  e,
                  actionName: "SAVE",
                  collageDataRef: collageData,
                  updateCollageDataRef: setCollageData,
                  widgetsRef: contextData.widgets,
                  updateWidgetsRef: data => updateContext("widget", data),
                })
              }>
              <div className={style["editor-wrapper"]}>
                <EditorTopbar
                  handleWidgetAction={handleWidgetAction}
                  toggle={toggle}
                  fitToScreen={fitToScreen}
                  setFitToScreen={setFitToScreen}
                />

                <EditorZoomNavigationPannel fitToScreen={fitToScreen} setFitToScreen={setFitToScreen} />

                {contextData.audios?.length > 0 && !isTimeLineViewOpen && (
                  <div
                    className={cx(style["editor-audio"], style["rounded"], {
                      [style["disable"]]: playingPageIdx !== null,
                    })}
                    onClick={() => {
                      setmetadata({
                        ...metadata,
                        activeWidgetId: false,
                        activeWidgetType: false,
                      });
                      setIsTimeLineViewOpen("document-timeline");
                      document.querySelector(".canvas-controller").style.display = "none";
                      if (document.getElementById("active-page-selctor"))
                        document.getElementById("active-page-selctor").style.display = "none";

                      setFitToScreen(true);

                      setTimeout(() => {
                        setmetadata({
                          ...metadata,
                          activeWidgetId: false,
                          activeWidgetType: false,
                          pageController: {
                            ...metadata.pageController,
                            style: { ...metadata.pageController.style, top: 0 + "px" },
                          },
                        });
                        document.querySelector(".canvas-controller").style.display = "block";
                        if (document.getElementById("active-page-selctor"))
                          document.getElementById("active-page-selctor").style.display = "block";
                      }, 500);
                    }}>
                    <div className={style["action"]}>
                      <Icon icon="ui-audio"></Icon>
                      <span className={style["filter-count"]}>{contextData.audios?.length}</span>
                    </div>
                  </div>
                )}

                <EditorCanvasPanel
                  toggle={toggle}
                  documentDetails={documentDetails}
                  fitToScreen={fitToScreen}
                  setFitToScreen={setFitToScreen}
                />

                {/* Display parsing time loader */}
                {!isDocumentReady && isDocumentParsable && (
                  <div className={style["parser-loader"]}>
                    <img src={parserLoaderSvg} />
                  </div>
                )}
              </div>

              {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && userInfo.video_enabled && (
                <VideoTimeline setFitToScreen={setFitToScreen} toggle={toggle} />
              )}
            </div>

            {/* Display when drag widget from left pane */}
            <div
              id="drag-path-display"
              className={cx(style["drag-path-display"], style["d-none"])}
              onContextMenu={e => e.preventDefault()}></div>

            {/* display when Right Click on editor */}
            {rightContextMenu.enable && <RightContextMenubar />}

            {/* Display map info tooltip on mouseMove */}
            {mapTooltip.enable && <EditorMapInfoTooltip />}
          </>
        )}

        {(!parseInt(dimension?.width) || !parseInt(dimension?.height)) && <EditorLoader />}

        {showModal && (
          <Modal
            size="xl"
            toggle={toggleModal}
            showModal={showModal}
            component={TableModalComponent}
            setShowModal={setShowModal}
            closeModal={toggleModal}
            backdrop="static"
            modalClassName={
              modalFullScreen ? "tabel-modal modal-with-leftpane full-screen-modal" : "tabel-modal modal-with-leftpane"
            }
            modalFullScreen={modalFullScreen}
            setModalFullScreen={setModalFullScreen}
          />
        )}
        {showPageResizeModal && (
          <PageResizeComponent showModal={showPageResizeModal} setShowModal={setShowPageResizeModal} />
        )}
        {showMapModal && (
          <Modal
            size="xl"
            toggle={toggleMapModal}
            showModal={showMapModal}
            component={Map}
            setShowModal={setShowMapModal}
            closeModal={toggleMapModal}
            backdrop="static"
            modalClassName={
              modalFullScreen
                ? "tabel-modal modal-with-leftpane full-screen-modal map-modal"
                : "tabel-modal modal-with-leftpane map-modal"
            }
            modalFullScreen={modalFullScreen}
            setModalFullScreen={setModalFullScreen}
          />
        )}
      </>
    </EditorContext.Provider>
  );
};

export default EditorLayout;
