import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { documentInfo } from "../components/Document/_utils";
import {
  APP_HEARTBEAT,
  COMPANY_DELETE_AFTER,
  COMPANY_SWITCH_AFTER,
  CUSTOM_FONTS_UPDATE_AFTER,
  DOCUMENT_SAVE_AFTER,
  JOIN_COMPANY_ROOM,
  PUBLISH_DOCUMENT_AFTER,
  PUBLISH_DOCUMENT_DOWNLOAD_AFTER,
  PUBLISH_DOCUMENT_PASSWORD_AFTER,
  SESSION_TERMINATE_AFTER,
  STREAMING_SUCCESS,
  UNPUBLISH_DOCUMENT_AFTER,
  USER_DEACTIVATE_AFTER,
  USER_DELETE_AFTER,
  USER_INFO_UPDATE_AFTER,
} from "../constants/socket";
import { clearCurrentUserSession, getUserInfo, logout } from "../store/actions/authActions";
import { fetchCompany, getUserList, switchCompany } from "../store/actions/companyActions";
import { fileDownload } from "../_helpers/utils";
import { fetchAllCustomFonts } from "../store/actions/resourcesActions";

const useAcceptSocketEmit = (webSocket, setIsSaveInProgress, documentWS) => {
  const dispatch = useDispatch();

  const authState = useSelector(state => state?.auth);
  const joinCompany = companyId => {
    const subscribe = { type: JOIN_COMPANY_ROOM, companyId, userId: authState?.uid };
    webSocket.send(JSON.stringify(subscribe));
  };

  const urlRedirection = (url = "/") => {
    window.location.replace(url);
  };

  // const downloadFile = (url, fileName) => {
  //   const iframe = document.createElement("iframe");
  //   iframe.src = "about:blank";
  //   iframe.name = "dh-download";
  //   iframe.style.border = 0;
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", fileName);
  //   link.target = "dh-download";

  //   // Append to html link element page
  //   document.body.appendChild(iframe);
  //   document.body.appendChild(link);

  //   // Start download
  //   link.click();

  //   // Clean up and remove the link
  //   setTimeout(() => {
  //     document.body.removeChild(iframe);
  //     document.body.removeChild(link);
  //   }, 1000 * 3);
  // };

  const switchActiveCompany = (isFetchComp = true) => {
    dispatch(getUserInfo()).then(resp => {
      if (isFetchComp) dispatch(fetchCompany());
      dispatch(switchCompany(resp.userInfo.company.id)).then(() => {
        urlRedirection("/templates");
      });
    });
  };

  const onMessage = useCallback(message => {
    const response = JSON.parse(message?.data);
    switch (response.type) {
      case APP_HEARTBEAT:
        console.log(`Heartbeat --> ${response.status}`);
        break;

      case COMPANY_SWITCH_AFTER:
        dispatch(switchCompany(response.companyId)).then(res => {
          // Disabled redirection for public checkout only
          if (res?.result?.id && window.location.pathname !== "/checkout") {
            dispatch(fetchCompany());
            dispatch(getUserInfo()).then(() => {
              urlRedirection("/templates");
            });
          }
        });
        break;

      case COMPANY_DELETE_AFTER:
        dispatch(fetchCompany()).then(res => {
          if (res.result.success || res.result.company_create) {
            switchActiveCompany(false);
          }
        });
        break;

      case PUBLISH_DOCUMENT_AFTER:
        documentInfo.set(response.data, { publish_id: response.publish_id });
        break;

      case UNPUBLISH_DOCUMENT_AFTER:
        documentInfo.set(response.data, {
          publish_id: response.publish_id,
        });
        documentInfo.set(response.data, {
          password: response.password,
        });
        documentInfo.set(response.data, {
          download: response.download,
        });
        break;

      case PUBLISH_DOCUMENT_PASSWORD_AFTER:
        documentInfo.set(response.data, {
          password: response.password,
        });
        break;

      case PUBLISH_DOCUMENT_DOWNLOAD_AFTER:
        documentInfo.set(response.data, {
          download: response.download,
        });
        break;

      case STREAMING_SUCCESS:
        fileDownload(response.downloadUrl, `${response.fileName}.${response.fileType}`);
        break;

      case SESSION_TERMINATE_AFTER:
        dispatch(logout());
        dispatch(clearCurrentUserSession());
        window.location.href = "/";
        break;

      case USER_DEACTIVATE_AFTER:
        let userList = response.users || [];
        if (userList.includes(authState?.uid)) switchActiveCompany();
        break;

      case USER_DELETE_AFTER:
        let deletedUserList = response.users || [];
        if (deletedUserList.includes(authState?.uid)) switchActiveCompany();
        break;

      case DOCUMENT_SAVE_AFTER:
        setTimeout(() => setIsSaveInProgress(false), 1000);
        break;

      case USER_INFO_UPDATE_AFTER:
        dispatch(getUserList());
        dispatch(getUserInfo());
        break;

      case CUSTOM_FONTS_UPDATE_AFTER:
        if (window.location.href.indexOf("/settings/fonts") === -1) dispatch(fetchAllCustomFonts(response.companyId));
        break;

      default:
        break;
    }
  }, []);

  // Below fn for set save process done
  const onMessageDocSave = useCallback(message => {
    const response = JSON.parse(message?.data);
    switch (response.type) {
      case DOCUMENT_SAVE_AFTER:
        setIsSaveInProgress(false);
        break;

      default:
        break;
    }
  }, []);

  // connet to active company collaboration room
  useEffect(() => {
    if (authState?.user?.company?.id && webSocket?.readyState === 1) {
      joinCompany(authState?.user?.company?.id);
    }
  }, [authState?.user?.company?.id, webSocket?.readyState]);

  // msg listner added
  useEffect(() => {
    // Event listner for default websocket
    if (webSocket?.readyState === 1) webSocket.addEventListener("message", onMessage);

    // Event listner for document save websocket
    if (documentWS?.readyState === 1) documentWS.addEventListener("message", onMessageDocSave);

    return () => {
      webSocket?.removeEventListener("message", onMessage);
      documentWS?.removeEventListener("message", onMessageDocSave);
    };
  }, [webSocket?.readyState, documentWS?.readyState]);
};

export default useAcceptSocketEmit;
