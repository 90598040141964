import { useContext } from "react";
import {
  TEXT,
  TEXT_FRAME,
  LINE,
  SHAPE,
  ICON,
  STICKER,
  ILLUSTRATION,
  PICTURE,
  UPLOAD,
  VIDEO,
  ANIMATION,
  TABLE,
  GROUP_WIDGET,
  STOCK,
  LIBRARY,
  TYPE_INFOGRAPHIC,
} from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  camelCaseToDash,
  dashToCamelCase,
  getCssTextObj,
  getCssTransformObj,
  getImgDimensionFromUrl,
  getNodeHeightAtRuntime,
  getWidgetInnerFileType,
  hexToRGB,
  removeWhiteSpaceBetweenTags,
  RGBToHex,
} from "../_helpers/utils";

// constants:::: internal reference
const PAGE = "page";
const BLOCK = "block";
const WIDGET = "widget";
const BG_COLOR = "bg_color";
const BG_IMAGE = "bg_image";

const HTML = "html";
const META = "meta";

// constants:::: context refernce
const ID = "id";
const STYLE = "style";
const DATA = "data";
const CLASS = "classLists";
const PAGE_ID = "pageId";
const PAGE_IDX = "pageIdx";
const PAGE_DURATION = "pageDuration";
const PAGE_TRANSITION = "pageTransition";
const PAGE_DIMENSION = "pageDimension";
const BLOCK_ID = "blockId";
const BLOCK_IDX = "blockIdx";
const INNER_HTML = "innerHTML";

const CONTEXT_OBJ_FOR_HTML = {
  pageNodes: [],
  blockNodes: [],
  widgets: [],
  backgroundColors: [],
  backgroundImages: [],
};

const TRANSITION = "transition";
const DIMENSION = "dimension";
const MARGIN = "margin";
const GRID = "grid";

const useDocumentParser = () => {
  const { dimension } = useContext(EditorContext);

  const _parserEngine = {
    meta: {
      selector: {
        [PAGE]: ".dhp-page-canvas",
        [BLOCK]: ".dhp-page-block",
        [WIDGET]: ".dhp-root-widget",
        [BG_COLOR]: ".dhp-page-bgcolor-overlay",
        [BG_IMAGE]: ".dhp-page-overlay",
      },
      required: {
        [PAGE]: [ID, STYLE],
        [BLOCK]: [ID, STYLE, PAGE_ID],
        [WIDGET]: [ID, STYLE, DATA, CLASS, PAGE_ID, BLOCK_ID, INNER_HTML],
        [BG_COLOR]: [STYLE, PAGE_ID, BLOCK_ID],
        [BG_IMAGE]: [STYLE, PAGE_ID, BLOCK_ID],
      },
      idMap: {
        [PAGE]: PAGE_ID,
        [BLOCK]: BLOCK_ID,
        [WIDGET]: ID,
      },
      widgetToAssetMap: {
        ["TEXT"]: TEXT,
        ["TEXT-FRAME"]: TEXT_FRAME,
        ["LINE-SHAPE"]: LINE,
        ["SHAPE"]: SHAPE,
        ["ICON"]: ICON,
        ["STICKER"]: STICKER,
        ["ILLUSTRATION"]: ILLUSTRATION,
        ["PICTURE"]: PICTURE,
        ["UPLOAD"]: UPLOAD,
        ["MEDIA"]: VIDEO,
        ["ANIMATION"]: ANIMATION,
        ["TABLE"]: TABLE,
        ["GROUP"]: GROUP_WIDGET,
      },
      // innerHtmlConversionRequired: [TEXT, PICTURE, UPLOAD, VIDEO, TABLE],
      transitionMap: [
        {
          name: "Fast",
          value: 0.5,
          oldValue: [1, 2],
        },
        {
          name: "Medium",
          value: 1,
          oldValue: [3, 4],
        },
        {
          name: "Slow",
          value: 2,
          oldValue: [5, 6],
        },
      ],
      exclude: {
        // [STYLE]: ["backface-visibility"],
        // [DATA]: ["data-color-scheme"],
        // [CLASS]: ["dhp-page-widget"],
        [STYLE]: ["cursor"],
        [DATA]: [],
        [CLASS]: [],
        [META]: [
          "header",
          "footer",
          "diffheader",
          "difffooter",
          // "fonts",
          // "durations",
          "copiedBrowserItem",
          "canvasBackgroundColorWhite",
        ],
      },
      [PAGE_DURATION]: "5.0s",
      [PAGE_TRANSITION]: false,
      [PAGE_DIMENSION]: false,
      documentType: "",
      dimension: {
        zoom: 100,
        height: 0,
      },
    },

    parseWidgetInnerHtml: {
      getSandBoxElement: ({ html }) => {
        const sandBox = document.createElement("div");
        sandBox.innerHTML = html;

        return sandBox.childElementCount === 1
          ? sandBox.childNodes[0]
          : sandBox.querySelector(".dhp-widget-inner") ?? sandBox.querySelector(".flippable");
      },

      svgColorBucketAdjustments: ({ svgParentNode }) => {
        svgParentNode.querySelectorAll(`svg *`).forEach(node => {
          const currentIconColor = node?.style?.fill ? RGBToHex(node.style.fill) : "";
          if (
            !["g", "defs"].includes(node.nodeName) &&
            !["defs", "linearGradient"].includes(node.parentNode.nodeName) &&
            currentIconColor !== ""
          ) {
            node.dataset.class = `${currentIconColor}body`;
          }
        });
      },

      parseSingleWidgetInnerHtml: ({ widgetNode, props }) => {
        const { assetType, fileType, fileNode, hasCropToShape, widgetAutoHeightConverted } = props;
        const innerHtmlNode = _parserEngine.parseWidgetInnerHtml.getSandBoxElement({ html: widgetNode.innerHTML });
        const widgetInnerNode = innerHtmlNode.classList.contains("dhp-widget-inner")
          ? innerHtmlNode
          : innerHtmlNode.querySelector(".dhp-widget-inner");
        const widgetOpacity = widgetInnerNode.style.opacity || 1;

        // generic
        // widgetInnerNode.style.opacity = "";
        widgetInnerNode.style.opacity = widgetOpacity;

        if (assetType === TEXT) {
          const textShadow = innerHtmlNode.style.textShadow;
          innerHtmlNode.style.transform = "";
          innerHtmlNode.style.transformOrigin = "center";

          if (!innerHtmlNode.style.color) {
            innerHtmlNode.style.color = "rgb(0, 0, 0)";
          }

          if (textShadow) {
            const [_, horaizentalShadow, verticalShadow, blur] = textShadow.split(" ");

            innerHtmlNode.style.filter =
              parseInt(horaizentalShadow) === 0 && parseInt(verticalShadow) === 0 && parseInt(blur) === 0
                ? "none"
                : `drop-shadow(${textShadow})`;

            innerHtmlNode.style.textShadow = "";
          }
        }

        if (assetType === LINE) {
          innerHtmlNode.classList.remove("line-v-align-top");

          // converting old line widgets to uploaded svg widgets (color bucket adjustments)
          // if (widgetNode.dataset.version !== "2.0") {
          if (parseFloat(widgetNode.dataset.version ?? 1) < 2) {
            _parserEngine.parseWidgetInnerHtml.svgColorBucketAdjustments({ svgParentNode: innerHtmlNode });
          }
        }

        if ([PICTURE, UPLOAD].includes(assetType) && fileType !== "svg") {
          const flippableNode = innerHtmlNode.classList.contains("flippable")
            ? innerHtmlNode
            : innerHtmlNode.querySelector(".flippable");

          if (hasCropToShape) {
            const config = {
              firstChild: {
                css: `width: ${widgetNode.style.width}; height: ${widgetAutoHeightConverted}; overflow: hidden; transform: ${flippableNode.style.transform};`,
                data: false,
              },
              secondChild: {
                css: `display: block; width: 210px; height: 210px; transform: ${widgetInnerNode.style.transform}; transform-origin: left top; clip-path: ${widgetInnerNode.style.clipPath}; opacity: ${widgetOpacity}`,
                data: {
                  horizontal: flippableNode.getAttribute("data-horizontal") || 1,
                  vertical: flippableNode.getAttribute("data-vertical") || 1,
                  top: parseFloat(widgetInnerNode.getAttribute("data-top")) + "px",
                  left: parseFloat(widgetInnerNode.getAttribute("data-left")) + "px",
                  width: parseFloat(widgetInnerNode.getAttribute("data-width")),
                  height: parseFloat(widgetInnerNode.getAttribute("data-height")),
                  clipPath: widgetInnerNode.style.clipPath,
                },
              },
              thirdChild: {
                css: `${fileNode.style.cssText}`,
                data: {
                  filter: fileNode.getAttribute("data-filter") || "normal",
                  filterType: fileNode.getAttribute("data-filter-type") || "default",
                },
                props: {
                  src: fileNode.getAttribute("src"),
                },
              },
            };

            const {
              firstChild: { css: firstChildCss },
              secondChild: { css: secondChildCss, data: secondChildData },
              thirdChild: { css: thirdChildCss, data: thirdChildData, props: thirdChildProps },
            } = config;

            const firstChildStyle = getCssTextObj(firstChildCss, true);
            const secondChildStyle = getCssTextObj(secondChildCss, true);
            const thirdChildStyle = getCssTextObj(thirdChildCss, true);

            return removeWhiteSpaceBetweenTags(`
              <div class="flippable" style="${firstChildStyle}">
                <div 
                  class="dhp-widget-inner target-image-wrap" 
                  style="${secondChildStyle}"
                  data-horizontal="${secondChildData.horizontal}" 
                  data-vertical="${secondChildData.vertical}"
                  data-top="${secondChildData.top}"
                  data-left="${secondChildData.left}"
                  data-width="${secondChildData.width}"
                  data-height="${secondChildData.height}"
                  data-clip-path="${secondChildData.clipPath}"
                  initial-data-width="${secondChildData.width}px"
                  initial-data-height="${secondChildData.height}px">
                    <img 
                      src="${thirdChildProps.src}" 
                      style="${thirdChildStyle}" 
                      data-filter="${thirdChildData.filter}"
                      data-filter-type="${thirdChildData.filterType}">
                </div>
              </div>
            `);
          } else {
            const config = {
              firstChild: {
                css: `width: ${widgetNode.style.width}; height: ${widgetAutoHeightConverted}; overflow: hidden;`,
                data: false,
              },
              secondChild: {
                css: `display: block; width: ${widgetNode.style.width}; height: ${widgetAutoHeightConverted}; transform: translate(0px, 0px) scale(1, 1); transform-origin: left top; opacity: ${widgetOpacity}`,
                data: {
                  horizontal: widgetInnerNode.getAttribute("data-horizontal") || 1,
                  vertical: widgetInnerNode.getAttribute("data-vertical") || 1,
                },
              },
              thirdChild: {
                css: `display: block; width: 100%; height: 100%; transform: ${widgetInnerNode.style.transform}; filter: ${widgetInnerNode.style.filter}`,
                data: {
                  filter: fileNode.getAttribute("data-filter") || "normal",
                  filterType: fileNode.getAttribute("data-filter-type") || "default",
                },
                props: {
                  src: fileNode.getAttribute("src"),
                },
              },
            };

            const {
              firstChild: { css: firstChildCss },
              secondChild: { css: secondChildCss, data: secondChildData },
              thirdChild: { css: thirdChildCss, data: thirdChildData, props: thirdChildProps },
            } = config;

            const firstChildStyle = getCssTextObj(firstChildCss, true);
            const secondChildStyle = getCssTextObj(secondChildCss, true);
            const thirdChildStyle = getCssTextObj(thirdChildCss, true);

            return removeWhiteSpaceBetweenTags(`
              <div class="flippable" style="${firstChildStyle}">
                <div 
                  class="dhp-widget-inner" 
                  style="${secondChildStyle}" 
                  data-horizontal="${secondChildData.horizontal}" 
                  data-vertical="${secondChildData.vertical}">
                    <img 
                      src="${thirdChildProps.src}" 
                      style="${thirdChildStyle}" 
                      data-filter="${thirdChildData.filter}"
                      data-filter-type="${thirdChildData.filterType}">
                </div>
              </div>
            `);
          }
        }

        if (assetType === VIDEO && widgetNode.dataset.source === LIBRARY) {
          const config = {
            firstChild: {
              css: `width: ${widgetNode.style.width}; height: ${widgetNode.style.height}; overflow: hidden;`,
              data: false,
            },
            secondChild: {
              css: `display: block; width: ${widgetNode.style.width}; height: ${widgetNode.style.height}; transform: translate(0px, 0px) scale(1, 1); transform-origin: left top; opacity: ${widgetOpacity}`,
              data: false,
            },
            thirdChild: {
              css: `display: block; width: 100%; height: 100%; object-fit: cover;`,
              data: false,
              props: {
                poster: fileNode.getAttribute("poster"),
                src: fileNode.getAttribute("src"),
                muted: fileNode.getAttribute("muted"),
                playsinline: fileNode.getAttribute("playsinline"),
                disablepictureinpicture: fileNode.getAttribute("disablepictureinpicture"),
                preload: fileNode.getAttribute("preload"),
              },
            },
          };

          const {
            firstChild: { css: firstChildCss },
            secondChild: { css: secondChildCss },
            thirdChild: { css: thirdChildCss, props: thirdChildProps },
          } = config;

          const firstChildStyle = getCssTextObj(firstChildCss, true);
          const secondChildStyle = getCssTextObj(secondChildCss, true);
          const thirdChildStyle = getCssTextObj(thirdChildCss, true);

          return removeWhiteSpaceBetweenTags(`
            <div class="flippable" style="${firstChildStyle}">
              <div 
                class="dhp-widget-inner" 
                style="${secondChildStyle}">
                  <video
                      poster="${thirdChildProps.poster}"
                      src="${thirdChildProps.src}"
                      muted="${thirdChildProps.muted}"
                      playsinline="${thirdChildProps.playsinline}"
                      disablepictureinpicture="${thirdChildProps.disablepictureinpicture}"
                      preload="${thirdChildProps.preload}"
                      style="${thirdChildStyle}">
                  </video>
              </div>
            </div>
          `);
        }

        if (assetType === ANIMATION) {
          const config = {
            firstChild: false,
            secondChild: {
              css: `display: block; width: 100%; height: 100%; transform: translate(0px, 0px) scale(1, 1); transform-origin: left top; opacity: ${widgetOpacity}`,
              data: {
                horizontal: widgetInnerNode.getAttribute("data-horizontal") || 1,
                vertical: widgetInnerNode.getAttribute("data-vertical") || 1,
              },
            },
            thirdChild: {
              css: `display: block; width: 100%; height: 100%; transform: ${widgetInnerNode.style.transform}; filter: ${widgetInnerNode.style.filter}`,
              data: {
                filter: fileNode.getAttribute("data-filter") || "normal",
                filterType: fileNode.getAttribute("data-filter-type") || "default",
              },
              props: {
                src: fileNode.getAttribute("src"),
              },
            },
          };

          const {
            secondChild: { css: secondChildCss, data: secondChildData },
            thirdChild: { css: thirdChildCss, data: thirdChildData, props: thirdChildProps },
          } = config;

          const secondChildStyle = getCssTextObj(secondChildCss, true);
          const thirdChildStyle = getCssTextObj(thirdChildCss, true);

          return removeWhiteSpaceBetweenTags(`
            <div class="flippable">
              <div 
                class="dhp-widget-inner" 
                style="${secondChildStyle}"
                data-horizontal="${secondChildData.horizontal}" 
                data-vertical="${secondChildData.vertical}">
                  <img 
                    src="${thirdChildProps.src}" 
                    style="${thirdChildStyle}"
                    data-filter="${thirdChildData.filter}"
                    data-filter-type="${thirdChildData.filterType}">
              </div>
            </div>
          `);
        }

        if (assetType === TABLE) {
          const tableCanvasWrapperNode = innerHtmlNode.querySelector(".table-canvas-wraper");
          const tableNode = innerHtmlNode.querySelector("table");
          const tableTransformObj = getCssTransformObj({ transform: tableNode.style.transform });
          const scaleFactor = parseFloat(tableTransformObj.scale.x);
          const scaledWidth = parseFloat(widgetNode.style.width) / scaleFactor;
          const scaledHeight = parseFloat(widgetNode.style.height) / scaleFactor;

          innerHtmlNode.style.cssText = `
            width: ${scaledWidth}px;
            height: ${scaledHeight}px;
            transform: scale(${scaleFactor}); 
            transform-origin: left top;
            opacity: ${widgetOpacity};
          `;

          tableNode.style.transform = "";
          tableNode.setAttribute("width", scaledWidth);
          tableNode.setAttribute("height", scaledHeight);
          innerHtmlNode.appendChild(tableNode);
          tableCanvasWrapperNode.remove();
        }

        // color bucket adjustments for applicable svg type widgets
        if (fileType === "svg" && [ICON, STICKER, ILLUSTRATION, UPLOAD].includes(assetType)) {
          _parserEngine.parseWidgetInnerHtml.svgColorBucketAdjustments({ svgParentNode: innerHtmlNode });
        }

        // for shape (as mono color)
        if (assetType === SHAPE) {
          let fillColor;
          innerHtmlNode.querySelectorAll(`svg *`).forEach(node => {
            const fill = node?.style?.fill ?? "";
            if (fill !== "") {
              node.style.fill = "";
              if (!fillColor) fillColor = fill === "none" ? "transparent" : RGBToHex(fill);
            }
          });
          if (fillColor) innerHtmlNode.style.fill = fillColor;
          else innerHtmlNode.style.fill = "rgb(0, 0, 0)";
        }

        return innerHtmlNode.outerHTML;
      },

      parseGroupWidgetInnerHtml: ({ widgetNode }) => {
        return widgetNode.innerHTML;
      },

      start: ({ node: widgetNode, props, returnStr = "" }) => {
        returnStr =
          props.assetType === GROUP_WIDGET
            ? _parserEngine.parseWidgetInnerHtml.parseGroupWidgetInnerHtml({ widgetNode })
            : _parserEngine.parseWidgetInnerHtml.parseSingleWidgetInnerHtml({ widgetNode, props });

        return returnStr;
      },
    },

    parseHtml: {
      getSandBox: ({ html }) => {
        const sandBox = document.createElement("div");
        sandBox.innerHTML = html;
        return sandBox;
      },

      formatter: async ({ node, component, type, props, returnObj = {}, returnArr = [], returnStr = "" }) => {
        const { assetType, fileType, hasCropToShape, widgetInnerNode, widgetAutoHeightConverted } = props;
        const excludeList = _parserEngine.meta.exclude[type];

        if (type === STYLE) {
          const dataStr = node.style.cssText;
          let left, top, transform;

          dataStr.split(";")?.forEach(e => {
            if (e.trim() !== "") {
              const [first, ...rest] = e.trim().split(":");
              const key = first.trim();
              const val = rest.join(":").trim();

              if (key !== "" && val !== "") {
                if (key === "left") left = val;
                else if (key === "top") top = val;
                else if (key === "transform") transform = val;
                else {
                  if (!excludeList.includes(key)) {
                    const camelKey = dashToCamelCase(key);
                    returnObj[camelKey] = val;
                  }
                }
              }
            }
          });

          if (component === PAGE) {
            returnObj.height = _parserEngine.meta[PAGE_DIMENSION]?.height + "px";
          }

          if (component === BLOCK) {
            returnObj.transformOrigin = "0px 0px";
          }

          if (component === WIDGET) {
            // returnObj.opacity = widgetInnerNode.style.opacity || 1;
            returnObj.opacity = 1;

            returnObj.transform = getCssTransformObj({
              translateX: left,
              translateY: top,
              rotateAngle: transform?.includes("matrix") && node.dataset.value && parseInt(node.dataset.value) + "deg",
              transform,
              returnStr: true,
            });

            if (
              (["auto", ""].includes(returnObj.height) || parseFloat(returnObj.height) === 0) &&
              fileType === "svg" &&
              [TEXT_FRAME, LINE, SHAPE, ICON, STICKER, ILLUSTRATION, UPLOAD].includes(assetType)
            ) {
              const widgetWidth = parseFloat(node.style.width);
              const svgNode = node.querySelector("svg");
              const viewBox = svgNode.viewBox.baseVal;
              const scaleFactor = widgetWidth / viewBox.width;
              returnObj.height = viewBox.height * scaleFactor;
            }

            if (
              (["auto", ""].includes(returnObj.height) || parseFloat(returnObj.height) === 0) &&
              fileType !== "svg" &&
              [PICTURE, UPLOAD, ANIMATION].includes(assetType)
            ) {
              returnObj.height = widgetAutoHeightConverted;
            }

            if (assetType === TEXT) {
              // correction for text widget height in past
              const widgetOffsetHeight = getNodeHeightAtRuntime(node);
              returnObj.height = widgetOffsetHeight;
            }
          }

          if (component === BG_COLOR) {
            returnObj.backgroundColor = RGBToHex(returnObj.backgroundColor);
            returnObj.top = "0px";
            returnObj.left = "0px";
            returnObj.right = "0px";
            returnObj.bottom = "0px";
          }

          if (component === BG_IMAGE) {
            returnObj.top = "0px";
            returnObj.left = "0px";
            returnObj.right = "0px";
            returnObj.bottom = "0px";
          }

          return returnObj;
        }

        if (type === DATA) {
          const dataObj = JSON.parse(JSON.stringify(node.dataset));

          Object.keys(dataObj)?.forEach(e => {
            const dataAttr = camelCaseToDash(e);
            const key = `data-${dataAttr}`;
            const val = dataObj[e];

            if (!excludeList.includes(key)) {
              returnObj[key] = val === "true" ? true : val === "false" ? false : val;
            }
          });

          if (component === WIDGET) {
            // generic
            returnObj["data-asset-type"] = assetType;
            returnObj["data-x-allignment"] = false;
            returnObj["data-y-allignment"] = false;

            if (assetType === TEXT && !returnObj["data-heading"]) {
              returnObj["data-heading"] = fileType;
            }

            // if (assetType === LINE && returnObj["data-version"] !== "2.0") {
            if (assetType === LINE && parseFloat(returnObj["data-version"] ?? 1) < 2) {
              // converting old line assets to uploaded svg widgets
              returnObj["data-widget"] = "UPLOAD";
              returnObj["data-asset-type"] = UPLOAD;
              returnObj["data-file-type"] = fileType;
              delete returnObj["data-category"];
            }

            if (assetType === PICTURE) {
              if (hasCropToShape) returnObj["data-crop-to-shape"] = "true";
            }

            if (assetType === UPLOAD) {
              returnObj["data-file-type"] = fileType;
              if (hasCropToShape) returnObj["data-crop-to-shape"] = "true";
            }

            if (assetType === VIDEO && returnObj["data-source"] === LIBRARY) {
              returnObj["data-source"] = STOCK;
            }

            if ([TABLE, GROUP_WIDGET].includes(assetType) || hasCropToShape) {
              // injecting data-version "1.0" for widgets where version was absent in past (later in react app, it will be updated to version "2.0" from feature modal) or widgets that cannot be parsed (example: group widget)
              returnObj["data-version"] = "1.0";
            } else {
              // for rest, mass upgrading to data-version "2.0" (overriding previous data-version if available)
              returnObj["data-version"] = "2.0";
            }
          }

          return returnObj;
        }

        if (type === CLASS) {
          const dataObj = JSON.parse(JSON.stringify(node.classList));
          returnArr = Object.values(dataObj)?.filter(e => !e.includes("ui-") && !excludeList.includes(e));

          if (component === WIDGET && node.dataset.layerLocked === "true") {
            returnArr.push("draggable-disabled");
          }

          return returnArr;
        }

        if (type === INNER_HTML) {
          returnStr = await _parserEngine.parseWidgetInnerHtml.start({ node, props });

          return returnStr;
        }
      },

      constructor: async ({ node, component, required, returnObj = {} }) => {
        let assetType, fileType, fileNode, hasCropToShape, widgetInnerNode, widgetAutoHeightConverted;
        const idMap = _parserEngine.meta.idMap;
        const selector = _parserEngine.meta.selector;
        const widgetToAssetMap = _parserEngine.meta.widgetToAssetMap;

        if (component === WIDGET) {
          const fileObj = getWidgetInnerFileType({ widgetNode: node });

          // delete widgets with empty innerHTML
          if (!fileObj) return;

          assetType = widgetToAssetMap[node.dataset.widget];
          fileType = fileObj.type;
          fileNode = fileObj.node;
          widgetInnerNode = node.querySelector(".dhp-widget-inner");

          if (assetType === VIDEO && node.dataset.source === LIBRARY && fileObj.group === "img") {
            // delete old stock/library video widgets with img tag
            return;
          }

          if ([PICTURE, UPLOAD, ANIMATION].includes(assetType) && fileType !== "svg") {
            hasCropToShape = widgetInnerNode.style.clipPath;

            if (node.style.height === "auto") {
              const { width: imgWidth, height: imgHeight, reject } = await getImgDimensionFromUrl(fileNode.src);

              // delete widgets with error in loading src url (access denied)
              if (reject) return;

              const imgAspectRatio = imgWidth / imgHeight;
              widgetAutoHeightConverted = parseFloat(node.style.width) / imgAspectRatio + "px";
            } else {
              widgetAutoHeightConverted = node.style.height;
            }
          }
        }

        if (required.includes(ID)) returnObj[idMap[component]] = node.id;
        if (required.includes(PAGE_ID)) returnObj[PAGE_ID] = node.closest(selector[PAGE])?.id;
        if (required.includes(BLOCK_ID)) returnObj[BLOCK_ID] = node.closest(selector[BLOCK])?.id;

        [STYLE, DATA, CLASS, INNER_HTML].forEach(async type => {
          if (required.includes(type)) {
            returnObj[type] = await _parserEngine.parseHtml.formatter({
              node,
              component,
              type,
              props: { assetType, fileType, fileNode, hasCropToShape, widgetInnerNode, widgetAutoHeightConverted },
            });
          }
        });

        return returnObj;
      },

      start: async ({ html, returnObj = CONTEXT_OBJ_FOR_HTML }) => {
        const selector = _parserEngine.meta.selector;
        const required = _parserEngine.meta.required;
        const sandBox = _parserEngine.parseHtml.getSandBox({ html });

        const page_promises = [];
        const block_promises = [];
        const widget_promises = [];
        const bg_color_promises = [];
        const bg_image_promises = [];

        // pages
        sandBox.querySelectorAll(selector[PAGE]).forEach((node, idx) => {
          page_promises.push(
            new Promise(async resolve => {
              const pageObj = await _parserEngine.parseHtml.constructor({
                node: node,
                component: PAGE,
                required: required[PAGE],
              });
              pageObj[PAGE_IDX] = idx;
              pageObj[PAGE_DURATION] = _parserEngine.meta[PAGE_DURATION];
              pageObj[PAGE_TRANSITION] = _parserEngine.meta[PAGE_TRANSITION];
              resolve(pageObj);
            })
          );
        });

        // blocks
        sandBox.querySelectorAll(selector[BLOCK]).forEach((node, idx) => {
          block_promises.push(
            new Promise(async resolve => {
              const blockObj = await _parserEngine.parseHtml.constructor({
                node: node,
                component: BLOCK,
                required: required[BLOCK],
              });
              blockObj[BLOCK_IDX] = idx;
              resolve(blockObj);
            })
          );
        });

        // widgets
        sandBox.querySelectorAll(selector[WIDGET]).forEach(node => {
          widget_promises.push(
            new Promise(async resolve => {
              const widgetObj = await _parserEngine.parseHtml.constructor({
                node: node,
                component: WIDGET,
                required: required[WIDGET],
              });
              if (widgetObj) resolve(widgetObj);
              else resolve({ delete: true });
            })
          );
        });

        // background colors
        sandBox.querySelectorAll(selector[BG_COLOR]).forEach(node => {
          bg_color_promises.push(
            new Promise(async resolve => {
              const bgColorObj = await _parserEngine.parseHtml.constructor({
                node: node,
                component: BG_COLOR,
                required: required[BG_COLOR],
              });
              resolve(bgColorObj);
            })
          );
        });

        // background images
        sandBox.querySelectorAll(selector[BG_IMAGE]).forEach(node => {
          bg_image_promises.push(
            new Promise(async resolve => {
              const bgImageObj = await _parserEngine.parseHtml.constructor({
                node: node,
                component: BG_IMAGE,
                required: required[BG_IMAGE],
              });
              resolve(bgImageObj);
            })
          );
        });

        returnObj.pageNodes = await Promise.all(page_promises);
        returnObj.blockNodes = await Promise.all(block_promises);
        returnObj.widgets = await Promise.all(widget_promises).then(result => result.filter(w => !w.delete));
        returnObj.backgroundColors = await Promise.all(bg_color_promises);
        returnObj.backgroundImages = await Promise.all(bg_image_promises);

        return returnObj;
      },
    },

    parseMeta: {
      formatter: ({ component, type, data, returnObj = null }) => {
        const meta = _parserEngine.meta;

        if (component === TRANSITION) {
          // disabled as per team request | 3 Feb, 2023
          /*
          const transition = data;
          const transitionObj = meta.transitionMap.find(e => e.oldValue.includes(parseInt(transition.speed)));

          if (type === HTML) {
            returnObj = {
              ...transition,
              type: transition.effect,
              speed: transitionObj.name,
            };
            delete returnObj.effect;
          }

          if (type === META) {
            returnObj = {
              ...transition,
              speed: transitionObj.value,
            };
          }

          return returnObj ?? transition;
          */

          // reset transition to default, as per team request | 3 Feb, 2023
          return {
            enabled: false,
            type: "Zoom In Center",
            speed: "Medium",
          };
        }

        if (component === DIMENSION) {
          const dimension = data;

          returnObj = {
            ...dimension,
            prevZoom: 100,
            zoom: 100,
          };
          delete returnObj.zoomLevel;

          return returnObj;
        }

        if (component === MARGIN) {
          const { enabled, value, color, style, width } = data;
          const margin = [TYPE_INFOGRAPHIC].includes(meta.documentType) ? value : value * (meta.dimension.zoom / 100);

          const dynamicStyle = {
            top: margin,
            left: margin,
            right: margin,
            bottom: margin,
            border: `${width}px ${style} ${color === "transparent" ? color : hexToRGB(color)}`,
            height: [TYPE_INFOGRAPHIC].includes(meta.documentType) ? `${meta.dimension.height - value * 2}px` : "auto",
          };

          returnObj = {
            enabled,
            value,
            style: {
              zIndex: 1000,
              position: "absolute",
              pointerEvents: "none",
              ...dynamicStyle,
            },
          };
          delete returnObj.unit;

          return returnObj;
        }

        if (component === GRID) {
          const grid = data;

          returnObj = {
            ...grid,
            strokeWidth: 0.5,
          };
          delete returnObj.unit;

          return returnObj;
        }
      },

      start: ({ metadata, returnObj = {} }) => {
        const excludeList = _parserEngine.meta.exclude[META];

        Object.keys(metadata)?.forEach(key => {
          if (!excludeList.includes(key)) {
            const val = metadata[key];

            // tranisition
            if (key === TRANSITION) {
              // for html parsing
              _parserEngine.meta[PAGE_TRANSITION] = _parserEngine.parseMeta.formatter({
                component: TRANSITION,
                type: HTML,
                data: val,
              });

              // for meta parsing
              returnObj[key] = _parserEngine.parseMeta.formatter({
                component: TRANSITION,
                type: META,
                data: val,
              });
            }

            // dimension
            else if (key === DIMENSION) {
              const formattedVal = _parserEngine.parseMeta.formatter({
                component: DIMENSION,
                data: val,
              });

              // for html parsing
              _parserEngine.meta[PAGE_DIMENSION] = formattedVal;

              // for meta parsing
              returnObj[key] = formattedVal;
            }

            // margin
            else if (key === MARGIN) {
              // for meta parsing
              returnObj[key] = _parserEngine.parseMeta.formatter({
                component: MARGIN,
                data: val,
              });
            }

            // grid
            else if (key === GRID) {
              // for meta parsing
              returnObj[key] = _parserEngine.parseMeta.formatter({
                component: GRID,
                data: val,
              });
            }

            // passing rest of the props as is
            else {
              returnObj[key] = val;
            }
          }
        });

        return returnObj;
      },
    },

    start: async ({ data }) => {
      const meta = _parserEngine.meta;
      const {
        template_info: { type: template_type },
        version: {
          rawdata: { html, metadata },
        },
      } = data;

      meta.documentType = template_type?.toLowerCase();
      meta.dimension.height = parseInt(metadata?.dimension?.height);
      if (dimension) meta.dimension.zoom = dimension?.zoom;

      const parsedMeta = _parserEngine.parseMeta.start({ metadata });
      const parsedHtml = await _parserEngine.parseHtml.start({ html });

      return {
        ...parsedMeta,
        ...parsedHtml,
      };
    },
  };

  return { start: _parserEngine.start };
};

export default useDocumentParser;
