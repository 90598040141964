import cx from "classnames";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";
import Zoom from "../../components/Editor/Zoom";
import FitOnScreen from "../../components/Editor/FitOnScreen";
import PageList from "../../components/Common/PageList";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";

let style = Object.assign({}, global);

const EditorZoomNavigationPannel = props => {
  let { blockNodes, documentType, isDocumentReady, isTimeLineViewOpen, playingPageIdx } = useContext(EditorContext);

  return (
    <div
      className={cx(
        style["slide-controls"],
        style["editor-navigation"],
        style["rounded"],
        { [style["d-none"]]: !isDocumentReady },
        { [style["disable"]]: playingPageIdx !== null }
      )}>
      <div className="page-info-wdg">
        <ul>
          <Zoom />
          <FitOnScreen {...props} />
          {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) &&
            blockNodes &&
            blockNodes.length > 1 &&
            !isTimeLineViewOpen && <PageList component="Editor" />}
        </ul>
      </div>
    </div>
  );
};

//Props type validation
EditorZoomNavigationPannel.propTypes = {
  fitToScreen: PropTypes.bool,
  setFitToScreen: PropTypes.func.isRequired,
};

export default EditorZoomNavigationPannel;
