import cx from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";

import global from "../../scss/dhp.scss";

import { Icon } from "../ui/icon";
import { EditorContext } from "../../containers/editor/EditorLayout";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../constants/editor";
import useSelectable from "../../hooks/useSelectable";
import useWidgetHighlighter from "../../hooks/useWidgetHighlighter";
import useTextFocusOut from "../../hooks/useTextFocusOut";
import useCollaborativeSelector from "../../hooks/useCollaborativeSelector";
import { debounceTrailing, getZoomedValue } from "../../_helpers/utils";
import useFindHighlighter from "../../hooks/useFindHighlighter";

let style = Object.assign({}, global);

const Zoom = () => {
  const zoomIn = useRef();
  const zoomOut = useRef();
  const { hide: hide_WHT } = useWidgetHighlighter();
  let {
    dimension,
    updateDimension,
    metadata,
    updateMetadata,
    documentType,
    isDocumentReady,
    changeBySocket,
    blockNodes,
  } = useContext(EditorContext);

  const [zoomInputValue, setZoomInputValue] = useState(0);
  const [inputValue, setInputValue] = useState(100);

  const { updateHandler } = useSelectable();
  const setTextFocusOut = useTextFocusOut();
  const updateCollaborativeWidgetSelectionStyle = useCollaborativeSelector();
  const updateFindHighlighter = useFindHighlighter();

  const applyZoom = () => {
    if (zoomInputValue > 0 && isDocumentReady) {
      let newZoom = zoomInputValue / 100;
      let userAgent = navigator.userAgent;
      let isFirefox = userAgent.match(/firefox|fxios/i);
      let isSafari = !userAgent.match(/chrome/i) && userAgent.match(/safari/i);

      let canvasPanelHeight = window.innerHeight - 150; // as (150) is the height of outside canvas area

      // increase or decrease active outline in block
      let activePageSelector = document.getElementById("active-page-selctor");
      if (activePageSelector && metadata?.activeBlockIdx) {
        activePageSelector.style.height = `${getZoomedValue(
          parseFloat(blockNodes[metadata.activeBlockIdx].style.height),
          zoomInputValue
        )}px`;
        activePageSelector.style.width = `${getZoomedValue(
          parseFloat(blockNodes[metadata.activeBlockIdx].style.width),
          zoomInputValue
        )}px`;
      }

      if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
        let curEditorWrap = document.getElementById("generic-editor-wrap");
        if (curEditorWrap) {
          // curEditorWrap.style.height = `${parseInt(dimension.height)}px`;
          curEditorWrap.style.width = `${parseInt(dimension.width)}px`;
          curEditorWrap.style.transform = `scale(${newZoom})`;
          curEditorWrap.style.willChange = "";
          setTimeout(() => {
            curEditorWrap.style.willChange = "transform"; // resolved background line issue in canvas
          }, 100);
        }
        let curEditorOuterWrap = document.querySelector(".editor-outer-wrap");
        if (curEditorOuterWrap) {
          curEditorOuterWrap.style.width = `${parseInt(dimension.width) * newZoom}px`;
          let newHeight = parseInt(dimension.height) * newZoom;
          curEditorOuterWrap.style.height = `${
            newHeight < canvasPanelHeight ? newHeight : parseInt(dimension.height) * newZoom + 80
          }px`; // as 80 is the bottom padding of canvas area
        }

        let curPageCanvas = document.querySelector(".dhp-page-canvas");
        if (curPageCanvas) {
          if ((isFirefox && canvasPanelHeight > dimension.height * newZoom) || zoomInputValue < 100)
            curPageCanvas.style.height = 0;
          else curPageCanvas.style.height = "auto";
        }
      } else {
        [...document.querySelectorAll(".dhp-page-canvas")].forEach(pageCanvas => {
          pageCanvas.style.height = `${parseInt(dimension.height) * newZoom}px`;
          pageCanvas.style.width = `${parseInt(dimension.width) * newZoom}px`;
        });

        [...document.querySelectorAll(".dhp-page-block")].forEach(pageBlock => {
          pageBlock.style.transform = `scale(${newZoom})`;
          pageBlock.style.willChange = "";
          setTimeout(() => {
            pageBlock.style.willChange = "transform"; // resolved background line issue in canvas
          }, 100);
        });

        if (isFirefox || isSafari) {
          let curEditorWrap = document.getElementById("generic-editor-wrap");
          if (curEditorWrap) {
            curEditorWrap.style.width = `${parseInt(dimension.width) * newZoom}px`;
            // curEditorWrap.style.overflow = `hidden`;
          }
        }
      }

      updateDimension({ ...dimension, zoom: zoomInputValue });
      injectCanvasSelectionStyle();
      updateCollaborativeWidgetSelectionStyle({ zoom: zoomInputValue });
      if (zoomInputValue) updateHandler(zoomInputValue);
      updateFindHighlighter(zoomInputValue);
      let controllerNewTop = 0;
      let blockSelector = document.getElementById(metadata.activeBlockId);
      if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && blockSelector) {
        controllerNewTop = blockSelector.offsetTop * newZoom;
      }
      let pageSelector = document.getElementById(metadata.activePageId);
      if (![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && pageSelector) {
        controllerNewTop = pageSelector.offsetTop;
      }

      updateMetadata({ ...metadata, pageController: { style: { top: controllerNewTop } } });
    }
  };

  const injectCanvasSelectionStyle = () => {
    let css = "";
    let zoomStyleElement = document.getElementById("zoomStyle");
    if (zoomStyleElement) zoomStyleElement.remove();
    if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
      let newFontSize = (16 * 100) / zoomInputValue;
      css = `.generic-editor-wrap .block-resize-handler i {font-size: ${parseInt(newFontSize)}px !important} `;

      let newTootltipFontSize = (12 * 100) / zoomInputValue;
      let newTootltipBorderRadius = (4 * 100) / zoomInputValue;
      let newLineHeight = (18 * 100) / zoomInputValue;

      css += `.generic-editor-wrap .custom-tooltip .custom-tooltip-content {font-size: ${newTootltipFontSize}px !important; border-radius: ${newTootltipBorderRadius}px !important; padding:${newTootltipBorderRadius}px ${newTootltipFontSize}px !important; line-height: ${newLineHeight}px !important; } `;
    }

    let newShadowWidth = parseFloat(zoomInputValue > 20 ? (100 / zoomInputValue).toFixed(2) : 100 / zoomInputValue);
    let outlineWidth = 2 * (100 / zoomInputValue);

    css += `.generic-editor-wrap .current-canvas-selected { -webkit-box-shadow: 0 0.15px 0 ${
      newShadowWidth ?? 1
    }px #00a4ef !important; box-shadow: 0 0.15px 0 ${newShadowWidth ?? 1}px #00a4ef !important;}`;

    css += `.group-selected, .child-selected, .drag-selected, .dhp-root-widget.border-heighlight { outline: ${outlineWidth}px solid #00a4ef !important;}`;

    css += `.widget-animate { outline: none !important;}`;

    let head = document.head || document.getElementsByTagName("head")[0];
    let style = document.createElement("style");
    style.setAttribute("id", "zoomStyle");
    head.appendChild(style);

    style.setAttribute("type", "text/css");
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  };

  const validateZoomRange = zoom => {
    if (zoom > 250) return 250;
    else if (zoom < 1) return 1;
    else return zoom;
  };

  const updateCustomZoomValueOnchange = e => {
    if (e.target?.validity.patternMismatch) return;

    setInputValue(e.target.value);
  };

  const updateCustomZoomValue = e => {
    if (e && ((e.type === "keyup" && 13 === e.keyCode) || e.type === "blur"))
      if (isNaN(Math.round(e.target.value)) || e.target.value == "") {
        setZoomInputValue(dimension.zoom);
        setInputValue(dimension.zoom);
      } else {
        setZoomInputValue(validateZoomRange(Math.round(e.target.value)));
        setInputValue(validateZoomRange(Math.round(e.target.value)));
      }
  };

  const handleKeyDown = ev => {
    if (!zoomIn.current || !zoomOut.current) return;
    if (localStorage.getItem("widget.event.isActive") === "true") return;

    ev = ev || window.event; // Event object 'ev'
    let key = ev.which || ev.keyCode; // Detecting keyCode
    let ctrl = ev.ctrlKey ? ev.ctrlKey : key === 17 ? true : false; // Detecting Ctrl
    let command = ev.metaKey; // Detecting Command

    if ([187, 107, 61].includes(key) && (ctrl || command)) {
      // CTRL +  ZOOM IN
      ev.preventDefault();
      handleZoomIn();
    }
    if ([189, 109, 173].includes(key) && (ctrl || command)) {
      // CTRL -  ZOOM OUT
      ev.preventDefault();
      handleZoomOut();
    }
    if ([48, 96].includes(key) && (ctrl || command)) {
      // CTRL + 0  ZOOM to 100%
      ev.preventDefault();
      handleZoomReset();
    }
  };

  const handleZoomIn = debounceTrailing(() => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
    zoomIn.current.click();
    hide_WHT();
  }, 100);

  const handleZoomOut = debounceTrailing(() => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
    zoomOut.current.click();
    hide_WHT();
  }, 100);

  const handleZoomReset = debounceTrailing(() => {
    if (document.querySelector(".dhp-content-editable-true-text")) setTextFocusOut(true);
    setZoomInputValue(100);
    setInputValue(100);
    hide_WHT();
  }, 100);

  useEffect(() => {
    setZoomInputValue(dimension.zoom);
    setInputValue(parseInt(dimension.zoom)); // show only int value
  }, [dimension.zoom]);

  useEffect(() => {
    applyZoom();
  }, [zoomInputValue, isDocumentReady]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      let zoomStyleElement = document.getElementById("zoomStyle");
      if (zoomStyleElement) zoomStyleElement.remove();
    };
  }, []);

  return (
    <>
      <li>
        <div
          ref={zoomOut}
          onClick={() => {
            setZoomInputValue(validateZoomRange(zoomInputValue - 10));
            setInputValue(validateZoomRange(zoomInputValue - 10));
          }}
          className={cx(style["custom-tooltip"], style["link-white"])}>
          <Icon icon="ui-minus-circle" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Zoom Out</div>
        </div>
      </li>

      <li className={style["zoom-percent"]}>
        <span>
          <Input
            name="zoom-value"
            type="text"
            pattern="[0-9]*"
            value={inputValue}
            onChange={e => updateCustomZoomValueOnchange(e)}
            onBlur={e => updateCustomZoomValue(e)}
            onKeyUp={e => updateCustomZoomValue(e)}
          />
          %
        </span>
      </li>

      <li>
        <div
          ref={zoomIn}
          onClick={() => {
            setZoomInputValue(validateZoomRange(parseInt(zoomInputValue) + 10));
            setInputValue(validateZoomRange(parseInt(zoomInputValue) + 10));
          }}
          className={cx(style["custom-tooltip"], style["link-white"])}>
          <Icon icon="ui-plus-circle" />
          <div className={cx(style["custom-tooltip-content"], style["top"])}>Zoom In</div>
        </div>
      </li>
    </>
  );
};

export default Zoom;
