import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import * as constant from "../../../constants/editor";
import PageResizeComponent from "../PageResizeComponent";
import { Button, DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Input } from "../../ui/input";
import { generateSmartResizedWidgets, getZoomedValue, validateNumberInput } from "../../../_helpers/utils";
import useSetDimension from "../../../hooks/useSetDimension";
import { LENGTH_UNITS_CONVERSION_FACTORS, SPINNER_FIELDS_META_INFOGRAPHIC } from "../../../constants/pageSizeModal";
import useGoToBlock from "../../../hooks/useGoToBlock";
import useControllerToSection from "../../../hooks/useControllerToSection";

let style = Object.assign({}, global);

const PageResize = () => {
  let { documentType } = useContext(EditorContext);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let tooltipText = ![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)
    ? "Page Size"
    : "Resize Block";

  const handleAction = () => {
    if (![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)) setShowModal(!showModal);
  };

  const toggle = () => {
    if ([constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)) setIsOpen(!isOpen);
  };

  return (
    <>
      <li className={style["custom-tooltip"]} onClick={() => handleAction()}>
        {![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) && (
          <span>
            <Icon icon="ui-page-size" />
          </span>
        )}
        {[constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) && (
          <UncontrolledDropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle
              tag="a"
              className={cx(style["p-0"], style["custom-tooltip"])}
              onClick={() => setIsOpen(!isOpen)}>
              <Icon icon="ui-page-size" />
            </DropdownToggle>

            <DropdownMenu
              className={cx(
                style["border-0"],
                style["shadow"],
                style["mt-2"],
                style["rounded"],
                style["block-config"],
                style["clearfix"]
              )}>
              <BlockResize isOpen={isOpen} setIsOpen={setIsOpen} />
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <div className={cx(style["custom-tooltip-content"], style["right"])}>{tooltipText}</div>
      </li>
      {showModal && <PageResizeComponent showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
};

const BlockResize = ({ isOpen, setIsOpen }) => {
  let {
    blockNodes,
    pageNodes,
    metadata,
    dimension,
    widgets,
    updateWidgets,
    updateMetadata,
    setTextFontSize,
    documentType,
  } = useContext(EditorContext);
  const [uom, setUom] = useState();
  const [pageDiaInCurrentUOM, setPageDiaInCurrentUOM] = useState();

  const [blockHeight, setBlockHeight] = useState(parseInt(dimension?.height));
  const [blockWidth, setBlockWidth] = useState(parseInt(dimension?.width));

  const { setTotalHeight } = useSetDimension();
  const { goToBlock } = useGoToBlock();
  const { controllerToSection } = useControllerToSection();

  useEffect(() => {
    setUom(LENGTH_UNITS_CONVERSION_FACTORS.find(mu => mu.name === (dimension?.displayUnit ?? dimension.unit)));
  }, [dimension]);

  useEffect(() => {
    if (uom?.factor) {
      let height = uom.name === "px" ? parseInt(blockHeight) : parseFloat((blockHeight * uom.factor).toFixed(2));
      height =
        height >= SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxHeight
          ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxHeight
          : height <= SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minHeight
          ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minHeight
          : height;

      let width = uom.name === "px" ? parseInt(blockWidth) : parseFloat((blockWidth * uom.factor).toFixed(2));
      width =
        width >= SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxWidth
          ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxWidth
          : width <= SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minWidth
          ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minWidth
          : width;

      setPageDiaInCurrentUOM({ height, width });
    }
  }, [uom]);

  useEffect(() => {
    if (metadata.activeBlockId) {
      const activeBlockData = blockNodes.find(bn => bn.blockId === metadata.activeBlockId);
      setBlockHeight(
        uom?.name === "px" ? parseInt(activeBlockData?.style?.height) : parseFloat(activeBlockData?.style?.height)
      );
      setBlockWidth(
        uom?.name === "px" ? parseInt(activeBlockData?.style?.width) : parseFloat(activeBlockData?.style?.width)
      );
      if (uom?.factor) {
        let height =
          uom?.name === "px"
            ? parseInt(activeBlockData?.style?.height)
            : parseFloat((parseFloat(activeBlockData?.style?.height) * uom.factor).toFixed(2));
        let width =
          uom?.name === "px"
            ? parseInt(activeBlockData?.style?.width)
            : parseFloat((parseFloat(activeBlockData?.style?.width) * uom.factor).toFixed(2));
        setPageDiaInCurrentUOM({ height, width });
      }
    }
  }, [metadata.activeBlockId]);

  useEffect(() => {
    if (isOpen) {
      updateMetadata({
        ...metadata,
        activeWidgetId: false,
        activeWidgetType: false,
      });
    }
  }, [isOpen]);

  const checkRange = e => {
    let curValue =
      uom === "px"
        ? parseInt(e.currentTarget.value ? e.currentTarget.value : 0)
        : parseFloat(parseFloat(e.currentTarget.value ? e.currentTarget.value : 0).toFixed(2));
    let curMax = uom === "px" ? parseInt(e.currentTarget.max) : parseFloat(e.currentTarget.max);
    let curMin = uom === "px" ? parseInt(e.currentTarget.min) : parseFloat(e.currentTarget.min);
    if (e.currentTarget.id === "Height") {
      let newHeight = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setBlockHeight(newHeightInPx);
    } else {
      let newWidth = curValue >= curMax ? curMax : curValue <= curMin ? curMin : curValue;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setBlockWidth(newWidthInPx);
    }
  };

  const onIncrease = target => {
    if (target === "Height") {
      let newHeight =
        pageDiaInCurrentUOM.height + 1 < SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxHeight
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.height + 1
            : parseFloat((pageDiaInCurrentUOM.height + 1).toFixed(2))
          : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxHeight;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setBlockHeight(newHeightInPx);
    } else {
      let newWidth =
        pageDiaInCurrentUOM.width + 1 < SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxWidth
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.width + 1
            : parseFloat((pageDiaInCurrentUOM.width + 1).toFixed(2))
          : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxWidth;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setBlockWidth(newWidthInPx);
    }
  };

  const onDecrease = target => {
    if (target === "Height") {
      let newHeight =
        pageDiaInCurrentUOM.height - 1 > SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minHeight
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.height - 1
            : parseFloat((pageDiaInCurrentUOM.height - 1).toFixed(2))
          : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minHeight;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, height: newHeight });
      let newHeightInPx = newHeight / uom.factor;
      setBlockHeight(newHeightInPx);
    } else {
      let newWidth =
        pageDiaInCurrentUOM.width - 1 > SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minWidth
          ? uom.factor === 1
            ? pageDiaInCurrentUOM.width - 1
            : parseFloat((pageDiaInCurrentUOM.width - 1).toFixed(2))
          : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minWidth;
      setPageDiaInCurrentUOM({ ...pageDiaInCurrentUOM, width: newWidth });
      let newWidthInPx = newWidth / uom.factor;
      setBlockWidth(newWidthInPx);
    }
  };

  const onChangeHandler = (e, target) => {
    if (target === "Width")
      setPageDiaInCurrentUOM({
        ...pageDiaInCurrentUOM,
        width: uom.name === "px" ? parseInt(e.currentTarget.value ?? 0) : parseFloat(e.currentTarget.value ?? 0),
      });
    else
      setPageDiaInCurrentUOM({
        ...pageDiaInCurrentUOM,
        height: uom.name === "px" ? parseInt(e.currentTarget.value ?? 0) : parseFloat(e.currentTarget.value ?? 0),
      });
  };

  const validateBlockSizeRange = (fieldType, value, uom) => {
    let curValue = uom.name === "px" ? parseInt(value) : parseFloat(value);

    if (uom.name !== "px") return curValue;

    if (fieldType === "Height") {
      return curValue >= SPINNER_FIELDS_META_INFOGRAPHIC["px"].maxHeight
        ? SPINNER_FIELDS_META_INFOGRAPHIC["px"].maxHeight
        : curValue <= SPINNER_FIELDS_META_INFOGRAPHIC["px"].minHeight
        ? SPINNER_FIELDS_META_INFOGRAPHIC["px"].minHeight
        : curValue;
    } else {
      return curValue >= SPINNER_FIELDS_META_INFOGRAPHIC["px"].maxWidth
        ? SPINNER_FIELDS_META_INFOGRAPHIC["px"].maxWidth
        : curValue <= SPINNER_FIELDS_META_INFOGRAPHIC["px"].minWidth
        ? SPINNER_FIELDS_META_INFOGRAPHIC["px"].minWidth
        : curValue;
    }
  };

  const applyBlockSmartResize = () => {
    let activeBlockStyle = blockNodes.find(block => block.blockId === metadata.activeBlockId)?.style;
    let oldWidth = parseFloat(activeBlockStyle.width);
    let oldHeight = parseFloat(activeBlockStyle.height);
    let { unit, displayUnit } = dimension;

    let curBlockHeight = validateBlockSizeRange("Height", blockHeight, uom);
    let curBlockWidth = validateBlockSizeRange("Width", blockWidth, uom);
    // No Change
    if (
      curBlockWidth === oldWidth &&
      curBlockHeight === oldHeight &&
      uom?.name === unit &&
      (!displayUnit || displayUnit === uom?.name)
    ) {
      setIsOpen(!isOpen);
      return;
    }

    let allPageNodes = Object.assign([], pageNodes);
    let allBlockNodes = Object.assign([], blockNodes);
    let newPageHeight = 0;
    allBlockNodes.forEach(
      block =>
        (newPageHeight += block.blockId === metadata.activeBlockId ? curBlockHeight : parseFloat(block.style.height))
    );

    allPageNodes = allPageNodes.map(pn => {
      return {
        ...pn,
        style: { ...pn.style, width: `${curBlockWidth}px` },
      };
    });

    allBlockNodes = allBlockNodes.map(bn => {
      if (bn.blockId === metadata.activeBlockId) {
        return {
          ...bn,
          style: { ...bn.style, height: `${curBlockHeight}px`, width: `${curBlockWidth}px` },
        };
      } else {
        return {
          ...bn,
          style: { ...bn.style, width: `${curBlockWidth}px` },
        };
      }
    });

    let allUpdatedWidgets = [];

    blockNodes.forEach(block => {
      let oldWidth = parseFloat(block.style.width);
      let oldHeight = parseFloat(block.style.height);
      let resizedBlockDia = allBlockNodes.find(blk => blk.blockId === block.blockId);
      let newBlockWidth = parseFloat(resizedBlockDia.style.width);
      let newBlockHeight = parseFloat(resizedBlockDia.style.height);

      // Height and width remain same
      if (newBlockWidth === oldWidth && newBlockHeight === oldHeight) {
        allUpdatedWidgets = [...allUpdatedWidgets, ...widgets.filter(wz => wz.blockId === block.blockId)];
      }

      // Only Width Increase
      if (newBlockWidth > oldWidth && newBlockHeight === oldHeight) {
        let shiftX = (newBlockWidth - oldWidth) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            1,
            shiftX,
            0,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }

      // Only Width Decrease
      if (newBlockWidth < oldWidth && newBlockHeight === oldHeight) {
        let decreseFactor = newBlockWidth / oldWidth;
        let shiftY = (oldHeight - newBlockHeight * decreseFactor) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            decreseFactor,
            0,
            shiftY,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }

      // Only Height Increase
      if (newBlockHeight > oldHeight && newBlockWidth === oldWidth) {
        let shiftY = (newBlockHeight - oldHeight) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            1,
            0,
            shiftY,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }

      // Only Height Decrease
      if (newBlockHeight < oldHeight && newBlockWidth === oldWidth) {
        let decreseFactor = newBlockHeight / oldHeight;
        let shiftX = (oldWidth - newBlockWidth * decreseFactor) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            decreseFactor,
            shiftX,
            0,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }

      // Both Height & Width Increase or Decrease
      if (
        (newBlockHeight > oldHeight && newBlockWidth > oldWidth) ||
        (newBlockHeight < oldHeight && newBlockWidth < oldWidth)
      ) {
        let increseFactor = Math.min(newBlockHeight / oldHeight, newBlockWidth / oldWidth);
        let shiftX = (newBlockWidth - oldWidth * increseFactor) / 2;
        let shiftY = (newBlockHeight - oldHeight * increseFactor) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            increseFactor,
            shiftX,
            shiftY,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }

      // if Height Decrease  & Width Increase
      if (newBlockHeight < oldHeight && newBlockWidth > oldWidth) {
        let decreseFactor = Math.min(newBlockHeight / oldHeight, newBlockWidth / oldWidth);
        let shiftY = (newBlockHeight - oldHeight * decreseFactor) / 2;
        let shiftX = (newBlockWidth - oldWidth * decreseFactor) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            decreseFactor,
            shiftX,
            shiftY,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }
      // if Height Increase & Width Decrease
      if (newBlockHeight > oldHeight && newBlockWidth < oldWidth) {
        let decreseFactor = Math.min(newBlockHeight / oldHeight, newBlockWidth / oldWidth);
        let shiftY = (newBlockHeight - oldHeight * decreseFactor) / 2;
        let shiftX = (newBlockWidth - oldWidth * decreseFactor) / 2;
        allUpdatedWidgets = [
          ...allUpdatedWidgets,
          ...generateSmartResizedWidgets(
            decreseFactor,
            shiftX,
            shiftY,
            newBlockHeight,
            newBlockWidth,
            widgets,
            dimension.zoom,
            block.blockId,
            setTextFontSize
          ),
        ];
      }
    });

    setTotalHeight(allBlockNodes, "Custom", curBlockWidth, undefined, undefined, uom.name); // passing displayUnit

    updateWidgets(
      allUpdatedWidgets,
      allPageNodes,
      allBlockNodes,
      false,
      false,
      false,
      {
        ...dimension,
        height: newPageHeight,
        width: curBlockWidth,
        displayUnit: uom.name,
      },
      "pageResized"
    );

    document.querySelector(".generic-editor-wrap").style.width = `${curBlockWidth}px`;
    let editorouterWrap = document.querySelector(".editor-outer-wrap");
    editorouterWrap.style.width = `${getZoomedValue(curBlockWidth, dimension.zoom)}px`;
    editorouterWrap.style.height = `${getZoomedValue(newPageHeight, dimension.zoom) + 80}px`;

    setIsOpen(!isOpen);

    // keep controller to active block
    setTimeout(() => {
      goToBlock();
      controllerToSection();
    }, 300);
  };

  return (
    <>
      <div
        className={cx(style["d-flex"], style["align-items-center"], style["justify-content-between"], style["mb-4"])}>
        <h6 className={cx(style["m-0"], style["fw-7"])}>Resize Block</h6>
        <div className={cx(style["close-block-resize"], style["rounded"])} onClick={() => setIsOpen(!isOpen)}>
          <Icon icon="ui-close" />
        </div>
      </div>

      <div
        className={cx(
          style["d-flex"],
          style["align-items-center"],
          style["justify-content-between"],
          style["resize-fild"]
        )}>
        {pageDiaInCurrentUOM &&
          SPINNER_FIELDS_META_INFOGRAPHIC.label.map(field => (
            <Input
              key={field}
              returnType="formGroup"
              id={field}
              type="spinner"
              className="custom-width"
              inputGroupClass={field === "Width" && [constant.TYPE_PROPOSAL].includes(documentType) ? "disabled" : ""}
              spinnerClass="input-spinner"
              value={field === "Width" ? pageDiaInCurrentUOM?.width : pageDiaInCurrentUOM?.height}
              label={field}
              max={
                field === "Width"
                  ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxWidth
                  : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].maxHeight
              }
              min={
                field === "Width"
                  ? SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minWidth
                  : SPINNER_FIELDS_META_INFOGRAPHIC[uom.name].minHeight
              }
              required={false}
              onBlur={e => checkRange(e)}
              onKeyDown={e => validateNumberInput(e)}
              onChange={e => onChangeHandler(e, field)}
              onDecrease={target => onDecrease(target)}
              onIncrease={target => onIncrease(target)}
            />
          ))}
        <UncontrolledDropdown className={style["border-style"]}>
          <DropdownToggle>
            {uom?.name}
            <Icon icon="ui-arrow-down" />
          </DropdownToggle>

          <DropdownMenu className={cx(style["shadow-sm"], style["border-0"], style["rounded"])}>
            {LENGTH_UNITS_CONVERSION_FACTORS.map((mu, i) => (
              <DropdownItem
                tag="a"
                key={i}
                onClick={() => setUom(mu)}
                className={cx({
                  [style["active"]]: mu.name === uom?.name,
                })}>
                {mu.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <Button type="button" color="primary" onClick={applyBlockSmartResize}>
        Apply
      </Button>
    </>
  );
};

//Props type validation
BlockResize.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default PageResize;
