import { useContext, useEffect } from "react";
import { widgetConfig } from "../components/Editor/editor_config";
import { PAGE_TIMELINE_LEFT_SECTION, TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useSnaptoGrid = () => {
  let { metadata, widgets, documentType, dimension, pageMargin, updateSnapGrid, blockNodes, isTimeLineViewOpen } =
    useContext(EditorContext);

  const activateGridLines = () => {
    let widgetContainerarray = [];
    let newWidgetGridArray = [];
    let newWidgetArray = [];
    let zoomval = 100 / parseFloat(dimension.zoom);
    let parentElem = document.getElementById(metadata.activeBlockId);
    let parentPos = parentElem.getBoundingClientRect();
    let activeBlockWidth = parentPos.width * zoomval;
    let activeBlockHeight = parentPos.height * zoomval;
    let activeBlockMiddle = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
      ? parentElem.offsetTop + activeBlockHeight / 2
      : parentPos.height / 2;
    let activeBlockCenter = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
      ? activeBlockWidth / 2
      : parentPos.width / 2;
    let marginPos = pageMargin.enabled
      ? [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
        ? pageMargin.style.top
        : pageMargin.value * (parseFloat(dimension.zoom) / 100)
      : [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
      ? widgetConfig.page_margin.defaultSize
      : widgetConfig.page_margin.defaultSize * (parseFloat(dimension.zoom) / 100);

    widgets.forEach(widget => {
      // when active widget is in a group widget
      if (
        metadata.activeWidgetId?.length === 1 &&
        document.querySelector(`#${metadata.activeWidgetId[0]}`)?.classList?.contains("child-selected")
      ) {
        let mainObjId = document
          .getElementById(metadata.activeWidgetId[0])
          .closest(".dhp-root-widget")
          .getAttribute("id");
        if (mainObjId !== widget.id && widget.blockId === metadata.activeBlockId) newWidgetArray.push(widget);
      }
      // when active widget is not in a group widget
      else if (!metadata.activeWidgetId.includes(widget.id) && widget.blockId === metadata.activeBlockId) {
        newWidgetArray.push(widget);
      }
    });

    // create active block grid obj
    let BlockSnapObj = {
      pageId: metadata.activePageId,
      blockId: metadata.activeBlockId,
      center: activeBlockCenter,
      middle: activeBlockMiddle,
      marginPos: marginPos,
    };

    // create widgets grid obj which are not active
    newWidgetArray.forEach(item => {
      let childPos = document.getElementById(item.id).getBoundingClientRect();

      let widgetLeft = parseFloat(childPos.left - parentPos.left) * zoomval;
      let widgetCenter = parseFloat(widgetLeft + Math.round((childPos.width * zoomval) / 2));
      let widgetRight = parseFloat(parentElem.style.width) + (childPos.right - parentPos.right) * zoomval;
      let widgetTop = parseFloat(childPos.top - parentPos.top) * zoomval;
      let widgetMiddle = parseFloat(widgetTop + parseFloat((childPos.height * zoomval) / 2));
      let widgetBottom = parseFloat(parentElem.style.height) + (childPos.bottom - parentPos.bottom) * zoomval;

      let snapObj = {
        widgetId: item.id,
        left: widgetLeft,
        center: widgetCenter,
        right: widgetRight,
        top: widgetTop,
        middle: widgetMiddle,
        bottom: widgetBottom,
      };

      newWidgetGridArray.push(snapObj);
    });

    // if page time line is open activate snap line of widget containers in page time line section
    if (isTimeLineViewOpen === "page-timeline") {
      let leftStaticAreaWidth = PAGE_TIMELINE_LEFT_SECTION;

      document.querySelectorAll(".widget-container .widget").forEach((item, index) => {
        if (!item.classList.contains("active")) {
          let target = item.querySelector(".widget-animation .item");
          let widgetContainerLeft = parseFloat(target?.style.transform.split("(")[1]) + leftStaticAreaWidth;
          let widgetContainerRight = parseFloat(target?.style.width) + widgetContainerLeft;

          let widgetContainerSnapObj = {
            widgetContainerIdx: index,
            left: widgetContainerLeft,
            right: widgetContainerRight,
          };

          widgetContainerarray.push(widgetContainerSnapObj);
        }
      });
    }

    updateSnapGrid({
      blockGrid: BlockSnapObj,
      widgetGrid: newWidgetGridArray,
      widgetContainerGrid: widgetContainerarray,
    });
  };

  const showSnappedGridLines = activeWidget => {
    let zoomval = 100 / parseFloat(dimension.zoom);
    let parentElem = document.getElementById(metadata.activeBlockId);
    let parentPos = parentElem.getBoundingClientRect();
    let childPos = document.getElementById(activeWidget.id).getBoundingClientRect();

    let activeWidgetLeft = parseFloat(childPos.left - parentPos.left) * zoomval;
    let activeWidgeCenter = parseFloat(activeWidgetLeft + Math.round((childPos.width * zoomval) / 2));
    let activeWidgetRight = parseFloat(parentElem.style.width) + (childPos.right - parentPos.right) * zoomval;
    let activeWidgetTop = parseFloat(childPos.top - parentPos.top) * zoomval;
    let activeWidgeMiddle = parseFloat(activeWidgetTop + parseFloat((childPos.height * zoomval) / 2));
    let activeWidgetBottom = parseFloat(parentElem.style.height) + (childPos.bottom - parentPos.bottom) * zoomval;

    let topGridSnappedClasslists = [],
      middleGridSnappedClasslists = [],
      bottomGridSnappedClasslists = [],
      leftGridSnappedClasslists = [],
      centerGridSnappedClasslists = [],
      rightGridSnappedClasslists = [],
      snappedClassLists = [];

    hideGrideLines();

    document.querySelectorAll(".grid-line").forEach(node => {
      let data_id = node.dataset.id;
      let data_line = node.dataset.line;
      let targetClass = node.classList[1];
      let gridLineLeft =
        (node.classList.contains("snap-line-block-vertical") ||
          node.classList.contains("snap-line-block-margin") ||
          node.classList.contains("snap-line-guide-vertical")) &&
        ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? parseFloat(node.style.left) * zoomval
          : parseFloat(node.style.left);
      let gridLineTop =
        (node.classList.contains("snap-line-block-horaizental") ||
          node.classList.contains("snap-line-block-margin") ||
          node.classList.contains("snap-line-guide-horaizental")) &&
        ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? parseFloat(node.style.top) * zoomval
          : node.classList.contains("snap-line-block-horaizental")
          ? parseFloat(node.style.top) - parentElem.offsetTop
          : parseFloat(node.style.top);

      if (data_line === "horaizental" || data_line === "margin") {
        if (
          [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) &&
          data_line === "margin" &&
          metadata.activeBlockIdx !== 0
        ) {
          // do nothing as infographic has margin in whole page not every block
        } else {
          if (gridLineTop - activeWidgetTop >= -2 && gridLineTop - activeWidgetTop <= 2) {
            topGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }
          if (gridLineTop - activeWidgeMiddle >= -2 && gridLineTop - activeWidgeMiddle <= 2) {
            middleGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }
          if (gridLineTop - activeWidgetBottom >= -2 && gridLineTop - activeWidgetBottom <= 2) {
            bottomGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }

          if (
            (gridLineTop - activeWidgetTop >= -2 && gridLineTop - activeWidgetTop <= 2) ||
            (gridLineTop - activeWidgeMiddle >= -2 && gridLineTop - activeWidgeMiddle <= 2) ||
            (gridLineTop - activeWidgetBottom >= -2 && gridLineTop - activeWidgetBottom <= 2)
          ) {
            if (activeWidget.id !== data_id) {
              document.getElementById(data_id)?.classList.add("border-heighlight");
            }
          }
        }
      }

      if (data_line == "vertical" || data_line == "margin") {
        if (gridLineLeft - activeWidgetLeft >= -2 && gridLineLeft - activeWidgetLeft <= 2) {
          leftGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
        if (gridLineLeft - activeWidgeCenter >= -2 && gridLineLeft - activeWidgeCenter <= 2) {
          centerGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
        if (gridLineLeft - activeWidgetRight >= -2 && gridLineLeft - activeWidgetRight <= 2) {
          rightGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }

        if (
          (gridLineLeft - activeWidgetLeft >= -2 && gridLineLeft - activeWidgetLeft <= 2) ||
          (gridLineLeft - activeWidgeCenter >= -2 && gridLineLeft - activeWidgeCenter <= 2) ||
          (gridLineLeft - activeWidgetRight >= -2 && gridLineLeft - activeWidgetRight <= 2)
        ) {
          if (activeWidget.id !== data_id) {
            document.getElementById(data_id)?.classList.add("border-heighlight");
          }
        }
      }

      if (data_line == "margin") {
        let marginRight = ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? parseFloat(node.style.left) * zoomval
          : parseFloat(node.style.left);
        let matginBottom = ![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? parseFloat(node.style.top) * zoomval
          : parseFloat(node.style.top);
        var gridLineRight = parseFloat(document.getElementById(metadata.activeBlockId).style.width) - marginRight;
        var gridLineBottom = parseFloat(document.getElementById(metadata.activeBlockId).style.height) - matginBottom;

        if (gridLineRight - activeWidgetLeft >= -2 && gridLineRight - activeWidgetLeft <= 2) {
          leftGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
        if (gridLineRight - activeWidgeCenter >= -2 && gridLineRight - activeWidgeCenter <= 2) {
          centerGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
        if (gridLineRight - activeWidgetRight >= -2 && gridLineRight - activeWidgetRight <= 2) {
          rightGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }

        if (
          [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) &&
          metadata.activeBlockIdx !== blockNodes.length - 1
        ) {
          // do nothing as infographic has margin in whole page not every block
        } else {
          if (gridLineBottom - activeWidgetTop >= -2 && gridLineBottom - activeWidgetTop <= 2) {
            topGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }
          if (gridLineBottom - activeWidgeMiddle >= -2 && gridLineBottom - activeWidgeMiddle <= 2) {
            middleGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }
          if (gridLineBottom - activeWidgetBottom >= -2 && gridLineBottom - activeWidgetBottom <= 2) {
            bottomGridSnappedClasslists.push({
              className: targetClass,
              dataId: data_id,
            });
          }

          if (
            (gridLineBottom - activeWidgetTop >= -2 && gridLineBottom - activeWidgetTop <= 2) ||
            (gridLineBottom - activeWidgeMiddle >= -2 && gridLineBottom - activeWidgeMiddle <= 2) ||
            (gridLineBottom - activeWidgetBottom >= -2 && gridLineBottom - activeWidgetBottom <= 2)
          ) {
            if (activeWidget.id !== data_id) {
              document.getElementById(data_id)?.classList.add("border-heighlight");
            }
          }
        }

        if (
          (gridLineRight - activeWidgetLeft >= -2 && gridLineRight - activeWidgetLeft <= 2) ||
          (gridLineRight - activeWidgeCenter >= -2 && gridLineRight - activeWidgeCenter <= 2) ||
          (gridLineRight - activeWidgetRight >= -2 && gridLineRight - activeWidgetRight <= 2)
        ) {
          if (activeWidget.id !== data_id) {
            document.getElementById(data_id)?.classList.add("border-heighlight");
          }
        }
      }
    });

    if (leftGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: leftGridSnappedClasslists[0].className,
        dataId: leftGridSnappedClasslists[0].data_id,
      });
    }
    if (centerGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: centerGridSnappedClasslists[0].className,
        dataId: centerGridSnappedClasslists[0].data_id,
      });
    }
    if (rightGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: rightGridSnappedClasslists[0].className,
        dataId: rightGridSnappedClasslists[0].data_id,
      });
    }
    if (topGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: topGridSnappedClasslists[0].className,
        dataId: topGridSnappedClasslists[0].data_id,
      });
    }
    if (middleGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: middleGridSnappedClasslists[0].className,
        dataId: middleGridSnappedClasslists[0].data_id,
      });
    }
    if (bottomGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: bottomGridSnappedClasslists[0].className,
        dataId: bottomGridSnappedClasslists[0].data_id,
      });
    }

    if (snappedClassLists.length > 0) {
      snappedClassLists.forEach(classlist => {
        document.querySelector("." + classlist.className).classList.add("visible-grid-line");
      });
    }
  };

  const showWidgetBarSnappedGridLines = activeWidgetBar => {
    let activeWidgetBarLeft = parseFloat(activeWidgetBar.style.transform.split("(")[1]) + PAGE_TIMELINE_LEFT_SECTION;
    let activeWidgetBarRight = activeWidgetBarLeft + parseFloat(activeWidgetBar.style.width);

    let leftGridSnappedClasslists = [],
      rightGridSnappedClasslists = [],
      snappedClassLists = [];

    hideGrideLines();

    document.querySelectorAll(".widget-container .grid-line").forEach(node => {
      let data_id = node.dataset.id;
      let data_line = node.dataset.line;
      let targetClass = node.classList[1];
      let gridLineLeft = parseFloat(node.style.left);

      if (data_line == "vertical") {
        if (gridLineLeft - activeWidgetBarLeft >= -2 && gridLineLeft - activeWidgetBarLeft <= 2) {
          leftGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
        if (gridLineLeft - activeWidgetBarRight >= -2 && gridLineLeft - activeWidgetBarRight <= 2) {
          rightGridSnappedClasslists.push({
            className: targetClass,
            dataId: data_id,
          });
        }
      }
    });

    if (leftGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: leftGridSnappedClasslists[0].className,
        dataId: leftGridSnappedClasslists[0].data_id,
      });
    }
    if (rightGridSnappedClasslists.length > 0) {
      snappedClassLists.push({
        className: rightGridSnappedClasslists[0].className,
        dataId: rightGridSnappedClasslists[0].data_id,
      });
    }
    if (snappedClassLists.length > 0) {
      snappedClassLists.forEach(classlist => {
        document.querySelector("." + classlist.className).classList.add("visible-grid-line");
      });
    }
  };

  const hideGrideLines = () => {
    document.querySelectorAll(".grid-line").forEach(node => {
      node.classList.remove("visible-grid-line");
    });
    document.querySelectorAll(".dhp-root-widget.border-heighlight").forEach(node => {
      node.classList.remove("border-heighlight");
    });
    document.querySelector(".dhp-page-block.border-heighlight")?.classList.remove("border-heighlight");
    document.querySelector(".dhp-page-canvas.border-heighlight")?.classList.remove("border-heighlight");
  };

  useEffect(() => {
    if (metadata.activeWidgetId.length > 0) activateGridLines();
    else updateSnapGrid();
  }, [metadata.activeWidgetId, dimension]);

  return { showSnappedGridLines, hideGrideLines, showWidgetBarSnappedGridLines };
};

export default useSnaptoGrid;
