import React, { useContext, useState } from "react";
import cx from "classnames";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import Modal from "../../ui/modal";
import BlockTileModal from "../Modals/BlockTileModal";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const BlockTile = () => {
  let { blockNodes, metadata } = useContext(EditorContext);

  const [showModalStatus, setShowModalStatus] = useState(false);

  return (
    <>
      <li className={style["custom-tooltip"]} onClick={() => setShowModalStatus(!showModalStatus)}>
        {blockNodes[metadata.activeBlockIdx]?.blockName && blockNodes[metadata.activeBlockIdx]?.blockName !== "" && (
          <>
            <span>
              <Icon icon="ui-ok" />
            </span>
            <div className={cx(style["custom-tooltip-content"], [style["right"]])}>
              {blockNodes[metadata.activeBlockIdx]?.blockName}
            </div>
          </>
        )}
        {(!blockNodes[metadata.activeBlockIdx]?.blockName || blockNodes[metadata.activeBlockIdx]?.blockName == "") && (
          <>
            <span>
              <Icon icon="ui-crayon" />
            </span>
            <div className={cx(style["custom-tooltip-content"], [style["right"]])}>Block Tile</div>
          </>
        )}
      </li>

      {showModalStatus && (
        <Modal
          showModal={showModalStatus}
          setShowModal={setShowModalStatus}
          component={BlockTileModal}
          modalClassName={"block-tile-modal"}
        />
      )}
    </>
  );
};

export default BlockTile;
