import { useContext } from "react";

import * as constant from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useGoToBlock = () => {
  let { documentType, metadata, dimension } = useContext(EditorContext);

  const goToBlock = () => {
    if (
      metadata.activeBlockId != "" &&
      [constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) &&
      !metadata.disableAutoScroll
    ) {
      let currentZoom = dimension.zoom / 100;
      let targetOffset = document.querySelector("#" + metadata.activeBlockId).offsetTop * currentZoom;

      setTimeout(function () {
        if (targetOffset >= 0) document.querySelector("#canvas-panel-area").scrollTop = targetOffset;
      }, 500);
    }
  };

  return { goToBlock };
};

export default useGoToBlock;
