import { useContext, useState } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import useDocumentParser from "./useDocumentParser";
import useAddPage from "./useAddPage";
import useFitToScreen from "./useFitToScreen";
import { calculateNewZoomValue } from "../_helpers/utils";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../constants/editor";
import { useSelector } from "react-redux";

const useDocumentReader = (fitToScreen, setFitToScreen) => {
  const { start: initDocumentParser } = useDocumentParser();
  const { addPage } = useAddPage();
  const userInfo = useSelector(state => state?.auth?.user);

  const {
    updateWidgets,
    updateGrid,
    updatePageMargin,
    updateDimension,
    updateMetadata,
    setIsDocumentReady,
    documentType,
    setAudios,
    isTimeLineViewOpen,
  } = useContext(EditorContext);

  const [onload, setOnload] = useState(true);
  useFitToScreen(fitToScreen, setFitToScreen, onload, setOnload);

  const documentReader = {
    meta: false,

    updateContext: ({ pageNodes, blockNodes, widgets, backgroundColors, backgroundImages, ...meta }) => {
      updateWidgets(
        widgets,
        pageNodes,
        blockNodes,
        backgroundColors,
        backgroundImages,
        false,
        false,
        false,
        false,
        meta?.audios
      );

      updateDimension({
        ...meta?.dimension,
        zoom:
          documentType === TYPE_INFOGRAPHIC
            ? 100
            : calculateNewZoomValue(
                meta.dimension.width,
                documentType === TYPE_PROPOSAL ? 768 : meta.dimension.height,
                isTimeLineViewOpen,
                userInfo?.video_enabled
              ),
      });
      updatePageMargin(meta?.margin ?? meta?.pageMargin);
      updateGrid(meta?.grid);

      updateMetadata({
        pageController: {
          style: {
            top: 0,
          },
        },
        activeOutSideCanvasArea: false,
        activePageId: pageNodes[0]?.pageId,
        activePageIdx: 0,
        activeBlockId: blockNodes[0]?.blockId,
        activeBlockIdx: 0,
        activeWidgetId: false,
        activeWidgetType: false,
        activeWidgetIdx: 0,
        pageCount: pageNodes?.length,
        blockCount: blockNodes?.length,
      });
    },

    getContext: async ({ data, returnObj = null }) => {
      // blank document (react js)
      if (!data?.version?.rawdata) {
        return returnObj;
      }

      // react js document
      if (data?.version?.rawdata?.contexts) {
        returnObj = data?.version?.rawdata?.contexts;

        return returnObj;
      }

      // angular js document
      if (!data?.version?.rawdata?.contexts) {
        returnObj = await initDocumentParser({ data });

        return returnObj;
      }
    },

    start: async ({ data }) => {
      const contextData = await documentReader.getContext({ data });

      if (contextData) {
        // for saved document (angular + react)
        documentReader.updateContext({ ...contextData });
      } else {
        // for blank document
        addPage();
      }

      setTimeout(() => {
        setIsDocumentReady(true);
        setFitToScreen(true);
      }, 30);
    },
  };

  return { start: documentReader.start };
};

export default useDocumentReader;
