import { ANIMATION, TABLE, TEXT, TYPE_INFOGRAPHIC, TYPE_PROPOSAL, UPLOAD, VIDEO } from "../../constants/editor";
import { durationInSec } from "../../_helpers/utils";
import { permission } from "./_permission";

const authenticate = async (actions, document, _helpers) => {
  return await actions.filter(action => permission(action.value, document, _helpers));
};

const formatUrl = (action, document, baseUrlRequired) => {
  const url = {
    base: baseUrlRequired ? process.env.BASE_URL : "",
    path: action?.path ? action.path.replace("{documentId}", document.id) : "",
    search: action?.search ?? "",
  };
  return url.base + url.path + url.search;
};

const documentInfo = {
  isCached: document => {
    const cacheGroup = localStorage.getItem("documents");
    const cacheGroupParsed = cacheGroup ? JSON.parse(cacheGroup) : false;
    const cacheItem = cacheGroupParsed ? cacheGroupParsed[document.id] : false;
    return {
      groupExists: cacheGroupParsed,
      groupObj: cacheGroupParsed ? cacheGroupParsed : {},
      itemExists: cacheItem,
      itemObj: cacheItem ? cacheItem : {},
    };
  },
  get: (document, key) => {
    const { itemExists, itemObj } = documentInfo.isCached(document);
    const ref = itemExists && [key] in itemObj ? itemObj : document;
    const exists = [key] in ref && ref[key] !== null && ref[key] !== undefined;
    return {
      exists: exists,
      data: exists ? ref[key] : "",
    };
  },
  set: (document, obj) => {
    let updatedObj = {};
    const { groupExists, groupObj, itemExists, itemObj } = documentInfo.isCached(document);
    if (!groupExists) {
      updatedObj = { [document.id]: obj };
    }
    if (groupExists && !itemExists) {
      updatedObj = { ...groupObj, [document.id]: obj };
    }
    if (groupExists && itemExists) {
      updatedObj = { ...groupObj, [document.id]: { ...itemObj, ...obj } };
    }
    localStorage.setItem("documents", JSON.stringify(updatedObj));
  },
};

const __exportPageCanvasRawDataByHTML = (htmlData, docType, metaData) => {
  let htmlElemObj = document.createElement("div");
  htmlElemObj.innerHTML = htmlData.trim();

  let removeElementList = [
    "ui-resizable-handle",
    "ui-rotatable-handle",
    "group-rotate-angle",
    "dhp-page-margin-overlay",
    "dhp-page-guideLine",
    "grid-line",
    "dhp-page-grid",
    "block-resize-handler",
  ];
  let removeClassList = {
    "dhp-page-block": ["border-heighlight", "current-canvas-selected"],
    "dhp-page-widget": ["border-heighlight", "group-selected", "child-selected", "drag-selected", "single-selected"],
    "dhp-page-group": ["border-heighlight", "group-selected", "child-selected", "drag-selected", "single-selected"],
  };

  // Remove filtered elements
  removeElementList.forEach(item => {
    htmlElemObj?.querySelectorAll(`.${item}`)?.forEach(el => el.remove());
  });

  // Remove class from filtered elements
  Object.keys(removeClassList)?.forEach(itemObj => {
    htmlElemObj?.querySelectorAll(`.${itemObj}`)?.forEach(el => {
      removeClassList[itemObj].forEach(item => {
        el.classList.remove(item);
      });
    });
  });

  // restrict document from being saved with the attribute data-playing='true'
  htmlElemObj?.querySelectorAll(`[data-asset-type='${VIDEO}']`)?.forEach(elem => {
    elem.setAttribute("data-playing", false);
  });

  htmlElemObj?.querySelectorAll(`[data-asset-type='${TEXT}'] *, [data-asset-type='${TABLE}'] *`)?.forEach(elem => {
    let fontFamily = elem.style.fontFamily.replace(/(^"|"$)/g, "");

    if (fontFamily !== "") {
      if (!metaData.fonts.includes(fontFamily)) {
        metaData.fonts.push(fontFamily);
      }
    }
  });

  if (![TYPE_INFOGRAPHIC.toLowerCase(), TYPE_PROPOSAL.toLowerCase()].includes(docType)?.toLowerCase()) {
    // calculate higth if type is not "Infographic" or "Proposal"
    htmlElemObj.querySelectorAll(".dhp-page-canvas")?.forEach(canvasData => {
      // Update value height width for  "dhp-page-canvas"
      canvasData.style.height = `${metaData.dimension.height}px`;
      canvasData.style.width = `${metaData.dimension.width}px`;

      // set scale to 1 for block
      canvasData.querySelector(".dhp-page-block").style.transform = "scale(1)";
    });
  }

  return htmlElemObj.innerHTML;
};

// Generate RAW HTML data for saving changes
const exportPageCanvasRawData = ({ docType, metaData, canvasHtml }) => {
  metaData.durations = [];
  let documentRawdata;
  documentRawdata = [];
  canvasHtml?.forEach(elem => {
    let htmlData = elem.outerHTML;
    htmlData = __exportPageCanvasRawDataByHTML(htmlData, docType, metaData);

    documentRawdata.push(htmlData);
    let allVideoLength = [0];

    elem.querySelectorAll("video")?.forEach(videoElem => {
      let duration =
        videoElem?.duration ||
        durationInSec(videoElem?.parentElement?.parentElement?.parentElement?.getAttribute("data-duration")) ||
        0;
      allVideoLength.push(!duration ? 0 : duration);
    });

    // Calculate duratioin for each video node
    elem.querySelectorAll(`[data-asset-type='${ANIMATION}'], [data-asset-type='${UPLOAD}']`)?.forEach(mediaElem => {
      let duration = mediaElem.getAttribute("data-duration") || 0;
      allVideoLength.push(!duration ? 0 : duration);
    });

    if (allVideoLength.length > 0) {
      metaData.durations.push(Math.max(...allVideoLength));
    }
  });

  return documentRawdata;
};

export { authenticate, formatUrl, documentInfo, exportPageCanvasRawData };
