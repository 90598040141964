import cx from "classnames";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import Modal from "../../ui/modal";
import DeleteElemModal from "../Modals/DeleteElemModal";
import { Icon } from "../../ui/icon";
import * as constant from "../../../constants/editor";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useDeletePage from "../../../hooks/useDeletePage";

let style = Object.assign({}, global);

const DeletePage = props => {
  let { updateScrollEvent } = useContext(EditorContext);
  const [showModalStatus, setShowModalStatus] = useState(false);

  const { deletePage, deleteBlock } = useDeletePage();

  const modalData = {
    title: `Delete this ${props.pageType}`,
    body: `Are you sure to delete this ${props.pageType}?`,
    btnLabel: "Delete",
  };

  //Function to show the delete modal
  const showModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  //Function for close modal
  const closeModal = () => {
    setShowModalStatus(!showModalStatus);
  };

  const deletePageBlock = () => {
    // To prevent auto scroll
    updateScrollEvent(true);

    if ([constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(props.documentType)) {
      deleteBlock();
    } else {
      deletePage();
    }
  };

  return (
    <ul className={cx(style["canvas-ctrl-group"], style["rounded"], style["delete-page"])} onClick={showModal}>
      <li className={style["custom-tooltip"]}>
        <span>
          <Icon icon="ui-trash" />
        </span>
        <div className={cx(style["custom-tooltip-content"], props.pageSorterList ? [style["left"]] : [style["right"]])}>
          Delete {props.pageType}
        </div>
      </li>

      {showModalStatus && (
        <Modal
          showModal={showModalStatus}
          setShowModal={setShowModalStatus}
          component={DeleteElemModal}
          modalTitle={modalData?.title}
          modalBody={modalData?.body}
          modalButtonLabel={modalData?.btnLabel}
          deleteElem={deletePageBlock}
          closeModal={closeModal}
        />
      )}
    </ul>
  );
};

//Props type validation
DeletePage.propTypes = {
  documentType: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  pageSorterList: PropTypes.bool,
};

export default DeletePage;
