import { useContext, useLayoutEffect } from "react";
import { EditorContext } from "../containers/editor/EditorLayout";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../../src/constants/editor";

const useSetActivePageBlock = (activePageBlockObj, isResizing) => {
  const { documentType, metadata, blockNodes, pageNodes, updateMetadata, pagePlayData, setWhichPagePlaying } =
    useContext(EditorContext);

  useLayoutEffect(() => {
    if (isResizing === true) return;
    if (!activePageBlockObj) return;
    const targetPageIdx = parseInt(activePageBlockObj?.targetPageIdx);
    const targetBlockIdx = parseInt(activePageBlockObj?.targetBlockIdx);

    let updatedPayload = {
      activeBlockId: blockNodes[targetBlockIdx]?.blockId,
      activeBlockIdx: targetBlockIdx,
      activeAudioId: activePageBlockObj?.activeAudioId ?? false,
      activeWidgetId: activePageBlockObj?.activeWidgetId ?? false,
      activeWidgetType: activePageBlockObj?.activeWidgetType ?? false,
      activeOutSideCanvasArea: activePageBlockObj?.activeOutSideCanvasArea ?? false,
      disableAutoScroll: activePageBlockObj?.disableAutoScroll ?? false,
    };

    // Check if the document type not infographic
    if (![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
      Object.assign(updatedPayload, { activePageId: pageNodes[targetPageIdx]?.pageId, activePageIdx: targetPageIdx });
    }

    // Update Editor context metadata
    updateMetadata({ ...metadata, ...updatedPayload });

    // stop previous page play (in-case scroll defocus)
    if (pagePlayData.pageProgress > 0 && pagePlayData.pageIdx !== targetPageIdx) {
      setWhichPagePlaying({
        action: "force_stop",
        playingPageIdx: pagePlayData.pageIdx,
      });
    }
  }, [activePageBlockObj]);
};

export default useSetActivePageBlock;
