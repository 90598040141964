import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Input as RInput,
  Table as RTable,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import {
  calculateNewPositionOnRotatedObjectResize,
  changeWidgetColorAccordingBackground,
  colorHexCodeValidation,
  getCssTransformObj,
  hexToRGB,
} from "../../../_helpers/utils";
import ColorPicker from "../../ui/colorPicker";
import FontFamily from "../ContextualMenu/FontFamily";
import FontSize from "../ContextualMenu/FontSize";
import Bold from "../ContextualMenu/Bold";
import Italic from "../ContextualMenu/Italic";
import Underline from "../ContextualMenu/Underline";
import TextAlign from "../ContextualMenu/TextAlign";
import { widgetConfig } from "../editor_config";
import useAddWidget from "../../../hooks/useAddWidget";
import useElementInnerHtml from "../../../hooks/useElementInnerHtml";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import useResizer from "../../../hooks/useResizer";
import useAlignment from "../../../hooks/useAlignment";
import { TABLE } from "../../../constants/editor";
import UseCheckWidgetAllignment from "../../../hooks/UseCheckWidgetAllignment";
import useOverflowDetection from "../../../hooks/useOverflowDetection";
import useTableResizer from "../../../hooks/useTableResizer";

let style = Object.assign({}, global);

const fontSizeList = widgetConfig?.tables?.fontSize;
const TOP = "start",
  MIDDLE = "center",
  BOTTOM = "end",
  HEADER = "header",
  BODY = "body",
  FOOTER = "footer";

const pasteFn = e => {
  if (e.clipboardData && e.clipboardData.getData) {
    let paste = (e.clipboardData || window.clipboardData).getData("text");
    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));
    e.preventDefault();
  }
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const FloatingTooltipComponent = ({
  applyBoldFn,
  applyUnderLineFn,
  tooltipFor,
  handleMergeCells,
  handleSplitCells,
  handleBulletApply,
  handleNumberApply,
  handleAlignCells,
  bulletActive,
  numberingAcive,
  defaltFontBold,
  defaltFontUnderline,
  isBoldeAvailable,
  horizontalScroll,
}) => {
  const elementRef = useRef(null);

  const [alignPosition, setAlignPosition] = useState(tooltipFor?.alignPosition || "");

  let tableDiv = document.querySelector(".TMright-pane");

  useEffect(() => {
    setTimeout(() => {
      if (elementRef.current && tableDiv) {
        const elementRect = elementRef.current.getBoundingClientRect();
        const paneRect = tableDiv.getBoundingClientRect();

        // Check if the element has crossed the right pane and left pane
        if (elementRect.left <= paneRect.left || elementRect.right >= paneRect.right) {
          elementRef.current.parentElement.classList.add("invisible");
        } else {
          elementRef.current.parentElement.classList.remove("invisible");
        }
      }
    }, 50);
  }, [horizontalScroll]);

  return (
    <div id="dhpTableCellEditableTooltip" className={cx(style[""])} ref={elementRef}>
      <ul className={style["d-flex"]}>
        {tooltipFor?.formattingOpt && (
          <>
            {/* <li
              className={cx(style["toolset-group"], {
                [style["active"]]: defaltFontBold,
                [style["disable"]]: isBoldeAvailable,
              })}
              onMouseDown={applyBoldFn}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="bold2" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Bold</div>
                </div>
              </div>
            </li> */}
            <li
              className={cx(style["toolset-group"], { [style["active"]]: defaltFontUnderline })}
              onMouseDown={applyUnderLineFn}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-underline" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Underline</div>
                </div>
              </div>
            </li>
            {/* <Bold
              defaultFontBold={defaltFontBold}
              setFontBold={applyBoldFn}
              isDisabledBold={isBoldeAvailable}
              sourceComponent={TABLE}
            /> */}

            {/* <Underline
              fontUnderline={defaltFontUnderline}
              setFontUnderline={applyUnderLineFn}
              sourceComponent={TABLE}
            /> */}

            {tooltipFor?.enableBullet && (
              <li
                className={cx(style["toolset-group"], { [style["active"]]: bulletActive })}
                onMouseDown={handleBulletApply}>
                <div className={style["toolset-item"]}>
                  <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                    <Icon icon="ui-list" />
                    <div className={cx(style["custom-tooltip-content"], style["top"])}>Bullets</div>
                  </div>
                </div>
              </li>
            )}
            {tooltipFor?.enableNumbering && (
              <li
                className={cx(style["toolset-group"], { [style["active"]]: numberingAcive })}
                onMouseDown={handleNumberApply}>
                <div className={style["toolset-item"]}>
                  <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                    <Icon icon="ui-numbered-list" />
                    <div className={cx(style["custom-tooltip-content"], style["top"])}>Numbering</div>
                  </div>
                </div>
              </li>
            )}
          </>
        )}
        {tooltipFor?.mergeSplitOpt && (
          <>
            <li
              className={cx(style["toolset-group"], { [style["disable"]]: !tooltipFor.mergeOpt })}
              onMouseDown={handleMergeCells}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-merge-cells" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Merge Cells</div>
                </div>
              </div>
            </li>
            <li
              className={cx(style["toolset-group"], { [style["disable"]]: !tooltipFor.splitOpt })}
              onMouseDown={handleSplitCells}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-split-cells" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Split Cell</div>
                </div>
              </div>
            </li>
          </>
        )}
        {tooltipFor?.alignOpt && (
          <>
            <li
              className={cx(style["toolset-group"], { [style["active"]]: alignPosition == TOP })}
              onMouseDown={() => (handleAlignCells(TOP), setAlignPosition(TOP))}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-align-text-top" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Align Top</div>
                </div>
              </div>
            </li>
            <li
              className={cx(style["toolset-group"], { [style["active"]]: alignPosition == MIDDLE })}
              onMouseDown={() => (handleAlignCells(MIDDLE), setAlignPosition(MIDDLE))}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-align-text-center" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Align Center</div>
                </div>
              </div>
            </li>
            <li
              className={cx(style["toolset-group"], { [style["active"]]: alignPosition == BOTTOM })}
              onMouseDown={() => (handleAlignCells(BOTTOM), setAlignPosition(BOTTOM))}>
              <div className={style["toolset-item"]}>
                <div className={cx(style["toolset-action"], style["custom-tooltip"])}>
                  <Icon icon="ui-align-text-bottom" />
                  <div className={cx(style["custom-tooltip-content"], style["top"])}>Align Bottom</div>
                </div>
              </div>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

const ColorPickerWidget = ({ color, setColor }) => {
  const [widgetColor, setWidgetColor] = useState();
  const [eyedropperColor, setEyedropperColor] = useState();

  //update color if user chose color from eyedropper
  useEffect(() => {
    if (eyedropperColor) {
      setColor(eyedropperColor);
      setEyedropperColor();
    }
  }, [eyedropperColor]);

  useEffect(() => {
    let isHex = colorHexCodeValidation(color);
    if (isHex) {
      let rgbColor = hexToRGB(color);
      setWidgetColor(changeWidgetColorAccordingBackground(rgbColor));
    }
  }, [color]);

  return (
    <>
      {widgetColor && (
        <UncontrolledDropdown
          setActiveFromChild
          className={cx(style["color-section-block"], style["color-picker-dropdown"])}>
          <DropdownToggle tag="a" className={cx(style["p-0"], style["d-flex"])}>
            <Icon
              icon="ui-fill-color2"
              style={{
                backgroundColor: color,
                color: widgetColor,
                padding: "0.5rem",
                borderRadius: "0.375rem",
              }}
            />
          </DropdownToggle>
          <DropdownMenu className={cx(style["border-0"], style["p-0"])}>
            <ColorPicker
              color={color}
              setColor={setColor}
              isHideTransparentOpt={true}
              eyedropperColor={eyedropperColor}
              setEyedropperColor={setEyedropperColor}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};

ColorPickerWidget.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
};

const TableFooter = ({
  idx,
  footerInfo,
  cellBorder,
  editText,
  tableFooterStyle,
  handleSelection,
  handleMouseDown,
  handleMouseEnter,
  isSelected,
  footerBackground,
  uniqueId,
  setSelectedTarget,
}) => {
  const footerContainer = useRef(null);
  const editableField = useRef(null);
  const doubleClickFlag = useRef(false);

  const [colValue, setColValue] = useState(footerInfo?.value);
  const [clickTimeout, setClickTimeout] = useState(null);

  const handleMouseDownWrapper = (rowIndex, colIndex, rowType) => {
    if (!doubleClickFlag.current) {
      handleMouseDown(rowIndex, colIndex, rowType);
    }
  };

  const handleMouseUpWrapper = () => {
    if (!doubleClickFlag.current && clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
  };

  const handleDoubleClick = () => {
    doubleClickFlag.current = true;
    editText(editableField, `${uniqueId}footer-tooltip-${idx}`, "footer", idx);
    setTimeout(() => {
      doubleClickFlag.current = false;
    }, 300);
  };

  const handleMouseDownEvent = (rowIndex, colIndex, rowType) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }

    setClickTimeout(
      setTimeout(() => {
        handleMouseDownWrapper(rowIndex, colIndex, rowType);
        setClickTimeout(null);
      }, 10)
    );
  };

  useEffect(() => {
    setColValue(footerInfo?.value);
  }, [footerInfo?.value]);

  return (
    <td
      id={`${uniqueId}footer-tooltip-${idx}`}
      style={{
        height: "max-content",
        background: footerBackground,
        opacity: isSelected(-2, idx, "footer") ? 0.5 : 1,
        ...cellBorder,
      }}
      ref={footerContainer}
      onDoubleClick={handleDoubleClick}
      colSpan={footerInfo?.colspan}
      onMouseDown={() => handleMouseDownEvent(-2, idx, "footer")}
      onMouseUp={handleMouseUpWrapper}
      onMouseEnter={() => handleMouseEnter(-2, idx, "footer")}
      className={style["position-relative"]}>
      <div
        className={`${footerInfo.alignClass || ""}`}
        style={{
          ...tableFooterStyle,
          padding: "0.3rem",
          // background: footerBackground,
          height: "100%",
          width: "100%",
          // opacity: isSelected(-2, idx, "footer") ? 0.5 : 1,
        }}>
        <div
          ref={editableField}
          contentEditable={false}
          onPaste={e => pasteFn(e)}
          // onMouseUp={() => handleSelection(`${uniqueId}footer-tooltip-${idx}`)}
          onMouseDown={() => setSelectedTarget(`${uniqueId}footer-tooltip-${idx}`)}
          // dangerouslySetInnerHTML={{
          //   __html: /<\/?[a-z][\s\S]*>/i.test(colValue) ? colValue : `<div>${colValue}</div>`,
          // }}
          dangerouslySetInnerHTML={{
            __html: /<\/?[a-z][\s\S]*>/i.test(colValue) ? colValue : `<div>${colValue || "&nbsp;"}</div>`,
          }}></div>
      </div>
    </td>
  );
};

TableFooter.propTypes = {
  idx: PropTypes.number.isRequired,
  footerInfo: PropTypes.object.isRequired,
  cellBorder: PropTypes.object.isRequired,
  editText: PropTypes.func.isRequired,
  tableFooterStyle: PropTypes.object.isRequired,
  setHoveredCol: PropTypes.func,
  updateTableData: PropTypes.func.isRequired,
};

const TableHeader = ({
  idx,
  colInfo,
  cellBorder,
  editText,
  tableHeaderStyle,
  updateTableData,
  handleSelection,
  handleMouseDown,
  handleMouseEnter,
  isSelected,
  headerBackground,
  uniqueId,
  setSelectedTarget,
}) => {
  const refRightResize = useRef(null);
  const headerContainer = useRef(null);
  const editableField = useRef(null);
  const doubleClickFlag = useRef(false);

  const [colValue, setColValue] = useState(colInfo?.value);
  const [clickTimeout, setClickTimeout] = useState(null);

  // Hooks for resize table header
  // let newWidth = useResizer(headerContainer, refRightResize);
  let newWidth = useTableResizer(headerContainer, refRightResize);

  const handleMouseDownWrapper = (rowIndex, colIndex, rowType) => {
    if (!doubleClickFlag.current) {
      handleMouseDown(rowIndex, colIndex, rowType);
    }
  };

  const handleMouseUpWrapper = () => {
    if (!doubleClickFlag.current && clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
  };

  const handleDoubleClick = () => {
    doubleClickFlag.current = true;
    editText(editableField, `${uniqueId}col-tooltip-${idx}`, "columns", idx);
    setTimeout(() => {
      doubleClickFlag.current = false;
    }, 300);
  };

  const handleMouseDownEvent = (rowIndex, colIndex, rowType) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }

    setClickTimeout(
      setTimeout(() => {
        handleMouseDownWrapper(rowIndex, colIndex, rowType);
        setClickTimeout(null);
      }, 10)
    );
  };

  useEffect(() => {
    // if (newWidth) updateTableData("columns", idx, newWidth, null, null, "width");
    if (newWidth) updateTableData("columns", idx, null, true, "width", newWidth);
  }, [newWidth]);

  useEffect(() => {
    setColValue(colInfo?.value);
  }, [colInfo?.value]);

  return (
    <th
      id={`${uniqueId}col-tooltip-${idx}`}
      style={{
        width: colInfo?.width,
        height: "max-content",
        background: headerBackground,
        opacity: isSelected(-1, idx, "header") ? 0.5 : 1,
        ...cellBorder,
      }}
      ref={headerContainer}
      onDoubleClick={handleDoubleClick}
      colSpan={colInfo?.colspan}
      onMouseDown={() => handleMouseDownEvent(-1, idx, "header")}
      onMouseUp={handleMouseUpWrapper}
      onMouseEnter={() => handleMouseEnter(-1, idx, "header")}
      className={style["position-relative"]}>
      <div
        className={`${colInfo.alignClass || ""}`}
        style={{
          ...tableHeaderStyle,
          // background: headerBackground,
          padding: "0.3rem",
          height: "100%",
          width: "100%",
          // opacity: isSelected(-1, idx, "header") ? 0.5 : 1,
        }}>
        <div
          ref={editableField}
          contentEditable={false}
          onPaste={e => pasteFn(e)}
          // onMouseUp={() => handleSelection(`${uniqueId}col-tooltip-${idx}`)}
          onMouseDown={() => setSelectedTarget(`${uniqueId}col-tooltip-${idx}`)}
          // dangerouslySetInnerHTML={{
          //   __html: /<\/?[a-z][\s\S]*>/i.test(colValue) ? colValue : `<div>${colValue}</div>`,
          // }}
          dangerouslySetInnerHTML={{
            __html: /<\/?[a-z][\s\S]*>/i.test(colValue) ? colValue : `<div>${colValue || "&nbsp;"}</div>`,
          }}></div>
      </div>

      <div className="resizer-r" ref={refRightResize}></div>
    </th>
  );
};

TableHeader.propTypes = {
  idx: PropTypes.number.isRequired,
  colInfo: PropTypes.object.isRequired,
  cellBorder: PropTypes.object.isRequired,
  editText: PropTypes.func.isRequired,
  tableHeaderStyle: PropTypes.object.isRequired,
  setHoveredCol: PropTypes.func,
  updateTableData: PropTypes.func.isRequired,
};

const TableRowTd = ({
  colIndex,
  editText,
  cellBorder,
  rowIndex,
  tableConfigData,
  tableBodyStyle,
  rowInfo,
  handleSelection,
  handleMouseDown,
  handleMouseEnter,
  isSelected,
  uniqueId,
  setSelectedTarget,
}) => {
  const editableField = useRef(null);
  const doubleClickFlag = useRef(false);
  const [rowValue, setRowValue] = useState(rowInfo?.cells[colIndex]?.value);
  const [clickTimeout, setClickTimeout] = useState(null);

  const handleMouseDownWrapper = (rowIndex, colIndex, rowType) => {
    if (!doubleClickFlag.current) {
      handleMouseDown(rowIndex, colIndex, rowType);
    }
  };

  const handleMouseUpWrapper = () => {
    if (!doubleClickFlag.current && clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }
  };

  const handleDoubleClick = () => {
    doubleClickFlag.current = true;
    editText(editableField, `${uniqueId}tableCell-col${colIndex}-row${rowIndex}`, "rows", colIndex, rowIndex);
    setTimeout(() => {
      doubleClickFlag.current = false;
    }, 300);
  };

  const handleMouseDownEvent = (rowIndex, colIndex, rowType) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    }

    setClickTimeout(
      setTimeout(() => {
        handleMouseDownWrapper(rowIndex, colIndex, rowType);
        setClickTimeout(null);
      }, 10) // previous 150
    );
  };

  useEffect(() => {
    setRowValue(rowInfo?.cells?.[colIndex]?.value);
  }, [rowInfo?.cells[colIndex]]);

  return (
    <td
      onDoubleClick={handleDoubleClick}
      style={{ ...cellBorder, padding: "0", height: "max-content", overflow: "hidden" }}
      colSpan={rowInfo?.cells[colIndex]?.colspan}
      rowSpan={rowInfo?.cells[colIndex]?.rowspan}
      onMouseDown={() => handleMouseDownEvent(rowIndex, colIndex, "body")}
      onMouseUp={handleMouseUpWrapper}
      onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
      id={`${uniqueId}tableCell-col${colIndex}-row${rowIndex}`}>
      <div
        className={`${rowInfo?.cells?.[colIndex]?.alignClass || ""}`}
        style={{
          ...tableBodyStyle,
          padding: "0.3rem",
          height: "100%",
          width: "100%",
          display: "block",
          background:
            tableConfigData?.formatting?.tbody?.colorScheme === "alternate"
              ? rowIndex % 2 === 0
                ? tableConfigData?.formatting?.tbody?.backgroundColorOdd
                : tableConfigData?.formatting?.tbody?.backgroundColorEven
              : tableConfigData?.formatting?.tbody?.backgroundColorOdd,
          opacity: isSelected(rowIndex, colIndex, "body") ? 0.5 : 1,
        }}>
        <div
          key={colIndex}
          ref={editableField}
          contentEditable={false}
          onPaste={e => pasteFn(e)}
          // onMouseUp={() => handleSelection(`${uniqueId}tableCell-col${colIndex}-row${rowIndex}`)}
          onMouseDown={() => setSelectedTarget(`${uniqueId}tableCell-col${colIndex}-row${rowIndex}`)}
          // dangerouslySetInnerHTML={{
          //   __html: /<\/?[a-z][\s\S]*>/i.test(rowValue) ? rowValue : `<div>${rowValue}</div>`,
          // }}
          dangerouslySetInnerHTML={{
            __html: /<\/?[a-z][\s\S]*>/i.test(rowValue) ? rowValue : `<div>${rowValue || "&nbsp;"}</div>`,
          }}></div>
      </div>
    </td>
  );
};

TableRowTd.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  colIndex: PropTypes.number.isRequired,
  editText: PropTypes.func.isRequired,
  cellBorder: PropTypes.object.isRequired,
  tableConfigData: PropTypes.object.isRequired,
  tableBodyStyle: PropTypes.object.isRequired,
  rowInfo: PropTypes.object,
};

const TableRow = ({
  rowIndex,
  tableDatas,
  editText,
  cellBorder,
  tableConfigData,
  tableBodyStyle,
  rowInfo,
  handleSelection,
  handleMouseDown,
  handleMouseEnter,
  isSelected,
  uniqueId,
  setSelectedTarget,
}) => {
  return (
    <tr key={rowIndex} id={`row-tooltip-${rowIndex}`} style={{ height: "31.59px" }}>
      {tableDatas?.columns?.map((_, colIndex) => {
        if (rowInfo?.cells[colIndex]?.merged) return null; // Skip merged cells
        return (
          <TableRowTd
            key={colIndex}
            colIndex={colIndex}
            editText={editText}
            cellBorder={cellBorder}
            rowIndex={rowIndex}
            tableConfigData={tableConfigData}
            tableBodyStyle={tableBodyStyle}
            rowInfo={rowInfo}
            handleSelection={handleSelection}
            handleMouseDown={handleMouseDown}
            handleMouseEnter={handleMouseEnter}
            isSelected={isSelected}
            uniqueId={uniqueId}
            setSelectedTarget={setSelectedTarget}
          />
        );
      })}
    </tr>
  );
};

TableRow.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  tableDatas: PropTypes.object.isRequired,
  selected: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
  editText: PropTypes.func.isRequired,
  cellBorder: PropTypes.object.isRequired,
  tableConfigData: PropTypes.object.isRequired,
  tableBodyStyle: PropTypes.object.isRequired,
  rowInfo: PropTypes.object,
  setReloadTableBody: PropTypes.func.isRequired,
  setTableDatas: PropTypes.func.isRequired,
};

const TableContent = ({
  forwardedRef,
  tableConfigData,
  tableDatas,
  deleteRowColumn,
  addRowColumn,
  setTableDatas,
  reloadTableBody,
  setReloadTableBody,
  setUpdatedDragData,
  setShowTable,
  applyFooter,
  uniqueId,
}) => {
  const styleComponent = widgetConfig?.tables?.styleComponent;
  const tableHeaderRef = useRef();
  const tableRef = useRef();

  const { isAtTop, parentRef, childRef } = useOverflowDetection();

  // Set Tooltip boundry
  const tooltipBoundry = document.querySelector(".table-modal-wrap");

  // Set Tooltip boundry
  const tooltipContainer = document.querySelector(".table-wrap");

  // Set header and body style func
  const setStyle = type => {
    if (tableConfigData?.formatting?.[type]) {
      let styleList = {};
      styleComponent.forEach(style => {
        if (tableConfigData?.formatting?.[type]?.[style]) {
          styleList[style] =
            style === "fontFamily"
              ? `"${tableConfigData?.formatting?.[type]?.[style]}"`
              : tableConfigData?.formatting?.[type]?.[style];
        }
      });
      return styleList;
    }
    return {};
  };

  const setCellBorderStyle = () => {
    return {
      borderTop: `${tableConfigData?.formatting?.border?.width}px ${tableConfigData?.formatting?.border?.horizontal} ${tableConfigData?.formatting?.border?.color}`,
      borderBottom: `${tableConfigData?.formatting?.border?.width}px ${tableConfigData?.formatting?.border?.horizontal} ${tableConfigData?.formatting?.border?.color}`,
      borderLeft: `${tableConfigData?.formatting?.border?.width}px ${tableConfigData?.formatting?.border?.vertical} ${tableConfigData?.formatting?.border?.color}`,
      borderRight: `${tableConfigData?.formatting?.border?.width}px ${tableConfigData?.formatting?.border?.vertical} ${tableConfigData?.formatting?.border?.color}`,
    };
  };

  const [tableHeaderStyle, setTableHeaderStyle] = useState(setStyle("thead"));
  const [tableBodyStyle, setTableBodyStyle] = useState(setStyle("tbody"));
  const [tableFooterStyle, setTableFooterStyle] = useState(setStyle("tfoot"));
  const [cellBorder, setCellBorder] = useState(setCellBorderStyle());
  const [selected, setSelected] = useState();
  const [hoveredCol, setHoveredCol] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [horizontalScroll, setHorizontalScroll] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTarget, setTooltipTarget] = useState();
  const [editableCellInfo, setEditableCellInfo] = useState({});
  const [selectedCells, setSelectedCells] = useState({});
  const [dragStart, setDragStart] = useState(null);
  const [tooltipFor, setTooltipFor] = useState({});
  const [bulletActive, setBulletActive] = useState(false);
  const [numberingAcive, setNumberingAcive] = useState(false);
  const [copiedCells, setCopiedCells] = useState([]);
  const [copiedStructure, setCopiedStructure] = useState([]);
  const [copyType, setCopyType] = useState(null);
  const [defaltFontBold, setDefaltFontBold] = useState(false);
  const [defaltFontUnderline, setDefaltFontUnderline] = useState(false);
  const [isBoldeAvailable, setIsBoldeAvailable] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState();
  const editableStateRef = useRef(editableCellInfo);
  const selectedTargetRef = useRef(selectedTarget);
  const editModeTargetRef = useRef(isEditMode);
  const [hideHeaderDelete, setHideHeaderDelete] = useState(false);
  const [hideBodyDelete, sethideBodyDelete] = useState(false);

  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const editText = (elemnt, cellId, type, idx, rowIdx = null) => {
    setIsEditMode(true);
    elemnt.current.setAttribute("contenteditable", true);
    elemnt.current.focus();
    setEditableCellInfo({
      cellId: cellId,
      type: type,
      idx: idx,
      rowIdx: rowIdx,
    });
    setSelectedCells({});
    resetTooltip();
  };

  const resetTooltip = () => {
    setTooltipTarget();
    setTooltipFor({});
    setDefaltFontBold(false);
    setIsBoldeAvailable(false);
    setBulletActive(false);
    setNumberingAcive(false);
    setDefaltFontUnderline(false);
  };

  const disableEditText = (elemnt, type, idx, rowIdx = null) => {
    if (!elemnt) return;
    setIsEditMode(false);
    updateTableData(type, idx, rowIdx, elemnt);
    elemnt.children[0]?.children[0]?.setAttribute("contenteditable", false);
    resetTooltip();
    setShowTooltip(false);
  };

  const updateTableData = (type, idx, rowIdx, elemnt, key = "value", resizedWidth) => {
    if (!elemnt) return;
    let datas = { ...tableDatas };

    if (type === "columns") {
      if (key === "width")
        datas[type][idx] = { ...datas[type][idx], [key]: resizedWidth };
      else
        datas[type][idx] = { ...datas[type][idx], [key]: elemnt?.children[0]?.children[0]?.innerHTML };
    } else if (type === "rows") {
      datas[type][rowIdx].cells[idx].value = elemnt?.children[0]?.children[0]?.innerHTML;
      const styles = window?.getComputedStyle(elemnt?.parentNode);
      if (styles.height) datas[type][rowIdx]["height"] = styles.height;
    } else if (type === "footer") {
      datas[type][idx] = { ...datas[type][idx], [key]: elemnt?.children[0]?.children[0]?.innerHTML };
    }
    setTableDatas(datas);
  };

  const scrollEvnt = e => {
    setHorizontalScroll(e.currentTarget.scrollLeft);
  };

  const checkSelectedPartFontFormatting = selection => {
    let parentEl;

    if (selection.rangeCount > 0) {
      parentEl = selection.getRangeAt(0).commonAncestorContainer;
      if (parentEl.nodeType != 1) {
        parentEl = parentEl.parentNode;
      }

      if (window.getComputedStyle(parentEl).fontWeight === "700") {
        setDefaltFontBold(true);
      }

      if (window.getComputedStyle(parentEl).textDecoration.includes("underline")) {
        setDefaltFontUnderline(true);
      }
    }
  };

  const checkIsBoldAvailable = fontFamily => {
    let currentFont = fonts?.find(font => font.name === fontFamily.replace(/["]+/g, ""));
    if (currentFont?.source !== "Custom") {
      if (currentFont.bold_weight === "") setIsBoldeAvailable(true);
      else setIsBoldeAvailable(false);
    } else setIsBoldeAvailable(false);
  };

  const handleSelection = targetId => {
    resetTooltip();
    setShowTooltip(false);
    setTimeout(() => {
      const selection = window.getSelection();
      if (selection && !selection.isCollapsed) {
        const cellInfo = targetId.split("-");
        const parentNode = selection.focusNode.parentNode;
        if (cellInfo[2] === "col") {
          // Frr header cell
          checkIsBoldAvailable(tableHeaderStyle.fontFamily);
        }
        if (cellInfo[2] === "tableCell") {
          checkIsBoldAvailable(tableBodyStyle.fontFamily);
          // For Body cell
        }
        if (cellInfo[2] === "footer") {
          checkIsBoldAvailable(tableFooterStyle.fontFamily);
          // For footer cell
        }
        checkSelectedPartFontFormatting(selection);

        const parentTdNode = parentNode.closest("td") || parentNode.closest("th");
        if (
          parentTdNode &&
          parentTdNode.firstElementChild.firstElementChild.tagName === "DIV" &&
          parentTdNode.firstElementChild.firstElementChild.getAttribute("contenteditable") === "true"
        ) {
          let checkUlNode = parentNode?.closest("ul");
          if (checkUlNode) {
            if (checkUlNode.style.listStyleType === "square") {
              setBulletActive(true);
            } else if (checkUlNode.style.listStyleType === "decimal") {
              setNumberingAcive(true);
            }
          }
          setTooltipTarget(targetId);
          setTooltipFor({
            formattingOpt: true,
            enableBullet: cellInfo[2] === "tableCell" ? true : false,
            enableNumbering: cellInfo[2] === "tableCell" ? true : false,
          });
          setShowTooltip(true);
        } else {
          // Handle the case where the parent node is not contentEditable
        }
      }
      setSelectedTarget(null);
    }, 300); // Delay execution by 200 milliseconds
  };

  const splitByDivs = htmlString => {
    // Use a regular expression to match the <span> tag around a <div> and remove it
    htmlString = htmlString.replace(/<span[^>]*>(\s*<div[^>]*>.*<\/div>\s*)<\/span>/, "$1");
    // Use a regular expression to match the content between <div> tags or outside of them
    const result = htmlString.split(/<\/?div>/).filter(text => text.trim() !== "");
    const listItems = result.map(item => `<li><div>${item}</div></li>`).join("");
    return listItems;
  };

  const selectedPartFontFormatting = fontFormat => {
    let selectedPart, sel, parentEl, activeHtml, range, html, rangeParentElement;
    let fontWeight = defaltFontBold ? 400 : 700;
    let fontStyle = "italic";
    let fontDecoration = defaltFontUnderline ? "none" : "underline";
    let removeSelection = false;
    if (window.getSelection) {
      sel = window.getSelection();
      // Get the selected text
      let selectedCount = sel.toString().trim();
      if (selectedCount.length == 0) {
        return;
      }

      // get parent elemet of selected part
      if (window.getSelection) {
        if (sel.rangeCount) {
          parentEl = sel.getRangeAt(0).commonAncestorContainer;
          if (parentEl.nodeType != 1) {
            parentEl = parentEl.parentNode;
          }
        }
      }

      // get selected part as a HTML format
      if (sel.rangeCount) {
        if (parentEl.innerText.trim() === sel.toString().trim() && !parentEl.hasAttribute("contenteditable")) {
          selectedPart = parentEl;
        } else {
          let container = document.createElement("div");
          for (let i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          // Remove any empty <li> elements
          let listItems = container.querySelectorAll("li");
          listItems.forEach(li => {
            if (!li.textContent.trim()) {
              li.remove();
            }
          });
          activeHtml = container.innerHTML;
          selectedPart = document.createElement("span");
          selectedPart.innerHTML = activeHtml;
        }
      }

      if (sel.getRangeAt && sel.rangeCount) {
        range = window.getSelection().getRangeAt(0);
        // Create a reference to the common ancestor container
        rangeParentElement = range.commonAncestorContainer;

        // apply bold on selected part
        if (fontFormat === "bold") {
          let isFontWeightApplied = false;

          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.fontWeight) {
              isFontWeightApplied = true;
              node.style.fontWeight = fontWeight;
            }
          });
          if (
            window.getComputedStyle(selectedPart).fontWeight ||
            window.getComputedStyle(selectedPart).textDecoration
          ) {
            isFontWeightApplied = true;
            if (window.getComputedStyle(selectedPart).fontWeight) {
              selectedPart.style.fontWeight = fontWeight;
            }
          }

          // If a font-weight was applied/updated, don't create a new span
          if (isFontWeightApplied) {
            html = selectedPart.innerHTML;
          } else {
            // Otherwise, create a new span to wrap the selection
            html = `<span style="font-weight:${fontWeight};">${selectedPart.innerHTML}</span>`;
            removeSelection = true;
          }

          // Toggle the default bold state (this assumes you have a state management for bold)
          setDefaltFontBold(!defaltFontBold);
        }

        // apply italic on selected part
        if (fontFormat === "italic") {
          let isItalic = false;
          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.fontStyle) {
              isItalic = true;
              node.style.fontStyle = fontStyle;
            }
          });
          if (isItalic) {
            html = selectedPart.innerHTML;
          } else html = `<span style="font-style:${fontStyle};">${selectedPart.innerHTML}</span>`;
        }

        // apply underline on selected part
        if (fontFormat === "underline") {
          let isUnderlineApplied = false;

          selectedPart.querySelectorAll("*").forEach(node => {
            if (node.style?.textDecoration) {
              isUnderlineApplied = true;
              node.style.textDecoration = fontDecoration;
            }
          });
          if (
            window.getComputedStyle(selectedPart).fontWeight ||
            window.getComputedStyle(selectedPart).textDecoration
          ) {
            isUnderlineApplied = true;
            if (window.getComputedStyle(selectedPart).textDecoration) {
              selectedPart.style.textDecoration = fontDecoration;
            }
          }

          // If a font-weight was applied/updated, don't create a new span
          if (isUnderlineApplied) {
            html = selectedPart.innerHTML;
          } else {
            // Otherwise, create a new span to wrap the selection
            html = `<span style="text-decoration:${fontDecoration};">${selectedPart.innerHTML}</span>`;
            removeSelection = true;
          }

          setDefaltFontUnderline(!defaltFontUnderline);
        }

        if (fontFormat === "bullet" || fontFormat === "number") {
          let currentClass = fontFormat === "bullet" ? "unorder-list" : "order-list";
          let wrapDiv = parentEl.closest("div");
          let parentDivEl;
          let selectedDivs = [];
          let removeMultipleDiv = false;

          // Check if parent div is main cell div then set wrapdiv as parentDivEl
          // let parentDivEl = wrapDiv.hasAttribute("contenteditable") ? parentEl : wrapDiv;
          if (wrapDiv.hasAttribute("contenteditable")) {
            const allDivs = wrapDiv.querySelectorAll("div");

            // Create a new wrapper div
            const wrapper = document.createElement("div");

            allDivs.forEach(div => {
              // Check if the div is within the selected range
              if (sel.containsNode(div, true)) {
                // Clone the div and append it to the wrapper
                wrapper.appendChild(div.cloneNode(true));
                // Collect the selected div for later removal
                selectedDivs.push(div);
              }
            });

            parentDivEl = selectedDivs.length == 0 ? parentEl : wrapper;
            removeMultipleDiv = true;
          } else {
            parentDivEl = wrapDiv;
          }

          // Get or create the <ul> element for bullet/numbered list
          let ulElement = parentDivEl.querySelector("ul") || parentEl.closest("ul");

          if (ulElement) {
            let allLiSelected = Array.from(ulElement.children).every(li => sel.containsNode(li, true));

            if (allLiSelected && currentClass === ulElement.className) {
              let childLiArray =
                ulElement.children[0].tagName === "SPAN" ? ulElement.children[0].children : ulElement.children;

              // Remove the ul and li tags, and make innerHTML plain elements
              let frag = document.createDocumentFragment();
              Array.from(childLiArray).forEach(li => {
                frag.appendChild(document.createRange().createContextualFragment(li.innerHTML));
              });
              ulElement.parentNode.replaceChild(frag, ulElement);

              resetBulletNumber("");
            }

            // If ul element already has the class "unorder-list" or "order-list", update it
            if (ulElement.classList.contains("unorder-list") && fontFormat === "number") {
              ulElement.classList.remove("unorder-list");
              ulElement.classList.add("order-list");
              ulElement.style.listStyleType = "decimal";
              resetBulletNumber("number");
            } else if (ulElement.classList.contains("order-list") && fontFormat === "bullet") {
              ulElement.classList.remove("order-list");
              ulElement.classList.add("unorder-list");
              ulElement.style.listStyleType = "square";
              resetBulletNumber("bullet");
            }
            return;
          } else {
            resetBulletNumber(fontFormat);
            const hasDiv = parentDivEl.querySelector("div");
            let resultHtmlString;
            if (hasDiv) {
              resultHtmlString = splitByDivs(parentDivEl.innerHTML);
            } else resultHtmlString = `<li><div>${parentDivEl.innerHTML}</div></li>`;
            // Create the ul element if it does not exist
            if (fontFormat === "bullet") {
              html = `<ul class="unorder-list" style="list-style-type: square;">${resultHtmlString}</ul>`;
            } else {
              html = `<ul class="order-list" style="list-style-type: decimal;">${resultHtmlString}</ul>`;
            }
            removeSelection = true;
          }

          // Below code to remove previous html
          if (removeMultipleDiv) {
            selectedDivs.forEach(div => {
              if (div.parentElement === parentEl) {
                parentEl.removeChild(div);
              }
            });
          } else parentEl.innerHTML = "";
          // parentEl.innerHTML = "";
        }

        // Remove the contents of the range
        range.deleteContents();

        // Create a new div element to hold the new HTML
        let el = document.createElement("div");
        el.innerHTML = html;

        // Create a document fragment to hold the new nodes
        let frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }

        // Now, remove any empty <li> elements left after deletion
        if (
          rangeParentElement.tagName === "LI" ||
          (rangeParentElement.tagName === "SPAN" && ["bullet", "number"].includes(fontFormat))
        ) {
          // If the <li> or <div> is now empty, remove it from the DOM
          if (rangeParentElement.textContent.trim() === "") {
            rangeParentElement.parentNode.removeChild(rangeParentElement);
          }
        } else if (rangeParentElement.tagName === "UL") {
          // If the parent is a <ul>, check for empty <li> elements within it
          const liElements = rangeParentElement.querySelectorAll("li");
          liElements.forEach(li => {
            if (li.textContent.trim() === "") {
              li.parentNode.removeChild(li);
            }
          });
        }
        range.insertNode(frag);

        if (removeSelection) window.getSelection().removeAllRanges();
      }
    }
  };

  const resetBulletNumber = type => {
    setNumberingAcive(type == "number" ? true : false);
    setBulletActive(type == "bullet" ? true : false);
  };

  // Below fn for applying Bold
  const applyBoldFn = () => {
    selectedPartFontFormatting("bold");
  };

  // Below fn for applying Italic
  const applyItalicFn = () => {
    selectedPartFontFormatting("italic");
  };

  // Below fn for applying underline
  const applyUnderLineFn = () => {
    selectedPartFontFormatting("underline");
  };

  // Below fn for handle merge cell
  const handleMergeCells = () => {
    let availableCell = Object.keys(selectedCells);

    if (availableCell.length <= 1) return;

    const cellPositions = availableCell.map(cellId => {
      const [type, rowIndex, colIndex] = cellId.split("-").map((v, i) => (i === 0 ? v : Number(v)));
      return { type, rowIndex, colIndex };
    });

    const cellType = cellPositions.map(pos => pos.type);
    const minRowIndex = Math.min(...cellPositions.map(pos => pos.rowIndex));
    const maxRowIndex = Math.max(...cellPositions.map(pos => pos.rowIndex));
    const minColIndex = Math.min(...cellPositions.map(pos => pos.colIndex));
    const maxColIndex = Math.max(...cellPositions.map(pos => pos.colIndex));

    const combinedValue = cellPositions
      .map(pos => {
        const cell =
          pos.type === BODY
            ? tableDatas?.rows[pos?.rowIndex]?.cells[pos?.colIndex]
            : pos.type === HEADER
            ? tableDatas?.columns[pos?.rowIndex]
            : tableDatas?.footer[pos?.rowIndex];

        let cellValue = cell?.value || "";
        // If cellValue is empty, skip wrapping
        if (cellValue.length === 0) {
          return `${cellValue}`;
        }

        // Check if cellValue contains any <div> wrapper
        const hasWrapperDiv = /<div.*?>.*?<\/div>/i.test(cellValue);

        // If no <div> wrapper, wrap cellValue in a <div>
        if (!hasWrapperDiv) {
          cellValue = `<div>${cellValue}</div>`;
        } else {
          cellValue = `${cellValue}`;
        }

        return cellValue;
      })
      .filter(value => value !== "") // Filter out empty values
      .join(""); // Join with ""

    let updatedRows = { ...tableDatas };

    /**
     * - Merge cell form header
     * - Merge merged cell form Body
     * - Merge merged cell form Footer
     */

    for (let i = minRowIndex; i <= maxRowIndex; i++) {
      if ([HEADER, FOOTER].includes(cellType[0]) && i !== minRowIndex) {
        if (cellType[0] === HEADER) updatedRows.columns[i] = { merged: true, value: "" };
        else if (cellType[0] === FOOTER) updatedRows.footer[i] = { merged: true, value: "" };
      } else if (cellType[0] === BODY) {
        for (let j = minColIndex; j <= maxColIndex; j++) {
          if (!(i === minRowIndex && j === minColIndex)) {
            updatedRows.rows[i].cells[j] = { merged: true, value: "", mergedId: `body-${minRowIndex}-${minColIndex}` };
          }
        }
      }
    }
    if (cellType[0] === HEADER) {
      updatedRows.columns[minRowIndex] = {
        ...updatedRows.columns[minRowIndex],
        value: combinedValue,
        colspan: maxRowIndex - minRowIndex + 1,
      };
    } else if (cellType[0] === FOOTER) {
      updatedRows.footer[minRowIndex] = {
        ...updatedRows.footer[minRowIndex],
        value: combinedValue,
        colspan: maxRowIndex - minRowIndex + 1,
      };
    } else if (cellType[0] === BODY) {
      let colSpanValue = maxColIndex - minColIndex + 1;
      let rowSpanValue = maxRowIndex - minRowIndex + 1;
      // Remove row span if all merged colspan vale == table column value
      // let rowSpanValue = tableConfigData?.formatting?.columns === colSpanValue ? 1 : maxRowIndex - minRowIndex + 1;
      updatedRows.rows[minRowIndex].cells[minColIndex] = {
        ...updatedRows.rows[minRowIndex].cells[minColIndex],
        value: combinedValue,
        colspan: colSpanValue,
        rowspan: rowSpanValue,
      };
    }

    setTableDatas(updatedRows);

    setTimeout(() => {
      setSelectedCells({});
      resetTooltip();
    }, 200);
  };

  // Below fn for handle split cell
  const handleSplitCells = () => {
    let availableCells = Object.keys(selectedCells);

    if (availableCells.length !== 1) return;
    const [availableCell] = availableCells;
    const [type, rowIndex, colIndex] = availableCell.split("-").map((v, i) => (i === 0 ? v : Number(v)));
    const cell =
      type === BODY
        ? tableDatas.rows[rowIndex].cells[colIndex]
        : type === HEADER
        ? tableDatas.columns[rowIndex]
        : tableDatas.footer[rowIndex];

    if (cell.colspan > 1 || cell.rowspan > 1) {
      const updatedRows = { ...tableDatas };

      /**
       * - Split merged cell form header
       * - Split merged cell form Body
       * - Split merged cell form Footer
       */

      if ([HEADER, FOOTER].includes(type)) {
        let arrName = type === HEADER ? "columns" : "footer";
        for (let i = 0; i < cell.colspan; i++) {
          updatedRows[arrName][rowIndex + i] = {
            value: i === 0 ? cell.value : "",
            colspan: 1,
          };
        }
      } else if (type === BODY) {
        for (let i = 0; i < cell.rowspan; i++) {
          for (let j = 0; j < cell.colspan; j++) {
            if (updatedRows.rows[rowIndex + i]) {
              updatedRows.rows[rowIndex + i].cells[colIndex + j] = {
                value: i === 0 && j === 0 ? cell.value : "",
                colspan: 1,
                rowspan: 1,
              };
            }
          }
        }
      }

      setTableDatas({ ...updatedRows });

      setTimeout(() => {
        setSelectedCells({});
        resetTooltip();
      }, 200);
    }
  };

  // Below fn for apply bullet
  const handleBulletApply = () => {
    selectedPartFontFormatting("bullet");
  };

  // Below fn for apply bullet
  const handleNumberApply = () => {
    selectedPartFontFormatting("number");
  };

  // Below fn for apply cell alignment
  const handleAlignCells = position => {
    const updatedRows = { ...tableDatas };
    let availableCells = Object.keys(selectedCells);
    availableCells.map(cellId => {
      const [type, rowIndex, colIndex] = cellId.split("-").map((v, i) => (i === 0 ? v : Number(v)));
      if (type === BODY) {
        updatedRows.rows[rowIndex].cells[colIndex].alignClass = `align-content-${position}`;
      } else if (type === HEADER) {
        updatedRows.columns[rowIndex].alignClass = `align-content-${position}`;
      } else if (type === FOOTER) {
        updatedRows.footer[rowIndex].alignClass = `align-content-${position}`;
      }
    });

    setTableDatas({ ...updatedRows });
  };

  const handleMouseDown = (rowIndex, columnIndex, rowType) => {
    if (isEditMode) return;
    setDragStart({ rowIndex, columnIndex });
    const cellKey = ["header", "footer"].includes(rowType)
      ? `${rowType}-${columnIndex}`
      : `body-${rowIndex}-${columnIndex}`;

    const newSelectedCells = { [cellKey]: true };
    setSelectedCells(newSelectedCells);
  };

  const handleMouseUp = () => {
    if (isEditMode) return;
    setDragStart(null);

    let availableCells = Object.keys(selectedCells);

    const headerSelected = availableCells.some(key => key.startsWith("header-"));
    const bodySelected = availableCells.some(key => key.startsWith("body-"));
    const footerSelected = availableCells.some(key => key.startsWith("footer-"));

    if (headerSelected && bodySelected) {
      // Both header and body selected
    } else if (headerSelected) {
      // This section Only Thead selected

      /**
       * Set tooltip for the first cell is selected
       * Enable splitOpt if only one cell is selected and this cell should be merged
       * Enable mergeOpt if selected cell are more than 1
       */
      let startInfoArray = availableCells?.[0].split("-");
      setTooltipTarget(`${uniqueId}col-tooltip-${startInfoArray[1]}`);

      // Check Previously any cell align position
      let alignClassList = [];
      availableCells.forEach(cellId => {
        const [, colIndex] = cellId.split("-").map(Number);
        let alignClass = tableDatas.columns[colIndex]?.alignClass;

        if (alignClass && !alignClassList.includes(alignClass)) alignClassList.push(alignClass);
      });

      let startCellInfo = tableDatas.columns[startInfoArray[1]];
      setTooltipFor({
        mergeSplitOpt: true,
        splitOpt: availableCells.length == 1 ? (startCellInfo.colspan > 1 ? true : false) : false,
        mergeOpt: availableCells.length > 1 ? true : false,
        alignOpt: true,
        alignPosition:
          alignClassList.length == 1
            ? alignClassList[0] == "align-content-start"
              ? TOP
              : alignClassList[0] == "align-content-center"
              ? MIDDLE
              : BOTTOM
            : "",
      });
      setShowTooltip(true);
    } else if (bodySelected) {
      // This section Only Tbody selected

      /**
       * Set tooltip for the first cell is selected
       * Enable splitOpt if only one cell is selected and this cell should be merged
       * Enable mergeOpt if selected cell are more than 1
       */
      let startInfoArray = availableCells?.[0].split("-");
      let dynamicTargetId = `${uniqueId}tableCell-col${startInfoArray[2]}-row${startInfoArray[1]}`;
      let targetIdDOM = document.getElementById(dynamicTargetId);
      let mergeFieldCount = 0;
      let splitFieldCount = 0;
      let enableMerge = true;

      // If not targetIdDom present then remove selected cell and return
      if (!targetIdDOM) {
        setSelectedCells({});
        return;
      }
      setTooltipTarget(`${uniqueId}tableCell-col${startInfoArray[2]}-row${startInfoArray[1]}`);

      // Check Previously any cell align position
      let alignClassList = [];
      availableCells.forEach(cellId => {
        const [, rowIndex, colIndex] = cellId.split("-").map(Number);
        let alignClass = tableDatas.rows[rowIndex]?.cells[colIndex]?.alignClass;

        if (alignClass && !alignClassList.includes(alignClass)) alignClassList.push(alignClass);

        if (
          tableDatas.rows[rowIndex]?.cells[colIndex]?.merged &&
          tableDatas.rows[rowIndex]?.cells[colIndex]?.mergedId
        ) {
          if (!availableCells.includes(tableDatas.rows[rowIndex].cells[colIndex].mergedId)) enableMerge = false;
        }
        // Check merge fiead count to show merge or split option
        if (
          tableDatas.rows[rowIndex]?.cells[colIndex]?.colspan > 1 ||
          tableDatas.rows[rowIndex]?.cells[colIndex]?.rowspan > 1
        ) {
          mergeFieldCount += 1;
        } else splitFieldCount += 1;
      });

      let startCellInfo = tableDatas.rows[startInfoArray[1]].cells[startInfoArray[2]];
      setTooltipFor({
        mergeSplitOpt: true,
        // splitOpt:
        //   availableCells.length == 1 ? (startCellInfo.colspan > 1 || startCellInfo.rowspan > 1 ? true : false) : false,
        // mergeOpt: availableCells.length > 1 ? true : false,
        splitOpt:
          availableCells.length == 1 ? (startCellInfo.colspan > 1 || startCellInfo.rowspan > 1 ? true : false) : false,
        mergeOpt: availableCells.length > 1 && mergeFieldCount <= 1 && enableMerge ? true : false,
        alignOpt: true,
        alignPosition:
          alignClassList.length == 1
            ? alignClassList[0] == "align-content-start"
              ? TOP
              : alignClassList[0] == "align-content-center"
              ? MIDDLE
              : BOTTOM
            : "",
      });
      setShowTooltip(true);
    } else if (footerSelected) {
      // This section Only Table footer selected

      /**
       * Set tooltip for the first cell is selected
       * Enable splitOpt if only one cell is selected and this cell should be merged
       * Enable mergeOpt if selected cell are more than 1
       */
      let startInfoArray = availableCells?.[0].split("-");
      setTooltipTarget(`${uniqueId}footer-tooltip-${startInfoArray[1]}`);

      // Check Previously any cell align position
      let alignClassList = [];
      availableCells.forEach(cellId => {
        const [, colIndex] = cellId.split("-").map(Number);
        let alignClass = tableDatas?.footer?.[colIndex]?.alignClass;

        if (alignClass && !alignClassList.includes(alignClass)) alignClassList.push(alignClass);
      });

      let startCellInfo = tableDatas.footer[startInfoArray[1]];
      setTooltipFor({
        mergeSplitOpt: true,
        splitOpt: availableCells.length == 1 ? (startCellInfo?.colspan > 1 ? true : false) : false, // Joyshree : optional checking added
        mergeOpt: availableCells.length > 1 ? true : false,
        alignOpt: true,
        alignPosition:
          alignClassList.length == 1
            ? alignClassList[0] == "align-content-start"
              ? TOP
              : alignClassList[0] == "align-content-center"
              ? MIDDLE
              : BOTTOM
            : "",
      });
      setShowTooltip(true);
    }
  };

  const handleMouseEnter = (rowIndex, columnIndex, rowType) => {
    if (!dragStart) return;

    const rowStart = Math.min(dragStart.rowIndex, rowIndex);
    const rowEnd = Math.max(dragStart.rowIndex, rowIndex);
    const colStart = Math.min(dragStart.columnIndex, columnIndex);
    const colEnd = Math.max(dragStart.columnIndex, columnIndex);

    const newSelectedCells = {};

    for (let row = rowStart; row <= rowEnd; row++) {
      for (let col = colStart; col <= colEnd; col++) {
        let cellKey, cellKey2;
        if (row === -1) {
          cellKey = `header-${col}`;
          newSelectedCells[cellKey] = true;
          if (col === colEnd) {
            let colSpan = tableDatas.columns[colEnd]?.colspan;
            if (colSpan && colSpan > 1) {
              for (let i = 0; i < colSpan - 1; i++) {
                cellKey = `header-${col + i + 1}`;
                newSelectedCells[cellKey] = true;
              }
            }
          }
        } else if (row === -2) {
          cellKey = `footer-${col}`;
          newSelectedCells[cellKey] = true;
          if (col === colEnd) {
            let colSpan = tableDatas.footer[colEnd]?.colspan;
            if (colSpan && colSpan > 1) {
              for (let i = 0; i < colSpan - 1; i++) {
                cellKey = `footer-${col + i + 1}`;
                newSelectedCells[cellKey] = true;
              }
            }
          }
        } else {
          cellKey = `body-${row}-${col}`;
          newSelectedCells[cellKey] = true;

          if (col <= colEnd) {
            let colSpan = tableDatas.rows[row]?.cells[col]?.colspan;
            if (colSpan && colSpan > 1) {
              for (let i = 1; i < colSpan; i++) {
                cellKey = `body-${row}-${col + i}`;
                newSelectedCells[cellKey] = true;

                // Below for loop for previous cells of merge cell
                for (let p = rowStart; p < row; p++) {
                  cellKey2 = `body-${p}-${col + i}`;
                  newSelectedCells[cellKey2] = true;
                }

                // Below for loop for next cells of merge cell
                for (let k = rowStart + 1; k <= rowEnd; k++) {
                  cellKey2 = `body-${k}-${col + i}`;
                  newSelectedCells[cellKey2] = true;
                }
              }
            }
          }
          if (row <= rowEnd) {
            let rowSpan = tableDatas.rows[row]?.cells[col]?.rowspan;
            if (rowSpan && rowSpan > 1) {
              for (let i = 1; i < rowSpan; i++) {
                cellKey = `body-${row + i}-${col}`;
                newSelectedCells[cellKey] = true;

                // Below for loop for previous cells of merge cell
                for (let p = colStart; p < col; p++) {
                  cellKey2 = `body-${row + i}-${p}`;
                  newSelectedCells[cellKey2] = true;
                }

                // Below for loop for next cells of merge cell
                for (let k = colStart + 1; k <= colEnd; k++) {
                  cellKey2 = `body-${row + i}-${k}`;
                  newSelectedCells[cellKey2] = true;
                }
              }
            }
          }
        }
      }
    }
    setSelectedCells(newSelectedCells);
  };

  const isSelected = (rowIndex, columnIndex, rowType) => {
    const cellKey = ["header", "footer"].includes(rowType)
      ? `${rowType}-${columnIndex}`
      : `body-${rowIndex}-${columnIndex}`;
    return !!selectedCells[cellKey];
  };

  const handleDragStart = event => {
    if (isEditMode) return;
    event.preventDefault();
  };

  // OutSideclick check if cell contentEditable is true
  const clickOutsideContentEditebleText = e => {
    let editableCellInfos = editableStateRef.current;
    let isClickOutside, editedCel, targetTableCell;
    let tooltipElem = document.getElementById("dhpTableCellEditableTooltip");
    let targetElem = e.target.closest("#dhpTableCellEditableTooltip"); // Clicked element
    // editable div click check
    if (editableCellInfos?.cellId) {
      editedCel = document.getElementById(editableCellInfos.cellId);
      targetTableCell = e.target.closest(`#${editableCellInfos.cellId}`);
    }
    if (targetElem && targetElem == tooltipElem) {
      // This is a click inside, does nothing, just return.
      isClickOutside = false;
    } else if (editedCel && editedCel == targetTableCell) {
      // This is a click inside, does nothing, just return.
      isClickOutside = false;
    } else {
      // This is a click outside.
      isClickOutside = true;
    }
    if (!isClickOutside) return; // Return if not clicked on outside
    window.getSelection().removeAllRanges();

    setSelectedCells({});
    resetTooltip();

    if (editableCellInfos?.cellId) {
      let editableCellElem = document.getElementById(editableCellInfos.cellId);
      disableEditText(editableCellElem, editableCellInfos.type, editableCellInfos.idx, editableCellInfos.rowIdx);
    }
  };

  useEffect(() => {
    editableStateRef.current = editableCellInfo;
  }, [editableCellInfo]);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideContentEditebleText);

    return () => {
      document.removeEventListener("mousedown", clickOutsideContentEditebleText);
    };
  }, []);

  // Update the ref whenever selectedTarget changes
  useEffect(() => {
    selectedTargetRef.current = selectedTarget;
  }, [selectedTarget]);

  // Update the editModeTargetRef whenever isEditMode changes
  useEffect(() => {
    editModeTargetRef.current = isEditMode;
  }, [isEditMode]);

  useEffect(() => {
    const mouseUpSelection = event => {
      // Call cell text format tooltip
      if (editableStateRef.current?.cellId && editModeTargetRef.current) {
        handleSelection(editableStateRef.current.cellId);
      }

      if (event.target.closest("table") === null && selectedTargetRef.current) {
        setSelectedCells({});
        setDragStart(null);

        // handleSelection(selectedTargetRef.current);
      }
    };

    document.addEventListener("mouseup", mouseUpSelection);
    return () => {
      document.removeEventListener("mouseup", mouseUpSelection);
    };
  }, []);

  useEffect(() => {
    setTableHeaderStyle(setStyle("thead"));
    setCellBorder(setCellBorderStyle());
    setTableBodyStyle(setStyle("tbody"));
    setTableFooterStyle(setStyle("tfoot"));
  }, [tableConfigData]);

  const handleCopyCells = useCallback(() => {
    let availableCells = Object.keys(selectedCells);
    if (availableCells.length === 0) return;

    const copiedValues = availableCells.map((cellId, idx) => {
      const [type, rowIndex, colIndex] = cellId.split("-").map((v, i) => (i === 0 ? v : Number(v)));
      if (idx == 0) setCopyType(type); // Save the type of copied cells
      if (type === HEADER) {
        return tableDatas.columns[rowIndex]?.value;
      } else if (type === BODY) {
        return tableDatas.rows[rowIndex]?.cells[colIndex]?.value;
      } else if (type === FOOTER) {
        return tableDatas.footer[rowIndex]?.value;
      }
    });

    const copiedStructure = availableCells.map(cellId => {
      const [type, rowIndex, colIndex] = cellId.split("-").map((v, i) => (i === 0 ? v : Number(v)));
      let cell;
      if (type === HEADER) {
        cell = tableDatas.columns[rowIndex];
        return { ...cell, rowIndex };
      } else if (type === BODY) {
        cell = tableDatas.rows[rowIndex].cells[colIndex];
        return { ...cell, rowIndex, colIndex };
      } else if (type === FOOTER) {
        cell = tableDatas.footer[rowIndex];
        return { ...cell, rowIndex };
      }
    });
    setCopiedCells(copiedValues);
    setCopiedStructure(copiedStructure);
  }, [selectedCells, tableDatas]);

  // Below fn to handle paste copied value
  const handlePasteCells = useCallback(() => {
    let availableCells = Object.keys(selectedCells);
    if (copiedCells.length === 0 || availableCells.length == 0) return;

    const [selectedCell] = availableCells;
    const [targetType, startRowIndex, startColIndex] = selectedCell.split("-").map((v, i) => (i === 0 ? v : Number(v)));

    // Prevent pasting if source and target types are different
    if (targetType !== copyType) return;

    const updatedRows = { ...tableDatas };

    copiedStructure.forEach(({ rowIndex, colIndex, value, colspan, rowspan }) => {
      const targetRowIndex = startRowIndex + (rowIndex - copiedStructure[0].rowIndex);
      const targetColIndex = startColIndex + (colIndex - copiedStructure[0].colIndex);

      /**
       * - Paste option for table body cell
       * - Paste option for table header
       * - Paste option for table footer
       */
      if (
        targetType === BODY &&
        targetRowIndex < updatedRows.rows.length &&
        targetColIndex < updatedRows.rows?.[targetRowIndex].cells?.length &&
        colspan &&
        rowspan
      ) {
        updatedRows.rows[targetRowIndex].cells[targetColIndex] = {
          value,
          colspan,
          rowspan,
        };

        if (colspan > 1 || rowspan > 1) {
          for (let i = 0; i < rowspan; i++) {
            for (let j = 0; j < colspan; j++) {
              if ((i !== 0 || j !== 0) && updatedRows.rows?.[targetRowIndex + i]) {
                updatedRows.rows[targetRowIndex + i].cells[targetColIndex + j] = { merged: true };
              }
            }
          }
        }
      } else if (targetType === HEADER && targetRowIndex < updatedRows.columns.length && colspan) {
        updatedRows.columns[targetRowIndex] = {
          value,
          colspan,
        };

        if (colspan > 1) {
          for (let j = 0; j < colspan; j++) {
            if (j !== 0 && updatedRows.columns?.[targetRowIndex + j]) {
              updatedRows.columns[targetRowIndex + j] = { merged: true };
            }
          }
        }
      } else if (targetType === FOOTER && targetRowIndex < updatedRows.footer.length && colspan) {
        updatedRows.footer[targetRowIndex] = {
          value,
          colspan,
        };

        if (colspan > 1) {
          for (let j = 0; j < colspan; j++) {
            if (j !== 0 && updatedRows.footer?.[targetRowIndex + j]) {
              updatedRows.footer[targetRowIndex + j] = { merged: true };
            }
          }
        }
      }
    });
    setCopiedCells([]);
    setCopiedStructure([]);
    setSelectedCells([]);
    setShowTable(false);
  }, [copiedCells, copiedStructure, selectedCells, tableDatas]);

  useEffect(() => {
    const handleKeyDown = event => {
      // Check if the event originated inside a table
      const isInsideTable = event.target.closest("table");
      if (!isInsideTable) return; // Exit if not inside a table

      if (event.ctrlKey && event.key === "c" && !isEditMode) {
        event.preventDefault();
        handleCopyCells();
      }
      // Set default paste function if cell is in edit mode
      if (event.ctrlKey && event.key === "v" && !isEditMode) {
        event.preventDefault();
        handlePasteCells();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleCopyCells, handlePasteCells, isEditMode]);

  useLayoutEffect(() => {
    const mergedCountCol = tableDatas.columns.filter(item => item.merged === true).length;
    setHideHeaderDelete(mergedCountCol === tableDatas.columns.length - 1 ? true : false);

    // Below merged cell calulation for table rows
    let allMergedExceptOne = false;
    let totalMergeCount = 0;
    let totalCellCount = 0;

    for (let i = 0; i < tableDatas.rows.length; i++) {
      const cells = tableDatas.rows[i].cells;
      totalCellCount += cells.length;

      // Count the number of objects with merged: true
      const mergedCount = cells.filter(cell => cell.merged === true).length;
      totalMergeCount += mergedCount;
    }
    // Check total merged and cell count
    if (totalMergeCount === totalCellCount - 1) {
      allMergedExceptOne = true;
    }

    sethideBodyDelete(allMergedExceptOne);
  }, [tableDatas]);

  return (
    <div
      className={cx(style["table-wrap"], style["customScroll"], style["scroll-X"], style["scroll-Y"])}
      onScroll={e => scrollEvnt(e)}
      ref={parentRef}>
      <div ref={forwardedRef} className={cx(style["table-canvas-wraper"], style["pt-3"])}>
        {!reloadTableBody && (
          <RTable
            size="sm"
            innerRef={tableRef}
            onMouseUp={handleMouseUp}
            onMouseDown={handleDragStart} // Prevent text selection during drag
            onDragStart={handleDragStart} // For Firefox compatibility
            // style={{ height: "1px", userSelect: "none" }}>
            style={{
              height: "100%",
              userSelect: "none",
              wordBreak: "break-all",
              width: tableConfigData.formatting.tableStyle?.width
                ? tableConfigData.formatting.tableStyle?.width + "px"
                : "",
            }}>
            {/* style={{ height: "1px" }}> */}
            <thead
              style={{
                // background: tableConfigData?.formatting?.thead?.backgroundColor,
                textAlign: tableConfigData?.formatting?.thead?.textAlign,
              }}>
              {/* <tr className={style["draggableTr"]}>
                {tableDatas?.columns.length > 1 &&
                  tableDatas?.columns?.map((colInfo, idx) => (
                    <th
                      key={idx}
                      draggable={false}
                      className={cx({ [style["table-header-hover"]]: idx === hoveredCol })}
                      onMouseEnter={() => setHoveredCol(idx)}></th>
                  ))}
                {tableDatas?.columns.length < 2 && <th draggable={false}>&nbsp;</th>}
              </tr> */}
              <tr ref={tableHeaderRef}>
                {tableDatas?.columns?.map((colInfo, idx) => {
                  if (colInfo?.merged) return null; // Skip merged cells
                  return (
                    <React.Fragment key={idx}>
                      <TableHeader
                        idx={idx}
                        colInfo={colInfo}
                        cellBorder={cellBorder}
                        editText={editText}
                        tableHeaderStyle={tableHeaderStyle}
                        headerBackground={tableConfigData?.formatting?.thead?.backgroundColor}
                        setHoveredCol={setHoveredCol}
                        updateTableData={updateTableData}
                        handleSelection={handleSelection}
                        handleMouseDown={handleMouseDown}
                        handleMouseEnter={handleMouseEnter}
                        isSelected={isSelected}
                        uniqueId={uniqueId}
                        setSelectedTarget={setSelectedTarget}
                      />

                      {!tooltipTarget && (
                        <UncontrolledTooltip
                          delay={{ show: 0, hide: 150 }}
                          placement="top-end"
                          target={`${uniqueId}col-tooltip-${idx}`}
                          className="custom-table-tooltip"
                          autohide={false}
                          boundariesElement={tooltipBoundry}>
                          <span
                            className={cx({
                              [style["disable"]]:
                                tableConfigData?.formatting?.columns >= tableConfigData?.formatting?.columnMaxSize,
                            })}
                            onClick={() =>
                              tableConfigData?.formatting?.columns < tableConfigData?.formatting?.columnMaxSize &&
                              addRowColumn("columns", idx)
                            }>
                            <Icon icon="ui-plus" />
                          </span>
                          <span
                            className={cx({
                              [style["disable"]]: tableConfigData?.formatting?.columns <= 1 || hideHeaderDelete,
                            })}
                            onClick={() =>
                              (tableConfigData?.formatting?.columns > 1 || !hideHeaderDelete) &&
                              deleteRowColumn("columns", idx)
                            }>
                            <Icon icon="ui-trash" />
                          </span>
                        </UncontrolledTooltip>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            </thead>
            <tbody
              style={{
                textAlign: tableConfigData?.formatting?.tbody?.textAlign,
              }}>
              {tableDatas?.rows?.map((rowInfo, rowIndex) => {
                // if (rowInfo?.cells?.filter(item => !item.merged).length === 0) return null; // Skip merged cells
                return (
                  <React.Fragment key={rowIndex}>
                    <TableRow
                      rowIndex={rowIndex}
                      tableDatas={tableDatas}
                      selected={selected}
                      setSelected={setSelected}
                      editText={editText}
                      cellBorder={cellBorder}
                      tableConfigData={tableConfigData}
                      tableBodyStyle={tableBodyStyle}
                      rowInfo={rowInfo}
                      setTableDatas={setTableDatas}
                      setReloadTableBody={setReloadTableBody}
                      handleSelection={handleSelection}
                      handleMouseDown={handleMouseDown}
                      handleMouseEnter={handleMouseEnter}
                      isSelected={isSelected}
                      uniqueId={uniqueId}
                      setSelectedTarget={setSelectedTarget}
                    />

                    {isEditMode === false && !tooltipTarget && horizontalScroll < 20 && (
                      <UncontrolledTooltip
                        delay={{ show: 0, hide: 150 }}
                        placement="left"
                        target={`row-tooltip-${rowIndex}`}
                        className={`custom-table-tooltip ${isAtTop && "invisible"}`}
                        autohide={false}
                        innerRef={childRef}
                        boundariesElement={tooltipBoundry}
                        // container={tooltipContainer}
                      >
                        <span
                          className={cx({
                            [style["disable"]]:
                              tableConfigData?.formatting?.rows >= tableConfigData?.formatting?.rowMaxSize,
                          })}
                          onClick={() =>
                            tableConfigData?.formatting?.rows < tableConfigData?.formatting?.rowMaxSize &&
                            addRowColumn("rows", rowIndex)
                          }>
                          <Icon icon="ui-plus" />
                        </span>
                        <span
                          className={cx({
                            [style["disable"]]: tableConfigData?.formatting?.rows <= 1 || hideBodyDelete,
                          })}
                          onClick={() =>
                            (tableConfigData?.formatting?.rows > 1 || !hideBodyDelete) &&
                            deleteRowColumn("rows", rowIndex)
                          }>
                          <Icon icon="ui-trash" />
                        </span>
                      </UncontrolledTooltip>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
            {applyFooter && (
              <tfoot>
                <tr style={{ height: "31.59px", textAlign: tableConfigData?.formatting?.tfoot?.textAlign }}>
                  {tableDatas?.columns?.map((_, colIndex) => {
                    if (tableDatas?.footer?.[colIndex]?.merged) return null; // Skip merged cells
                    return (
                      <React.Fragment key={colIndex}>
                        <TableFooter
                          idx={colIndex}
                          footerInfo={tableDatas?.footer?.[colIndex] || {}}
                          cellBorder={cellBorder}
                          editText={editText}
                          tableFooterStyle={tableFooterStyle}
                          footerBackground={tableConfigData?.formatting?.tfoot?.backgroundColor}
                          updateTableData={updateTableData}
                          handleSelection={handleSelection}
                          handleMouseDown={handleMouseDown}
                          handleMouseEnter={handleMouseEnter}
                          isSelected={isSelected}
                          uniqueId={uniqueId}
                          setSelectedTarget={setSelectedTarget}
                        />
                      </React.Fragment>
                    );
                  })}
                </tr>
              </tfoot>
            )}
            {/* </RTable> */}
          </RTable>
        )}
      </div>
      {tooltipTarget && (
        <Tooltip
          placement="top-start"
          target={tooltipTarget}
          isOpen={showTooltip}
          className="table-editable-tooltip"
          boundariesElement={tooltipBoundry}
          hideArrow={true}>
          <FloatingTooltipComponent
            tooltipFor={tooltipFor}
            applyBoldFn={applyBoldFn}
            applyItalicFn={applyItalicFn}
            applyUnderLineFn={applyUnderLineFn}
            handleMergeCells={handleMergeCells}
            handleSplitCells={handleSplitCells}
            handleBulletApply={handleBulletApply}
            handleNumberApply={handleNumberApply}
            handleAlignCells={handleAlignCells}
            bulletActive={bulletActive}
            numberingAcive={numberingAcive}
            defaltFontBold={defaltFontBold}
            defaltFontUnderline={defaltFontUnderline}
            isBoldeAvailable={isBoldeAvailable}
            horizontalScroll={horizontalScroll}
          />
        </Tooltip>
      )}
    </div>
  );
};

TableContent.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  tableDatas: PropTypes.object.isRequired,
  deleteRowColumn: PropTypes.func.isRequired,
  addRowColumn: PropTypes.func.isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  setTableDatas: PropTypes.func,
  reloadTableBody: PropTypes.bool.isRequired,
  setReloadTableBody: PropTypes.func.isRequired,
  setUpdatedDragData: PropTypes.func.isRequired,
};

const RowColumnSection = ({ tableConfigData, setTableConfigData, addRowColumn, deleteRowColumn }) => {
  const increaseDecreaseFn = (type, action, size) => {
    let value;
    if (action === "add") {
      // Increase column/ row size
      if (tableConfigData?.formatting?.[type] < tableConfigData?.formatting?.[size]) {
        value = tableConfigData?.formatting?.[type] + 1;
        addRowColumn(type, tableConfigData?.formatting?.[type], "navbar");
      }
    } else {
      // Decrease column/ row size
      if (tableConfigData?.formatting?.[type] >= tableConfigData?.formatting?.[size]) {
        value = tableConfigData?.formatting?.[type] - 1;
        deleteRowColumn(type, tableConfigData?.formatting?.[type] - 1);
      }
    }
    if (!value) return;
    setTableConfigData({
      ...tableConfigData,
      formatting: { ...tableConfigData.formatting, [type]: value },
    });
  };
  return (
    <div className={cx(style["pr-4"])}>
      <div className={style["line-controls-wrap"]}>
        <div className={style["slidelabel"]}>Rows</div>
        <Input
          returnType="formGroup"
          id="table-row-size"
          type="spinner"
          className="custom-width"
          spinnerClass="input-spinner"
          pattern="[0-9]*"
          value={tableConfigData?.formatting?.rows}
          inputGroupClass={
            tableConfigData?.formatting?.rowMaxSize === tableConfigData?.formatting?.rows ? "disableBtn" : ""
          }
          disabled
          max={tableConfigData?.formatting?.rowMaxSize}
          min={tableConfigData?.formatting?.rowMinSize}
          onIncrease={() => increaseDecreaseFn("rows", "add", "rowMaxSize")}
          onDecrease={() => increaseDecreaseFn("rows", "subtract", "rowMinSize")}
        />
      </div>

      <div className={style["line-controls-wrap"]}>
        <div className={style["slidelabel"]}>Columns</div>
        <Input
          returnType="formGroup"
          id="table-column-size"
          type="spinner"
          className="custom-width"
          spinnerClass="input-spinner"
          pattern="[0-9]*"
          value={tableConfigData?.formatting?.columns}
          inputGroupClass={
            tableConfigData?.formatting?.columnMaxSize === tableConfigData?.formatting?.columns ? "disableBtn" : ""
          }
          disabled
          max={tableConfigData?.formatting?.columnMaxSize}
          min={tableConfigData?.formatting?.columnMinSize}
          onIncrease={() => increaseDecreaseFn("columns", "add", "columnMaxSize")}
          onDecrease={() => increaseDecreaseFn("columns", "subtract", "columnMinSize")}
        />
      </div>
    </div>
  );
};

RowColumnSection.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  setTableConfigData: PropTypes.func.isRequired,
  deleteRowColumn: PropTypes.func.isRequired,
  addRowColumn: PropTypes.func.isRequired,
};

const HeaderOptionComponents = ({ tableConfigData, setTableConfigData }) => {
  const [headerColor, setHeaderColor] = useState(tableConfigData?.formatting?.thead?.backgroundColor);
  const [pageMarginColor, setPageMarginColor] = useState(tableConfigData?.formatting?.thead?.color);
  const [headerFontFamily, setHeaderFontFamily] = useState(tableConfigData?.formatting?.thead?.fontFamily);
  const [headerFontSize, setHeaderFontSize] = useState(tableConfigData?.formatting?.thead?.fontSize);
  const [fontAlign, setFontAlign] = useState(tableConfigData?.formatting?.thead?.textAlign);
  const [fontUnderline, setFontUnderline] = useState(
    tableConfigData?.formatting?.thead?.textDecoration === "none" ? false : true
  );
  const [fontBold, setFontBold] = useState(tableConfigData?.formatting?.thead?.bold);
  const [fontItalic, setFontItalic] = useState(tableConfigData?.formatting?.thead?.italic);
  const [updatedData, setUpdatedData] = useState(false);
  const [isBoldDisable, setIsBoldDisable] = useState();
  const [isItalicDisable, setIsItalicDisable] = useState();
  const [currentFont, setCurrentFont] = useState();
  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const updateTableData = (key, value) => {
    setTableConfigData({
      ...tableConfigData,
      formatting: {
        ...tableConfigData.formatting,
        thead: { ...tableConfigData.formatting.thead, [key]: value },
      },
    });
    setUpdatedData(false);
  };

  useMemo(() => {
    setUpdatedData({ key: "backgroundColor", value: headerColor });
  }, [headerColor]);

  useMemo(() => {
    setCurrentFont(fonts?.find(font => font.name === headerFontFamily.replace(/["]+/g, "")));
    setUpdatedData({ key: "fontFamily", value: `${headerFontFamily}` });
  }, [headerFontFamily]);

  useMemo(() => {
    setUpdatedData({ key: "fontSize", value: headerFontSize });
  }, [headerFontSize]);

  useMemo(() => {
    setUpdatedData({ key: "bold", value: fontBold });
    setUpdatedData({
      key: "fontWeight",
      value: fontBold
        ? currentFont?.bold_weight
        : currentFont?.normal_weight ?? tableConfigData.formatting.defaultFontWeight,
    });
  }, [fontBold]);

  useMemo(() => {
    setUpdatedData({ key: "italic", value: fontItalic });
    setUpdatedData({ key: "fontStyle", value: fontItalic ? "italic" : "normal" });
  }, [fontItalic]);

  useMemo(() => {
    setUpdatedData({ key: "color", value: pageMarginColor });
  }, [pageMarginColor]);

  useMemo(() => {
    setUpdatedData({ key: "textAlign", value: fontAlign });
  }, [fontAlign]);

  useMemo(() => {
    setUpdatedData({ key: "textDecoration", value: fontUnderline ? "underline" : "none" });
  }, [fontUnderline]);

  useEffect(() => {
    if (updatedData?.value) {
      updateTableData(updatedData.key, updatedData.value);
    }
  }, [updatedData]);

  useEffect(() => {
    if (currentFont?.source !== "Custom") {
      if (currentFont.bold_weight === "") {
        setIsBoldDisable(true);
        setFontBold(false);
      } else {
        setUpdatedData({ key: "fontWeight", value: fontBold ? currentFont.bold_weight : currentFont.normal_weight });
        setIsBoldDisable(false);
      }

      if (currentFont.bold_italic_weight === "") {
        setIsItalicDisable(true);
        setFontItalic(false);
      } else {
        setIsItalicDisable(false);
      }
    } else {
      setUpdatedData({ key: "fontWeight", value: currentFont?.normal_weight });
      setIsBoldDisable(true);
      setFontBold(false);
      setTimeout(() => {
        setUpdatedData({ key: "fontStyle", value: currentFont?.style });
        setIsItalicDisable(true);
        setFontItalic(false);
      }, 10);
    }
  }, [currentFont]);

  return (
    <>
      <div className={cx(style["line-controls-wrap"], style["justify-content-start"], style["left-shift"])}>
        <div className={style["slidelabel"]}>Header Color</div>
        <ColorPickerWidget color={headerColor} setColor={setHeaderColor} />
      </div>
      <div className={style["mb-3"]}>
        <div className={cx(style["font-15"], style["mb-3"])}>Header Font</div>
        <ul className={style["font-size-family"]}>
          <FontFamily defaultFontSet={headerFontFamily} setFontFamily={setHeaderFontFamily} sourceComponent={TABLE} />
          <FontSize
            defaultFontSizeSet={headerFontSize}
            setFontSize={setHeaderFontSize}
            defaultFontList={fontSizeList}
            sourceComponent={TABLE}
          />
        </ul>
        <ul className={cx(style["font-others"], style["d-flex"])}>
          <li className={style["custom-tooltip"]}>
            <ColorPickerWidget color={pageMarginColor} setColor={setPageMarginColor} />
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Text Color</div>
          </li>
          <Bold
            defaultFontBold={fontBold}
            setFontBold={setFontBold}
            isDisabledBold={isBoldDisable}
            sourceComponent={TABLE}
          />
          <Italic
            defaultFontItalic={fontItalic}
            setFontItalic={setFontItalic}
            isDisabledItalic={isItalicDisable}
            sourceComponent={TABLE}
          />
          <Underline fontUnderline={fontUnderline} setFontUnderline={setFontUnderline} sourceComponent={TABLE} />
          <TextAlign defaultFontAlign={fontAlign} setFontAlign={setFontAlign} sourceComponent={TABLE} />
        </ul>
      </div>
    </>
  );
};

HeaderOptionComponents.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  setTableConfigData: PropTypes.func.isRequired,
};

const FooterOptionComponents = ({ tableConfigData, setTableConfigData, applyFooter, setApplyFooter }) => {
  const [footerColor, setFooterColor] = useState(tableConfigData?.formatting?.tfoot?.backgroundColor);
  const [pageMarginColor, setPageMarginColor] = useState(tableConfigData?.formatting?.tfoot?.color);
  const [footerFontFamily, setFooterFontFamily] = useState(tableConfigData?.formatting?.tfoot?.fontFamily);
  const [FooterFontSize, setFooterFontSize] = useState(tableConfigData?.formatting?.tfoot?.fontSize);
  const [fontAlign, setFontAlign] = useState(tableConfigData?.formatting?.tfoot?.textAlign);
  const [fontUnderline, setFontUnderline] = useState(
    tableConfigData?.formatting?.tfoot?.textDecoration === "none" ? false : true
  );
  const [fontBold, setFontBold] = useState(tableConfigData?.formatting?.tfoot?.bold);
  const [fontItalic, setFontItalic] = useState(tableConfigData?.formatting?.tfoot?.italic);
  const [updatedData, setUpdatedData] = useState(false);
  const [isBoldDisable, setIsBoldDisable] = useState();
  const [isItalicDisable, setIsItalicDisable] = useState();
  const [currentFont, setCurrentFont] = useState();
  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const updateTableData = (key, value) => {
    setTableConfigData({
      ...tableConfigData,
      formatting: {
        ...tableConfigData.formatting,
        tfoot: { ...tableConfigData.formatting.tfoot, [key]: value },
      },
    });
    setUpdatedData(false);
  };

  useMemo(() => {
    setUpdatedData({ key: "backgroundColor", value: footerColor });
  }, [footerColor]);

  useMemo(() => {
    setCurrentFont(fonts?.find(font => font.name === footerFontFamily?.replace(/["]+/g, "")));
    setUpdatedData({ key: "fontFamily", value: `${footerFontFamily}` });
  }, [footerFontFamily]);

  useMemo(() => {
    setUpdatedData({ key: "fontSize", value: FooterFontSize });
  }, [FooterFontSize]);

  useMemo(() => {
    setUpdatedData({ key: "bold", value: fontBold });
    setUpdatedData({
      key: "fontWeight",
      value: fontBold
        ? currentFont?.bold_weight
        : currentFont?.normal_weight ?? tableConfigData.formatting.defaultFontWeight,
    });
  }, [fontBold]);

  useMemo(() => {
    setUpdatedData({ key: "italic", value: fontItalic });
    setUpdatedData({ key: "fontStyle", value: fontItalic ? "italic" : "normal" });
  }, [fontItalic]);

  useMemo(() => {
    setUpdatedData({ key: "color", value: pageMarginColor });
  }, [pageMarginColor]);

  useMemo(() => {
    setUpdatedData({ key: "textAlign", value: fontAlign });
  }, [fontAlign]);

  useMemo(() => {
    setUpdatedData({ key: "textDecoration", value: fontUnderline ? "underline" : "none" });
  }, [fontUnderline]);

  useEffect(() => {
    if (updatedData?.value) {
      updateTableData(updatedData.key, updatedData.value);
    }
  }, [updatedData]);

  useEffect(() => {
    if (currentFont?.source !== "Custom") {
      if (currentFont?.bold_weight === "") {
        setIsBoldDisable(true);
        setFontBold(false);
      } else {
        setUpdatedData({ key: "fontWeight", value: fontBold ? currentFont?.bold_weight : currentFont?.normal_weight });
        setIsBoldDisable(false);
      }

      if (currentFont?.bold_italic_weight === "") {
        setIsItalicDisable(true);
        setFontItalic(false);
      } else {
        setIsItalicDisable(false);
      }
    } else {
      setUpdatedData({ key: "fontWeight", value: currentFont?.normal_weight });
      setIsBoldDisable(true);
      setFontBold(false);
      setTimeout(() => {
        setUpdatedData({ key: "fontStyle", value: currentFont?.style });
        setIsItalicDisable(true);
        setFontItalic(false);
      }, 10);
    }
  }, [currentFont]);

  return (
    <>
      <div className={cx(style["custom-control"], style["custom-switch"], style["my-3"])}>
        <input
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={applyFooter}
          id="applyFooter"
          onClick={() => setApplyFooter(!applyFooter)}
        />
        <label className={style["custom-control-label"]} htmlFor="applyFooter">
          Apply Footer
        </label>
      </div>
      {applyFooter && (
        <>
          <div className={cx(style["line-controls-wrap"], style["justify-content-start"], style["left-shift"])}>
            <div className={style["slidelabel"]}>Footer Color</div>
            <ColorPickerWidget color={footerColor} setColor={setFooterColor} />
          </div>
          <div className={style["mb-3"]}>
            <div className={cx(style["font-15"], style["mb-3"])}>Footer Font</div>
            <ul className={style["font-size-family"]}>
              <FontFamily
                defaultFontSet={footerFontFamily}
                setFontFamily={setFooterFontFamily}
                sourceComponent={TABLE}
              />
              <FontSize
                defaultFontSizeSet={FooterFontSize}
                setFontSize={setFooterFontSize}
                defaultFontList={fontSizeList}
                sourceComponent={TABLE}
              />
            </ul>
            <ul className={cx(style["font-others"], style["d-flex"])}>
              <li className={style["custom-tooltip"]}>
                <ColorPickerWidget color={pageMarginColor} setColor={setPageMarginColor} />
                <div className={cx(style["custom-tooltip-content"], style["top"])}>Text Color</div>
              </li>
              <Bold
                defaultFontBold={fontBold}
                setFontBold={setFontBold}
                isDisabledBold={isBoldDisable}
                sourceComponent={TABLE}
              />
              <Italic
                defaultFontItalic={fontItalic}
                setFontItalic={setFontItalic}
                isDisabledItalic={isItalicDisable}
                sourceComponent={TABLE}
              />
              <Underline fontUnderline={fontUnderline} setFontUnderline={setFontUnderline} sourceComponent={TABLE} />
              <TextAlign defaultFontAlign={fontAlign} setFontAlign={setFontAlign} sourceComponent={TABLE} />
            </ul>
          </div>
        </>
      )}
    </>
  );
};

FooterOptionComponents.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  setTableConfigData: PropTypes.func.isRequired,
};

const BodyOptionComponents = ({ tableConfigData, setTableConfigData }) => {
  const [bodyColorOdd, setBodyColorOdd] = useState(tableConfigData?.formatting?.tbody?.backgroundColorOdd);
  const [bodyColorEven, setBodyColorEven] = useState(tableConfigData?.formatting?.tbody?.backgroundColorEven);
  const [bodyFontColor, setbodyFontColor] = useState(tableConfigData?.formatting?.tbody?.color);
  const [bodyFontFamily, setBodyFontFamily] = useState(tableConfigData?.formatting?.tbody?.fontFamily);
  const [bodyFontSize, setBodyFontSize] = useState(tableConfigData?.formatting?.tbody?.fontSize);
  const [fontAlign, setFontAlign] = useState(tableConfigData?.formatting?.tbody?.textAlign);
  const [fontUnderline, setFontUnderline] = useState(
    tableConfigData?.formatting?.tbody?.textDecoration === "none" ? false : true
  );
  const [fontBold, setFontBold] = useState(tableConfigData?.formatting?.tbody?.bold);
  const [fontItalic, setFontItalic] = useState(tableConfigData?.formatting?.tbody?.italic);
  const [useOneColor, setUseOneColor] = useState(tableConfigData?.formatting?.tbody?.colorScheme === "mono");
  const [updatedData, setUpdatedData] = useState(false);
  const [isBoldDisable, setIsBoldDisable] = useState();
  const [isItalicDisable, setIsItalicDisable] = useState();
  const [currentFont, setCurrentFont] = useState();
  const fonts = JSON.parse(localStorage?.getItem("allFonts"));

  const updateTableData = (key, value) => {
    setTableConfigData({
      ...tableConfigData,
      formatting: {
        ...tableConfigData.formatting,
        tbody: { ...tableConfigData.formatting.tbody, [key]: value },
      },
    });
  };
  useMemo(() => setUpdatedData({ key: "backgroundColorEven", value: bodyColorEven }), [bodyColorEven]);
  useMemo(() => setUpdatedData({ key: "backgroundColorOdd", value: bodyColorOdd }), [bodyColorOdd]);
  useMemo(() => {
    setCurrentFont(fonts?.find(font => font.name === bodyFontFamily.replace(/["]+/g, "")));
    setUpdatedData({ key: "fontFamily", value: `${bodyFontFamily}` });
  }, [bodyFontFamily]);
  useMemo(() => setUpdatedData({ key: "fontSize", value: bodyFontSize }), [bodyFontSize]);

  useMemo(() => {
    setUpdatedData({ key: "bold", value: fontBold });
    setUpdatedData({
      key: "fontWeight",
      value: fontBold
        ? currentFont?.bold_weight
        : currentFont?.normal_weight ?? tableConfigData.formatting.defaultFontWeight,
    });
  }, [fontBold]);

  useMemo(() => {
    setUpdatedData({ key: "italic", value: fontItalic });
    setUpdatedData({ key: "fontStyle", value: fontItalic ? "italic" : "normal" });
  }, [fontItalic]);

  useMemo(() => setUpdatedData({ key: "color", value: bodyFontColor }), [bodyFontColor]);
  useMemo(() => setUpdatedData({ key: "textAlign", value: fontAlign }), [fontAlign]);
  useMemo(
    () => setUpdatedData({ key: "textDecoration", value: fontUnderline ? "underline" : "none" }),
    [fontUnderline]
  );

  useEffect(() => {
    setUpdatedData({ key: "colorScheme", value: useOneColor ? "mono" : "alternate" });
  }, [useOneColor]);

  useEffect(() => {
    if (updatedData?.value) {
      updateTableData(updatedData.key, updatedData.value);
    }
  }, [updatedData]);

  useEffect(() => {
    if (currentFont?.source !== "Custom") {
      if (currentFont.bold_weight === "") {
        setIsBoldDisable(true);
        setFontBold(false);
      } else {
        setUpdatedData({ key: "fontWeight", value: fontBold ? currentFont.bold_weight : currentFont.normal_weight });
        setIsBoldDisable(false);
      }

      if (currentFont.bold_italic_weight === "") {
        setIsItalicDisable(true);
        setFontItalic(false);
      } else {
        setIsItalicDisable(false);
      }
    } else {
      setUpdatedData({ key: "fontWeight", value: currentFont?.normal_weight });
      setIsBoldDisable(true);
      setFontBold(false);
      setTimeout(() => {
        setUpdatedData({ key: "fontStyle", value: currentFont?.style });
        setIsItalicDisable(true);
        setFontItalic(false);
      }, 10);
    }
  }, [currentFont]);

  return (
    <>
      <div className={cx(style["custom-control"], style["custom-switch"], style["my-3"])}>
        <input
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={useOneColor}
          id="useOneColor"
          onClick={() => setUseOneColor(!useOneColor)}
        />
        <label className={style["custom-control-label"]} htmlFor="useOneColor">
          Use One Color
        </label>
      </div>
      <div className={cx(style["line-controls-wrap"], style["justify-content-start"], style["left-shift"])}>
        <div className={style["slidelabel"]}>Body Color</div>
        <ul className={style["d-flex"]}>
          <li className={style["pr-3"]}>
            <ColorPickerWidget color={bodyColorOdd} setColor={setBodyColorOdd} />
          </li>
          {!useOneColor && (
            <li>
              <ColorPickerWidget color={bodyColorEven} setColor={setBodyColorEven} />
            </li>
          )}
        </ul>
      </div>
      <div className={style["mb-3"]}>
        <div className={cx(style["font-15"], style["mb-2"])}>Body Font</div>
        <ul className={style["font-size-family"]}>
          <FontFamily defaultFontSet={bodyFontFamily} setFontFamily={setBodyFontFamily} sourceComponent={TABLE} />
          <FontSize
            defaultFontSizeSet={bodyFontSize}
            setFontSize={setBodyFontSize}
            defaultFontList={fontSizeList}
            sourceComponent={TABLE}
          />
        </ul>
        <ul className={cx(style["font-others"], style["d-flex"])}>
          <li className={style["custom-tooltip"]}>
            <ColorPickerWidget color={bodyFontColor} setColor={setbodyFontColor} />
            <div className={cx(style["custom-tooltip-content"], style["top"])}>Text Color</div>
          </li>
          <Bold
            defaultFontBold={fontBold}
            setFontBold={setFontBold}
            isDisabledBold={isBoldDisable}
            sourceComponent={TABLE}
          />
          <Italic
            defaultFontItalic={fontItalic}
            setFontItalic={setFontItalic}
            isDisabledItalic={isItalicDisable}
            sourceComponent={TABLE}
          />
          <Underline fontUnderline={fontUnderline} setFontUnderline={setFontUnderline} sourceComponent={TABLE} />
          <TextAlign defaultFontAlign={fontAlign} setFontAlign={setFontAlign} sourceComponent={TABLE} />
        </ul>
      </div>
    </>
  );
};

BodyOptionComponents.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  setTableConfigData: PropTypes.func.isRequired,
};

const BorderOptionComponents = ({ tableConfigData, setTableConfigData, setShowTable }) => {
  const [borderColor, setBorderColor] = useState(tableConfigData?.formatting?.border?.color);
  const [verticalBorder, setVerticalBorder] = useState(tableConfigData?.formatting?.border?.vertical === "solid");
  const [horizontalBorder, setHorizontalBorder] = useState(tableConfigData?.formatting?.border?.horizontal === "solid");

  const updateTableData = (key, value) => {
    setTableConfigData({
      ...tableConfigData,
      formatting: {
        ...tableConfigData.formatting,
        border: { ...tableConfigData.formatting.border, [key]: value },
      },
    });
  };

  useEffect(() => {
    updateTableData("color", borderColor);
  }, [borderColor]);

  useEffect(() => {
    updateTableData("vertical", verticalBorder === true ? "solid" : "none");
    if (!verticalBorder) setShowTable(false);
  }, [verticalBorder]);

  useEffect(() => {
    updateTableData("horizontal", horizontalBorder === true ? "solid" : "none");
    if (!horizontalBorder) setShowTable(false);
  }, [horizontalBorder]);

  return (
    <>
      <div className={cx(style["custom-control"], style["custom-switch"], style["my-1"])}>
        <input
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={horizontalBorder}
          id="horizontalBorder"
          onClick={() => setHorizontalBorder(!horizontalBorder)}
        />
        <label className={style["custom-control-label"]} htmlFor="horizontalBorder">
          Horizontal Border
        </label>
      </div>
      <div className={cx(style["custom-control"], style["custom-switch"], style["my-1"])}>
        <input
          type="checkbox"
          className={style["custom-control-input"]}
          defaultChecked={verticalBorder}
          id="verticalBorder"
          onClick={() => setVerticalBorder(!verticalBorder)}
        />
        <label className={style["custom-control-label"]} htmlFor="verticalBorder">
          Vertical Border
        </label>
      </div>
      {(verticalBorder || horizontalBorder) && (
        <>
          <div
            className={cx(
              style["line-controls-wrap"],
              style["justify-content-start"],
              style["mt-3"],
              style["mb-0"],
              style["left-shift"]
            )}>
            <div className={style["slidelabel"]}>Border Color</div>
            <ColorPickerWidget color={borderColor} setColor={setBorderColor} />
          </div>

          <div
            className={cx(
              style["line-controls-wrap"],
              style["d-flex"],
              style["flex-column"],
              style["align-items-baseline"]
            )}>
            <div className={style["slidelabel"]}>Border Width</div>
            <div className={cx(style["slidecontainer"], style["flex-fill"], style["w-100"])}>
              <RInput
                type="range"
                min={0}
                max={5}
                step={1}
                className="slider"
                value={tableConfigData?.formatting?.border?.width}
                id="tableBorderRange"
                onChange={e => updateTableData("width", e.target.value)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

BorderOptionComponents.propTypes = {
  tableConfigData: PropTypes.object.isRequired,
  setTableConfigData: PropTypes.func.isRequired,
  setShowTable: PropTypes.func.isRequired,
};

const Table = ({ closeModal, modalFullScreen, setModalFullScreen, editTable }) => {
  const assetType = TABLE;
  const tableInnerContent = useRef(null);
  const { checkWidgetAllignmentForSingleWidget } = UseCheckWidgetAllignment();

  const tableDefaultData = JSON.parse(JSON.stringify(widgetConfig?.tables));

  let { metadata, widgets, updateWidgets } = useContext(EditorContext);
  let targetWidgetIndex = widgets.findIndex(widget => widget.id === metadata.activeWidgetId[0]);
  const [activeTab, setActiveTab] = useState("rowColumn");
  const [isTabSelected, setIsTabSelected] = useState(false);
  const [addWidgetClicked, setAddWidgetClicked] = useState(false);
  const [dataparam, setDataParam] = useState();
  const [assetInnerContent, setAssetInnerContent] = useState();
  const [assetAdded, setAssetadded] = useState(false);
  const [editableDataUpdated, setEditableDataUpdated] = useState(editTable ? false : true);
  const [tableConfigData, setTableConfigData] = useState(tableDefaultData?.tableConfig);
  const [tableDatas, setTableDatas] = useState(tableDefaultData?.defaultTableData);
  const [showTable, setShowTable] = useState(true);
  const [reloadTableBody, setReloadTableBody] = useState(false);
  const [updatedDragData, setUpdatedDragData] = useState();
  const [reloadTableData, setReloadTableData] = useState(false);
  const [applyFooter, setApplyFooter] = useState(
    editTable?.footerEnabled ?? tableDefaultData?.tableConfig?.formatting?.footerEnabled
  );
  // Generate a unique ID for the tooltip
  const [uniqueId, setUniqueId] = useState("edit-table-");

  const editTitle = {
    rowColumn: "Rows and Columns",
    header: "Header Options",
    body: "Body Options",
    footer: "Footer Options",
    border: "Border Options",
  };

  // let borderAdjust =
  //   tableConfigData.formatting.border.horizontal !== "none" && tableConfigData.formatting.border.vertical !== "none"
  //     ? parseInt(tableConfigData.formatting.border.width)
  //     : handlerBorderAdjust;

  // // Insert Table into editor canvas
  // let tableWidth =
  //   tableInnerContent?.current?.children[0]?.clientWidth - (document.querySelector("td.draggableTd")?.offsetWidth || 0);
  // let tableHeight =
  //   tableInnerContent?.current?.children[0]?.children[1]?.clientHeight +
  //   tableInnerContent?.current?.children[0]?.children[0]?.children[1]?.clientHeight +
  //   borderAdjust;
  let tableWidth = document.querySelector(".table-wrap table")?.clientWidth;
  let tableHeight = document.querySelector(".table-wrap table")?.clientHeight;

  const { postion: getPosition } = useAlignment(addWidgetClicked, tableWidth, tableHeight, "middle-center");

  const getNewWidgetObject = useAddWidget(addWidgetClicked, assetType, dataparam, getPosition, tableWidth, tableHeight);
  useElementInnerHtml(
    addWidgetClicked,
    setAddWidgetClicked,
    assetType,
    assetInnerContent,
    dataparam,
    getNewWidgetObject
  );
  // --------------------------------//
  const toggleTab = tab => {
    setIsTabSelected(!isTabSelected);
    if (tab && activeTab !== tab) setActiveTab(tab);
  };
  const close = () => {
    closeModal && closeModal();
    if (modalFullScreen) setModalFullScreen(false);
  };

  const setFullScreen = () => {
    setModalFullScreen(!modalFullScreen);
  };

  const addMergedTags = dataArrays => {
    const updatedTableData = dataArrays.map(row => ({
      ...row,
      cells: row.cells.map(cell =>
        cell.merged
          ? {
              ...cell,
              merged: undefined,
              mergedId: undefined,
              colspan: 1,
              rowspan: 1,
            }
          : cell
      ),
    }));
    const updatedData = JSON.parse(JSON.stringify(updatedTableData)); // Deep copy to avoid mutating original data.

    // Handle `colspan` and `rowspan`.
    updatedData.forEach((row, rowIndex) => {
      row.cells.forEach((cell, colIndex) => {
        const { colspan = 1, rowspan = 1 } = cell;

        // // Handle `colspan`
        // if (colspan > 1) {
        //   for (let i = 1; i < colspan; i++) {
        //     if (row.cells[colIndex + i]) {
        //       row.cells[colIndex + i] = {
        //         merged: true,
        //         mergedId: `body-${rowIndex}-${colIndex}`,
        //         value: "",
        //       };
        //     }
        //   }
        // }

        // // Handle `rowspan`
        // if (rowspan > 1) {
        //   for (let i = 1; i < rowspan; i++) {
        //     if (updatedData[rowIndex + i]) {
        //       updatedData[rowIndex + i].cells[colIndex] = {
        //         merged: true,
        //         mergedId: `body-${rowIndex}-${colIndex}`,
        //         value: "",
        //       };
        //     }
        //   }
        // }

        // Handle rowspan first
        if (rowspan > 1) {
          for (let i = 1; i < rowspan; i++) {
            if (updatedData[rowIndex + i]) {
              updatedData[rowIndex + i].cells[colIndex] = {
                merged: true,
                mergedId: `body-${rowIndex}-${colIndex}`,
                value: "",
              };
            }
          }
        }

        // Handle colspan
        if (colspan > 1) {
          for (let i = 1; i < colspan; i++) {
            if (row.cells[colIndex + i]) {
              row.cells[colIndex + i] = {
                merged: true,
                mergedId: `body-${rowIndex}-${colIndex}`,
                value: "",
              };
            }
          }

          // If rowspan > 1, propagate merged cells across columns in subsequent rows
          if (rowspan > 1) {
            for (let i = 1; i < rowspan; i++) {
              if (updatedData[rowIndex + i]) {
                for (let j = 1; j < colspan; j++) {
                  updatedData[rowIndex + i].cells[colIndex + j] = {
                    merged: true,
                    mergedId: `body-${rowIndex}-${colIndex}`,
                    value: "",
                  };
                }
              }
            }
          }
        }
      });
    });
    return updatedData;
  };

  // For add column and row
  const addRowColumn = (type, idx, triggerFrom) => {
    let dataObj = { ...tableDatas };
    if (type === "columns") {
      if (tableConfigData.formatting.columns === tableConfigData.formatting.columnMaxSize) return;
      if (dataObj?.columns[idx]?.colspan > 1) {
        let nextInsertId = dataObj.columns[idx].colspan + idx;
        dataObj.columns.splice(nextInsertId, 0, { value: "", width: "100px", colspan: 1 });
      } else {
        dataObj.columns.splice(idx + 1, 0, { value: "", width: "100px", colspan: 1 });
      }

      // For footer add column
      if (dataObj?.footer[idx]?.colspan > 1) {
        let nextInsertId2 = dataObj.footer[idx].colspan + idx;
        dataObj.footer.splice(nextInsertId2, 0, { value: "", colspan: 1 });
      } else {
        dataObj.footer.splice(idx + 1, 0, { value: "", colspan: 1 });
      }

      for (let index = 0; index < dataObj.rows.length; index++) {
        dataObj.rows[index].cells.splice(idx + 1, 0, { value: "", rowspan: 1, colspan: 1 });
      }
    } else {
      if (tableConfigData.formatting.rows === tableConfigData.formatting.rowMaxSize) return;
      let row = { cells: [] };
      for (let index = 0; index < dataObj.columns.length; index++) {
        if (dataObj.rows[idx]?.cells[index]?.rowspan > 1) {
          dataObj.rows[idx].cells[index].rowspan = dataObj.rows[idx].cells[index].rowspan + 1;
          row.cells.push({
            merged: true,
            value: "",
          });
        }

        if (dataObj.rows[idx]?.cells[index]?.colspan == 1 && dataObj.rows[idx]?.cells[index]?.rowspan == 1) {
          row.cells.push({
            colspan: 1,
            rowspan: 1,
            value: "",
          });
        }
        if (dataObj.rows[idx]?.cells[index]?.colspan > 1 && dataObj.rows[idx]?.cells[index]?.rowspan == 1) {
          row.cells.push({
            colspan: dataObj.rows[idx]?.cells[index]?.colspan,
            rowspan: 1,
            value: "",
          });
        }
        if (dataObj.rows[idx]?.cells[index]?.merged) {
          row.cells.push({
            merged: true,
            value: "",
          });

          // Update main cell where rowspan is updated
          let i = idx - 1;
          while (i < idx) {
            // Check if rows[i] or cells[index] is undefined, if so, break the loop
            if (!dataObj.rows[i] || !dataObj.rows[i].cells[index]) {
              break; // Exit the loop if undefined is encountered
            }

            if (dataObj?.rows[i]?.cells[index]?.rowspan > 1) {
              dataObj.rows[i].cells[index].rowspan = dataObj.rows[i].cells[index].rowspan + 1;
              break; // Exit the loop once the target value is found
            }
            i--;
          }
        }

        // Below section form add from side bar
        if (triggerFrom === "navbar") {
          row.cells.push({
            colspan: 1,
            rowspan: 1,
            value: "",
          });
        }
      }
      dataObj.rows.splice(idx + 1, 0, row);
    }
    // update table width to DOM
    if (type === "columns") {
      document.querySelector(".table-canvas-wraper table").style.width = parseFloat(document.querySelector(".table-canvas-wraper table").style.width) + 100 + "px";
    }

    setTableConfigData({
      ...tableConfigData,
      formatting: { ...tableConfigData.formatting, [type]: dataObj[type].length },
    });
    setTableDatas(dataObj);
    // setShowTable(false);
  };

  const deleteRowColumn = (type, idx) => {
    // Check minimum 1 column and row should present
    if (tableConfigData.formatting[type] < 2) return;

    let dataObj = { ...tableDatas };
    let deletedWidth = 0;
    if (type === "columns") {
      let deletedColArray = [idx];

      // Calculate colspan for merged column
      if (dataObj?.columns[idx]?.merged) {
        let i = idx - 1;
        while (i < idx) {
          // Check if rows[i] or cells[index] is undefined, if so, break the loop
          if (!dataObj?.columns[i]) {
            break; // Exit the loop if undefined is encountered
          }

          if (dataObj?.columns[i]?.colspan > 1) {
            dataObj.columns[i].colspan = dataObj.columns[i].colspan - 1;
            break; // Exit the loop once the target value is found
          }
          i--;
        }
      }

      // Push merged cell id to deletedColArray
      if (dataObj.columns[idx].colspan > 1) {
        for (let k = idx + 1; k < dataObj.columns[idx].colspan + idx; k++) deletedColArray.unshift(k);
      }

      deletedColArray.forEach(colCellIdx => {
        for (let index = 0; index < dataObj.rows.length; index++) {
          if (dataObj.rows[index]?.cells[colCellIdx]?.merged) {
            // Update main cell where rowspan is updated
            let i = colCellIdx - 1;
            while (i < colCellIdx) {
              // Check if rows[i] or cells[index] is undefined, if so, break the loop
              if (!dataObj.rows[index].cells[i]) {
                break; // Exit the loop if undefined is encountered
              }

              if (dataObj?.rows[index]?.cells[i]?.colspan > 1) {
                dataObj.rows[index].cells[i].colspan = dataObj.rows[index].cells[i].colspan - 1;
                break; // Exit the loop once the target value is found
              }
              i--;
            }
          } else if (dataObj.rows[index]?.cells[colCellIdx]?.colspan > 1) {
            for (let deleteIdx = 1; deleteIdx < dataObj.rows[index].cells[colCellIdx].colspan; deleteIdx++) {
              // Update if current selected colspan is more than 1
              delete dataObj.rows[index]?.cells[colCellIdx + deleteIdx].merged;
              dataObj.rows[index].cells[colCellIdx + deleteIdx].rowspan = 1;
              dataObj.rows[index].cells[colCellIdx + deleteIdx].colspan = 1;
            }
          }

          dataObj.rows[index].cells.splice(colCellIdx, 1);
        }
        deletedWidth = document.getElementById(`edit-table-col-tooltip-${idx}`).offsetWidth;
        dataObj.columns.splice(colCellIdx, 1);
        // dataObj.footer.splice(colCellIdx, 1);
        // Delete Footer cell
        if (dataObj?.footer[colCellIdx]?.colspan > 1) {
          dataObj.footer[colCellIdx].colspan = dataObj.footer[colCellIdx].colspan - 1;
          dataObj.footer.splice(colCellIdx + 1, 1);
        } else if (dataObj?.footer[colCellIdx]?.merged) {
          // Update main cell where rowspan is updated
          let i = colCellIdx - 1;
          while (i < colCellIdx) {
            // Check if rows[i] or cells[index] is undefined, if so, break the loop
            if (!dataObj.footer[i]) {
              break; // Exit the loop if undefined is encountered
            }

            if (dataObj.footer[i]?.colspan > 1) {
              dataObj.footer[i].colspan = dataObj.footer[i].colspan - 1;
              dataObj.footer.splice(colCellIdx, 1);
              break; // Exit the loop once the target value is found
            }
            i--;
          }
        } else {
          dataObj.footer.splice(colCellIdx, 1);
        }
      });
      // setShowTable(false);
    } else {
      let firstCellRowspanCount = dataObj?.rows[idx]?.cells[0]?.rowspan ?? 1;
      for (let index = 0; index < dataObj.columns.length; index++) {
        if (dataObj?.rows[idx]?.cells[index]?.rowspan > 1) {
          // Update if current selected rowspan is more than 1
          delete dataObj.rows[idx + 1].cells[index].merged;
          dataObj.rows[idx + 1].cells[index].colspan = 1;
          dataObj.rows[idx + 1].cells[index].rowspan = dataObj.rows[idx].cells[index].rowspan - 1;
        } else if (dataObj?.rows[idx]?.cells[index]?.merged) {
          // Update main cell where rowspan is updated
          let i = idx - 1;
          while (i < idx) {
            // Check if rows[i] or cells[index] is undefined, if so, break the loop
            if (!dataObj.rows[i] || !dataObj.rows[i].cells[index]) {
              break; // Exit the loop if undefined is encountered
            }

            if (dataObj?.rows[i]?.cells[index]?.rowspan > 1) {
              dataObj.rows[i].cells[index].rowspan = dataObj.rows[i].cells[index].rowspan - 1;
              break; // Exit the loop once the target value is found
            }
            i--;
          }
        }
      }
      // dataObj.rows.splice(idx, 1);
      if (firstCellRowspanCount > 1) {
        //
        let k = idx + firstCellRowspanCount - 1;
        while (k >= idx) {
          dataObj.rows.splice(k, 1);
          k--;
        }
      } else {
        dataObj.rows.splice(idx, 1);
      }
    }
    dataObj.rows = addMergedTags(dataObj.rows);
    
    // update table width to DOM
    document.querySelector(".table-canvas-wraper table").style.width = parseFloat(document.querySelector(".table-canvas-wraper table").style.width) - deletedWidth + "px";

    setTableConfigData({
      ...tableConfigData,
      formatting: { ...tableConfigData.formatting, rows: dataObj.rows.length, columns: dataObj.columns.length },
    });
    setTableDatas(dataObj);
  };

  // :: ==> Insert tanle in to canvas function
  const insertTable = async () => {
    setUniqueId("");
    await sleep(300);

    if (!editTable) {
      let addTableHtml = tableInnerContent.current.cloneNode(true);

      // Set background color for table header
      addTableHtml.children[0].children[0].style.backgroundColor = tableConfigData?.formatting?.thead?.backgroundColor;
      if (addTableHtml.querySelector("tfoot"))
        addTableHtml.querySelector("tfoot").style.backgroundColor = tableConfigData?.formatting?.tfoot?.backgroundColor;

      // Remove All draggable attribute
      addTableHtml.children[0].children[1].childNodes.forEach(el => {
        el.removeAttribute("draggable");
      });

      // background color for table body
      addTableHtml.children[0].children[1].style.backgroundColor =
        tableConfigData?.formatting?.tbody?.backgroundColorOdd;

      // Remove specific nodes
      // removeSpecificNode(addTableHtml);

      setAssetInnerContent(addTableHtml);
      setDataParam({
        ...tableDefaultData.dataAttr,
        "data-color-scheme": tableConfigData?.formatting?.tbody?.colorScheme,
      });
      setAddWidgetClicked(true);
      setAssetadded(true);
    } else {
      //Update new node element
      let addTableHtml = tableInnerContent.current.cloneNode(true);
      let widgetInner = document.querySelector("#" + metadata.activeWidgetId[0] + " .dhp-widget-inner");
      // Set transform scale value for editable table
      let editTableStyleValue = widgetInner?.style;
      if (editTableStyleValue?.cssText) addTableHtml.style = editTableStyleValue.cssText;
      let {
        scale: { x: innerScale },
      } = getCssTransformObj({
        transform: editTableStyleValue.transform,
      });

      // Set background color for table header
      addTableHtml.children[0].children[0].style.backgroundColor = tableConfigData?.formatting?.thead?.backgroundColor;

      // Remove All draggable attribute
      addTableHtml.children[0].children[1].childNodes.forEach(el => {
        el.removeAttribute("draggable");
      });

      // background color for table body
      addTableHtml.children[0].children[1].style.backgroundColor =
        tableConfigData?.formatting?.tbody?.backgroundColorOdd;

      // Remove specific nodes
      // removeSpecificNode(addTableHtml);

      // let borderAdjust =
      //   tableConfigData.formatting.border.horizontal !== "none" && tableConfigData.formatting.border.vertical !== "none"
      //     ? parseInt(tableConfigData.formatting.border.width)
      //     : handlerBorderAdjust;

      // tableWidth =
      //   tableInnerContent?.current?.children[0]?.clientWidth -
      //   (document.querySelector("td.draggableTd")?.offsetWidth || 0);
      // tableHeight =
      //   tableInnerContent?.current?.children[0]?.children[1]?.clientHeight +
      //   tableInnerContent?.current?.children[0]?.children[0]?.children[1]?.clientHeight +
      //   borderAdjust;
      tableWidth = document.querySelector(".table-wrap table").clientWidth;
      tableHeight = document.querySelector(".table-wrap table").clientHeight;

      widgetInner.style.height = `${tableHeight}px`;
      widgetInner.style.width = `${tableWidth}px`;
      widgetInner.innerHTML = addTableHtml.innerHTML;
      if (widgetInner.querySelector("tfoot"))
        widgetInner.querySelector("tfoot").style.backgroundColor = tableConfigData?.formatting?.tfoot?.backgroundColor;
      widgetInner.parentElement.setAttribute("data-version", "2.0");

      let { x_al, y_al } = checkWidgetAllignmentForSingleWidget(
        tableHeight * parseFloat(innerScale),
        false,
        tableWidth * parseFloat(innerScale)
      );

      let widgetObject = widgets[targetWidgetIndex];
      // adjust left top for rotated TABLE update
      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: widgetObject.style.transform,
      });
      const { left, top } = calculateNewPositionOnRotatedObjectResize(
        parseFloat(widgetTransX),
        parseFloat(widgetTransY),
        parseFloat(tableWidth * parseFloat(innerScale)),
        parseFloat(tableHeight * parseFloat(innerScale)),
        parseFloat(widgetObject.style.width),
        parseFloat(widgetObject.style.height),
        parseFloat(widgetTheta)
      );
      const widgetTransformStr = getCssTransformObj({
        translateX: `${left}px`,
        translateY: `${top}px`,
        transform: widgetObject.style.transform,
        returnStr: true,
      });

      let newArray = Object.assign([...widgets], {
        [targetWidgetIndex]: {
          ...widgetObject,
          style: {
            ...widgetObject.style,
            height: `${tableHeight * parseFloat(innerScale)}px`,
            width: `${tableWidth * parseFloat(innerScale)}px`,
            transform: widgetTransformStr,
          },
          innerHTML: document.getElementById(metadata.activeWidgetId[0]).innerHTML,
          data: {
            ...widgetObject.data,
            "data-color-scheme": tableConfigData?.formatting?.tbody?.colorScheme,
            "data-x-allignment": x_al,
            "data-y-allignment": y_al,
            "data-version": "2.0",
          },
        },
      });
      updateWidgets(newArray);
      setAssetadded(true);
    }
  };

  // Remove table drag elements
  // const removeSpecificNode = node => {
  //   // Remove Drag tr
  //   let draggableHandlesTr = node.querySelectorAll(".draggableTr");
  //   draggableHandlesTr.forEach(trElem => {
  //     trElem.parentNode.removeChild(trElem);
  //   });

  //   // Remove Drag td
  //   let draggableHandlesTd = node.querySelectorAll(".draggableTd");
  //   draggableHandlesTd.forEach(tdElem => {
  //     tdElem.parentNode.removeChild(tdElem);
  //   });
  // };

  useEffect(() => {
    // Hide modal after added table into canvas
    setTimeout(() => {
      if (assetAdded) closeModal();
      if (modalFullScreen) setModalFullScreen(false);
    }, 200);
  }, [assetAdded]);

  useEffect(() => {
    if (!editTable) return;

    let modifiedConfig = { ...tableConfigData.formatting };
    modifiedConfig["rows"] = editTable.rows;
    modifiedConfig["columns"] = editTable.columns;
    modifiedConfig["thead"] = { ...modifiedConfig.thead, ...editTable.thead };
    modifiedConfig["tbody"] = { ...modifiedConfig.tbody, ...editTable.tbody };
    modifiedConfig["tfoot"] = { ...modifiedConfig.tfoot, ...editTable.tfoot };
    modifiedConfig["border"] = { ...modifiedConfig.border, ...editTable.border };
    // Dynamically set the table width in the modal based on the CANVAS dimensions. 
    // Using the <table> tag to ensure compatibility with older table versions that don't have the .table class.
    modifiedConfig["tableStyle"] = { width: document.querySelector("#" + metadata.activeWidgetId[0] + " table").offsetWidth };
    setTableConfigData({ formatting: modifiedConfig });
    setTableDatas(editTable.defaultTableData);
    setEditableDataUpdated(true);
  }, [editTable]);

  useEffect(() => {
    if (!showTable) setShowTable(true);
  }, [showTable]);

  useEffect(() => {
    if (reloadTableBody) setReloadTableBody(false);
  }, [reloadTableBody]);

  useEffect(() => {
    if (updatedDragData) {
      setTableDatas(updatedDragData);
      setUpdatedDragData(null);
      setReloadTableData(true);
    }
  }, [updatedDragData]);

  useEffect(() => {
    if (reloadTableData) setReloadTableData(false);
  }, [reloadTableData]);

  // Prevent the default Ctrl + A behavior
  useEffect(() => {
    window.getSelection().removeAllRanges(); // Deselect all text
  }, []);

  return (
    <>
      {editableDataUpdated && !reloadTableData && (
        <div className="table-modal-wrap">
          <div className="d-flex justify-content-between modal-header">
            <h4 className={cx(style["fw-7"], style["mb-0"])}>Table</h4>
            <div>
              <span className={cx(style["expand-btn"], style["rounded"])} onClick={setFullScreen}>
                <Icon icon={modalFullScreen ? "ui-collapse" : "ui-expand"} />
              </span>
              <span className={cx(style["cross-modal"], style["rounded"])} onClick={close}>
                <Icon icon="ui-close" />
              </span>
            </div>
          </div>
          <Row className={cx(style["table-modal-tab"], style["m-0"])}>
            <Col xs={4} className={cx(style["TMleft-pane"])}>
              <div className={cx(style["pl-3"], style["pt-3"])}>
                {!isTabSelected && (
                  <Nav tag="div" tabs vertical>
                    <NavItem>
                      <NavLink tag="span" onClick={() => toggleTab("rowColumn")}>
                        <Icon icon="ui-table"></Icon> Rows and Columns
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag="span" onClick={() => toggleTab("header")}>
                        <Icon icon="ui-table-header"></Icon> Header
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag="span" onClick={() => toggleTab("body")}>
                        <Icon icon="ui-table-body"></Icon> Body
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag="span" onClick={() => toggleTab("footer")}>
                        <Icon icon="ui-table-footer"></Icon> Footer
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink tag="span" onClick={() => toggleTab("border")}>
                        <Icon icon="ui-grid"></Icon> Border
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}

                {isTabSelected && (
                  <>
                    <h6
                      onClick={toggleTab}
                      className={cx(
                        style["mb-3"],
                        style["pt-2"],
                        style["fw-6"],
                        style["d-flex"],
                        style["align-items-center"],
                        style["cursor-pointer"],
                        style["ml-n1"]
                      )}>
                      <Icon icon="ui-arrow-left" additionalclass="mr-2" />
                      {editTitle[activeTab]}
                    </h6>
                    <div className={cx(style["table-option"], style["ml-n1"])}>
                      <div className={style["table-option-inside"]}>
                        {activeTab === "rowColumn" && (
                          <RowColumnSection
                            tableConfigData={tableConfigData}
                            setTableConfigData={setTableConfigData}
                            addRowColumn={addRowColumn}
                            deleteRowColumn={deleteRowColumn}
                          />
                        )}
                        {activeTab === "header" && (
                          <HeaderOptionComponents
                            tableConfigData={tableConfigData}
                            setTableConfigData={setTableConfigData}
                          />
                        )}
                        {activeTab === "body" && (
                          <BodyOptionComponents
                            tableConfigData={tableConfigData}
                            setTableConfigData={setTableConfigData}
                          />
                        )}
                        {activeTab === "footer" && (
                          <FooterOptionComponents
                            tableConfigData={tableConfigData}
                            setTableConfigData={setTableConfigData}
                            applyFooter={applyFooter}
                            setApplyFooter={setApplyFooter}
                          />
                        )}
                        {activeTab === "border" && (
                          <BorderOptionComponents
                            tableConfigData={tableConfigData}
                            setTableConfigData={setTableConfigData}
                            setShowTable={setShowTable}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Col>
            {showTable && (
              <Col xs={9} className={cx(style["TMright-pane"])}>
                <TableContent
                  forwardedRef={tableInnerContent}
                  tableConfigData={tableConfigData}
                  tableDatas={tableDatas}
                  addRowColumn={addRowColumn}
                  deleteRowColumn={deleteRowColumn}
                  setTableDatas={setTableDatas}
                  reloadTableBody={reloadTableBody}
                  setReloadTableBody={setReloadTableBody}
                  setUpdatedDragData={setUpdatedDragData}
                  setShowTable={setShowTable}
                  applyFooter={applyFooter}
                  uniqueId={uniqueId}
                />
                <div className={style["Mfooter"]}>
                  <div className={cx(style["align-items-center"], style["d-flex"])}>
                    <Button cssModule={style} color="primary" onClick={insertTable}>
                      {!editTable ? "Insert" : "Update"}
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

Table.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalFullScreen: PropTypes.bool,
  setModalFullScreen: PropTypes.func,
  editTable: PropTypes.object,
};

export default Table;
