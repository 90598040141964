import React, { useState, useEffect, useContext } from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Icon } from "../../ui/icon";
import { fetchDocumentDetails } from "../../../store/actions/documentActions";
import { fetchTemplateTypes, fetchTemplatePageSizes } from "../../../store/actions/templateActions";
import {
  TEMPLATE_TYPE_INFOGRAPHIC,
  TEMPLATE_TYPE_PRESENTATION,
  TEMPLATE_TYPE_PROPOSAL,
} from "../../../constants/pageSizeModal";

import global from "../../../scss/dhp.scss";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import PageResizeComponent from "../PageResizeComponent";
import { getDimentionInCurrentUOM } from "../../../_helpers/utils";

let style = Object.assign({}, global);

const PageSize = () => {
  const { dimension, documentType } = useContext(EditorContext);

  const dispatch = useDispatch();
  const { id } = useParams();

  const documentDetails = useSelector(state => state.document?.documentDetails?.data);
  const { templateGroups: availableTemplateGroups, templatePageSizes } = useSelector(state => state.template);
  const isCompSuperAdmin = useSelector(state => state.auth?.user?.company?.superadmin_company);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (id && !documentDetails) dispatch(fetchDocumentDetails(id));
    if (!availableTemplateGroups) dispatch(fetchTemplateTypes(isCompSuperAdmin));
    if (!templatePageSizes) dispatch(fetchTemplatePageSizes());
  }, []);

  return (
    <>
      <li className={cx(style["toolset-group"], style["current-page-size"])}>
        <span className={cx(style["fw-7"], style["mr-1"])}>
          <span className={style["text-capitalize"]}>
            {TEMPLATE_TYPE_PRESENTATION === documentType
              ? dimension?.size
              : dimension?.label.startsWith("Twitter")
              ? dimension?.label.replace("Twitter", "X/Twitter")
              : dimension?.label}{" "}
          </span>
        </span>
        {getDimentionInCurrentUOM(dimension?.width, dimension?.displayUnit)}x
        {getDimentionInCurrentUOM(dimension?.height, dimension?.displayUnit)}{" "}
        {dimension?.displayUnit ?? dimension?.unit}
        {![TEMPLATE_TYPE_PRESENTATION, TEMPLATE_TYPE_INFOGRAPHIC, TEMPLATE_TYPE_PROPOSAL].includes(documentType) && (
          <span
            className={cx(style["d-flex"], style["cursor-pointer"])}
            data-toggle="modal"
            onClick={() => setShowModal(!showModal)}>
            <Icon icon="ui-arrow-down" additionalclass="ml-2" />
          </span>
        )}
      </li>
      {showModal && <PageResizeComponent showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
};

export default PageSize;
