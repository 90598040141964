import { useEffect, useState } from "react";

const useTableResizer = (resizableContainer, resizer) => {
  const [resizedCellWidth, setResizedCellWidth] = useState(null);

  useEffect(() => {
    if (!resizableContainer?.current || !resizer?.current) return;

    const resizableElem = resizableContainer.current;
    const resizerElem = resizer.current;
    let startX = 0;
    let startWidth = 0;
    let tableStartWidth = 0;
    let newTableWidth = 0;

    const getExactWidth = (element) => {
      const computedStyles = window.getComputedStyle(element);
      const width = parseFloat(computedStyles.width);
      return width;
    };

    const onMouseMove = (event) => {
      const dx = event.clientX - startX;
      const newWidth = startWidth + dx;
      newTableWidth = tableStartWidth + dx;

      let colspan = resizableContainer?.current.getAttribute("colspan")
      const minWidth = 50 * colspan; // 50 is the minimum width for each cell
      if (newWidth >= minWidth) {
        resizableElem.style.width = `${newWidth}px`;

        // Adjust the table width in MODAL
        resizableElem.closest("table").style.width = `${newTableWidth}px`;
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      setResizedCellWidth(`${getExactWidth(resizableElem)}px`);
      resizerElem?.parentNode?.classList.remove("resizer-wrapper");
    };

    const onMouseDown = (event) => {
      startX = event.clientX;
      startWidth = getExactWidth(resizableElem);
      tableStartWidth = getExactWidth(resizableElem.closest("table"));
      resizerElem?.parentNode?.classList.add("resizer-wrapper");

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
    };

    resizerElem.addEventListener("mousedown", onMouseDown);

    return () => {
      resizerElem.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  return resizedCellWidth
};

export default useTableResizer;
