import { useContext, useEffect } from "react";

import * as constant from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";
import useGuideLine from "./useGuideLine";
import useSelectable from "./useSelectable";
import { useSelector } from "react-redux";

const useFitToScreen = (fitToScreen, setFitToScreen, onload, setOnload) => {
  let { documentType, dimension, blockNodes, updateDimension, isDocumentReady, isTimeLineViewOpen } =
    useContext(EditorContext);
  const activeGuideLine = useGuideLine();
  const { updateHandler } = useSelectable();
  const userInfo = useSelector(state => state?.auth?.user);

  useEffect(() => {
    if (!isDocumentReady && onload) return;
    if (fitToScreen) {
      const pageMarginTopBottom = userInfo.video_enabled ? constant.PAGE_MARGIN_TOP_BOTTOM : constant.PAGE_MARGIN_TOP_BOTTOM_NON_VIDEO_TYPE_USER;
      let adjustablePaddingValue = isTimeLineViewOpen
        ? constant.PAGE_MARGIN_TOP_BOTTOM_WITH_TIMELINE
        : pageMarginTopBottom;
      let canvasPanelArea = document.getElementById("canvas-panel-area");
      let canvasPannelareaPaddingLeft = parseFloat(
        window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-left")
      );
      let canvasPannelareaPaddingRight = parseFloat(
        window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-right")
      );
      let canvasPannelareaPaddingTop = parseFloat(
        window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-top")
      );
      let canvasPannelareaPaddingBottom = parseFloat(
        window.getComputedStyle(canvasPanelArea, null).getPropertyValue("padding-bottom")
      );
      let canvasWidth = canvasPanelArea.offsetWidth - (canvasPannelareaPaddingLeft + canvasPannelareaPaddingRight);
      let canvasHeight = canvasPanelArea.offsetHeight - (canvasPannelareaPaddingTop + canvasPannelareaPaddingBottom);

      let pageHeight;

      if (documentType === constant.TYPE_INFOGRAPHIC) {
        pageHeight = 0;
        blockNodes.map(blockNode => {
          pageHeight += parseFloat(blockNode.style.height);
        });
      } else if (documentType === constant.TYPE_PROPOSAL) {
        pageHeight = 768;
      } else {
        pageHeight = parseFloat(dimension.height);
      }

      let zoomval = parseFloat(
        Math.min(
          (canvasWidth - constant.PAGE_MARGIN_LEFT_RIGHT) / parseInt(dimension.width),
          (canvasHeight - adjustablePaddingValue) / pageHeight
        ) * 100
      );
      zoomval = zoomval > 250 ? 250 : zoomval;

      updateHandler(zoomval);

      if (documentType === constant.TYPE_INFOGRAPHIC && onload) updateDimension({ ...dimension, zoom: 100 });
      else updateDimension({ ...dimension, zoom: zoomval });
      setFitToScreen(false);
      setOnload(false);

      if (
        (documentType.toLowerCase() === constant.TYPE_YOUTUBE_BANNER && dimension.size === "youtube-banner") ||
        (documentType.toLowerCase() === constant.TYPE_BROCHURE &&
          dimension.size === "a4" &&
          dimension.orientation === "landscape")
      ) {
        setTimeout(() => {
          activeGuideLine(zoomval);
        }, 50);
      }
    }
  }, [fitToScreen]);
};

export default useFitToScreen;
