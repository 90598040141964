import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Button, Form, UncontrolledTooltip } from "reactstrap";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { Input } from "../../ui/input";
import { preventFormSubmitOnEnter } from "../../../_helpers/utils";
import { EditorContext } from "../../../containers/editor/EditorLayout";

let style = Object.assign({}, global);

const BlockTileModal = props => {
  let { blockNodes, metadata, updateBlockNodes } = useContext(EditorContext);

  const [blockTileValue, setBlockTileValue] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const checkValidity = e => {
    if (e.target.value !== "") setBlockTileValue(e.target.value);
    else setBlockTileValue("");
  };

  const applyBlockTitle = () => {
    let newArray = Object.assign([...blockNodes], {
      [metadata.activeBlockIdx]: {
        ...blockNodes[metadata.activeBlockIdx],
        blockName: blockTileValue.replace(/^\s+|\s+$/g, ""),
      },
    });

    updateBlockNodes(newArray);
    props.setShowModal(!props.showModal);
  };

  useEffect(() => {
    setBlockTileValue(blockNodes[metadata.activeBlockIdx]?.blockName);
  }, []);

  return (
    <>
      

      <div className={cx(style["d-flex"], style["align-items-center"], style["mb-4"], style["modal-heading"])}>
        <h4 className={cx(style["fw-7"], style["mb-0"])}>Block Tile</h4>
         <div className={cx(style["cursor-pointer"], style["custom-tooltip"], style["d-flex"], style["ml-2"])} > {/* id="tooltip-block-tile" */}
          <Icon icon="ui-info" additionalclass="font-base" />
          <span className={cx(style["custom-tooltip-content"], style["bottom"], style["text-center"])}>Block Title is displayed in the <br></br> navigation menu in the published page</span>
        </div>
        {/* <Tooltip
          placement="bottom-end"
          isOpen={tooltipOpen}
          target="tooltip-block-tile"
          toggle={toggle}
          className={cx(style["tooltip-block-tile"])}
          innerClassName="text-left p-3">
          <span>Block Title is displayed in the navigation menu in the published page</span>
        </Tooltip> */}
      </div>

      <span className={cx(style["cross-modal"], style["rounded"])} onClick={() => props.setShowModal(!props.showModal)}>
        <Icon icon="ui-close" />
      </span>

      <Form id="blockTileForm" onKeyDown={e => preventFormSubmitOnEnter(e)}>
        <div className={cx(style["form-group"], style["mb-3"])}>
          <div className="block-tile-field clearfix">
            <Input
              type="text"
              name="blockTileTextField"
              className="blockTileTextField"
              label="Block Name"
              value={blockTileValue}
              onChange={e => checkValidity(e)}
              autoComplete="off"
            />
          </div>
        </div>

        <Button
          type="button"
          color="primary"
          disabled={!blockTileValue || blockTileValue.trim() === ""}
          onClick={applyBlockTitle}>
          Save
        </Button>
      </Form>
    </>
  );
};
BlockTileModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default BlockTileModal;
