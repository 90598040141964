import { useEffect, useContext } from "react";
import { widgetConfig } from "../components/Editor/editor_config";
import { TYPE_INFOGRAPHIC, TYPE_PROPOSAL } from "../constants/editor";
import { EditorContext } from "../containers/editor/EditorLayout";

const useSetBlockHeight = (isResize, blockContainer, height) => {
  const {
    documentType,
    blockNodes,
    updateBlockNodes,
    dimension,
    updateDimension,
    updateMetadata,
    metadata,
    pageMargin,
    updatePageMargin,
  } = useContext(EditorContext);
  useEffect(() => {
    if (!isResize) return;

    let calculatedHeight = 0;
    if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && blockContainer) {
      let curBlock = blockNodes?.findIndex(blockNode => blockContainer?.current?.id === blockNode?.blockId);
      let items = [...blockNodes];
      let item = { ...items[curBlock] };
      item.style = { ...item.style, height: `${height}px` };
      items[curBlock] = item;
      updateBlockNodes(items);
    }

    blockNodes?.map(blockInfo => {
      if (blockContainer?.current?.id === blockInfo?.blockId) calculatedHeight += parseFloat(height);
      else calculatedHeight += parseFloat(blockInfo.style.height);
    });

    calculatedHeight = calculatedHeight !== 0 ? calculatedHeight : height;

    //  set width & height to editor config data
    updateDimension({ ...dimension, size: "custom", label: "Custom", height: parseInt(calculatedHeight) });
    updateMetadata({ ...metadata, disableAutoScroll: true });

    // update page margin on block resize
    if (document.querySelector(".dhp-page-margin-overlay")) {
      let marginvalue = pageMargin.value ? pageMargin.value : widgetConfig.page_margin.defaultSize;
      updatePageMargin({
        ...pageMargin,
        style: {
          ...pageMargin.style,
          height: `${calculatedHeight - marginvalue * 2}px`,
        },
      });
    }
  }, [height, isResize]);
};

export default useSetBlockHeight;
