import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import { EditorContext } from "./EditorLayout";
import {
  getClassListToString,
  getRandomString,
  generateTableObj,
  getCssTextObjToString,
  getCssTransformObj,
  getCssTransformObjToString,
  getZoomedValue,
  getUpdatedGroupInnerWidgetPos,
  RGBToHex,
  toggleZoomStyle,
  isOnlyTextInGroup,
  getDimentionInCurrentUOM,
  calculateNewPositionOnRotatedObjectResize,
  checkChildOutsideParent,
} from "../../_helpers/utils";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import useBlockActiveOnscroll from "../../hooks/useBlockActiveOnscroll";
import useHandelKeyBoardEvent from "../../hooks/useHandelKeyBoardEvent";
import useCopyWidget from "../../hooks/useCopyWidget";
import usePasteWidget from "../../hooks/usePasteWidget";
import useCutWidget from "../../hooks/useCutWidget";
import useCloneWidget from "../../hooks/useCloneWidget";
import useSetBlockHeight from "../../hooks/useSetBlockHeight";
import { Icon } from "../../components/ui/icon";
import {
  TEXT,
  TEXT_FRAME,
  TYPE_INFOGRAPHIC,
  TABLE,
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
  TYPE_BROCHURE,
  TYPE_YOUTUBE_BANNER,
  GROUP_WIDGET,
  GENERIC_EDITOR_WRAP_PADDING,
  STOCK,
  PICTURE,
  UPLOAD,
  VIDEO,
  TYPE_FORM,
  JOT_FORM,
  MAP,
  WHITE_COLOR,
  BLACK_COLOR,
  COLLAGE,
  BRAND,
  UPLOAD_VIDEO,
  TYPE_PROPOSAL,
} from "../../constants/editor";
import TableModalComponent from "../../components/Editor/Widgets/TableV2";
import Modal from "../../components/ui/modal";
import useSortableList from "../../hooks/useSortableList";
import WidgetHandler from "../../components/Editor/WidgetHandler";
import useSelectable from "../../hooks/useSelectable";
import useDraggable from "../../hooks/useDraggable";
import useTextFocusOut from "../../hooks/useTextFocusOut";
import useCopyStyleWidget from "../../hooks/useCopyStyleWidget";
import useSnaptoGrid from "../../hooks/useSnaptoGrid";
import SnapGrid from "../../components/Editor/Widgets/SnapGrid";
import PageTopOption from "./PageTopOption";
import PageGuideLine from "../../components/Editor/PageBlocks/PageGuideLine";
import ActivePageSelector from "../../components/Editor/ActivePageSelector";
import useClipboard from "../../hooks/useClipboard";
import useUploadInsertOutsideImage from "../../hooks/useUploadInsertOutsideImage";
import CollaborativeSelector from "../../components/Editor/CollaborativeSelector";
import useWidgetHighlighter from "../../hooks/useWidgetHighlighter";
import useReplaceDuplicateShapeId from "../../hooks/useReplaceDuplicateShapeId";
import useFontFormatting from "../../hooks/useFontFormatting";
import { AppContext } from "../../contexts";
import Map from "../../components/Editor/Widgets/Map";
import useCollage from "../../hooks/useCollage";
import useAddWidgetOnDocumentCreate from "../../hooks/useAddWidgetOnDocumentCreate";
import useUploadInsertOutsideVideo from "../../hooks/useUploadInsertOutsideVideo";
import PageBottomOption from "./PageBottomOption";
import useDocumentPlay from "../../hooks/useDocumentPlay";
import useAudioPlay from "../../hooks/useAudioPlay";
import usePagePlay from "../../hooks/usePagePlay";

let style = Object.assign({}, global);

const BlockBackgroundColor = props => {
  return <div className="dhp-page-bgcolor-overlay" style={props.backgroundColor.style}></div>;
};
//Props type validation
BlockBackgroundColor.propTypes = {
  backgroundColor: PropTypes.object,
};

const BlockBackgroundImage = props => {
  return <div className="dhp-page-overlay" style={props.backgroundImage.style}></div>;
};
//Props type validation
BlockBackgroundImage.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
};

const Widget = props => {
  let {
    copyStyle,
    rightContextMenu,
    updateRightContextMenu,
    metadata,
    dimension,
    updateDoubleClickActive,
    snapGrid,
    documentType,
    updateTogglePlayButton,
    mapTooltip,
    updateEditorMapTooltip,
    isTimeLineViewOpen,
    setActiveWidgetFromTimeLine,
  } = useContext(EditorContext);

  let widgetClassList = getClassListToString(props.widget.classLists);
  const safariBugfixCss = props.isSafari ? { willChange: "filter" } : {};
  const iframePageSorterBugfixCss = [TYPE_FORM, JOT_FORM].includes(props.widget.data["data-asset-type"])
    ? { pointerEvents: "none" }
    : {};

  const widgetRef = useRef(null);

  const [activeWidgetInfo, setAactiveWidgetInfo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalFullScreen, setModalFullScreen] = useState(false);
  const [tableConfig, setTableConfig] = useState();
  const [sorterInnerHtml, setSorterInnerHtml] = useState();
  const [showMapModal, setShowMapModal] = useState(false);
  const [mapConfig, setMapConfig] = useState();

  const { start: initSelectable } = useSelectable();
  const { start: initDraggable } = useDraggable();
  const { show: show_WHT, hide: hide_WHT } = useWidgetHighlighter();
  const setCopyStyleWidget = useCopyStyleWidget();
  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();
  useSetActivePageBlock(activeWidgetInfo); // For set active page block widget by Hook
  useSnaptoGrid();
  const { fetchCollageEvent, activateCollageEvent, collageItemDrag: initCollageItemDrag } = useCollage();

  // show right context menu on right click on Widget
  const showRightContextMenu = e => {
    e.preventDefault();

    if (widgetRef.current.dataset.layerLocked === "true" || localStorage.getItem("widget.event.isActive") === "true")
      return;

    let isWidgetGroupSelected = e.target.closest(".group-selected");

    // do not set any active widget is multiple widget is selected
    if (!isWidgetGroupSelected) {
      const isGroupWidget = e.target.closest(".dhp-page-group");
      const isClickedOnChildOfAGroupWidget = isGroupWidget && e.target.closest(".dhp-page-widget");
      setActiveWidget(isClickedOnChildOfAGroupWidget);
    }

    updateRightContextMenu({
      ...rightContextMenu,
      enable: true,
      top: e.clientY,
      left: e.clientX,
      style: { ...rightContextMenu.style, top: "0px", left: "0px", visibility: "hidden" },
    });
  };

  const setActiveWidget = isClickedOnChildOfAGroupWidget => {
    if (
      copyStyle.enable &&
      !isClickedOnChildOfAGroupWidget &&
      document.getElementById(widgetRef.current.id).dataset.assetType !== GROUP_WIDGET
    )
      setCopyStyleWidget(widgetRef); // prevent apply copy style in a group widget or any widget under the group

    setAactiveWidgetInfo({
      targetPageIdx: document.getElementById(widgetRef.current.id).closest(".dhp-page-canvas").dataset.pageIdx,
      targetBlockIdx: document.getElementById(widgetRef.current.id).closest(".dhp-page-block").dataset.blockIdx,
      activeWidgetId: [isClickedOnChildOfAGroupWidget?.id ?? widgetRef.current.id],
      activeWidgetType: [
        isClickedOnChildOfAGroupWidget?.dataset.assetType ??
          document.getElementById(widgetRef.current.id).dataset.assetType,
      ],
      disableAutoScroll: !props.pageSorterList ? true : false,
    });
  };

  const handleDoubleClick = e => {
    e.preventDefault();
    let isWidgetGroupSelected = e.target.closest(".group-selected");
    let doubleClickActive =
      widgetRef.current.dataset.assetType === GROUP_WIDGET
        ? isOnlyTextInGroup(metadata.activeWidgetType[0], metadata.activeWidgetId[0])
        : true;

    // return if widget is locked or multiple widget is selcted or the selcted text or text frame is in a group widget which contain multiple type asste type or selected text frame is new text frame ie: version 3
    if (
      widgetRef.current.dataset.layerLocked === "true" ||
      isWidgetGroupSelected ||
      !doubleClickActive ||
      (metadata.activeWidgetType[0] === TEXT_FRAME &&
        parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) >= 3)
    )
      return;

    // handle Double click on text or text-frame widget
    if (
      [TEXT, TEXT_FRAME].includes(widgetRef.current.dataset.assetType) ||
      [TEXT, TEXT_FRAME].includes(metadata.activeWidgetType[0])
    ) {
      const { top: editorOuterWrapTop } = document.querySelectorAll(".dhp-page-canvas")[0].getBoundingClientRect();
      const { top: activeBlockTop } = document.getElementById(metadata.activeBlockId).getBoundingClientRect();
      const {
        translate: { x: transX, y: transY },
      } = getCssTransformObj({
        transform: widgetRef.current.style.transform,
      });

      const widgetTransformObj = getCssTransformObj({
        translateX: `${getZoomedValue(transX, dimension.zoom)}px`,
        translateY: `${getZoomedValue(transY, dimension.zoom)}px`,
        transform: widgetRef.current.style.transform,
      });

      const widgetTransformStr = getCssTransformObjToString({ transformObj: widgetTransformObj });
      let addedLeft = getZoomedValue(WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
      let addedTop = getZoomedValue(WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
      let newClassLists = ["dhp-content-editable-true-text", "draggable-disabled"];

      // Set new style property for outside text/text-frame widget
      let styleProperty = {
        ...widgetRef.current.style,
        position: "absolute",
        // opacity: widgetRef.current.style.opacity,
        width: `${getZoomedValue(widgetRef.current.style.width, dimension.zoom)}px`,
        height: `${getZoomedValue(widgetRef.current.style.height, dimension.zoom)}px`,
        transform: widgetTransformStr,
        left: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? `${addedLeft}px`
          : isTimeLineViewOpen
          ? "0px"
          : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
        top: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? `${Math.abs(activeBlockTop - editorOuterWrapTop) + addedTop}px`
          : isTimeLineViewOpen
          ? "0px"
          : `${
              Math.abs(activeBlockTop - editorOuterWrapTop) + (WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING)
            }px`,
      };

      // remove previous active class and set active class on current target in text frame
      if (widgetRef.current.dataset.assetType === TEXT_FRAME || metadata.activeWidgetType[0] === TEXT_FRAME) {
        document.querySelectorAll(`#${widgetRef.current.id} .active-text`).forEach(function (node) {
          node.classList.remove("active-text");
        });

        if (e.target.nodeName === "DIV") {
          if (e.target.querySelector(".title")) e.target.querySelector(".title").classList.add("active-text");
          else e.target.closest(".title").classList.add("active-text");
        } else e.target.closest("svg").querySelector(".textwrap div .title").classList.add("active-text");
      }

      // if font weight, style and decoration is applied previously in whole widget update the structure to child divs also like new structure(text version 3)
      if (widgetRef.current.dataset.assetType === TEXT && widgetRef.current.dataset.version < 3.0) {
        let target = widgetRef.current;
        let targetinner = target.querySelector(".primary-text-widget");

        if (!targetinner.firstChild.tagName) {
          let updatedInnerHtml = document.createElement("div");
          updatedInnerHtml.innerHTML = targetinner.innerHTML;
          targetinner.innerHTML = updatedInnerHtml.outerHTML;
        }

        if (
          parseInt(targetinner.style.fontWeight) === 700 ||
          targetinner.style.fontStyle === "italic" ||
          (targetinner.style.textDecoration && targetinner.style.textDecoration === "underline")
        ) {
          targetinner.querySelectorAll("*").forEach(element => {
            if (parseInt(targetinner.style.fontWeight) === 700) element.style.fontWeight = 700;
            if (targetinner.style.fontStyle === "italic") element.style.fontStyle = "italic";
            if (targetinner.style.textDecoration && targetinner.style.textDecoration === "underline")
              element.style.textDecoration = "underline";
          });
        }
      }

      // remove hyperlink class if widget has hyperlinked
      widgetRef.current.classList.forEach(className => {
        if (className !== "dhp-widget-hyperlinked") newClassLists.unshift(className);
      });

      props.setOutsideDoubleclickedObj({
        classLists: newClassLists,
        style: styleProperty,
        dataWidgetId: widgetRef.current.id,
        dataAssetType: widgetRef.current.dataset.assetType,
        innerHTML: widgetRef.current.innerHTML,
      });

      updateDoubleClickActive(true);
    }

    // handle Double click on table widget (restrict double click, if selected table is in a group widget)
    if (
      TABLE === widgetRef.current.dataset.assetType &&
      !document.getElementById(widgetRef.current.id).closest(".dhp-page-group")
    ) {
      setTableConfig(generateTableObj(widgetRef.current.id));
      toggleModal();
    }

    // handle Double click on Map widget (restrict double click, if selected table is in a group widget)
    if (
      MAP === widgetRef.current.dataset.assetType &&
      !document.getElementById(widgetRef.current.id).closest(".dhp-page-group")
    ) {
      setMapConfig(document.getElementById(widgetRef.current.id));
      toggleMapModal();
    }

    // handle Double click on collage widget
    if (COLLAGE === widgetRef.current.dataset.assetType) {
      activateCollageEvent({ e, widgetNode: widgetRef.current });
    }
  };

  const checkClick = e => {
    if (widgetRef.current.dataset.assetType === TABLE || widgetRef.current.dataset.assetType === MAP) return;

    // more than double click edit text ot text frame
    if (e.detail >= 2) {
      handleDoubleClick(e);
    }
  };

  // Toggle modal func
  const toggleModal = () => {
    setShowModal(prevState => !prevState);
  };

  // Toggle Map modal func
  const toggleMapModal = () => {
    setShowMapModal(prevState => !prevState);
  };

  const hoverIn = e => {
    if (copyStyle.enable) e.target.classList.add("cursor-copy-style");
    show_WHT({ hoverWidgetId: e.target.closest(".dhp-root-widget").id });
  };

  const hoverOut = e => {
    e.target.classList.remove("cursor-copy-style");
    hide_WHT();
    updateEditorMapTooltip({
      ...mapTooltip,
      enable: false,
      content: {},
    });
  };

  const mouseDownSubscribers = e => {
    // set previous active widget (to refer the scroll pos on active widget in page timeline section)
    if (isTimeLineViewOpen === "page-timeline") setActiveWidgetFromTimeLine(false);

    hide_WHT();
    const isLeftClick = e.button === 0;
    const isShiftKeyPressed = e.shiftKey;
    const isWidgetGroupSelected = e.target.closest(".group-selected");
    const { isActive: isCollageEventActive, widgetId: collageWidgetId } = fetchCollageEvent();
    const isClickedOnActiveCollageItem =
      isCollageEventActive && e.target.closest(`#${collageWidgetId} .collage-item.active`);

    // NOTE:::: Only when left mouse button is pressed i.e. MouseEvent.button = 0 AND shift key is not pressed when clicked
    if (isLeftClick && !isShiftKeyPressed) {
      if (isWidgetGroupSelected) {
        initDraggable({ widgetId: props.widget.id }, e);
      } else if (isClickedOnActiveCollageItem) {
        initCollageItemDrag({ widgetId: collageWidgetId, domEvent: e });
      } else {
        const isGroupWidget = e.target.closest(".dhp-page-group");
        const isClickedOnChildOfAGroupWidget = isGroupWidget && e.target.closest(".dhp-page-widget");
        const activeWId = isClickedOnChildOfAGroupWidget?.id ?? props.widget.id;

        setActiveWidget(isClickedOnChildOfAGroupWidget);
        initSelectable({ activeWidgetId: [activeWId], finalUpdate: true });
        if (!copyStyle.enable) initDraggable({ widgetId: props.widget.id }, e);

        // pause video on change active widget and select
        if (
          metadata.activeWidgetId[0] !== activeWId &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(document.getElementById(activeWId).getAttribute("data-source"))
        ) {
          updateTogglePlayButton(false);
        }
      }
    }
  };

  const handleMouseMove = e => {
    if (
      e.target.closest(".dhp-page-widget")?.getAttribute("data-asset-type") === MAP &&
      e.target.closest("svg")?.getAttribute("data-caption-on") === "true" &&
      e.target.tagName === "path"
    ) {
      let title = e.target.getAttribute("title");
      let dataCaptionType = e.target.closest("svg").getAttribute("data-caption-type");
      let background = dataCaptionType === "Light" ? WHITE_COLOR : BLACK_COLOR;
      let color = dataCaptionType === "Light" ? BLACK_COLOR : WHITE_COLOR;
      const x = e.clientX;
      const y = e.clientY;
      let tableCaptionObj;
      let targetPath = e.target.closest("path");
      const dataVersion = parseInt(e.target.closest(".dhp-page-widget")?.getAttribute("data-version"));

      if (targetPath) {
        const attributes = targetPath.attributes;
        for (let i = 0; i < attributes.length; i++) {
          const attributeName = attributes[i].name;
          let attributeValue = attributes[i].value;

          // version-specific logic
          if (dataVersion >= 4 && attributeName.startsWith("data-")) {
            attributeValue = JSON.parse(attributeValue);
            tableCaptionObj = {
              ...tableCaptionObj,
              [`${attributeValue.name}`]: attributeValue.value ? attributeValue.value : 0,
            };
          } else if (attributeName.startsWith("data-")) {
            let formattedKey = attributeName.replace(/^data-/, ""); // Removed "data-"
            // Capitalize first character
            formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
            tableCaptionObj = { ...tableCaptionObj, [formattedKey]: attributeValue ? attributeValue : 0 };
          }
        }
      }

      updateEditorMapTooltip({
        ...mapTooltip,
        enable: true,
        style: {
          ...mapTooltip.style,
          left: x + 10,
          top: y + 10,
          background: background,
          color: color,
        },
        content: {
          ...mapTooltip.content,
          areaTitle: title,
          tableCaptionObj: tableCaptionObj,
        },
      });
    } else {
      updateEditorMapTooltip({
        ...mapTooltip,
        enable: false,
        content: {},
      });
    }
  };

  useLayoutEffect(() => {
    if (props.pageSorterList) {
      let targetDOM = document.createElement("div");
      targetDOM.innerHTML = props.widget.innerHTML;

      targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
        element.removeAttribute("id");
        element.classList.remove("child-selected");
      });

      replaceDuplicateIdFromSVG(); // Replace duplicate id for shape use in shape border

      setSorterInnerHtml(targetDOM.innerHTML);
    }
  }, [props.pageSorterList]);

  useEffect(() => {
    let targetDOM = document.createElement("div");
    targetDOM.innerHTML = props.widget.innerHTML;
    targetDOM.querySelectorAll(".dhp-widget-inner").forEach(element => {
      let widgetDataSet = props.widget.data;
      if ([PICTURE, UPLOAD, VIDEO].includes(widgetDataSet["data-asset-type"]) && props.isSafari)
        element.style.willChange = "transform";

      if ([TEXT].includes(widgetDataSet["data-asset-type"]) && element.style.backgroundColor !== "transparent")
        element.classList.add("text-background-active");
    });
    props.widget.innerHTML = targetDOM.innerHTML;
  }, []);

  return (
    <>
      {props.pageSorterList && (
        <div
          style={{ ...props.widget.style, ...safariBugfixCss, ...iframePageSorterBugfixCss }}
          {...props.widget.data}
          {...(props.widget.classLists.includes("dhp-widget-hyperlinked") && {
            className: "nav-widget dhp-widget-hyperlinked",
          })}
          {...(!props.widget.classLists.includes("dhp-widget-hyperlinked") && { className: "nav-widget" })}
          dangerouslySetInnerHTML={{ __html: sorterInnerHtml }}></div>
      )}
      {!props.pageSorterList && (
        <>
          {/* widgets Grid Lines for snap to grid */}
          {snapGrid?.widgetGrid?.map(
            (grid, idx) => grid.widgetId == props.widget.id && <SnapGrid key={idx} grid={grid} component={"widget"} />
          )}

          <div
            ref={widgetRef}
            className={widgetClassList}
            id={props.widget.id}
            style={{ ...props.widget.style, ...safariBugfixCss }}
            onClick={e => checkClick(e)}
            onDoubleClick={e => handleDoubleClick(e)}
            onMouseDown={e => mouseDownSubscribers(e)}
            onMouseEnter={e => hoverIn(e)}
            onMouseLeave={e => hoverOut(e)}
            onContextMenu={e => showRightContextMenu(e)}
            onDragStart={e => e.preventDefault()}
            onMouseMove={e => handleMouseMove(e)}
            {...props.widget.data}
            dangerouslySetInnerHTML={{ __html: props.widget.innerHTML }}></div>
        </>
      )}
      {showModal && (
        <Modal
          size="xl"
          toggle={toggleModal}
          showModal={showModal}
          component={TableModalComponent}
          setShowModal={setShowModal}
          closeModal={toggleModal}
          backdrop="static"
          modalClassName={
            modalFullScreen ? "tabel-modal modal-with-leftpane full-screen-modal" : "tabel-modal modal-with-leftpane"
          }
          modalFullScreen={modalFullScreen}
          setModalFullScreen={setModalFullScreen}
          editTable={tableConfig}
        />
      )}

      {showMapModal && (
        <Modal
          size="xl"
          toggle={toggleMapModal}
          showModal={showMapModal}
          component={Map}
          setShowModal={setShowMapModal}
          closeModal={toggleMapModal}
          backdrop="static"
          modalClassName={
            modalFullScreen
              ? "tabel-modal modal-with-leftpane full-screen-modal map-modal"
              : "tabel-modal modal-with-leftpane map-modal"
          }
          modalFullScreen={modalFullScreen}
          setModalFullScreen={setModalFullScreen}
          editable={mapConfig}
        />
      )}
    </>
  );
};

//Props type validation
Widget.propTypes = {
  widget: PropTypes.object,
  pageSorterList: PropTypes.bool,
  scaleFactor: PropTypes.number,
  setOutsideDoubleclickedObj: PropTypes.func,
  isSafari: PropTypes.bool,
};

const Block = props => {
  let {
    documentType,
    backgroundColors,
    backgroundImages,
    widgets,
    rightContextMenu,
    updateRightContextMenu,
    dimension,
    updateScrollEvent,
  } = useContext(EditorContext);

  const blockContainer = useRef(null);
  const refBottomResize = useRef(null);
  useAddWidgetOnDocumentCreate({ blockNode: blockContainer.current });
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // const safariBugfixCss = isSafari ? { overflow: "", overflowY: "hidden" } : {};
  const safariBugfixCss = {};

  // local states
  const [activeContainerInfo, setActiveContainerInfo] = useState();
  const [blockHeight, setBlockHeight] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipValue, setTooltipValue] = useState();
  const [isResize, setIsResize] = useState();
  const [isClipBoardExist, setIsClipBoardExist] = useState(false);

  // global states
  const companyInfo = useSelector(state => state?.auth?.company);

  // used custom hooks
  useSetActivePageBlock(activeContainerInfo); // For set active page block by Hook
  useSetBlockHeight(isResize, blockContainer, blockHeight); // Set block height on resize block
  const { read: readFromClipboard } = useClipboard();
  const { uploadOutsideImage } = useUploadInsertOutsideImage();
  const { uploadOutsideVideo } = useUploadInsertOutsideVideo();

  // Set Active container info to update hook -> useSetActivePageBlock
  const setActivePage = e => {
    let elemnt = e.current ?? e.target;
    if (
      elemnt.classList.contains("dhp-page-block") ||
      elemnt.classList.contains("nav-block") ||
      elemnt.closest(".nav-block") ||
      elemnt.classList.contains("dhp-page-overlay") ||
      elemnt.classList.contains("dhp-page-bgcolor-overlay")
    ) {
      // Note : keep this commented code
      // if (activeBlockId === blockContainer.current.id && !activeOutSideCanvasArea && !activeWidgetId) return;

      // To prevent auto scroll
      updateScrollEvent(true);

      setActiveContainerInfo({
        // targetPageIdx: blockContainer.current.parentNode.dataset.pageIdx,
        targetPageIdx:
          blockContainer.current.closest(".dhp-page-canvas")?.dataset?.pageIdx ??
          blockContainer.current.parentNode.dataset.pageIdx,
        targetBlockIdx: blockContainer.current.dataset.blockIdx,
        disableAutoScroll: !props.pageSorterList ? true : false,
      });
    }

    //Page sorter add class to show page list controller, on active page click, add time out to stop transition when controller move to active page
    setTimeout(() => {
      props.pageSorterList &&
        document.querySelector(".page-sorter-controller").classList.add("show-page-list-controller");
    }, 300);
  };

  // check clipboard data is available or not
  const checkClipBoardData = async () => {
    const { dataSource, dataType, data, isValidWidget } = await readFromClipboard();

    setIsClipBoardExist(
      isValidWidget &&
        ((dataSource === "dhp" && data?.companyId === companyInfo?._id) ||
          (dataSource === "non_dhp" && dataType === "image/png"))
    );
  };

  // show right context menu on right click on block
  const showRightContextMenu = e => {
    checkClipBoardData();
    let rightClickOnBlock = false;
    e.preventDefault();
    setActivePage(e);

    if (
      e.target.classList.contains("dhp-page-block") ||
      e.target.classList.contains("nav-block") ||
      e.target.closest(".nav-block") ||
      e.target.classList.contains("dhp-page-overlay") ||
      e.target.classList.contains("dhp-page-bgcolor-overlay")
    )
      rightClickOnBlock = true;

    if (props.pageSorterList || !isClipBoardExist || !rightClickOnBlock) return;

    updateRightContextMenu({
      ...rightContextMenu,
      enable: true,
      top: e.clientY,
      left: e.clientX,
      style: { ...rightContextMenu.style, top: "0px", left: "0px", visibility: "hidden" },
    });
  };

  const overrideDefault = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const fileHover = () => document.getElementById(props.blockNode.blockId).classList.add("fileHover");

  const fileHoverEnd = () => document.getElementById(props.blockNode.blockId).classList.remove("fileHover");

  // Drag and drop outside file to upload in canvas
  const addFiles = e => {
    let droppedFiles = e.target.files || e.dataTransfer.files;

    let allowedImageTypes = ["jpeg", "jpg", "png", "svg+xml", "gif"];
    let allowedVideoTypes = ["mp4", "mov", "webm", "quicktime"];
    const maxImazeSizeInBytes = 5 * 1024 * 1024;
    const maxVideoSizeInBytes = 100 * 1024 * 1024;

    if (droppedFiles[0]) {
      const file = droppedFiles[0];
      const fileType = file.type.split("/").pop(); // Get file extension

      // Check if the file type is allowed and not exceeded limit
      if (allowedImageTypes.includes(fileType.toLowerCase()) && file.size <= maxImazeSizeInBytes) {
        uploadOutsideImage(file);
      } else if (allowedVideoTypes.includes(fileType.toLowerCase()) && file.size <= maxVideoSizeInBytes) {
        uploadOutsideVideo(file);
      } else {
        return; // Exit function if not allowed
      }
    }
  };

  useEffect(() => {
    if ( [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
      const resizableElem = blockContainer.current;
      let height = 0;
      let y = 0;

      const onMouseMoveBottomResize = event => {
        event.preventDefault();
        props.setIsResizing(true);
        if (!tooltipOpen) setTooltipOpen(true); // Enable tooltip on drag

        const dy = event.clientY - y;
        if (height + dy > 79) {
          height = height + dy;
          y = event.clientY;
          setTooltipValue(
            `${getDimentionInCurrentUOM(height, dimension?.displayUnit)} ${dimension?.displayUnit ?? dimension?.unit}`
          );
          resizableElem.style.height = `${height}px`;
          //setting editor-outer-wrap height on resize
          let blockNodes = document.querySelectorAll(".dhp-page-block");
          let totalPageHeight = 0;
          blockNodes?.forEach(blockInfo => {
            totalPageHeight += parseFloat(blockInfo.style.height);
          });

          document.querySelector(".editor-outer-wrap").style.height = `${
            getZoomedValue(parseInt(totalPageHeight), dimension.zoom) + 80
          }px`;
          let pageGrid = document.querySelector(".dhp-page-grid");
          if (pageGrid) {
            pageGrid.style.height = `${totalPageHeight}px`;
            pageGrid.getElementsByTagName("svg")[0].setAttribute("height", `${totalPageHeight}px`);
          }

          // increase or decrease active outline in block
          if (document.getElementById("active-page-selctor")) {
            document.getElementById("active-page-selctor").style.height = `${getZoomedValue(height, dimension.zoom)}px`;
          }

          // increase or decrease page margin in block into DOM
          if (document.querySelector(".dhp-page-margin-overlay")) {
            let marginvalue = parseInt(document.querySelector(".dhp-page-margin-overlay").style.top);
            document.querySelector(".dhp-page-margin-overlay").style.height = `${totalPageHeight - marginvalue * 2}px`;
          }
        }
      };

      const onMouseUpBottomResize = () => {
        setBlockHeight(height);
        setTooltipOpen(false);
        props.setIsResizing(false);
        if (!isResize) setIsResize(true);
        document.removeEventListener("mousemove", onMouseMoveBottomResize);
        document.removeEventListener("mouseup", onMouseUpBottomResize);
      };

      const onMouseDownResize = event => {
        setActivePage(blockContainer);
        event.preventDefault();
        y = event.clientY;
        const styles = window.getComputedStyle(resizableElem);
        height = parseInt(styles.height);
        document.addEventListener("mousemove", onMouseMoveBottomResize);
        document.addEventListener("mouseup", onMouseUpBottomResize);
      };

      const resizerBottom = refBottomResize.current;
      resizerBottom.addEventListener("mousedown", onMouseDownResize);

      return () => {
        resizerBottom.removeEventListener("mousedown", onMouseDownResize);
        document.removeEventListener("mouseup", onMouseUpBottomResize);
      };
    }
  }, [dimension]);

  return (
    <div
      ref={blockContainer}
      onMouseDown={e => setActivePage(e)}
      onContextMenu={e => showRightContextMenu(e)}
      onDragOver={e => {
        overrideDefault(e), fileHover();
      }}
      onDragEnter={e => {
        overrideDefault(e), fileHover();
      }}
      onDragLeave={e => {
        overrideDefault(e), fileHoverEnd();
      }}
      onDrop={e => {
        overrideDefault(e), fileHoverEnd(), addFiles(e);
      }}
      className={cx(!props.pageSorterList ? [style["dhp-page-block"]] : "nav-block")}
      id={!props.pageSorterList ? props.blockNode.blockId : `dhp-nav-block-${props.blockNode.blockIdx}`}
      data-block-idx={props.blockNode.blockIdx}
      style={
        !props.pageSorterList
          ? {
              ...props.blockNode.style,
              ...safariBugfixCss,
              transform: `scale(${
                [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) ? 1 : dimension.zoom / 100
              })`,
            }
          : {
              ...props.blockNode.style,
              transform: `scale(${props.scaleFactor})`,
            }
      }>
      {backgroundColors.map(
        backgroundColor =>
          backgroundColor.blockId == props.blockNode.blockId && (
            <BlockBackgroundColor key={backgroundColor.blockId} backgroundColor={backgroundColor} />
          )
      )}

      {backgroundImages.map(
        backgroundImage =>
          backgroundImage.blockId == props.blockNode.blockId && (
            <BlockBackgroundImage key={backgroundImage.blockId} backgroundImage={backgroundImage} />
          )
      )}

      {widgets.map(
        widget =>
          widget.blockId == props.blockNode.blockId && (
            <Widget
              key={widget.id}
              widget={widget}
              pageSorterList={props.pageSorterList}
              scaleFactor={1}
              setOutsideDoubleclickedObj={props.setOutsideDoubleclickedObj}
              isSafari={isSafari}
            />
          )
      )}

      { [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
        <div
          className={cx(style["block-resize-handler"], style["d-flex"], style["justify-content-center"], {
            [style["active"]]: tooltipOpen,
          })}>
          <div
            draggable={false}
            onDragStart={e => e.preventDefault()}
            ref={refBottomResize}
            className={cx(style["d-flex"], style["custom-tooltip"])}
            id={"Tooltip-" + props.blockNode.blockId}>
            <Icon icon="menu5" additionalclass={style["ui-resizable-s"]} />
            <div className={cx(style["custom-tooltip-content"], style["top"])}>{tooltipValue}</div>
          </div>
        </div>
      )}
    </div>
  );
};
//Props type validation
Block.propTypes = {
  blockNode: PropTypes.object.isRequired,
  pageSorterList: PropTypes.bool,
  scaleFactor: PropTypes.number,
  setIsResizing: PropTypes.func,
  setOutsideDoubleclickedObj: PropTypes.func,
};

const PageMarginGrid = props => {
  const { documentType, dimension, grid, pageMargin } = useContext(EditorContext);

  let gridHeight = "100%",
    strokeWidth = grid.strokeWidth,
    gridSize = grid.size,
    zoomFactor = dimension.zoom / 100;

  if ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)) {
    gridHeight = `${parseFloat(dimension.height)}px`;
  } else {
    strokeWidth = grid.strokeWidth * zoomFactor;
    gridSize = grid.size * zoomFactor;
  }

  return (
    <>
      {grid.enabled && (
        <div
          className={cx(style["dhp-page-grid"], style[grid.color])}
          style={{ height: gridHeight, willChange: "transform" }}>
          <svg width="100%" height={gridHeight} xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern
                id={`smallGrid-${props.unique}`}
                width={gridSize}
                height={gridSize}
                patternUnits="userSpaceOnUse">
                <path
                  d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`}
                  fill="none"
                  stroke="gray"
                  strokeWidth={strokeWidth}></path>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill={`url(#smallGrid-${props.unique})`}></rect>
          </svg>
        </div>
      )}

      {pageMargin.enabled && <div className={style["dhp-page-margin-overlay"]} style={pageMargin.style}></div>}
    </>
  );
};
//Props type validation
PageMarginGrid.propTypes = {
  unique: PropTypes.number,
};

const Page = props => {
  let { blockNodes, metadata, pageSorterList, dimension, snapGrid, documentType, isTimeLineViewOpen, pagePlayData } =
    useContext(EditorContext);
  let figureWidth = 144;
  let figureHeight = (figureWidth / dimension.width) * dimension.height;
  let zoomFactor = dimension.zoom / 100;

  const userInfo = useSelector(state => state?.auth?.user);

  const { start: initSortableList } = useSortableList();

  // Show controller on active page hover in pagelist section
  const pageListHover = e => {
    if (parseInt(e.target.closest(".pagelist-item").getAttribute("data-index")) === metadata.activePageIdx) {
      calculatePageListControllerTop(); // set controller top
      // add time out to stop transition when controller move to active page
      setTimeout(() => {
        document.querySelector(".page-sorter-controller").classList.add("show-page-list-controller");
      }, 300);
    } else {
      document.querySelector(".page-sorter-controller").classList.remove("show-page-list-controller");
    }
  };

  // set controller top
  const calculatePageListControllerTop = () => {
    let parentPos = document.getElementById("pageList").getBoundingClientRect(),
      childPos = document.getElementById(`fig-${metadata.activePageIdx}`).getBoundingClientRect();
    document.querySelector(".page-sorter-controller").style.top = `${childPos.top - parentPos.top + 4}px`;
  };

  return (
    <>
      {props.pageSorterList && (
        <li
          className={cx(
            { [style["active"]]: metadata.activePageIdx === parseInt(props.index) },
            style["pagelist-item"],
            style["sortable-item"]
          )}
          id={`fig-${props.index}`}
          data-index={props.index}
          data-canvas-id={props.pageNode.pageId}
          onMouseEnter={e => pageListHover(e)}
          onMouseDown={e => {
            calculatePageListControllerTop(),
              initSortableList(
                {
                  id: `fig-${props.index}`,
                  idx: props.index,
                  pageId: props.pageNode.pageId,
                  blockId: blockNodes[props.index].blockId,
                },
                e
              );
          }}>
          <div className="content">
            <figure
              className="m-0"
              style={{
                height: figureHeight,
                width: figureWidth,
              }}>
              {props.pageSorterList && (
                <div style={pageSorterList?.style} data-page-idx={props.pageNode.pageIdx}>
                  {blockNodes.map(
                    blockNode =>
                      blockNode.pageId == props.pageNode.pageId && (
                        <Block
                          key={blockNode.blockId}
                          blockNode={blockNode}
                          pageSorterList={props.pageSorterList}
                          scaleFactor={props.scaleFactor}
                        />
                      )
                  )}
                </div>
              )}
            </figure>
            <span>{parseInt(props.index) + 1}</span>
          </div>
          <div className={cx(style["placeholder"], style["d-none"])}>
            <figure
              style={{
                height: figureHeight,
                width: "144px",
              }}></figure>
          </div>
        </li>
      )}

      {!props.pageSorterList && (
        <>
          {/* canvas Grid Lines for snap to grid */}
          {[TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && metadata.activeWidgetId && (
            <>
              <SnapGrid component={"margin"} /> <SnapGrid component={"block-center"} />
            </>
          )}

          <div
            className={style["dhp-page-canvas"]}
            id={props.pageNode.pageId}
            data-page-idx={props.pageNode.pageIdx}
            style={{
              ...props.pageNode.style,
              width: `${
                [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                  ? props.pageNode.style.width
                  : parseFloat(dimension.width) * zoomFactor
              }px`,
              height: `${
                [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                  ? props.pageNode.style.height
                  : parseFloat(dimension.height) * zoomFactor
              }px`,
            }}>
            {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) &&
              !isTimeLineViewOpen &&
              userInfo.video_enabled && (
                <PageTopOption pageIndex={props.index} toggle={props.toggle} setFitToScreen={props.setFitToScreen} />
              )}

            <PageMarginGrid key={props.index} unique={props.index} />

            {((documentType?.toLowerCase() === TYPE_YOUTUBE_BANNER && dimension.size === "youtube-banner") ||
              (documentType?.toLowerCase() === TYPE_BROCHURE &&
                dimension.size === "a4" &&
                dimension.orientation === "landscape")) && <PageGuideLine />}

            {blockNodes.map(
              blockNode =>
                blockNode.pageId == props.pageNode.pageId && (
                  <React.Fragment key={blockNode.blockId}>
                    {/* canvas Grid Lines for snap to grid */}
                    {snapGrid?.blockGrid?.blockId === blockNode.blockId && (
                      <>
                        {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
                          <>
                            <SnapGrid component={"margin"} /> <SnapGrid component={"block-center"} />
                          </>
                        )}
                        <SnapGrid component={"block-middle"} />

                        {((documentType?.toLowerCase() === TYPE_YOUTUBE_BANNER &&
                          dimension.size === "youtube-banner") ||
                          (documentType?.toLowerCase() === TYPE_BROCHURE &&
                            dimension.size === "a4" &&
                            dimension.orientation === "landscape")) && <SnapGrid component={"guide-line"} />}
                      </>
                    )}

                    {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
                      <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                        <Block
                          key={blockNode.blockId}
                          blockNode={blockNode}
                          setIsResizing={props.setIsResizing}
                          setOutsideDoubleclickedObj={props.setOutsideDoubleclickedObj}
                        />
                      </div>
                    )}
                    {[TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) && (
                      <Block
                        key={blockNode.blockId}
                        blockNode={blockNode}
                        setIsResizing={props.setIsResizing}
                        setOutsideDoubleclickedObj={props.setOutsideDoubleclickedObj}
                      />
                    )}
                  </React.Fragment>
                )
            )}

            {![TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType) &&
              !isTimeLineViewOpen &&
              pagePlayData.pageProgress > 0 &&
              pagePlayData.pageProgress < 100 &&
              pagePlayData.pageId === props.pageNode.pageId && <PageBottomOption />}
          </div>
        </>
      )}
    </>
  );
};

//Props type validation
Page.propTypes = {
  pageNode: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  pageSorterList: PropTypes.bool,
  scaleFactor: PropTypes.number,
  setIsResizing: PropTypes.func,
  setOutsideDoubleclickedObj: PropTypes.func,
  toggle: PropTypes.func,
};

const EditorCanvas = ({ pageSorterList, scaleFactor, setFitToScreen, ...props }) => {
  const {
    pageNodes,
    metadata,
    ignoreNextScrollEvent,
    dimension,
    doubleClickActive,
    isDocumentReady,
    textSelectedAreaColor,
    setTextSelectedAreaColor,
    setSelectionRange,
    setDoubleClickSelctedareaColor,
    setPreviousSelection,
    updateDoubleClickActive,
    rightContextMenu,
    updateRightContextMenu,
    isTimeLineViewOpen,
    showFindAndReplace,
    audios,
    playingPageIdx,
    setWhichPagePlaying,
    widgets,
    updateWidgets,
    changeBySocket
  } = useContext(EditorContext);
  const { setPasteColorActive } = useContext(AppContext);

  const [isResizing, setIsResizing] = useState(false);
  const [outsideDoubleclickedObj, setOutsideDoubleclickedObj] = useState();
  // const [previousSelection, setPreviousSelection] = useState();

  // const setTextFocusOut = useTextFocusOut();
  const { selectedPartFontFormatting } = useFontFormatting();
  const { handleFocusOut } = useTextFocusOut();

  // Active block on page scroll
  useHandelKeyBoardEvent(setFitToScreen);
  useCopyWidget();
  useCloneWidget();
  usePasteWidget();
  useCutWidget();
  useBlockActiveOnscroll(metadata.activeBlockId, ignoreNextScrollEvent, isResizing);

  // Update Text widget Handler height when double click and edit text widget
  const updateWidgetHandlerHeight = e => {
    let id = metadata.activeWidgetId[0];
    let isGroupWidget = document.getElementById(id)?.closest(".dhp-page-group");
    let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;

    if (metadata.activeWidgetType[0] === TEXT) {
      let curSelection = document.querySelector(`[data-widget-id="${targetId}"].collborative-selector`);
      if (isGroupWidget) {
        let { groupDivHeight } = getUpdatedGroupInnerWidgetPos(targetId, id, e.target.offsetHeight, dimension.zoom);

        // update DOM and group handler
        document.querySelector(`.dhp-content-editable-true-text .child-selected`).style.outline = "0px solid #00a4ef";
        document.querySelector(`.dhp-content-editable-true-text .child-selected .dhp-widget-inner`).style.outline =
          "2px solid #00a4ef";
        document.getElementById("dhp-widget-handler").style.height = `${groupDivHeight}px`;
        if (curSelection) curSelection.style.height = `${groupDivHeight}px`;
      } else {
        let containEditor = document.querySelector(".dhp-content-editable-true-text");
        // handle rotated text widget
        let handler = document.getElementById("dhp-widget-handler");
        const {
          translate: { x: handlerTransX, y: handlerTransY },
          rotate: { theta: handlerTheta },
        } = getCssTransformObj({
          transform: handler.style.transform,
        });
        const { left, top } = calculateNewPositionOnRotatedObjectResize(
          parseFloat(handlerTransX),
          parseFloat(handlerTransY),
          parseFloat(handler.style.width),
          parseFloat(e.target.offsetHeight),
          parseFloat(handler.style.width),
          parseFloat(handler.style.height),
          parseFloat(handlerTheta)
        );
        const handlerTransformStr = getCssTransformObj({
          translateX: `${left}px`,
          translateY: `${top}px`,
          transform: handler.style.transform,
          returnStr: true,
        });

        handler.style.transform = handlerTransformStr;
        handler.style.height = `${e.target.offsetHeight}px`;
        containEditor.style.transform = handlerTransformStr;
        containEditor.style.height = `${e.target.offsetHeight}px`;

        if (curSelection) curSelection.style.height = `${e.target.offsetHeight}px`;
      }
    }
  };

  // hold the previous selction range for safari
  const holdSelectionRange = () => {
    setPreviousSelection({
      start: window.getSelection().getRangeAt(0).startOffset,
      end: window.getSelection().getRangeAt(0).endOffset,
      startContainer: window.getSelection().getRangeAt(0).startContainer,
      endContainer: window.getSelection().getRangeAt(0).endContainer,
    });
  };

  const clickOutsideContentEditebleText = e => {
    let isClickOutside;
    let flyoutEl = document.querySelector(".dhp-content-editable-true-text"),
      targetEl = e.target.closest(".dhp-content-editable-true-text"); // clicked element

    if (targetEl == flyoutEl) {
      // This is a click inside, does nothing, just return.
      isClickOutside = false;
    } else {
      // This is a click outside.
      isClickOutside = true;
    }

    if (!isClickOutside) return;
    else {
      if (e.target.closest("#bold-wrap")) {
        setSelectionRange(false);
        selectedPartFontFormatting("bold");
        setTimeout(() => {
          setSelectionRange("bold");
        }, 10);
      } else if (e.target.closest("#italic-wrap")) {
        setSelectionRange(false);
        selectedPartFontFormatting("italic");
        setTimeout(() => {
          setSelectionRange("italic");
        }, 10);
      } else if (e.target.closest("#underline-wrap")) {
        setSelectionRange(false);
        selectedPartFontFormatting("underline");
        setTimeout(() => {
          setSelectionRange("underline");
        }, 10);
      } else if (
        e.target.closest(".color-wrap") &&
        !document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-grad-scolor")
      ) {
        // focus on input box of color section if click on input box
        if (e.target?.id === "Color-Input") {
          window.getSelection().removeAllRanges();
          e.target.focus();
          setPasteColorActive(false);
        }
      } else {
        handleFocusOut();
        setTextSelectedAreaColor(false);
        toggleZoomStyle({ type: "inject" });
      }
    }
  };

  const allEqual = arr => arr.every(val => val === arr[0]);

  //fetch color on double for firefox browser
  const fetchColorForFireFox = () => {
    let userAgent = navigator.userAgent;
    let isFirefox = userAgent.match(/firefox|fxios/i);

    if (!isFirefox) return;

    setDoubleClickSelctedareaColor();
    let sel, activeHtml, selectedPart, parentEl;
    let colorArray = [];

    sel = window.getSelection();

    // get parent elemet of selected part
    if (window.getSelection) {
      if (sel.rangeCount) {
        parentEl = sel.getRangeAt(0).commonAncestorContainer;
        if (parentEl.nodeType != 1) {
          parentEl = parentEl.parentNode;
        }
      }
    }

    // get html of selected part
    if (sel.rangeCount) {
      let container = document.createElement("div");
      for (let i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      activeHtml = container.innerHTML;
      selectedPart = document.createElement("span");
      selectedPart.innerHTML = activeHtml;
    }

    let parentColor = parentEl?.style?.color
      ? parentEl.style?.color
      : document.querySelector(`#${metadata.activeWidgetId[0]} .dhp-widget-inner`).style.color;
    let selColor = parentColor;

    selectedPart?.querySelectorAll("*").forEach(node => {
      if (node.style?.color) colorArray.push(node.style.color);
    });

    if (colorArray.length === 0) {
      selectedPart?.querySelectorAll("*").forEach(node => {
        if (node.style?.color && parentColor !== node.style?.color) {
          selColor = "Mixed Colors";
        }
      });
    } else if (allEqual(colorArray)) {
      selColor = colorArray[0];
    } else {
      selColor = "Mixed Colors";
    }

    setDoubleClickSelctedareaColor(selColor);
  };

  const handleScroll = () => {
    // Focusout triggerd if any text or text frame widget is contenteditable
    if (document.querySelector(".dhp-content-editable-true-text")) handleFocusOut();

    // remove right context menu if it is open
    if (rightContextMenu.enable) {
      updateRightContextMenu({
        ...rightContextMenu,
        enable: false,
      });
    }
  };

  // trigger whenever text selected part color is changed
  useEffect(() => {
    if (textSelectedAreaColor) {
      selectedPartFontFormatting("color");
    }
  }, [textSelectedAreaColor]);

  // trigger when doubleclick is activated
  useLayoutEffect(() => {
    if (doubleClickActive) {
      if (![TEXT, TEXT_FRAME].includes(outsideDoubleclickedObj.dataAssetType)) {
        updateDoubleClickActive(false);
        return;
      }

      let outsideElem = document.querySelector(".dhp-content-editable-true-text");

      // hide Main text/text-frame/Group widget
      let id = outsideDoubleclickedObj.dataWidgetId;
      let isGroupWidget = document.getElementById(id).closest(".dhp-page-group");
      let targetId = isGroupWidget ? document.getElementById(id).closest(".dhp-root-widget").getAttribute("id") : id;
      document.getElementById(targetId).style.visibility = "hidden";

      // add style on outside text/text-frame/Group widget main div on double click actiavte
      outsideElem.style.cssText = getCssTextObjToString(outsideDoubleclickedObj?.style);
      outsideElem.setAttribute("data-widget-id", outsideDoubleclickedObj.dataWidgetId);
      outsideElem.setAttribute("data-asset-type", outsideDoubleclickedObj.dataAssetType);
      outsideElem.innerHTML = outsideDoubleclickedObj.innerHTML;

      // adjust style on outside text widget inner div on double click actiavte
      if (metadata.activeWidgetType[0] === TEXT) {
        // For group text update
        if (isGroupWidget) {
          //Adjust width and font size for all text widgets in a group and focus child selected widget only
          outsideElem.querySelectorAll(".dhp-page-widget").forEach(element => {
            element.classList.remove("dhp-widget-hyperlinked"); // remove hyperlink class if widget has hyperlinked

            let targetElem = element.querySelector(".dhp-widget-inner");
            let targetElemIsChildSelcted = element.classList.contains("child-selected");
            let strokeValue = targetElem.style.webkitTextStroke;

            targetElem.style.width = `${getZoomedValue(targetElem.style.width, dimension.zoom)}px`;
            targetElem.style.fontSize = `${getZoomedValue(targetElem.style.fontSize, dimension.zoom)}px`;
            targetElem.style.letterSpacing = `${getZoomedValue(targetElem.style.letterSpacing, dimension.zoom)}px`;

            if (targetElem.style.borderWidth) {
              targetElem.style.borderWidth = `${getZoomedValue(targetElem.style.borderWidth, dimension.zoom)}px`;
              targetElem.style.borderRadius = `${getZoomedValue(targetElem.style.borderRadius, dimension.zoom)}px`;
            }
            if (strokeValue) {
              let strokeArray = strokeValue.split(" ");
              let rgbValue =
                strokeArray[1] === "transparent"
                  ? strokeArray[1]
                  : RGBToHex(`${strokeArray[1]} ${strokeArray[2]} ${strokeArray[3]}`);
              let zoomedStrokeValue = getZoomedValue(parseInt(strokeArray[0]), dimension.zoom);

              targetElem.style.webkitTextStroke = `${zoomedStrokeValue}px ${rgbValue}`;
            }

            targetElem.style.outline = "none";

            if (targetElemIsChildSelcted) {
              element.classList.add("focusout-child-selected");
              targetElem.style["-webkit-user-select"] = "text";
              targetElem.style["user-select"] = "text";
              targetElem.setAttribute("contenteditable", true);
              targetElem.focus();
            }
          });
        }
        // For single text update
        else {
          let targetElem = outsideElem.querySelector(".dhp-widget-inner");
          let filterValue = targetElem.style.filter;
          let strokeValue = targetElem.style.webkitTextStroke;

          targetElem.style.width = `${getZoomedValue(targetElem.style.width, dimension.zoom)}px`;
          targetElem.style.fontSize = `${getZoomedValue(targetElem.style.fontSize, dimension.zoom)}px`;
          targetElem.style.letterSpacing = `${getZoomedValue(targetElem.style.letterSpacing, dimension.zoom)}px`;

          if (targetElem.style.borderWidth) {
            targetElem.style.borderWidth = `${getZoomedValue(targetElem.style.borderWidth, dimension.zoom)}px`;
            targetElem.style.borderRadius = `${getZoomedValue(targetElem.style.borderRadius, dimension.zoom)}px`;
          }
          if (filterValue && filterValue !== "none") {
            let filterArray = filterValue.split("drop-shadow")[1].split(" ");
            let rgbValue = filterArray[0].split("(");
            rgbValue =
              rgbValue[1] === "transparent"
                ? rgbValue[1]
                : RGBToHex(`${rgbValue[1]}(${rgbValue[2]} ${filterArray[1]} ${filterArray[2]}`);

            let horaizentalShadow = `${getZoomedValue(
              parseInt(filterArray[rgbValue === "transparent" ? 1 : 3]),
              dimension.zoom
            )}px`;
            let verticalShadow = `${getZoomedValue(
              parseInt(filterArray[rgbValue === "transparent" ? 2 : 4]),
              dimension.zoom
            )}px`;
            let blur = `${getZoomedValue(parseInt(filterArray[rgbValue === "transparent" ? 3 : 5]), dimension.zoom)}px`;
            let shadowColor = rgbValue;
            targetElem.style.filter = `drop-shadow(${horaizentalShadow} ${verticalShadow} ${blur} ${shadowColor})`;
          }
          if (strokeValue) {
            let strokeArray = strokeValue.split(" ");
            let rgbValue =
              strokeArray[1] === "transparent"
                ? strokeArray[1]
                : RGBToHex(`${strokeArray[1]} ${strokeArray[2]} ${strokeArray[3]}`);
            let zoomedStrokeValue = getZoomedValue(parseInt(strokeArray[0]), dimension.zoom);

            targetElem.style.webkitTextStroke = `${zoomedStrokeValue}px ${rgbValue}`;
          }

          targetElem.style.outline = "none";
          targetElem.style["-webkit-user-select"] = "text";
          targetElem.style["user-select"] = "text";
          targetElem.setAttribute("contenteditable", true);
          targetElem.focus();

          //for safari shifiting issue
          var styleElem = document.head.appendChild(document.createElement("style"));
          styleElem.setAttribute("id", "contenteditableStyle");
          styleElem.innerHTML = "*, :after, :before {box-sizing: border-box;}";
        }
      }

      // adjust style on outside text-frame widget inner div on double click actiavte
      if (
        metadata.activeWidgetType[0] === TEXT_FRAME &&
        parseInt(document.getElementById(metadata.activeWidgetId[0]).getAttribute("data-version")) < 3
      ) {
        let targetElem = isGroupWidget
          ? outsideElem.querySelector(".child-selected .dhp-widget-inner .active-text")
          : outsideElem.querySelector(".dhp-widget-inner .active-text");
        let svgElem = isGroupWidget
          ? outsideElem.querySelector(".child-selected .dhp-widget-inner")
          : outsideElem.querySelector(".dhp-widget-inner");
        let filterValue = svgElem.style.filter;

        if (isGroupWidget) {
          outsideElem.querySelectorAll(".dhp-page-widget").forEach(element => {
            element.classList.remove("dhp-widget-hyperlinked"); // remove hyperlink class if widget has hyperlinked
          });
        }

        outsideElem.querySelector(".child-selected")?.classList.add("focusout-child-selected");

        if (filterValue && filterValue !== "none") {
          let filterArray = filterValue.split("drop-shadow")[1].split(" ");
          let rgbValue = filterArray[0].split("(");
          rgbValue =
            rgbValue[1] === "transparent"
              ? rgbValue[1]
              : RGBToHex(`${rgbValue[1]}(${rgbValue[2]} ${filterArray[1]} ${filterArray[2]}`);

          let horaizentalShadow = `${getZoomedValue(
            parseInt(filterArray[rgbValue === "transparent" ? 1 : 3]),
            dimension.zoom
          )}px`;
          let verticalShadow = `${getZoomedValue(
            parseInt(filterArray[rgbValue === "transparent" ? 2 : 4]),
            dimension.zoom
          )}px`;
          let blur = `${getZoomedValue(parseInt(filterArray[rgbValue === "transparent" ? 3 : 5]), dimension.zoom)}px`;
          let shadowColor = rgbValue;
          svgElem.style.filter = `drop-shadow(${horaizentalShadow} ${verticalShadow} ${blur} ${shadowColor})`;
        }

        targetElem.style.outline = "none";
        targetElem.style["-webkit-user-select"] = "text";
        targetElem.style["user-select"] = "text";
        targetElem.setAttribute("contenteditable", true);
        targetElem.focus();
      }
    }
  }, [doubleClickActive]);

  // hide all pages accept active page : when timeLine is open
  useEffect(() => {
    if (isTimeLineViewOpen) {
      setTimeout(() => {
        document.querySelectorAll(".dhp-page-canvas").forEach(element => {
          if (element.getAttribute("id") !== metadata.activePageId) {
            element.style.display = "none";
          } else element.style.display = "block";
        });
      }, 100);
    } else {
      document.querySelectorAll(".dhp-page-canvas").forEach(element => {
        element.style.display = "block";
      });
    }
  }, [metadata.activePageId, isTimeLineViewOpen, changeBySocket]);

  useEffect(() => {
    if (!isTimeLineViewOpen) {
      let OutsideWidgetCanvasArray = [];

      // fetch outside widget after widget or block change
      document.querySelectorAll(`#${metadata.activeBlockId} .dhp-root-widget`).forEach(element => {
        let isWidgetsOutsideCanvas = checkChildOutsideParent({
          parent: `#${metadata.activeBlockId}`,
          child: element,
        });
        if (
          isWidgetsOutsideCanvas &&
          element.style.display !== "none" &&
          (!metadata.activeWidgetId ||
            (metadata.activeWidgetId && !metadata.activeWidgetId.includes(element.getAttribute("id"))))
        )
          OutsideWidgetCanvasArray.push(element.getAttribute("id"));
      });

      // delete outside widget after activewidget got focused out (multi widget)
      if (
        OutsideWidgetCanvasArray.length > 0 &&
        (!metadata.activeWidgetId || metadata.activeWidgetId.length === 0 || metadata.activeWidgetId.length === 1)
      ) {
        let newWidget = widgets.filter(widget => !OutsideWidgetCanvasArray.includes(widget.id));
        updateWidgets(newWidget);
      }
    }
  }, [metadata.activeWidgetId, metadata.activeBlockId]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);

    // trigger on mousedown when doubleclick is activate for text
    if (doubleClickActive) {
      document.addEventListener("mousedown", clickOutsideContentEditebleText);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      setTimeout(() => {
        document.removeEventListener("mousedown", clickOutsideContentEditebleText);
      }, 10);
    };
  });

  // document play, audio play, page play, functionalities
  useDocumentPlay();
  useAudioPlay();
  usePagePlay();

  const handleClickOutsideForTimelinePlay = e => {
    const playingPageIdx = document.querySelector(".dhp-page-canvas.page-playing")?.getAttribute("data-page-idx");
    if (
      playingPageIdx &&
      !e?.target?.closest(`#page-top-play-icon-${playingPageIdx}`) &&
      !e?.target?.closest(".dhp-page-progress-bar") &&
      !e?.target?.classList.contains("dhp-page-progress-bar") &&
      !e?.target?.closest(".page-timeline-player") &&
      !e?.target?.closest(".page-timeline-playhead") &&
      !e?.target?.classList.contains("timeline-body") &&
      !e?.target?.closest(".document-timeline-player") &&
      !e?.target?.closest(".document-timeline-playhead")
    ) {
      setWhichPagePlaying({
        action: "force_stop",
        playingPageIdx: parseInt(playingPageIdx),
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideForTimelinePlay);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForTimelinePlay);
    };
  }, []);

  return (
    <>
      {!pageSorterList && isDocumentReady && (
        <>
          <div id="generic-editor-wrap" className={style["generic-editor-wrap"]}>
            {pageNodes.map((pageNode, idx) => (
              <Page
                key={pageNode.pageId}
                pageNode={pageNode}
                index={idx + getRandomString()}
                setIsResizing={setIsResizing}
                setOutsideDoubleclickedObj={setOutsideDoubleclickedObj}
                toggle={props.toggle}
                setFitToScreen={setFitToScreen}
              />
            ))}
          </div>

          <CollaborativeSelector />

          <WidgetHandler />

          {/* Display when hover on a widget */}
          <div id="dhp-widget-highlighter" className={cx(style["dhp-widget-highlighter"], style["d-none"])}></div>

          {/* active page selector from outside */}
          {metadata.activeBlockId &&
            !metadata.activeWidgetId &&
            !metadata.activeOutSideCanvasArea &&
            playingPageIdx === null && <ActivePageSelector />}

          {/* outside text/text-frame widget on doubleclick */}
          {doubleClickActive && (
            <div
              className={getClassListToString(outsideDoubleclickedObj.classLists)}
              style={{ visibility: "hidden" }}
              onMouseUp={() => {
                setSelectionRange(true), holdSelectionRange();
              }}
              onKeyUp={() => {
                setSelectionRange(true), holdSelectionRange();
              }}
              onDoubleClick={fetchColorForFireFox}
              onMouseDown={() => {
                setSelectionRange(false), setDoubleClickSelctedareaColor(false);
              }}
              onKeyDown={() => {
                setSelectionRange(false), setDoubleClickSelctedareaColor(false);
              }}
              onInput={e => {
                updateWidgetHandlerHeight(e);
                toggleZoomStyle({ type: "revoke" });
              }}
              onFocus={() => toggleZoomStyle({ type: "revoke" })}></div>
          )}

          {/* Display when finding string from text and table */}
          {showFindAndReplace?.isActive && (
            <div id="dhp-find-highlighter" className={cx(style["dhp-find-highlighter"], style["d-none"])}></div>
          )}

          {/* Audio elements */}
          <div id="dhp-audio-files">
            {audios?.map((audio, idx) => (
              <audio
                id={`player-${audio.id}`}
                key={idx}
                src={audio.src}
                preload="auto"
                data-audio-id={audio.id}></audio>
            ))}
          </div>
        </>
      )}

      {pageSorterList && isDocumentReady && (
        <>
          {pageNodes.map((pageNode, idx) => (
            <Page
              key={pageNode.pageId}
              pageNode={pageNode}
              index={idx + getRandomString()}
              pageSorterList={pageSorterList}
              scaleFactor={scaleFactor}
            />
          ))}
        </>
      )}
    </>
  );
};
//Props type validation
EditorCanvas.propTypes = {
  pageSorterList: PropTypes.bool,
  scaleFactor: PropTypes.number,
  setFitToScreen: PropTypes.func,
  toggle: PropTypes.func,
};

export default EditorCanvas;
