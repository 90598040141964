import {
  TEXT,
  TEXT_FRAME,
  BACKGROUND,
  LINE,
  SHAPE,
  ICON,
  STICKER,
  ILLUSTRATION,
  PICTURE,
  UPLOAD,
  VIDEO,
  ANIMATION,
  PAGE_MARGIN,
  STOCK,
  YOUTUBE,
  TABLE,
  GROUP_WIDGET,
  N,
  S,
  W,
  E,
  NW,
  NE,
  SW,
  SE,
  SELECT,
  DRAG,
  ROTATE,
  RESIZE,
  PAGE_TRANSITION,
  LINE_HEIGHT,
  TYPE_FORM,
  QR_CODE,
  JOT_FORM,
  AI_WRITER,
  REWRITE,
  SUMMARIZE,
  EXPAND,
  MAP,
  WHITE_COLOR,
  COLLAGE,
  BRAND,
  UPLOAD_VIDEO,
  AUDIO,
  WIDGET_ANIMATION,
  UPLOAD_AUDIO,
} from "../../constants/editor";
import transparentSmall from "../../assets/images/transparent-small.jpg";
import transparentMidium from "../../assets/images/transparent-midium.jpg";
import mixedColor from "../../assets/images/mixed-colors.jpg";

export const widgetConfig = {
  [TEXT]: {
    minWidth: 10,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [W, E, NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    content: "<div>Double click to edit</div>",
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Heading",
        "FontFamily",
        "FontSize",
        "Color",
        "Bold",
        "Italic",
        "Underline",
        "TextAlign",
        "Spacing",
        "Effect",
        "More",
      ],
      effect: ["Shadow", "Border", "Outline"],
      arrange: ["Layer", "Align", "Rotate"],
      [AI_WRITER]: [REWRITE, SUMMARIZE, EXPAND],
      more: [
        AI_WRITER,
        "Strickthrough",
        "Transform",
        "List",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    h1: {
      tag: "h1",
      width: 295,
      height: 32,
      heading: "h1",
      fontSize: "32px",
      dataAttr: {
        "data-widget": "TEXT",
        "data-asset-type": TEXT,
        "data-heading": "h1",
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-version": "3.0",
      },
      innerHTML: {
        classList: "primary-text-widget dhp-widget-inner",
        style: {
          display: "block",
          width: 295 + "px",
          "font-size": 32 + "px",
          "font-family": "Open Sans",
          "text-align": "center",
          "line-height": LINE_HEIGHT,
          "letter-spacing": 0,
          margin: 0,
          padding: 0,
          "text-transform": "inherit",
          "overflow-wrap": "break-word",
          "border-style": "none",
          "background-color": "transparent",
          "border-width": 1,
          "border-radius": 0,
          "border-color": "rgb(0, 0, 0)",
          "font-weight": 400,
          // transform: "scale(0.67)",
          "transform-origin": "center", //Previously it's value was left top, now it is change for flip property
          color: "rgb(0, 0, 0)",
          opacity: 1,
        },
        dataAttr: {},
      },
    },
    h2: {
      tag: "h2",
      width: 225,
      height: 24,
      heading: "h2",
      fontSize: "24px",
      dataAttr: {
        "data-widget": "TEXT",
        "data-asset-type": TEXT,
        "data-heading": "h2",
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-version": "3.0",
      },
      innerHTML: {
        classList: "primary-text-widget dhp-widget-inner",
        style: {
          display: "block",
          width: 225 + "px",
          "font-size": 24 + "px",
          "font-family": "Open Sans",
          "text-align": "center",
          "line-height": LINE_HEIGHT,
          "letter-spacing": 0,
          margin: 0,
          padding: 0,
          "text-transform": "inherit",
          "overflow-wrap": "break-word",
          "border-style": "none",
          "background-color": "transparent",
          "border-width": 1,
          "border-radius": 0,
          "border-color": "rgb(0, 0, 0)",
          "font-weight": 400,
          // transform: "scale(0.67)",
          "transform-origin": "center", //Previously it's value was left top, now it is change for flip property
          color: "rgb(0, 0, 0)",
          opacity: 1,
        },
        dataAttr: {},
      },
    },
    normal: {
      tag: "div",
      width: 140,
      height: 14,
      heading: "normal",
      fontSize: "14px",
      dataAttr: {
        "data-widget": "TEXT",
        "data-asset-type": TEXT,
        "data-heading": "normal",
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-version": "3.0",
      },
      innerHTML: {
        classList: "primary-text-widget dhp-widget-inner",
        style: {
          display: "block",
          width: 140 + "px",
          "font-size": 14 + "px",
          "font-family": "Open Sans",
          "text-align": "center",
          "line-height": LINE_HEIGHT,
          "letter-spacing": 0,
          margin: 0,
          padding: 0,
          "text-transform": "inherit",
          "overflow-wrap": "break-word",
          "border-style": "none",
          "background-color": "transparent",
          "border-width": 1,
          "border-radius": 0,
          "border-color": "rgb(0, 0, 0)",
          "font-weight": 400,
          // transform: "scale(0.67)",
          "transform-origin": "center", //Previously it's value was left top, now it is change for flip property
          color: "rgb(0, 0, 0)",
          opacity: 1,
        },
        dataAttr: {},
      },
    },
  },
  [TEXT_FRAME]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "TEXT-FRAME",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-asset-type": TEXT_FRAME,
      "data-version": "3.0",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [BACKGROUND]: {
    contextualMenu: {
      style: {
        backgroundImage: "url(https://app.dochipo.com/assets/images/canvas-bg-tool-icon.jpg)",
        backgroundSize: 32,
        opacity: 1,
      },
    },
    color: {
      style: {
        backgroundColor: "#ffffff",
        position: "absolute",
        zIndex: 10,
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px",
      },
    },
    image: {
      style: {
        backgroundImage: "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "repeat",
        position: "absolute",
        zIndex: 11,
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px",
        opacity: 1,
        transform: "scale(1.002)",
      },
    },
  },
  [LINE]: {
    minWidth: 10,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [W, E],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "LineThickness",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "LineThickness", "Link", "Delete"],
    },
    tag: "svg",
    width: 150,
    height: 5,
    dataAttr: {
      "data-widget": "LINE-SHAPE",
      "data-asset-type": LINE,
      "data-category": "",
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        overflow: "visible",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [SHAPE]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "SHAPE",
      "data-asset-type": SHAPE,
      "data-scheme": "",
      "data-category": "",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-version": "3.0",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        display: "block",
        opacity: 1,
      },
      dataAttr: { preserveAspectRatio: "none", width: "100%", height: "100%" },
    },
  },
  [ICON]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "ICON",
      "data-asset-type": ICON,
      "data-scheme": "",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-version": "2.0",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [STICKER]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "STICKER",
      "data-asset-type": STICKER,
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [ILLUSTRATION]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Color",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "ILLUSTRATION",
      "data-asset-type": ILLUSTRATION,
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [PICTURE]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "RemoveBackground",
        "Filter",
        "Crop",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "RemoveBackground", "Crop", "Filter", "Link", "Delete"],
    },
    tag: "div",
    width: 512,
    height: 341,
    dataAttr: {
      "data-widget": "PICTURE",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-asset-type": PICTURE,
      "data-crop-to-shape": "false",
      "data-version": "2.0",
    },
    innerHTML: {
      classList: "flippable",
      style: {
        "transform-origin": "left top",
        transform: "translate(0px, 0px) scale(1, 1)",
        display: "block",
        opacity: 1,
        "will-change": "transform",
      },
      dataAttr: {},
    },
    childElement: {
      style: { height: "100%", width: "100%", display: "block" },
    },
  },
  [UPLOAD]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    svg: {
      minWidth: 16,
      minHeight: false,
      aspectRatio: true,
      events: [SELECT, DRAG, ROTATE, RESIZE],
      resizers: [NW, NE, SW, SE],
      eventSideEffects: [],
      contextualMenu: {
        topLevel: [
          "ClipBoard",
          "Color",
          "Effect",
          "CopyStyle",
          "Animate",
          "Opacity",
          "Link",
          "Arrange",
          "Lock",
          "Delete",
        ],
        effect: ["Shadow"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
      },
      tag: "svg",
      innerHTML: {
        classList: "flippable dhp-widget-inner",
        style: {
          height: "100%",
          width: "100%",
          display: "block",
          opacity: 1,
        },
        dataAttr: {},
      },
    },
    gif: {
      contextualMenu: {
        topLevel: [
          "ClipBoard",
          "Filter",
          "Crop",
          "Effect",
          "CopyStyle",
          "Animate",
          "Opacity",
          "Link",
          "Arrange",
          "Lock",
          "Delete",
        ],
        effect: ["Shadow"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Crop", "Filter", "Link", "Delete"],
      },
      tag: "img",
    },
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "RemoveBackground",
        "Filter",
        "Crop",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "RemoveBackground", "Crop", "Filter", "Link", "Delete"],
    },
    tag: "div",
    width: 512,
    height: 341,
    dataAttr: {
      "data-widget": "UPLOAD",
      "data-asset-type": UPLOAD,
      "data-file-type": "",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-crop-to-shape": "false",
      "data-version": "2.0",
    },
    innerHTML: {
      classList: "flippable",
      style: {
        "transform-origin": "left top",
        transform: "translate(0px, 0px) scale(1, 1)",
        display: "block",
        opacity: 1,
        "will-change": "transform",
      },
      dataAttr: {},
    },
    childElement: {
      style: { height: "100%", width: "100%", display: "block" },
    },
  },
  [VIDEO]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    width: 240,
    height: 135,
    [STOCK]: {
      contextualMenu: {
        topLevel: [
          "ClipBoard",
          "Filter",
          "Pause",
          "Loop",
          "Crop",
          "Trim",
          "Volume",
          "Effect",
          "CopyStyle",
          "Animate",
          "Opacity",
          "Arrange",
          "Lock",
          "Delete",
        ],
        effect: ["Shadow", "Border"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
      },
      tag: "div",
      dataAttr: {
        "data-widget": "MEDIA",
        "data-asset-type": VIDEO,
        "data-source": STOCK,
        "data-origin": "",
        "data-poster": "",
        "data-url": "",
        "data-loop": true,
        "data-duration": "",
        "data-trimmed-duration": "",
        "data-playing": false,
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-crop-to-shape": "false",
        "data-trim": "",
        "data-volume": "100",
        "data-version": "2.0",
      },
      innerHTML: {
        classList: "flippable",
        style: {
          "transform-origin": "left top",
          transform: "translate(0px, 0px) scale(1, 1)",
          display: "block",
          opacity: 1,
          "will-change": "transform",
        },
        dataAttr: {
          // muted: true,
          playsinline: true,
          disablepictureinpicture: true,
          preload: "auto",
        },
      },
      childElement: {
        style: { height: "100%", width: "100%", display: "block", "object-fit": "cover" },
      },
    },
    [YOUTUBE]: {
      contextualMenu: {
        topLevel: ["ClipBoard", "Effect", "CopyStyle", "Animate", "Opacity", "Arrange", "Lock", "Delete"],
        effect: ["Shadow"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
      },
      tag: "img",
      dataAttr: {
        "data-widget": "MEDIA",
        "data-asset-type": VIDEO,
        "data-source": YOUTUBE,
        "data-url": "",
        "data-key": "",
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-version": "2.0",
      },
      innerHTML: {
        classList: "dhp-widget-inner",
        style: {
          width: "100%",
          opacity: 1,
        },
        dataAttr: {},
      },
    },
    [BRAND]: {
      contextualMenu: {
        topLevel: [
          "ClipBoard",
          "Filter",
          "Pause",
          "Loop",
          "Crop",
          "Trim",
          "Volume",
          "Effect",
          "CopyStyle",
          "Animate",
          "Opacity",
          "Arrange",
          "Lock",
          "Delete",
        ],
        effect: ["Shadow", "Border"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
      },
      tag: "div",
      dataAttr: {
        "data-widget": "MEDIA",
        "data-asset-type": VIDEO,
        "data-source": BRAND,
        "data-poster": "",
        "data-url": "",
        "data-loop": true,
        "data-duration": "",
        "data-trimmed-duration": "",
        "data-playing": false,
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-crop-to-shape": "false",
        "data-trim": "",
        "data-volume": "100",
        "data-version": "2.0",
      },
      innerHTML: {
        classList: "flippable",
        style: {
          "transform-origin": "left top",
          transform: "translate(0px, 0px) scale(1, 1)",
          display: "block",
          opacity: 1,
          "will-change": "transform",
        },
        dataAttr: {
          // muted: true,
          playsinline: true,
          disablepictureinpicture: true,
          preload: "auto",
        },
      },
      childElement: {
        style: { height: "100%", width: "100%", display: "block", "object-fit": "cover" },
      },
    },
    [UPLOAD_VIDEO]: {
      contextualMenu: {
        topLevel: [
          "ClipBoard",
          "Filter",
          "Pause",
          "Loop",
          "Crop",
          "Trim",
          "Volume",
          "Effect",
          "CopyStyle",
          "Animate",
          "Opacity",
          "Arrange",
          "Lock",
          "Delete",
        ],
        effect: ["Shadow", "Border"],
        arrange: ["Layer", "Align", "Rotate"],
        right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
      },
      tag: "div",
      dataAttr: {
        "data-widget": "MEDIA",
        "data-asset-type": VIDEO,
        "data-source": UPLOAD_VIDEO,
        "data-poster": "",
        "data-url": "",
        "data-loop": true,
        "data-duration": "",
        "data-trimmed-duration": "",
        "data-playing": false,
        "data-x-allignment": "center",
        "data-y-allignment": "middle",
        "data-crop-to-shape": "false",
        "data-trim": "",
        "data-volume": "100",
        "data-version": "2.0",
      },
      innerHTML: {
        classList: "flippable",
        style: {
          "transform-origin": "left top",
          transform: "translate(0px, 0px) scale(1, 1)",
          display: "block",
          opacity: 1,
          "will-change": "transform",
        },
        dataAttr: {
          // muted: true,
          playsinline: true,
          disablepictureinpicture: true,
          preload: "auto",
        },
      },
      childElement: {
        style: { height: "100%", width: "100%", display: "block", "object-fit": "cover" },
      },
    },
  },
  [ANIMATION]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Filter",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "div",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "ANIMATION",
      "data-asset-type": ANIMATION,
      "data-duration": null,
      "data-thumb": null,
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-version": "2.0",
    },
    innerHTML: {
      classList: "flippable",
      style: {
        "transform-origin": "left top",
        transform: "translate(0px, 0px) scale(1, 1)",
        display: "block",
        opacity: 1,
        "will-change": "transform",
      },
      dataAttr: {},
    },
    childElement: {
      style: { height: "100%", width: "100%", display: "block" },
    },
  },
  [TABLE]: {
    minWidth: 150,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "TableToolBar",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "div",
    width: null,
    height: null,
    dataAttr: {
      "data-widget": "TABLE",
      "data-asset-type": TABLE,
      "data-color-scheme": "mono",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-version": "2.0",
    },
    fontSize: [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40],
    innerHTML: {
      classList: "dhp-widget-inner",
      style: { transform: "scale(1)", "transform-origin": "left top", opacity: 1 },
      dataAttr: {},
    },
    styleComponent: [
      "color",
      "fontFamily",
      "fontWeight",
      "fontStyle",
      "fontSize",
      "bold",
      "italic",
      "width",
      "textDecoration",
      "outline",
    ],
    tableConfig: {
      formatting: {
        rows: 3,
        columns: 3,
        columnMaxSize: 10,
        columnMinSize: 1,
        rowMaxSize: 10,
        rowMinSize: 1,
        defaultFontWeight: "400",
        footerEnabled: false,
        tableStyle: { width: 300 },
        thead: {
          backgroundColor: "#4472C4",
          fontFamily: "Open Sans",
          fontSize: 14,
          color: "#ffffff",
          fontWeight: "400",
          fontStyle: "normal",
          textDecoration: "none",
          outline: "none",
          textAlign: "center",
          bold: false,
          italic: false,
        },
        tbody: {
          backgroundColorEven: "#dae3f3",
          backgroundColorOdd: "#f2f2f2",
          fontFamily: "Open Sans",
          fontSize: 14,
          color: "#000000",
          fontWeight: "400",
          fontStyle: "normal",
          textDecoration: "none",
          textAlign: "left",
          colorScheme: "mono", // [mono & alternate]
          bold: false,
          italic: false,
        },
        tfoot: {
          backgroundColor: "#4472C4",
          fontFamily: "Open Sans",
          fontSize: 14,
          color: "#ffffff",
          fontWeight: "400",
          fontStyle: "normal",
          textDecoration: "none",
          outline: "none",
          textAlign: "center",
          bold: false,
          italic: false,
        },
        border: {
          horizontal: "solid",
          vertical: "solid",
          width: 1,
          color: "#ffffff",
        },
      },
    },
    defaultTableData: {
      columns: [
        {
          value: "Laptop",
          width: "100px",
          colspan: 1,
        },
        {
          value: "Mobile",
          width: "100px",
          colspan: 1,
        },
        {
          value: "Car",
          width: "100px",
          colspan: 1,
        },
      ],
      rows: [
        {
          // cells: ["Lenovo", "Samsung", "BMW"],
          cells: [
            { value: "Lenovo", colspan: 1, rowspan: 1 },
            { value: "Samsung", colspan: 1, rowspan: 1 },
            { value: "BMW", colspan: 1, rowspan: 1 },
          ],
        },
        {
          // cells: ["HP", "Nokia", "Honda"],
          cells: [
            { value: "HP", colspan: 1, rowspan: 1 },
            { value: "Nokia", colspan: 1, rowspan: 1 },
            { value: "Honda", colspan: 1, rowspan: 1 },
          ],
        },
        {
          // cells: ["Acer", "Vivo", "Ford"],
          cells: [
            { value: "Acer", colspan: 1, rowspan: 1 },
            { value: "Vivo", colspan: 1, rowspan: 1 },
            { value: "Ford", colspan: 1, rowspan: 1 },
          ],
        },
      ],
      footer: [
        { value: "", colspan: 1 },
        { value: "", colspan: 1 },
        { value: "", colspan: 1 },
      ],
    },
  },
  shift: {
    contextualMenu: {
      topLevel: ["Group", "UnGroup", "ClipBoard", "Animate", "Arrange", "Lock", "Delete"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Group", "UnGroup", "Layer", "Delete"],
    },
  },
  [PAGE_MARGIN]: {
    px: {
      minSize: 1,
      maxSize: 40,
    },
    in: {
      minSize: 0.01,
      maxSize: 0.55,
    },
    cm: {
      minSize: 0.03,
      maxSize: 1.41,
    },
    mm: {
      minSize: 0.35,
      maxSize: 14.11,
    },
    // minSize: 1,
    defaultSize: 35,
    // maxSize: 40,
    minWidth: 0,
    defaultWidth: 1.8,
    maxWidth: 5,
    defaultColor: "#5b9bd5",
    defaultType: "dotted",
  },
  [PAGE_TRANSITION]: {
    types: [
      {
        groupName: "Back",
        options: [
          { name: "Back In Left" },
          { name: "Back In Right" },
          { name: "Back In Up" },
          { name: "Back In Down" },
        ],
      },
      {
        groupName: "Bounce",
        options: [
          { name: "Bounce In Center" },
          { name: "Bounce In Left" },
          { name: "Bounce In Right" },
          { name: "Bounce In Up" },
          { name: "Bounce In Down" },
        ],
      },
      {
        groupName: "Fade",
        options: [
          { name: "Fade In Center" },
          { name: "Fade In Left" },
          { name: "Fade In Right" },
          { name: "Fade In Up" },
          { name: "Fade In Down" },
        ],
      },
      {
        groupName: "Flip",
        options: [
          { name: "Flip In Left" },
          { name: "Flip In Right" },
          { name: "Flip In Up" },
          { name: "Flip In Down" },
        ],
      },
      {
        groupName: "Puff",
        options: [
          { name: "Puff In Center" },
          { name: "Puff In Left" },
          { name: "Puff In Right" },
          { name: "Puff In Up" },
          { name: "Puff In Down" },
        ],
      },
      {
        groupName: "Roll",
        options: [
          { name: "Roll In Left" },
          { name: "Roll In Right" },
          { name: "Roll In Up" },
          { name: "Roll In Down" },
        ],
      },
      {
        groupName: "Rotate",
        options: [
          { name: "Rotate In Center" },
          { name: "Rotate In Left" },
          { name: "Rotate In Right" },
          { name: "Rotate In Up" },
          { name: "Rotate In Down" },
        ],
      },
      {
        groupName: "Slide",
        options: [
          { name: "Slide In Left" },
          { name: "Slide In Right" },
          { name: "Slide In Up" },
          { name: "Slide In Down" },
        ],
      },
      {
        groupName: "Slit",
        options: [{ name: "Slit In Vertical" }, { name: "Slit In Horizontal" }],
      },
      {
        groupName: "Zoom",
        options: [
          { name: "Zoom In Center" },
          { name: "Zoom In Left" },
          { name: "Zoom In Right" },
          { name: "Zoom In Up" },
          { name: "Zoom In Down" },
        ],
      },
      {
        groupName: "Perspective",
        options: [
          { name: "Perspective Left" },
          { name: "Perspective Right" },
          { name: "Perspective Up" },
          { name: "Perspective Down" },
        ],
      },
      {
        groupName: "Wipe",
        options: [
          { name: "Line Wipe Left" },
          { name: "Line Wipe Right" },
          { name: "Line Wipe Up" },
          { name: "Line Wipe Down" },
          { name: "Circle Wipe" },
        ],
      },
      {
        groupName: "Special",
        options: [{ name: "Gallery" }, { name: "Cubic Bezier" }, { name: "Glitch" }],
      },
    ],
    speeds: [
      {
        name: "Fast",
        value: "0.5",
      },
      {
        name: "Medium",
        value: "1.0",
      },
      {
        name: "Slow",
        value: "2.0",
      },
    ],
    defaultType: "Zoom In Center",
    defaultSpeed: "Medium",
  },
  [GROUP_WIDGET]: {
    minWidth: 50,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: ["UnGroup", "ClipBoard", "Animate", "Arrange", "Lock", "Delete"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "UnGroup", "Layer", "Delete"],
    },
    tag: "div",
    width: false,
    height: false,
    dataAttr: {
      "data-widget": "GROUP",
      "data-asset-type": GROUP_WIDGET,
      "data-x-allignment": "false",
      "data-y-allignment": "false",
      "data-version": "2.0",
    },
    innerHTML: {},
  },
  [QR_CODE]: {
    minMargin: 0,
    defaultMargin: 8,
    maxMargin: 15,
    defaultQrCodeColor: "#000000",
    defaultQrCodeBackgroundColor: "#ffffff",
    defaultSize: 150,
    defaultValue: "DocHipo",
    defaultLength: 200,
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [],
    contextualMenu: {
      topLevel: ["ClipBoard", "Qr", "Effect", "CopyStyle", "Animate", "Opacity", "Arrange", "Lock", "Delete"],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
    },
    tag: "svg",
    width: 176,
    height: 176,
    dataAttr: {
      "data-widget": "QRCODE",
      "data-asset-type": QR_CODE,
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {},
      dataAttr: {},
    },
  },
  [COLLAGE]: {
    minWidth: 100,
    minHeight: 100,
    aspectRatio: false,
    events: [SELECT, DRAG, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "Filter",
        "GridSpacing",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "div",
    width: 512,
    height: 512,
    dataAttr: {
      "data-widget": "COLLAGE",
      "data-asset-type": COLLAGE,
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-version": "2.0",
    },
    innerHTML: {
      classList: "dhp-widget-inner",
      style: {
        width: "100%",
        height: "100%",
        display: "block",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [TYPE_FORM]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [],
    linkParams: "typeform-cache=0&disable-auto-focus=true",
    contextualMenu: {
      topLevel: ["ClipBoard", "Effect", "CopyStyle", "Animate", "Opacity", "Arrange", "Lock", "Delete"],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
    },
    tag: "div",
    width: 510,
    height: 430,
    dataAttr: {
      "data-widget": "TYPE_FORM",
      "data-asset-type": TYPE_FORM,
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner connected-form",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
    iframeInnerHTML: {
      cssText: "width: 100%; height: 100%; border-style: none;",
    },
  },
  [JOT_FORM]: {
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [N, S, W, E, NW, NE, SW, SE],
    eventSideEffects: [],
    linkParams: "jotform-cache=0&disable-auto-focus=true",
    contextualMenu: {
      topLevel: ["ClipBoard", "Effect", "CopyStyle", "Animate", "Opacity", "Arrange", "Lock", "Delete"],
      effect: ["Shadow", "Border"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Delete"],
    },
    tag: "div",
    width: 510,
    height: 430,
    dataAttr: {
      "data-widget": "JOT_FORM",
      "data-asset-type": JOT_FORM,
      "data-version": "2.0",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
    },
    innerHTML: {
      classList: "dhp-widget-inner connected-form",
      style: {
        width: "100%",
        height: "100%",
        opacity: 1,
      },
      dataAttr: {},
    },
    iframeInnerHTML: {
      cssText: "width: 100%; height: 100%; border-style: none;",
    },
  },
  [MAP]: {
    default_color: "#276395",
    default_border_color: WHITE_COLOR,
    default_caption_type: "Light",
    dafault_area_value: "Population",
    default_start_color: "#276395",
    default_end_color: "#eb7d3c",
    default_legend_position: "Right",
    minWidth: 16,
    minHeight: false,
    aspectRatio: true,
    events: [SELECT, DRAG, ROTATE, RESIZE],
    resizers: [NW, NE, SW, SE],
    eventSideEffects: [RESIZE],
    contextualMenu: {
      topLevel: [
        "ClipBoard",
        "MapToolBar",
        "Effect",
        "CopyStyle",
        "Animate",
        "Opacity",
        "Link",
        "Arrange",
        "Lock",
        "Delete",
      ],
      effect: ["Shadow"],
      arrange: ["Layer", "Align", "Rotate"],
      right: ["Cut", "Copy", "Paste", "Clone", "Layer", "Link", "Delete"],
    },
    tag: "div",
    width: 200,
    height: 200,
    dataAttr: {
      "data-widget": "MAP",
      "data-x-allignment": "center",
      "data-y-allignment": "middle",
      "data-asset-type": MAP,
      "data-version": "5.0",
    },
    innerHTML: {
      classList: "dhp-widget-inner mapWrap",
      style: {
        width: "100%",
        height: "100%",
        transform: "scale(1)",
        "transform-origin": "left top",
        opacity: 1,
      },
      dataAttr: {},
    },
  },
  [AUDIO]: {
    contextualMenu: {
      topLevel: ["Clone", "Trim", "Fade", "Volume", "Delete"],
      right: ["Clone", "Trim", "Fade", "Volume", "Delete"],
    },
    [UPLOAD_AUDIO]: {
      dataAttr: {
        "data-widget": "UPLOAD",
        "data-asset-type": UPLOAD,
        "data-loop": true,
        "data-duration": "",
        "data-trimmed-duration": "",
        "data-trim": "",
        "data-volume": "100",
        "data-version": "2.0",
        "data-fade-in-value": "None",
        "data-fade-out-value": "None",
      },
    },
    [STOCK]: {
      dataAttr: {
        "data-widget": "AUDIO",
        "data-asset-type": AUDIO,
        "data-loop": true,
        "data-duration": "",
        "data-trimmed-duration": "",
        "data-trim": "",
        "data-volume": "100",
        "data-version": "2.0",
        "data-fade-in-value": "None",
        "data-fade-out-value": "None",
      },
    },
  },
  [WIDGET_ANIMATION]: {
    entrance_types: [
      {
        groupName: "Back",
        options: [
          { name: "Back In Left" },
          { name: "Back In Right" },
          { name: "Back In Up" },
          { name: "Back In Down" },
        ],
      },
      {
        groupName: "Bounce",
        options: [
          { name: "Bounce In Center" },
          { name: "Bounce In Left" },
          { name: "Bounce In Right" },
          { name: "Bounce In Up" },
          { name: "Bounce In Down" },
        ],
      },
      {
        groupName: "Fade",
        options: [
          { name: "Fade In Center" },
          { name: "Fade In Left" },
          { name: "Fade In Right" },
          { name: "Fade In Up" },
          { name: "Fade In Down" },
        ],
      },
      {
        groupName: "Flip",
        options: [
          { name: "Flip In Left" },
          { name: "Flip In Right" },
          { name: "Flip In Up" },
          { name: "Flip In Down" },
        ],
      },
      {
        groupName: "Puff",
        options: [
          { name: "Puff In Center" },
          { name: "Puff In Left" },
          { name: "Puff In Right" },
          { name: "Puff In Up" },
          { name: "Puff In Down" },
        ],
      },
      {
        groupName: "Roll",
        options: [
          { name: "Roll In Left" },
          { name: "Roll In Right" },
          { name: "Roll In Up" },
          { name: "Roll In Down" },
        ],
      },
      {
        groupName: "Rotate",
        options: [
          { name: "Rotate In Center" },
          { name: "Rotate In Left" },
          { name: "Rotate In Right" },
          { name: "Rotate In Up" },
          { name: "Rotate In Down" },
        ],
      },
      {
        groupName: "Slide",
        options: [
          { name: "Slide In Left" },
          { name: "Slide In Right" },
          { name: "Slide In Up" },
          { name: "Slide In Down" },
        ],
      },
      {
        groupName: "Slit",
        options: [{ name: "Slit In Vertical" }, { name: "Slit In Horizontal" }],
      },
      {
        groupName: "Zoom",
        options: [
          { name: "Zoom In Center" },
          { name: "Zoom In Left" },
          { name: "Zoom In Right" },
          { name: "Zoom In Up" },
          { name: "Zoom In Down" },
        ],
      },
    ],
    exit_types: [
      {
        groupName: "Back",
        options: [
          { name: "Back Out Left" },
          { name: "Back Out Right" },
          { name: "Back Out Up" },
          { name: "Back Out Down" },
        ],
      },
      {
        groupName: "Bounce",
        options: [
          { name: "Bounce Out Center" },
          { name: "Bounce Out Left" },
          { name: "Bounce Out Right" },
          { name: "Bounce Out Up" },
          { name: "Bounce Out Down" },
        ],
      },
      {
        groupName: "Fade",
        options: [
          { name: "Fade Out Center" },
          { name: "Fade Out Left" },
          { name: "Fade Out Right" },
          { name: "Fade Out Up" },
          { name: "Fade Out Down" },
        ],
      },
      {
        groupName: "Flip",
        options: [
          { name: "Flip Out Left" },
          { name: "Flip Out Right" },
          { name: "Flip Out Up" },
          { name: "Flip Out Down" },
        ],
      },
      {
        groupName: "Puff",
        options: [
          { name: "Puff Out Center" },
          { name: "Puff Out Left" },
          { name: "Puff Out Right" },
          { name: "Puff Out Up" },
          { name: "Puff Out Down" },
        ],
      },
      {
        groupName: "Roll",
        options: [
          { name: "Roll Out Left" },
          { name: "Roll Out Right" },
          { name: "Roll Out Up" },
          { name: "Roll Out Down" },
        ],
      },
      {
        groupName: "Rotate",
        options: [
          { name: "Rotate Out Center" },
          { name: "Rotate Out Left" },
          { name: "Rotate Out Right" },
          { name: "Rotate Out Up" },
          { name: "Rotate Out Down" },
        ],
      },
      {
        groupName: "Slide",
        options: [
          { name: "Slide Out Left" },
          { name: "Slide Out Right" },
          { name: "Slide Out Up" },
          { name: "Slide Out Down" },
        ],
      },
      {
        groupName: "Slit",
        options: [{ name: "Slit Out Vertical" }, { name: "Slit Out Horizontal" }],
      },
      {
        groupName: "Zoom",
        options: [
          { name: "Zoom Out Center" },
          { name: "Zoom Out Left" },
          { name: "Zoom Out Right" },
          { name: "Zoom Out Up" },
          { name: "Zoom Out Down" },
        ],
      },
    ],
    speeds: [
      {
        name: "Fast",
        value: "0.5",
      },
      {
        name: "Medium",
        value: "1.0",
      },
      {
        name: "Slow",
        value: "2.0",
      },
    ],
    defaultEntryType: "Zoom In Center",
    defaultExitType: "Zoom Out Center",
    defaultSpeed: "Medium",
  },
};

export const contextualConfig = {
  Outline: {
    style: "None",
    minWidth: 1,
    defaultWidth: 1,
    maxWidth: 5,
    color: "#00a4ef",
  },
  Border: {
    style: "none",
    minWidth: 1,
    defaultWidth: 1,
    maxWidth: 20,
    maxShapeWidth: 20,
    minRadious: 0,
    defaultRadious: 0,
    maxRadious: 50,
    color: "#000000",
    shapeColor: "#00a4ef",
    BackgroundColor: "transparent",
  },
  Heading: [
    {
      name: "Heading 1",
      value: "h1",
      "font-size": 32,
    },
    {
      name: "Heading 2",
      value: "h2",
      "font-size": 24,
    },
    {
      name: "Heading 3",
      value: "h3",
      "font-size": 19,
    },
    {
      name: "Heading 4",
      value: "h4",
      "font-size": 15,
    },
    {
      name: "Heading 5",
      value: "h5",
      "font-size": 13,
    },
    {
      name: "Heading 6",
      value: "h6",
      "font-size": 11,
    },
    {
      name: "Normal",
      value: "normal",
      "font-size": 14,
    },
  ],
  FontSize: [
    8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36, 38, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
    200, 300, 400, 500, 600, 700, 800,
  ],
  TextAllign: ["left", "center", "right", "justify"],
  LetterSpacing: {
    minValue: 0,
    maxValue: 10,
  },
  LineHeight: {
    minValue: 1,
    maxValue: 5,
  },
  TextTransform: ["default", "uppercase", "lowercase", "capitalize"],
  List: [
    {
      name: "Bullets",
      tag: "ul",
      class: "unorder-list",
      style: { "list-style-type": "square" },
    },
    {
      name: "Numbering",
      tag: "ol",
      class: "order-list",
      style: { "list-style-type": "decimal" },
    },
  ],
  colorUrl: {
    small: transparentSmall,
    midium: transparentMidium,
    mixedColor: mixedColor,
  },
  Fade: ["None", "0.5 sec", "1.0 sec", "2.0 sec"],
};

export const widgetHandlerConfig = {
  resizers: {
    [N]: {
      baseClass: "resizer1",
      resizerClass: N,
      opacity: "1",
    },
    [S]: {
      baseClass: "resizer1",
      resizerClass: S,
      opacity: "1",
    },
    [W]: {
      baseClass: "resizer1",
      resizerClass: W,
      opacity: "1",
    },
    [E]: {
      baseClass: "resizer1",
      resizerClass: E,
      opacity: "1",
    },
    [NW]: {
      baseClass: "resizer2",
      resizerClass: NW,
      opacity: "1",
    },
    [NE]: {
      baseClass: "resizer2",
      resizerClass: NE,
      opacity: "1",
    },
    [SW]: {
      baseClass: "resizer2",
      resizerClass: SW,
      opacity: "1",
    },
    [SE]: {
      baseClass: "resizer2",
      resizerClass: SE,
      opacity: "1",
    },
  },
};
