import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Form,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import cx from "classnames";

import {
  DOCUMENT_PUBLISH_SUCCESS,
  DOCUMENT_PUBLISH_FAILED,
  DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS,
  DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
  DOCUMENT_UNPUBLISH_SUCCESS,
  DOCUMENT_UNPUBLISH_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
  DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS,
  DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS,
  DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
} from "../../../store/actions/actionTypes";
import { getUserInfo } from "../../../store/actions/authActions";
import {
  fetchDocumentDetails,
  publishDocument,
  publishDocumentEmailVerification,
  publishDocumentHandleBadge,
  publishDocumentHandleDownload,
  publishDocumentSetPassword,
  publishDocumentUnsetPassword,
  unpublishDocument,
} from "../../../store/actions/documentActions";
import { preventFormSubmitOnEnter as preventSubmit } from "../../../_helpers/utils";
import { documentInfo } from "../_utils";
import { Input } from "../../ui/input";
import { Icon } from "../../ui/icon";
import { ProcessLoader } from "../../ui/loader/processLoader";
import { FlashAlert } from "../../ui/flashAlert";
import { useSocket } from "../../../hooks/useSocket";
import {
  PUBLISH_DOCUMENT,
  PUBLISH_DOCUMENT_DOWNLOAD,
  PUBLISH_DOCUMENT_PASSWORD,
  UNPUBLISH_DOCUMENT,
} from "../../../constants/socket";

import global from "../../../scss/dhp.scss";
import thumbsUpSvg from "../../../assets/images/ui-thumbs-up.svg";
import sourceCodeSvg from "../../../assets/images/ui-source-code.svg";
import Crown from "../../../assets/images/ui-crown.svg";
import { useContextualUpgrade } from "../../../hooks/useContextualUpgrade";
import { useCheckCompanyPlanInfo } from "../../../hooks/useCheckCompanyPlanInfo";
import { COMPANY_SUPERADMIN } from "../../../constants/company";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import TabSchemeSelector from "../../Common/TabSchemeSelector";
import { getCompanyInfo } from "../../../store/actions/companyActions";

let style = Object.assign({}, global);

const PublishModal = ({ document: data, _helpers }) => {
  const contextData = useContext(EditorContext);
  const webSocket = useSocket();
  const dispatch = useDispatch();

  // useContextualUpgrade Hook to show update modal
  const showUpgrade = useContextualUpgrade();

  // check is this company is paid or not
  const paidCompanyInfo = useCheckCompanyPlanInfo(true);

  const companyId = useSelector(state => state.auth?.user?.company?.id);
  const modifiedData = { ...data.publish, publish_id: data?.publish?.id, id: data?.id };
  const companyData = useSelector(state => state?.auth?.company);

  const alertData = {
    show: false,
    type: "",
  };
  const processEndTypes = {
    publish: [DOCUMENT_PUBLISH_SUCCESS, DOCUMENT_PUBLISH_FAILED],
    unpublish: [DOCUMENT_UNPUBLISH_SUCCESS, DOCUMENT_UNPUBLISH_FAILED],
    set_password: [DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS, DOCUMENT_PUBLISH_SET_PASSWORD_FAILED],
    unset_password: [DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS, DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED],
    download: [DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS, DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED],
    badge: [DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS, DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED],
    email_verification: [DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS, DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED],
  };
  const errorTypes = [
    DOCUMENT_PUBLISH_FAILED,
    DOCUMENT_PUBLISH_SET_PASSWORD_FAILED,
    DOCUMENT_PUBLISH_UNSET_PASSWORD_FAILED,
    DOCUMENT_UNPUBLISH_FAILED,
    DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_FAILED,
    DOCUMENT_PUBLISH_HANDLE_BADGE_FAILED,
    DOCUMENT_PUBLISH_EMAIL_VERIFICATION_FAILED,
  ];
  const publishFieldIds = {
    link: "document-publish-link",
    embed: "document-publish-embed",
  };
  const socialShareOptions = [
    {
      name: "facebook",
      tooltip: "Facebook",
      className: "fb",
      iconClassName: "facebook",
    },
    {
      name: "twitter",
      tooltip: "X/Twitter",
      className: "tw",
      iconClassName: "twitterx",
    },
    {
      name: "linkedin",
      tooltip: "Linkedin",
      className: "lin",
      iconClassName: "linkedin",
    },
    {
      name: "whatsapp",
      tooltip: "WhatsApp",
      className: "whatsapp",
      iconClassName: "ui-whatsapp",
    },
    // {
    //   name: "skype",
    //   tooltip: "Skype",
    //   className: "skype",
    //   iconClassName: "ui-skype",
    // },
  ];
  const { exists: isPublished, data: publishId } = documentInfo.get(modifiedData, "publish_id");
  const { exists: isPasswordEnabled } = documentInfo.get(modifiedData, "password");
  const { data: isDownloadEnabled } = documentInfo.get(modifiedData, "download");
  const { data: isBadgeEnabled } = documentInfo.get({ ...companyData, id: data?.id }, "hide_badge");
  const { exists: isEmailVerificationEnabled, data: isEmailVerificationData } = documentInfo.get(
    modifiedData,
    "email_verification"
  );

  const publishSchemes = ["General", "Other Settings"];
  const verificationTypes = {
    ["AE"]: "Any Email",
    ["SE"]: "Specific Email",
    ["ED"]: "Email Domain",
  };
  const initialState = {
    published: isPublished,
    download_enabled: isDownloadEnabled,
    password_enabled: isPasswordEnabled,
    publish_id: publishId,
    toggle_publish: isPublished,
    toggle_set_password: isPasswordEnabled,
    dochipo_badge_enabled: isBadgeEnabled,
    email_verification_enabled: isEmailVerificationEnabled,
    toggle_email_verification: isEmailVerificationEnabled,
    password: {
      value: "",
      validation: {
        required: true,
        min_length: 6,
        max_length: 25,
      },
      placeholder: isPasswordEnabled ? "******" : `Minimum 6 characters`,
      touched: false,
      valid: false,
      error: "",
    },
    set_password_button_label: isPasswordEnabled ? "Change" : "Save",
    tab: "social_media",
    email_verification: {
      value: isEmailVerificationData?.value || "",
      displayValue: (isEmailVerificationData && isEmailVerificationData?.value) || "",
      validation: {
        required: true,
      },
      verification_type_dropdown: {
        selected: {
          name: isEmailVerificationData ? isEmailVerificationData.key : "Any Email",
          value: isEmailVerificationData ? isEmailVerificationData.key : "Any Email",
        },
      },
      placeholder: "neel@gmail.com, sam@gmail.com",
      email_domain_placeholder: "example.com, domain.com",
      valid: false,
    },
  };

  const [state, setState] = useState(initialState);
  const [isMounted, setIsMounted] = useState(false);
  const [isPasswordSuccessResponse, setIsPasswordSuccessResponse] = useState("");
  const [alert, setAlert] = useState(alertData);
  const [linkCopied, setLinkCopied] = useState(false);
  const [embedCopied, setEmbedCopied] = useState(false);
  const [isUserDocumentOwner, setIsUserDocumentOwner] = useState(false);
  const [disable, setDisable] = useState({
    publish: false,
    unpublish: false,
    set_download: false,
    unset_download: false,
    set_password: false,
    unset_password: false,
    set_badge_enable: false,
    unset_badge_enable: false,
  });
  const [disableEmail, setDisableEmail] = useState({
    set_email_verification: false,
    unset_email_verification: false,
  });
  const [schemeType, setSchemeType] = useState("General");
  const [emailInput, setEmailInput] = useState();
  const [isEmailMounted, setIsEmailMounted] = useState();
  const activeTabIndex = publishSchemes.indexOf(schemeType);

  const userInfo = useSelector(state => state?.auth?.user);
  const { type, publish_id, documentDetails } = useSelector(state => state?.document);

  const toggleAlert = () => setAlert({ ...alert, show: !alert.show });

  const copyToClipBoard = fieldId => {
    const textToCopy = document.getElementById(fieldId).value;
    navigator.clipboard.writeText(textToCopy);
    if (fieldId === publishFieldIds.link) {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000);
    }
    if (fieldId === publishFieldIds.embed) {
      setEmbedCopied(true);
      setTimeout(() => setEmbedCopied(false), 3000);
    }
  };

  const generatePublishCode = () => {
    let documentDia = documentDetails?.data?.version?.rawdata?.metadata?.dimension;
    let paddingValue = documentDia?.height
      ? (parseFloat(documentDia.height) / parseFloat(documentDia.width)) * 100
      : 100;
    const publishUrl = `${process.env.BASE_URL}/pub/${userInfo?.company?.id}/documents/${state.publish_id}`;
    document.getElementById(publishFieldIds.link).value = publishUrl;
    document.getElementById(
      publishFieldIds.embed
    ).value = `<div style="position: relative; width: 100%; height: 0; padding-top: ${paddingValue}%; padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden; border-radius: 8px; will-change: transform;"> <iframe loading="lazy" style="position:absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;" src="${publishUrl}?embed=true" frameborder="0"></iframe> </div>`;
  };

  const socialShareOpener = channel => {
    let urlToOpen = "";
    const message = encodeURIComponent(
      `${userInfo?.name} has shared you the following document: ${data?.document_name}`
    );
    const publishUrl = `${process.env.BASE_URL}/pub/${userInfo?.company?.id}/documents/${state.publish_id}`;

    switch (channel) {
      case "facebook":
        urlToOpen = `https://www.facebook.com/sharer/sharer.php?u=${publishUrl}`;
        break;

      case "twitter":
        urlToOpen = `https://twitter.com/intent/tweet?text=${message}&url=${publishUrl}`;
        break;

      case "linkedin":
        urlToOpen = `https://www.linkedin.com/sharing/share-offsite/?url=${publishUrl}`;
        break;

      case "whatsapp":
        urlToOpen = `https://wa.me/?text=${message}%20${publishUrl}`;
        break;

      case "skype":
        urlToOpen = `https://web.skype.com/share?url=${publishUrl}&text=${message}`;
        break;

      default:
        break;
    }

    window.open(urlToOpen, "_blank", "noopener,noreferrer");
  };

  // Function to convert email array to input format
  const convertArrayToInputFormat = emailArray => {
    return emailArray?.join(", ");
  };

  const handleVerificationTypeClick = type => {
    if (verificationTypes[type] !== state.email_verification.verification_type_dropdown.selected.value) {
      updateState({ field: "email_type_dropdown", value: verificationTypes[type] });
    }
    //To retain input value between switch the type
    if (verificationTypes[type] === isEmailVerificationData?.key && isEmailVerificationData?.value) {
      const inputVal = convertArrayToInputFormat(isEmailVerificationData?.value);
      setEmailInput(inputVal);
    } else {
      setEmailInput("");
    }
  };

  const updateState = ({ field, type, e, value }) => {
    let stateObj = { ...state };

    if (field === "toggle_publish") {
      e.target.checked = stateObj.published;
      if (!stateObj.published) {
        stateObj.toggle_publish = true;
        setState(stateObj);
        setIsMounted(true);
        dispatch(publishDocument([data.id]));
        setDisable({ ...disable, publish: true, unset_download: false, set_download: false });
      }
      if (stateObj.published) {
        setIsMounted(true);
        dispatch(unpublishDocument([data.id]));
        setDisable({ ...disable, publish: false, unpublish: true, unset_download: false, set_download: false });
      }
    }

    if (field === "toggle_allow_download") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        let docPagesMeta =
          contextData && Object.keys(contextData).length > 0
            ? { ...contextData }
            : { ...documentDetails?.data?.version?.rawdata?.contexts };
        showUpgrade("publishDownload", {
          docPagesMeta:
            docPagesMeta && Object.keys(docPagesMeta).length > 0
              ? docPagesMeta
              : { thumb: documentDetails?.data?.thumb },
        });
        if (_helpers?.modal?.toggle) _helpers.modal.toggle();
        return;
      }

      setIsMounted(true);
      dispatch(publishDocumentHandleDownload({ documentId: data.id, companyId, download: e.target.checked }));
      setDisable({ ...disable, unset_download: !e.target.checked, set_download: e.target.checked });
      e.target.checked = stateObj.download_enabled;
    }

    if (field === "toggle_set_password") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        showUpgrade("publishPassword");
        if (_helpers?.modal?.toggle) _helpers.modal.toggle();
        return;
      }
      if (stateObj.password_enabled) {
        e.target.checked = true;
        setIsMounted(true);
        dispatch(publishDocumentUnsetPassword([data.id]));
        setDisable({ ...disable, unset_password: true });
      }
      if (!stateObj.password_enabled) {
        stateObj.toggle_set_password = e.target.checked;
        if (!e.target.checked) {
          stateObj.password.value = "";
          stateObj.password.touched = false;
          stateObj.password.valid = false;
          stateObj.password.error = "";
        }
      }
      setState(stateObj);
    }

    if (field === "password") {
      let password = e?.target?.value ?? value;
      if (type === "change") {
        stateObj.password.value = password;
        stateObj.password.valid =
          password.length >= stateObj.password.validation.min_length &&
          password.length <= stateObj.password.validation.max_length;
        if (stateObj.password.valid) stateObj.password.error = "";
        if (!stateObj.password.valid && stateObj.password.touched)
          updateState({ field: "password", type: "blur", value: password });
      }
      if (type === "blur") {
        stateObj.password.touched = true;
        if (password.length === 0) stateObj.password.error = "Please enter a password";
        if (password.length > 0 && password.length < stateObj.password.validation.min_length)
          stateObj.password.error = `Password must be minimum ${stateObj.password.validation.min_length} characters`;
      }
      setState(stateObj);
    }

    if (field === "set_password") {
      if (stateObj.password.valid) {
        e.preventDefault();
        setIsMounted(true);
        let payload = {
          documentId: data.id,
          password: stateObj.password.value,
        };
        dispatch(publishDocumentSetPassword(payload));
        setDisable({ ...disable, set_password: true });
      }
    }

    if (field === "tab" && stateObj.tab !== value) {
      stateObj.tab = value;
      setState(stateObj);
    }

    if (field === "toggle_badge_enable") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        let docPagesMeta =
          contextData && Object.keys(contextData).length > 0
            ? { ...contextData }
            : { ...documentDetails?.data?.version?.rawdata?.contexts };
        showUpgrade("publishBadge", {
          docPagesMeta:
            docPagesMeta && Object.keys(docPagesMeta).length > 0
              ? docPagesMeta
              : { thumb: documentDetails?.data?.thumb },
        });
        if (_helpers?.modal?.toggle) _helpers.modal.toggle();
        return;
      }

      setIsMounted(true);
      dispatch(publishDocumentHandleBadge({ documentId: data.id, hideBadge: e.target.checked }));
      setDisable({ ...disable, set_badge_enable: e.target.checked, unset_badge_enable: !e.target.checked });
      e.target.checked = stateObj.dochipo_badge_enabled;
    }

    if (field === "toggle_set_email_verification") {
      if (paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN && !paidCompanyInfo?.isPaid) {
        // Call showUpgrade function to show contextual upgrade modal based on your condition
        showUpgrade("publishEmailVerification");
        if (_helpers?.modal?.toggle) _helpers.modal.toggle();
        return;
      }
      if (stateObj.email_verification_enabled) {
        e.target.checked = stateObj.toggle_email_verification;
        setIsEmailMounted(true);
        dispatch(publishDocumentEmailVerification({ documentId: data.id, verification: false }));
        setDisableEmail({ ...disableEmail, unset_email_verification: true, set_email_verification: false });
      }
      if (!stateObj.email_verification_enabled) {
        e.target.checked = stateObj.toggle_email_verification;
        setIsEmailMounted(true);
        let payload = { key: "Any Email" };
        dispatch(publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true }));
        setDisableEmail({ ...disableEmail, set_email_verification: true, unset_email_verification: false });
      }
      setState(stateObj);
    }

    if (field === "set_email_verification") {
      if (stateObj.email_verification.valid) {
        e.preventDefault();
        setIsEmailMounted(true);
        let payload = {
          key: stateObj.email_verification.verification_type_dropdown.selected.value,
          value: stateObj.email_verification.value,
        };
        dispatch(publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true }));
        setDisableEmail({ ...disableEmail, set_email_verification: true, unset_email_verification: false });
      }
    }

    if (field === "verify_email") {
      let emails = e?.target?.value ?? value;
      setEmailInput(emails);
      const emailArray = emails.split(",").map(email => email.trim());
      stateObj.email_verification.value = emailArray;

      // to validate email
      const validateEmail = email => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return emailPattern.test(email);
      };

      // to validate email domain
      const validateEmailDomain = email => {
        const domainPattern = /^[a-zA-Z][a-zA-Z0-9-]*(\.[a-zA-Z]{2,})+$/;
        return domainPattern.test(email);
      };

      let allValid;

      if (stateObj.email_verification.verification_type_dropdown.selected.value === "Specific Email") {
        // Validate all emails with specific email validation
        allValid = emailArray.every(email => validateEmail(email));
      } else if (stateObj.email_verification.verification_type_dropdown.selected.value === "Email Domain") {
        // Validate all emails' domains
        allValid = emailArray.every(email => validateEmailDomain(email));
      }

      //Check if both list are equal
      const areArraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        return arr1.sort().every((value, index) => value === arr2.sort()[index]);
      };
      // Check for duplicates within the inputArray itself
      const hasDuplicatesInInput = new Set(emailArray).size !== emailArray.length;
      const storedEmailArray = isEmailVerificationData?.value || []; // Existing stored emails
      //Check if duplicate or equal
      const isDuplicateOrEqual = areArraysEqual(emailArray, storedEmailArray) || hasDuplicatesInInput;
      // Make the validity true or false
      if (allValid && !isDuplicateOrEqual) {
        stateObj.email_verification.valid = true;
      } else {
        stateObj.email_verification.valid = false;
      }
      setState(stateObj);
    }

    if (field === "email_type_dropdown") {
      stateObj.email_verification.verification_type_dropdown.selected = { name: value, value: value };
      setState(stateObj);
      if (value === "Any Email" && isEmailVerificationData?.key !== value) {
        setIsEmailMounted(true);
        let payload = { key: "Any Email" };
        dispatch(publishDocumentEmailVerification({ payLoad: payload, documentId: data.id, verification: true }));
        setDisableEmail({ ...disableEmail, set_email_verification: true });
      }
    }
  };

  const postApiCallActions = () => {
    let stateObj = { ...state };
    if (type === DOCUMENT_PUBLISH_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { publish_id: publish_id });
      document.getElementById("document-publish-toggle").checked = true;
      stateObj.published = true;
      stateObj.publish_id = publish_id;
      stateObj.toggle_publish = true;
      setState(stateObj);
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT, data, publish_id: publish_id };
        webSocket.send(JSON.stringify(subscribe));
      }
    }

    if (type === DOCUMENT_PUBLISH_HANDLE_DOWNLOAD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { download: disable.set_download });
      document.getElementById("document-publish-link-toggle-allow-download").checked = disable.set_download;
      setDisable({ ...disable, set_download: false, unset_download: false });

      stateObj.download_enabled = disable.set_download;
      setState(stateObj);
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_DOWNLOAD, data, download: disable.set_download };
        webSocket.send(JSON.stringify(subscribe));
      }
    }

    if (type === DOCUMENT_PUBLISH_SET_PASSWORD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { password: true });
      document.getElementById("document-publish-password").value = "";
      stateObj.password_enabled = true;
      stateObj.toggle_set_password = true;
      stateObj.password.value = "";
      stateObj.password.placeholder = "******";
      stateObj.password.touched = false;
      stateObj.password.valid = false;
      stateObj.password.error = "";
      stateObj.set_password_button_label = "Change";
      setState(stateObj);
      setIsPasswordSuccessResponse("Your password has been set");
      setTimeout(() => {
        setIsPasswordSuccessResponse("");
      }, 3000);
      setDisable({ ...disable, set_password: false });
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_PASSWORD, data, password: true };
        webSocket.send(JSON.stringify(subscribe));
      }
    }
    if (type === DOCUMENT_PUBLISH_UNSET_PASSWORD_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { password: null });
      document.getElementById("document-publish-link-toggle-setpassword").checked = false;
      stateObj.password_enabled = false;
      stateObj.toggle_set_password = false;
      stateObj.password.value = "";
      stateObj.password.placeholder = `Minimum ${stateObj.password.validation.min_length} characters`;
      stateObj.password.touched = false;
      stateObj.password.valid = false;
      stateObj.password.error = "";
      stateObj.set_password_button_label = "Save";
      setState(stateObj);
      setDisable({ ...disable, unset_password: false });
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: PUBLISH_DOCUMENT_PASSWORD, data, password: null };
        webSocket.send(JSON.stringify(subscribe));
      }
    }
    if (type === DOCUMENT_UNPUBLISH_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { publish_id: null });
      documentInfo.set(data, { password: null });
      documentInfo.set(data, { download: null });
      documentInfo.set(data, { publish_email_verification: null });
      document.getElementById("document-publish-toggle").checked = false;
      initialState.published = false;
      initialState.password_enabled = false;
      initialState.publish_id = "";
      initialState.toggle_publish = false;
      initialState.toggle_set_password = false;
      initialState.password.placeholder = `Minimum ${stateObj.password.validation.min_length} characters`;
      initialState.set_password_button_label = "Save";
      initialState.download_enabled = false;
      initialState.email_verification_enabled = false;
      initialState.email_verification.value = "";
      initialState.email_verification.displayValue = "";
      initialState.email_verification.placeholder = "neel@gmail.com, sam@gmail.com";
      initialState.email_verification.verification_type_dropdown.selected.name = "Any Email";
      initialState.email_verification.verification_type_dropdown.selected.value = "Any Email";
      setEmailInput("");
      setState(initialState);
      setDisable({ ...disable, unpublish: false });
      // check is web socket is in ready state
      if (webSocket?.readyState === 1) {
        const subscribe = { type: UNPUBLISH_DOCUMENT, data, publish_id: null, password: null, download: null };
        webSocket.send(JSON.stringify(subscribe));
      }
    }
    if (type === DOCUMENT_PUBLISH_HANDLE_BADGE_SUCCESS) {
      setIsMounted(false);
      documentInfo.set(data, { hide_badge: disable.set_badge_enable });
      document.getElementById("document-toggle-dochipo-badge").checked = disable.set_badge_enable;
      setDisable({ ...disable, set_badge_enable: false, unset_badge_enable: false });

      stateObj.dochipo_badge_enabled = disable.set_badge_enable;
      setState(stateObj);
      // To Update in company setting
      dispatch(getCompanyInfo(companyId));
    }
    if (errorTypes.includes(type)) {
      setIsMounted(false);
      setIsEmailMounted(false);
      setAlert({ ...alert, show: true, type: "error" });
    }
  };

  const postEmailApiCallaction = () => {
    let stateObj = { ...state };
    if (type === DOCUMENT_PUBLISH_EMAIL_VERIFICATION_SUCCESS) {
      setIsEmailMounted(false);

      if (disableEmail.unset_email_verification) {
        documentInfo.set(data, {
          email_verification: null,
        });
        document.getElementById("document-publish-email-verfication").checked = false;
        stateObj.email_verification_enabled = false;
        stateObj.toggle_email_verification = false;
        stateObj.email_verification.placeholder = "neel@gmail.com, sam@gmail.com";
        stateObj.email_verification.value = "";
        stateObj.email_verification.verification_type_dropdown.selected.name = "Any Email";
        stateObj.email_verification.verification_type_dropdown.selected.value = "Any Email";
        setEmailInput("");
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      } else if (
        disableEmail.set_email_verification &&
        stateObj.email_verification.verification_type_dropdown.selected.value == "Any Email"
      ) {
        documentInfo.set(data, {
          email_verification: {
            key: stateObj.email_verification.verification_type_dropdown.selected.value,
          },
        });
        document.getElementById("document-publish-email-verfication").checked = true;
        stateObj.email_verification_enabled = true;
        stateObj.toggle_email_verification = true;
        stateObj.email_verification.placeholder = "neel@gmail.com, sam@gmail.com";
        stateObj.email_verification.value = "";
        setEmailInput("");
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      } else {
        documentInfo.set(data, {
          email_verification: {
            key: stateObj.email_verification.verification_type_dropdown.selected.value,
            value: stateObj.email_verification.value,
          },
        });
        stateObj.email_verification_enabled = true;
        stateObj.toggle_email_verification = true;
        stateObj.email_verification.valid = false;
        setDisableEmail({ ...disableEmail, set_email_verification: false, unset_email_verification: false });
      }
      setState(stateObj);
    }
  };

  useEffect(() => {
    if (isMounted && type) postApiCallActions();
  }, [type, isMounted]);

  useEffect(() => {
    if (isEmailMounted && type) postEmailApiCallaction();
  }, [type, isEmailMounted]);

  useEffect(() => {
    if (state.published && userInfo && schemeType === "General") generatePublishCode();
    if (state.published && userInfo && schemeType === "Other Settings") {
      const publishUrl = `${process.env.BASE_URL}/pub/${userInfo?.company?.id}/documents/${state.publish_id}`;
      document.getElementById(publishFieldIds.link).value = publishUrl;
    }
  }, [state.published, userInfo, schemeType]);

  useEffect(() => {
    if (userInfo) setIsUserDocumentOwner(data?.permission === "OWNER");
  }, [userInfo]);

  useEffect(() => {
    if (!documentDetails && documentDetails?.data?.id !== data.id) dispatch(fetchDocumentDetails(data.id));
  }, [data.id]);

  useEffect(() => {
    if (state.email_verification.displayValue) {
      const inputVal = convertArrayToInputFormat(state.email_verification.displayValue);
      setEmailInput(inputVal);
    }
  }, [state.email_verification.displayValue]);

  useEffect(() => {
    if (!userInfo) dispatch(getUserInfo());
  }, []);

  return (
    <React.Fragment>
      <h4 className={cx(style["fw-7"], style["mb-4"])}>Publish to the Web</h4>
      <Link to="#" className={cx(style["cross-modal"], style["rounded"])} onClick={_helpers?.modal?.toggle}>
        <Icon icon="ui-close" />
      </Link>

      {state.published && (
        <>
          <h6 className={style["fw-7"]}>Share Link</h6>
          <div className={style["copy-url"]}>
            <Input
              cssModule={style}
              returnType="noGroup"
              type="text"
              className={cx(style["publish-link"])}
              id={publishFieldIds.link}
              readOnly
            />
            <div className={style["right"]}>
              {!linkCopied && (
                <Button color="primary" onClick={() => copyToClipBoard(publishFieldIds.link)}>
                  Copy
                </Button>
              )}
              {linkCopied && <Button color="primary">Copied</Button>}
            </div>
          </div>
        </>
      )}

      {!state.published && (
        <p className={style["mb-0"]}>Publish to the web to allow your viewers to access this document.</p>
      )}

      <div className={cx(style["publishTool"], { [style["inactivePublishUser"]]: !isUserDocumentOwner })}>
        <div className={cx(style["d-flex"], style["align-items-center"], { [style["mb-n4"]]: !state.published })}>
          <div className={cx(style["custom-control"], style["custom-switch"], style["my-4"])}>
            <Input
              cssModule={style}
              returnType="noGroup"
              type="checkbox"
              className={style["custom-control-input"]}
              id="document-publish-toggle"
              label="Publish"
              defaultChecked={state.published}
              onChange={e => updateState({ field: "toggle_publish", e: e })}
              disabled={!isUserDocumentOwner || disable.unpublish || disable.set_password || disable.unset_password}
            />
          </div>

          {!isUserDocumentOwner && (
            <div className={cx(style["ml-2"], style["info-link"])}>
              <span className={style["color-33"]}>
                <Icon icon="information" />
              </span>
              <div className={cx(style["info-link-content"], style["rounded"], style["shadow-sm"])}>
                Only the Document Owner can make changes to the Publish settings.
              </div>
            </div>
          )}

          {isUserDocumentOwner && state.published && (
            <div className={cx(style["custom-tooltip"], style["ml-2"])}>
              <Icon icon="ui-info" additionalclass="font-14" />
              <div className={cx(style["custom-tooltip-content"], style["text-center"], style["bottom"])}>
                If you turn this off, the page <br></br> will be unpublished
              </div>
            </div>
          )}

          <ProcessLoader
            isOpen={
              (disable.publish &&
                !processEndTypes.publish.includes(type) &&
                !state.published &&
                state.toggle_publish) ||
              (disable.unpublish && !processEndTypes.unpublish.includes(type))
            }
          />
        </div>

        {state.published && (
          <>
            <TabSchemeSelector
              schemeTypeArray={publishSchemes}
              setSchemeType={setSchemeType}
              schemeType={schemeType}
              activeTabIndex={activeTabIndex}
            />
            <TabContent>
              {schemeType === "General" && (
                <TabPane tabId="general_setting" className="d-block">
                  <div className={style["publish-details"]}>
                    <div className={cx(style["d-flex"], style["align-items-center"], style["mb-3"])}>
                      <div className={cx(style["custom-control"], style["custom-switch"])}>
                        <Input
                          cssModule={style}
                          returnType="noGroup"
                          type="checkbox"
                          className={style["custom-control-input"]}
                          id="document-publish-link-toggle-allow-download"
                          label="Allow Viewers to Download"
                          defaultChecked={state.download_enabled}
                          onChange={e => updateState({ field: "toggle_allow_download", e: e })}
                          disabled={!isUserDocumentOwner}
                        />
                      </div>
                      <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                        <Icon icon="ui-info" additionalclass="font-14" />
                        <div className={cx(style["custom-tooltip-content"], style["text-center"], style["bottom"])}>
                          Allow viewers to <br></br> download in PDF format
                        </div>
                      </div>
                      <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                      <ProcessLoader
                        isOpen={
                          (disable.set_download && !processEndTypes.download.includes(type)) ||
                          (disable.unset_download && !processEndTypes.download.includes(type))
                        }
                        wrapperClass="ml-2"
                      />
                    </div>

                    <div className={cx(style["d-flex"], style["align-items-center"], style["mb-4"])}>
                      <div className={cx(style["custom-control"], style["custom-switch"])}>
                        <Input
                          cssModule={style}
                          returnType="noGroup"
                          type="checkbox"
                          className={style["custom-control-input"]}
                          id="document-publish-link-toggle-setpassword"
                          label="Set Password"
                          defaultChecked={state.password_enabled}
                          onChange={e => updateState({ field: "toggle_set_password", e: e })}
                          disabled={!isUserDocumentOwner || disable.set_password || isPasswordSuccessResponse !== ""}
                        />
                      </div>
                      <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                        <Icon icon="ui-info" additionalclass="font-14" />
                        <div className={cx(style["custom-tooltip-content"], style["text-center"])}>
                          Share document privately <br></br> with a password
                        </div>
                      </div>
                      <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                      <ProcessLoader
                        isOpen={
                          (disable.set_password && !processEndTypes.set_password.includes(type)) ||
                          (disable.unset_password && !processEndTypes.unset_password.includes(type))
                        }
                        wrapperClass="ml-2"
                      />
                    </div>

                    {state.toggle_set_password && (
                      <div className={cx(style["password-generation"])}>
                        <Form
                          className={style["form-inline"]}
                          onKeyDown={e => preventSubmit(e)}
                          onSubmit={e => updateState({ field: "set_password", e: e })}>
                          <Input
                            cssModule={style}
                            returnType="formGroup"
                            type="password_length"
                            limit={state.password.validation.max_length}
                            length={state.password.value.length}
                            showerror={state.password.error}
                            showsuccess={isPasswordSuccessResponse}
                            id="document-publish-password"
                            placeholder={state.password.placeholder}
                            maxLength={state.password.validation.max_length}
                            onChange={e => updateState({ field: "password", type: "change", e: e })}
                            onBlur={e => updateState({ field: "password", type: "blur", e: e })}
                            disabled={!isUserDocumentOwner || disable.set_password || isPasswordSuccessResponse !== ""}
                          />
                          <Button
                            type="submit"
                            color="primary"
                            cssModule={style}
                            className={style["ml-auto"]}
                            disabled={
                              !isUserDocumentOwner ||
                              !state.password.valid ||
                              disable.set_password ||
                              disable.unset_password
                            }>
                            {state.set_password_button_label}
                          </Button>
                        </Form>
                      </div>
                    )}

                    <div className={cx(style["social-embed"], { [style["d-none"]]: state.toggle_set_password })}>
                      <Nav className={cx(style["nav-pills"], style["mb-3"])}>
                        <NavItem className={style["mr-2"]}>
                          <NavLink
                            className={cx({ [style["active"]]: state.tab === "social_media" })}
                            onClick={() => updateState({ field: "tab", value: "social_media" })}>
                            <img src={thumbsUpSvg} alt="Social Media" /> Social Media
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={cx({ [style["active"]]: state.tab === "embed" })}
                            onClick={() => updateState({ field: "tab", value: "embed" })}>
                            <img src={sourceCodeSvg} alt="Embed" /> Embed
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={state.tab}>
                        <TabPane tabId="social_media">
                          <Row>
                            <Col sm="12">
                              <ul className={style["social-publish"]}>
                                {socialShareOptions.map(option => (
                                  <li key={`document_publish_${option.name}`}>
                                    <span
                                      className={cx(
                                        style[option.className],
                                        style["sharing-button"],
                                        style["custom-tooltip"]
                                      )}
                                      onClick={() => socialShareOpener(option.name)}>
                                      <Icon icon={option.iconClassName} />
                                      <div
                                        className={cx(
                                          style["custom-tooltip-content"],
                                          style["text-center"],
                                          style["bottom"],
                                          style["mt-4"]
                                        )}>
                                        {option.tooltip}
                                      </div>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="embed">
                          <Row>
                            <Col sm="12">
                              <div className={style["copy-url"]}>
                                <Input
                                  cssModule={style}
                                  returnType="noGroup"
                                  type="textarea"
                                  id={publishFieldIds.embed}
                                  readOnly
                                />
                                <p className={cx(style["clearfix"], style["my-2"])}>
                                  {!embedCopied && (
                                    <Link to="#" onClick={() => copyToClipBoard(publishFieldIds.embed)}>
                                      <Icon icon="ui-clone2" /> Copy Code
                                    </Link>
                                  )}
                                  {embedCopied && <span>Copied to Clipboard</span>}
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </TabPane>
              )}
              {schemeType === "Other Settings" && (
                <TabPane tabId="other_setting" className="d-block">
                  <div>
                    <div
                      className={cx(
                        style["d-flex"],
                        style["align-items-center"],
                        style["mb-3"],
                        style[
                          !(paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN)
                            ? "inactiveCompanyBadge"
                            : "activeCompanyBadge"
                        ]
                      )}>
                      <div className={cx(style["custom-control"], style["custom-switch"])}>
                        <Input
                          cssModule={style}
                          returnType="noGroup"
                          type="checkbox"
                          className={style["custom-control-input"]}
                          id="document-toggle-dochipo-badge"
                          label="Hide DocHipo Badge"
                          defaultChecked={state.dochipo_badge_enabled}
                          onChange={e => updateState({ field: "toggle_badge_enable", e: e })}
                          disabled={!(paidCompanyInfo?.companyRole === COMPANY_SUPERADMIN)}
                        />
                      </div>
                      <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                        <Icon icon="ui-info" additionalclass="font-14" />
                        <div className={cx(style["custom-tooltip-content"], style["text-center"], style["mt-n2"])}>
                          Hide DocHipo badge from the <br></br> page footer of all published pages. <br></br> Only Admin
                          can manage this
                        </div>
                      </div>
                      <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />
                      <ProcessLoader
                        isOpen={
                          (disable.set_badge_enable && !processEndTypes.badge.includes(type)) ||
                          (disable.unset_badge_enable && !processEndTypes.badge.includes(type))
                        }
                        wrapperClass="ml-2"
                      />
                    </div>

                    <div
                      className={cx(style["d-flex"], style["align-items-center"], {
                        [style["mb-4"]]:
                          state.email_verification.verification_type_dropdown.selected.value !== "Any Email",
                      })}>
                      <div className={cx(style["custom-control"], style["custom-switch"])}>
                        <Input
                          cssModule={style}
                          returnType="noGroup"
                          type="checkbox"
                          className={style["custom-control-input"]}
                          id="document-publish-email-verfication"
                          label="Email Verification"
                          defaultChecked={state.email_verification_enabled}
                          onChange={e => updateState({ field: "toggle_set_email_verification", e: e })}
                          disabled={!isUserDocumentOwner}
                        />
                      </div>
                      <div className={cx(style["custom-tooltip"], style["ml-2"], style["d-flex"])}>
                        <Icon icon="ui-info" additionalclass="font-14" />
                        <div className={cx(style["custom-tooltip-content"], style["text-center"])}>
                          Requires email address to <br></br> access the document
                        </div>
                      </div>
                      <img alt="premium" width={20} src={Crown} className={cx(style["ml-2"])} />

                      {state.email_verification_enabled && (
                        <div
                          className={cx(style["ml-3"], style["email-dropdown"], {
                            [style["disable"]]: !isUserDocumentOwner,
                          })}>
                          <UncontrolledDropdown className={cx(style["w-100"])}>
                            <DropdownToggle caret tag="a" className={style["rounded"]}>
                              <span>{state.email_verification.verification_type_dropdown.selected.value}</span>
                              <Icon icon="ui-arrow-down" additionalclass={"ml-1"} />
                            </DropdownToggle>
                            <DropdownMenu className={cx(style["shadow"], style["border-0"], style["rounded"])}>
                              {Object.keys(verificationTypes).map((p, i) => (
                                <DropdownItem
                                  tag="a"
                                  key={i}
                                  className={cx({
                                    [style["active-item"]]:
                                      verificationTypes[p] ===
                                      state.email_verification.verification_type_dropdown.selected.value,
                                  })}
                                  onClick={() => handleVerificationTypeClick(p)}>
                                  {verificationTypes[p]}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      )}

                      <ProcessLoader
                        isOpen={
                          (disableEmail.set_email_verification && !processEndTypes.email_verification.includes(type)) ||
                          (disableEmail.unset_email_verification && !processEndTypes.email_verification.includes(type))
                        }
                        wrapperClass="ml-2"
                      />
                    </div>
                    {state.email_verification_enabled &&
                      state.email_verification.verification_type_dropdown.selected.value !== "Any Email" && (
                        <div className={style["multiple-email"]}>
                          <Form
                            onKeyDown={e => preventSubmit(e)}
                            onSubmit={e => updateState({ field: "set_email_verification", e: e })}>
                            <Input
                              className={{ [style["disable"]]: !isUserDocumentOwner }}
                              cssModule={style}
                              returnType="formGroup"
                              type="text"
                              id="document-publish-email-verify"
                              placeholder={
                                !emailInput &&
                                state.email_verification.verification_type_dropdown.selected.value !== "Email Domain"
                                  ? state.email_verification.placeholder
                                  : state.email_verification.email_domain_placeholder
                              }
                              onChange={e => updateState({ field: "verify_email", e: e })}
                              value={emailInput}
                            />
                            <div className={style["right"]}>
                              <Button
                                type="submit"
                                color="primary"
                                cssModule={style}
                                disabled={
                                  !state.email_verification.valid ||
                                  disableEmail.set_email_verification ||
                                  disableEmail.unset_email_verification ||
                                  !emailInput
                                }>
                                Save
                              </Button>
                            </div>
                          </Form>
                        </div>
                      )}
                  </div>
                </TabPane>
              )}
            </TabContent>
          </>
        )}

        <FlashAlert isOpen={alert.show} toggle={toggleAlert} type={alert.type} wrapperClass="mt-3" />
      </div>
    </React.Fragment>
  );
};

PublishModal.propTypes = {
  document: PropTypes.object.isRequired,
  _helpers: PropTypes.object.isRequired,
};

export default PublishModal;
