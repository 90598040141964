import { useContext } from "react";
import {
  getCssTransformObj,
  getPercentToPixel,
  getUnScaledValue,
  getWidgetAndParentsDomReference,
  getZoomedValue,
  normalizeAngle,
  rotatePoint,
} from "../_helpers/utils";
import { EditorContext } from "../containers/editor/EditorLayout";
import {
  GENERIC_EDITOR_WRAP_PADDING,
  TEXT,
  TYPE_INFOGRAPHIC,
  TYPE_PROPOSAL,
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
} from "../constants/editor";
import useWidgetHandler from "./useWidgetHandler";
import useSelectable from "./useSelectable";

const useFind = ({ state }) => {
  const {
    metadata,
    updateMetadata,
    documentType,
    dimension,
    showFindAndReplace,
    setShowFindAndReplace,
    retainRotation,
    setRetainRotation,
  } = useContext(EditorContext);
  const { hideAllWidgetSelection } = useSelectable();
  const { show: showNewHandler } = useWidgetHandler();
  const findHelper = {
    highlighter: document.getElementById("dhp-find-highlighter"),
    customSelectionRange: document.createRange(),
    collectWidgetsFromDOM: () => {
      if (!state.findKey) return;
      const domWidgets = document.querySelectorAll('[data-widget="TEXT"], [data-widget="TABLE"]');
      const widgetMatches = [];
      const widgetMatchesById = [];
      let lockedInPages = [];
      let totalCount = 0;

      domWidgets.forEach(widget => {
        const widgetId = widget?.id; // Assuming each widget has an ID
        if (widgetId && !widgetId.includes("cloned") && !findHelper.checkWidgetGroupHierarchy(widget)) {
          const matchesInCurrentWidget = findHelper.findMatchingNodesRefFromDOM(widget);
          if (matchesInCurrentWidget.length > 0) {
            if (matchesInCurrentWidget[0].widgetMeta.isLocked)
              lockedInPages.push(matchesInCurrentWidget[0].widgetMeta.activePageIdx + 1);
            widgetMatchesById.push({
              id: widgetId,
              matches: matchesInCurrentWidget,
              isLocked: matchesInCurrentWidget[0]?.widgetMeta?.isLocked,
            });
            widgetMatches.push(...matchesInCurrentWidget);
            totalCount += matchesInCurrentWidget.length;
          }
        }
      });

      // Sort widgetMatches based on the order of widgets on DOM
      widgetMatches.sort((a, b) => {
        const aRect = a.widgetMeta.widgetRect;
        const bRect = b.widgetMeta.widgetRect;
        return aRect.top - bRect.top;
      });

      return { widgetMatches, totalCount, widgetMatchesById, lockedInPages: [...new Set(lockedInPages)] };
    },
    checkWidgetGroupHierarchy: widget => {
      let isInsideGroup = false;
      if (widget) {
        let parentWidget = widget.closest(".dhp-page-group.dhp-root-widget");
        if (parentWidget) {
          let groupWidgets = parentWidget.querySelectorAll(".dhp-page-group");
          if (groupWidgets.length > 0) isInsideGroup = true;
        }
      }
      return isInsideGroup;
    },
    getTransformText: (text, transformation) => {
      let textTransformed = "";

      switch (transformation) {
        case "uppercase":
          textTransformed = text.toUpperCase();
          break;
        case "lowercase":
          textTransformed = text.toLowerCase();
          break;
        case "capitalize":
          textTransformed = text.replace(/\b\w/g, char => char.toUpperCase());
          break;
        default:
          textTransformed = text;
          break;
      }

      return textTransformed;
    },
    findMatchingNodesRefFromDOM: (widget, cloned) => {
      let allMatches = [];
      let widgetText = widget.textContent;
      const regex = new RegExp(
        state.findKey.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
        state.caseInsensitive ? "g" : "gi"
      );
      let match;
      let idX = 0;
      let widgetStyle,
        widgetRect,
        widgetNode,
        isChildWidget,
        widgetData,
        isLocked,
        parentId,
        parentNode,
        activeBlockId,
        activeBlockIdx,
        activeBlockTop,
        blockNode,
        activePageId,
        activePageIdx,
        editorOuterWrapTop;

      if (!cloned) {
        ({
          widget: {
            css: widgetStyle,
            rect: widgetRect,
            node: widgetNode,
            isChildWidget,
            data: widgetData,
            isLocked,
            parent: { id: parentId, node: parentNode },
          },
          block: {
            id: activeBlockId,
            idx: activeBlockIdx,
            rect: { top: activeBlockTop = 0 },
            node: blockNode,
          },
          page: { id: activePageId, idx: activePageIdx },
          editorOuterWrapTop,
        } = getWidgetAndParentsDomReference(widget.id));

        isLocked = !isChildWidget
          ? isLocked
          : widget?.closest(".dhp-page-group.dhp-root-widget").getAttribute("data-layer-locked") === "true"
          ? true
          : false;

        if (widgetData.assetType === TEXT) {
          let textTransform = widget.getElementsByClassName("dhp-widget-inner")[0].style.textTransform;
          widgetText = findHelper.getTransformText(widgetText, textTransform).replace(/\u00a0/g, " "); // replace &nbsp; by space
        }
      }
      while ((match = regex.exec(widgetText)) !== null) {
        const startIndex = match.index;
        const endIndex = startIndex + match[0].length;
        const { startNode, startOffset, endNode, endOffset } = findHelper.findTextNodesForRange(
          widget,
          startIndex,
          endIndex
        );
        if (startNode && endNode) {
          allMatches.push({
            startNode,
            startOffset,
            endNode,
            endOffset,
            widgetId: cloned ? undefined : widget.id,
            widgetMeta: cloned
              ? undefined
              : {
                  widgetStyle,
                  widgetRect,
                  widgetNode,
                  isChildWidget,
                  widgetData,
                  isLocked,
                  parentId,
                  parentNode,
                  activeBlockId,
                  activeBlockIdx,
                  activeBlockTop,
                  blockNode,
                  activePageId,
                  activePageIdx,
                  editorOuterWrapTop,
                },
            idX: idX++,
          });
        }
      }
      return allMatches;
    },
    findTextNodesForRange: (widget, startIndex, endIndex) => {
      const textNodes = findHelper.collectTextNodes(widget);
      let startNode, endNode;
      let charCount = 0;
      let startOffset, endOffset;

      for (const node of textNodes) {
        const nodeTextLength = node.textContent.length;
        if (startIndex >= charCount && startIndex < charCount + nodeTextLength) {
          startNode = node;
          startOffset = startIndex - charCount; // Calculate start offset within the current node
        }
        if (endIndex >= charCount && endIndex <= charCount + nodeTextLength) {
          endNode = node;
          endOffset = endIndex - charCount; // Calculate end offset within the current node
          break; // No need to continue searching after finding the end node
        }
        charCount += nodeTextLength;
      }

      return { startNode, startOffset, endNode, endOffset };
    },
    collectTextNodes: (element, textNodes = []) => {
      if (element.nodeType === Node.TEXT_NODE) {
        textNodes.push(element);
      } else {
        if (element.childNodes.length > 0) {
          for (let i = 0; i < element.childNodes.length; i++) {
            findHelper.collectTextNodes(element.childNodes[i], textNodes);
          }
        }
      }
      return textNodes;
    },
    highlightMatchingWidget: (activeIndex, allMatches, updateMeta = true) => {
      if (!state.findKey) return;
      const wz = allMatches ? allMatches[activeIndex] : state.allMatches[activeIndex];

      if (wz && wz.widgetId) {
        if (updateMeta) {
          updateMetadata({
            ...metadata,
            disableAutoScroll: false,
            activeWidgetId: [wz.widgetId],
            activeWidgetType: [wz.widgetMeta.widgetData.assetType],
            activeBlockId: wz.widgetMeta.activeBlockId,
            activePageId: wz.widgetMeta.activePageId,
            activeBlockIdx: wz.widgetMeta.activeBlockIdx,
            activePageIdx: wz.widgetMeta.activePageIdx,
            pageController: {
              ...metadata.pageController,
              style: {
                ...metadata.pageController.style,
                top:
                  wz.widgetMeta.activeBlockIdx === 0
                    ? 0
                    : [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
                    ? getZoomedValue(document.getElementById(wz.widgetMeta.activeBlockId).offsetTop, dimension.zoom) +
                      "px"
                    : document.getElementById(wz.widgetMeta.activePageId).offsetTop + "px",
              },
            },
          });
          if (metadata.activeWidgetId.length >= 2) {
            hideAllWidgetSelection({ type: "all" });
            showNewHandler({ activeWidgetId: wz.widgetMeta.isChildWidget ? wz.widgetMeta.parentId : wz.widgetId });
          }
        }
        setShowFindAndReplace({
          ...showFindAndReplace,
          curState: { widgetRef: wz, findKey: state.findKey, caseInsensitive: state.caseInsensitive },
        });
        findHelper.highlightCurrentMatch(wz);
      }
    },
    applyChildSelection: (wzId, parentNode) => {
      let curActiveChild = document.getElementById(wzId);
      parentNode.querySelectorAll(".dhp-page-widget").forEach(wz => {
        wz.classList.remove("child-selected");
      });
      curActiveChild.classList.add("child-selected");
    },
    generatePathDataFromRange: (range, widgetRect) => {
      const pathDataParts = [];
      // Iterate over the range's client rects (each represents a line of text)
      for (let i = 0; i < range.getClientRects().length; i++) {
        const rect = range.getClientRects()[i];

        // Calculate coordinates of the rotated rectangle
        const x = rect.left - widgetRect.left;
        const y = rect.top - widgetRect.top;
        const width = rect.width;
        const height = rect.height;

        // Generate path data for the rectangle
        const rectPathData = `M${x},${y}L${x},${y + height}L${x + width},${y + height}L${x + width},${y}Z`;
        pathDataParts.push(rectPathData);
      }

      // Combine path data parts into a single path
      const combinedPathData = pathDataParts.join("");

      return { svgPath: combinedPathData, clonedRect: widgetRect };
    },
    highlightCurrentMatch: (wz, zoom) => {
      const { startNode, endNode, startOffset, endOffset, widgetId: activeWidgetId, idX: matchIdx } = wz;
      let {
        widget: {
          css: widgetStyle,
          rect: widgetRect,
          node: widgetNode,
          isChildWidget,
          parent: { node: parentNode },
        },
        block: {
          rect: { top: activeBlockTop = 0 },
          node: blockNode,
        },
        editorOuterWrapTop,
      } = getWidgetAndParentsDomReference(activeWidgetId);

      const cssObj = {
        width: widgetStyle.width,
        height: widgetStyle.height,
        transform: widgetStyle.transform,
      };

      const {
        translate: { x: widgetTransX, y: widgetTransY },
        rotate: { theta: widgetTheta },
      } = getCssTransformObj({
        transform: cssObj.transform,
      });

      const widgetTransformStr = getCssTransformObj({
        translateX: getZoomedValue(widgetTransX, zoom ?? dimension.zoom) + "px",
        translateY: getZoomedValue(widgetTransY, zoom ?? dimension.zoom) + "px",
        transform: cssObj.transform,
        returnStr: true,
      });

      let addedLeft = WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING;
      let addedTop = WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING;
      const activePageTop = Math.abs(activeBlockTop - editorOuterWrapTop);

      let svgPath, clonedRect;
      findHelper.highlighter?.classList.remove("d-none");
      findHelper.highlighter?.setAttribute("data-widget-id", activeWidgetId);

      if (isChildWidget) {
        findHelper.applyChildSelection(activeWidgetId, parentNode);
      }
      const blockRect = blockNode.getBoundingClientRect();
      const parentWidgetRect = isChildWidget ? parentNode.getBoundingClientRect() : {};

      if (!widgetTheta || (parseInt(widgetTheta) === 0 && !isChildWidget)) {
        try {
          findHelper.customSelectionRange.setStart(startNode, startOffset);
          findHelper.customSelectionRange.setEnd(endNode, endOffset);
        } catch {
          return;
        }

        ({ svgPath } = findHelper.generatePathDataFromRange(findHelper.customSelectionRange, widgetRect));

        addedLeft += isChildWidget
          ? parentWidgetRect.left - blockRect.left + (widgetRect.left - parentWidgetRect.left)
          : 0;
        addedTop += isChildWidget ? parentWidgetRect.top - blockRect.top + (widgetRect.top - parentWidgetRect.top) : 0;

        let height = getZoomedValue(cssObj.height, zoom ?? dimension.zoom);
        let width = getZoomedValue(cssObj.width, zoom ?? dimension.zoom);
        let left = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
          ? getZoomedValue(addedLeft, zoom ?? dimension.zoom)
          : addedLeft;
        let top =
          activePageTop +
          ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
            ? getZoomedValue(addedTop, zoom ?? dimension.zoom)
            : addedTop);

        findHelper.applyHeighlighterPositionAndSVG({ height, width, left, top, widgetTransformStr, svgPath });
      } else {
        let pathAndRect = findHelper.generatePathDataFromRangeWithNoRotation(
          matchIdx,
          widgetNode,
          activeWidgetId,
          blockNode,
          isChildWidget,
          parentNode
        );
        if (!pathAndRect) return;
        ({ svgPath, clonedRect } = pathAndRect);
        if (isChildWidget) {
          let widget = document.getElementById(activeWidgetId);
          const {
            translate: { x: groupWidgetTransX, y: groupWidgetTransY },
            rotate: { theta: groupWidgetTheta },
          } = getCssTransformObj({
            transform: parentNode.style.transform,
          });
          const cssObj = {
            width: parseFloat(parentNode.style.width),
            height: parseFloat(parentNode.style.height),
            left: parseFloat(groupWidgetTransX),
            top: parseFloat(groupWidgetTransY),
          };
          const {
            rotate: { theta: widgetTheta },
          } = getCssTransformObj({
            transform: widget.style.transform,
          });
          const theta = normalizeAngle(parseInt(widgetTheta) + parseInt(groupWidgetTheta));
          let childWidgetDia = {
            width: getPercentToPixel({ parent: cssObj.width, child: widget.style.width }),
            height: getPercentToPixel({ parent: cssObj.height, child: widget.style.height }),
          };
          const widgetLeftPx = getPercentToPixel({ parent: cssObj.width, child: widget.style.left });
          const widgetTopPx = getPercentToPixel({ parent: cssObj.height, child: widget.style.top });
          const widgetCenterX = parseFloat(groupWidgetTransX) + widgetLeftPx + childWidgetDia.width / 2;
          const widgetCenterY = parseFloat(groupWidgetTransY) + widgetTopPx + childWidgetDia.height / 2;
          const groupCenterX = parseFloat(groupWidgetTransX) + cssObj.width / 2;
          const groupCenterY = parseFloat(groupWidgetTransY) + cssObj.height / 2;
          const rotatedCenter = rotatePoint(
            widgetCenterX,
            widgetCenterY,
            groupCenterX,
            groupCenterY,
            parseInt(groupWidgetTheta)
          );
          const newLeft = rotatedCenter.x - childWidgetDia.width / 2;
          const newTop =
            getUnScaledValue(activePageTop, zoom ?? dimension.zoom) + rotatedCenter.y - childWidgetDia.height / 2;
          const widgetTransformStrUpgraded = getCssTransformObj({
            translateX: `${getZoomedValue(newLeft, zoom ?? dimension.zoom)}px`,
            translateY: `${getZoomedValue(newTop, zoom ?? dimension.zoom)}px`,
            rotateAngle: `${theta}deg`,
            transform: widget.style.transform,
            returnStr: true,
          });

          let width = clonedRect.width;
          let height = clonedRect.height;
          findHelper.applyHeighlighterPositionAndSVG({
            height,
            width,
            left: "0",
            top: "0",
            widgetTransformStr: widgetTransformStrUpgraded,
            svgPath,
          });
        } else {
          let width = getZoomedValue(cssObj.width, zoom ?? dimension.zoom);
          let height = getZoomedValue(cssObj.height, zoom ?? dimension.zoom);
          let left = [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
            ? getZoomedValue(addedLeft, zoom ?? dimension.zoom)
            : addedLeft;
          let top =
            activePageTop +
            ([TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
              ? getZoomedValue(addedTop, zoom ?? dimension.zoom)
              : addedTop);
          findHelper.applyHeighlighterPositionAndSVG({ height, width, left, top, widgetTransformStr, svgPath });
        }
      }
    },
    applyHeighlighterPositionAndSVG: ({ height, width, left, top, widgetTransformStr, svgPath }) => {
      findHelper.highlighter.style.cssText = `
                width: ${width}px;
                height: ${height}px;
                transform: ${widgetTransformStr};
                left: ${left}px;
                top: ${top}px;
              `;
      findHelper.highlighter.innerHTML = "";
      findHelper.highlighter.innerHTML = `<svg viewBox = "0 0 ${width} ${height}" style = "position: absolute; top: 0px; left: 0px; overflow: visible;"> <path fill="rgb(188, 151, 252)" d="${svgPath}"></path></svg > `;
    },
    generatePathDataFromRangeWithNoRotation: (
      matchIdx,
      widgetNode,
      activeWidgetId,
      blockNode,
      isChildWidget,
      groupWidget
    ) => {
      // Create a clone of the element
      const clone = !isChildWidget ? widgetNode.cloneNode(true) : groupWidget.cloneNode(true);
      clone.style.opacity = "0";
      clone.removeAttribute("id");
      if (!isChildWidget)
        clone.style.transform = getCssTransformObj({
          transform: widgetNode.style.transform,
          exclude: ["rotate"],
          returnStr: true,
        });
      else {
        clone.style.transform = getCssTransformObj({
          transform: groupWidget.style.transform,
          exclude: ["rotate"],
          returnStr: true,
        });
        clone.querySelectorAll(".dhp-page-widget").forEach(child => {
          if (child.getAttribute("id") === activeWidgetId) {
            child.setAttribute("id", `${activeWidgetId}-cloned`);
            child.style.transform = getCssTransformObj({
              transform: child.style.transform,
              exclude: ["rotate"],
              returnStr: true,
            });
          } else child.remove();
        });
      }

      blockNode.appendChild(clone);

      // Get the bounding client rectangle of the clone
      const clientRect = !isChildWidget
        ? clone.getBoundingClientRect()
        : document.getElementById(`${activeWidgetId}-cloned`).getBoundingClientRect();

      let match = findHelper.findMatchingNodesRefFromDOM(clone, true)?.[matchIdx];
      if (!match) return;

      const { startNode, startOffset, endNode, endOffset } = match;
      try {
        findHelper.customSelectionRange.setStart(startNode, startOffset);
        findHelper.customSelectionRange.setEnd(endNode, endOffset);
      } catch {
        return;
      }

      // Remove the clone from the document body
      setTimeout(() => {
        clone.parentNode.removeChild(clone);
      }, 10);

      return findHelper.generatePathDataFromRange(findHelper.customSelectionRange, clientRect);
    },
    removeHeighlighter: () => {
      findHelper.highlighter?.classList?.add("d-none");
    },
  };

  return {
    collectWidgets: findHelper.collectWidgetsFromDOM,
    heiglightMatch: findHelper.highlightMatchingWidget,
    removeHeighlighter: findHelper.removeHeighlighter,
    adjustHeighlighter: findHelper.highlightCurrentMatch,
  };
};

export default useFind;
