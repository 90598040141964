import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { EditorContext } from "../../containers/editor/EditorLayout";
import {
  GENERIC_EDITOR_WRAP_PADDING,
  TYPE_INFOGRAPHIC,
  TYPE_PROPOSAL,
  WIDGET_HANDLER_MARGIN_LEFT,
  WIDGET_HANDLER_MARGIN_TOP,
} from "../../constants/editor";
import global from "../../scss/dhp.scss";
import { getCssTextObj, getCssTransformObj, getZoomedValue } from "../../_helpers/utils";

const style = Object.assign({}, global);

export default function CollaborativeSelector() {
  let { socketState, widgets, dimension, documentType, metadata, isTimeLineViewOpen } = useContext(EditorContext);
  const companyUsers = useSelector(state => state.company?.users);
  const [activeWidgets, setActiveWidgets] = useState([]);

  useEffect(() => {
    if (socketState && companyUsers) {
      let collaborativeUserWidgets = [];
      for (let key in socketState?.activeUsers)
        if (key !== socketState?.currentUser && socketState?.activeUsers[key].activeWidgetId !== undefined)
          collaborativeUserWidgets.push({ userHash: key, ...socketState?.activeUsers[key] });

      let activeWidgetDetails = [];
      let addedLeft = getZoomedValue(WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
      let addedTop = getZoomedValue(WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING, dimension.zoom);
      collaborativeUserWidgets?.forEach(widgetRec => {
        let userInfo = companyUsers.find(rec => rec.id === widgetRec.userId);
        const selectionArea = {
          left: [],
          right: [],
          top: [],
          bottom: [],
        };
        let collaborativeActiveWidgetId = [];
        let isGroupWidget = document.getElementById(widgetRec?.activeWidgetId[0])?.closest(".dhp-page-group");
        let targetId = isGroupWidget
          ? document.getElementById(widgetRec?.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
          : widgetRec?.activeWidgetId[0];
        let widgetInfo = widgets.find(rec => rec.id === targetId);
        // for video editor
        let hideSelection = isTimeLineViewOpen && metadata?.activeBlockId !== widgetInfo?.blockId;
        const blockRect = document.getElementById(widgetInfo?.blockId)?.getBoundingClientRect();
        const { top: editorOuterWrapTop } = document.querySelectorAll(".dhp-page-canvas")[0].getBoundingClientRect();
        let widgetStyle = {};
        if (widgetRec?.activeWidgetId.length > 1 && blockRect && !hideSelection) {
          widgetRec?.activeWidgetId?.forEach(awId => {
            if (document.getElementById(awId)) {
              const { left, right, top, bottom } = document.getElementById(awId).getBoundingClientRect();
              selectionArea.left = [...selectionArea.left, left];
              selectionArea.right = [...selectionArea.right, right];
              selectionArea.top = [...selectionArea.top, top];
              selectionArea.bottom = [...selectionArea.bottom, bottom];
            }
          });
          selectionArea.top = Math.min(...selectionArea.top) - blockRect.top;
          selectionArea.left = Math.min(...selectionArea.left) - blockRect.left;
          selectionArea.right = Math.max(...selectionArea.right) - blockRect.left;
          selectionArea.bottom = Math.max(...selectionArea.bottom) - blockRect.top;
          widgetStyle = {
            transform: `translate(${selectionArea.left}px, ${selectionArea.top}px) scale(1, 1) rotate(0deg)`,
            width: `${selectionArea.right - selectionArea.left}px`,
            height: `${selectionArea.bottom - selectionArea.top}px`,
            top: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
              ? `${Math.abs(blockRect.top - editorOuterWrapTop) + addedTop}px`
              : isTimeLineViewOpen
              ? 0
              : `${
                  Math.abs(blockRect.top - editorOuterWrapTop) + WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING
                }px`,
            left: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
              ? `${addedLeft}px`
              : isTimeLineViewOpen
              ? 0
              : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
          };
          collaborativeActiveWidgetId = widgetRec?.activeWidgetId;
        }
        // sigle widget selection with rotate angle
        if (widgetRec?.activeWidgetId.length === 1 && blockRect && !hideSelection) {
          let isGroupWidget = document.getElementById(widgetRec?.activeWidgetId)?.closest(".dhp-page-group");
          let targetId = isGroupWidget
            ? document.getElementById(widgetRec?.activeWidgetId).closest(".dhp-root-widget").getAttribute("id")
            : widgetRec?.activeWidgetId;
          let widgetDOMStyle = getCssTextObj(document.getElementById(targetId).style.cssText);
          // on play widget animation transform is taking from widget context
          widgetDOMStyle = widgetDOMStyle?.transform
            ? widgetDOMStyle
            : { ...widgetDOMStyle, transform: widgetInfo.style.transform };
          const {
            translate: { x: widgetTransX, y: widgetTransY },
            rotate: { theta: widgetTheta },
          } = getCssTransformObj({
            transform: widgetDOMStyle.transform,
          });

          widgetStyle = {
            transform: `translate(${getZoomedValue(parseFloat(widgetTransX), dimension.zoom)}px, ${getZoomedValue(
              parseFloat(widgetTransY),
              dimension.zoom
            )}px) scale(1, 1) rotate(${widgetTheta})`,
            width: `${getZoomedValue(parseFloat(widgetDOMStyle.width), dimension.zoom)}px`,
            height: `${getZoomedValue(parseFloat(widgetDOMStyle.height), dimension.zoom)}px`,
            top: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
              ? `${Math.abs(blockRect.top - editorOuterWrapTop) + addedTop}px`
              : isTimeLineViewOpen
              ? "0px"
              : `${
                  Math.abs(blockRect.top - editorOuterWrapTop) + WIDGET_HANDLER_MARGIN_TOP + GENERIC_EDITOR_WRAP_PADDING
                }px`,
            left: [TYPE_INFOGRAPHIC, TYPE_PROPOSAL].includes(documentType)
              ? `${addedLeft}px`
              : isTimeLineViewOpen
              ? "0px"
              : `${WIDGET_HANDLER_MARGIN_LEFT + GENERIC_EDITOR_WRAP_PADDING}px`,
          };
          collaborativeActiveWidgetId = [targetId];
        }

        if (collaborativeActiveWidgetId[0]) {
          // check if exist update the count
          let widgetIds = collaborativeActiveWidgetId.join(" ");
          let activeWidgetIndex = activeWidgetDetails?.findIndex(aw => aw?.widgetId === widgetIds);
          if (activeWidgetIndex > -1) {
            activeWidgetDetails[activeWidgetIndex].count = activeWidgetDetails[activeWidgetIndex].count + 1;
          }
          // else add
          else {
            activeWidgetDetails.push({
              email: userInfo?.email,
              firstname: userInfo?.firstname,
              lastname: userInfo?.lastname,
              id: userInfo?.id,
              image: userInfo?.image,
              widgetStyle,
              widgetId: widgetIds,
              color: widgetRec.color,
              count: 0,
            });
          }
        }
      });
      setActiveWidgets(activeWidgetDetails);
    }
  }, [socketState, widgets, isTimeLineViewOpen, metadata.activeBlockId]);

  return activeWidgets?.map((rec, i) => (
    <div
      key={i}
      data-widget-id={rec.widgetId}
      id={`collaborativeSelector-${i}`}
      style={{ ...rec.widgetStyle, outline: `2px solid ${rec.color}` }}
      className={cx(style["collborative-selector"], { [style["d-none"]]: !Object.keys(rec?.widgetStyle).length > 0 })}>
      <div className={cx(style["collborative-user"])} style={{ backgroundColor: rec.color }}>
        <span className={style["text-truncate"]}>{rec.firstname}</span>
        {rec.count > 0 && <span className={cx(style["more"], style["ml-2"])}>+{rec.count}</span>}
      </div>
    </div>
  ));
}
