import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";

import * as constant from "../../constants/editor";
import AddPage from "../../components/Editor/PageBlocks/AddPage";
import ClonePage from "../../components/Editor/PageBlocks/ClonePage";
import PageMoveArrows from "../../components/Editor/PageBlocks/PageMoveArrows";
import PageResize from "../../components/Editor/PageBlocks/PageResize";
import PageGrid from "../../components/Editor/PageBlocks/PageGrid";
import DeletePage from "../../components/Editor/PageBlocks/DeletePage";
import useGoToPage from "../../hooks/useGoToPage";
import useGoToBlock from "../../hooks/useGoToBlock";
import useControllerToSection from "../../hooks/useControllerToSection";
import { EditorContext } from "./EditorLayout";
import useDocumentReader from "../../hooks/useDocumentReader";
import BlockTile from "../../components/Editor/PageBlocks/BlockTile";

let style = Object.assign({}, global);

const PageBlockControllerGroup = ({ pageSorterList, documentDetails, fitToScreen, setFitToScreen }) => {
  let { documentType, metadata, blockNodes, isTimeLineViewOpen } = useContext(EditorContext);
  const { start: initDocumentReader } = useDocumentReader(fitToScreen, setFitToScreen);

  const { goToPage } = useGoToPage();
  const { goToBlock } = useGoToBlock();
  const { controllerToSection } = useControllerToSection();

  let pageType = [constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)
    ? "Block"
    : documentType === constant.TYPE_PRESENTATION
    ? "Slide"
    : "Page";

  useLayoutEffect(() => {
    goToPage();
    goToBlock();
    controllerToSection();
  }, [metadata.activeBlockIdx]);

  useEffect(() => {
    if (!pageSorterList) initDocumentReader({ data: documentDetails });
  }, []);

  return (
    <>
      {!isTimeLineViewOpen && (
        <ul className={cx(style["canvas-ctrl-group"], style["rounded"])}>
          <AddPage documentType={documentType} pageType={pageType} pageSorterList={pageSorterList} />
          <ClonePage documentType={documentType} pageType={pageType} pageSorterList={pageSorterList} />
        </ul>
      )}

      {blockNodes.length > 1 && !pageSorterList && (
        <ul className={cx(style["canvas-ctrl-group"], style["rounded"])}>
          <PageMoveArrows documentType={documentType} pageType={pageType} />
        </ul>
      )}

      {documentType === constant.TYPE_PROPOSAL && (
        <ul className="canvas-ctrl-group rounded">
          <BlockTile />
        </ul>
      )}

      {!pageSorterList && (
        <ul className="canvas-ctrl-group rounded">
          <PageGrid />
          {documentType !== constant.TYPE_PRESENTATION && <PageResize />}
        </ul>
      )}

      {blockNodes.length > 1 && !isTimeLineViewOpen && (
        <DeletePage documentType={documentType} pageType={pageType} pageSorterList={pageSorterList} />
      )}
    </>
  );
};
//Props type validation
PageBlockControllerGroup.propTypes = {
  pageSorterList: PropTypes.bool,
  documentDetails: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

const EditorCanvasController = ({ pageSorterList, activePageListHover, ...props }) => {
  let { metadata, isDocumentReady, playingPageIdx } = useContext(EditorContext);

  const [pageSorterControllerStyle, setPageSorterControllerStyle] = useState();

  //Set pageSorterList canvas controller top when pagelist is open
  useEffect(() => {
    setTimeout(() => {
      if (!pageSorterList || !document.getElementById(`fig-${metadata.activePageIdx}`)) return;

      let parentPos = document.getElementById("pageList").getBoundingClientRect(),
        childPos = document.getElementById(`fig-${metadata.activePageIdx}`).getBoundingClientRect(),
        cureentPagelistControllertop = childPos.top - parentPos.top + 4;

      setPageSorterControllerStyle(cureentPagelistControllertop);
    }, 10);
  }, [metadata.activePageIdx, activePageListHover]);

  return (
    <>
      {!pageSorterList && (
        <div
          className={cx(
            style["canvas-controller"],
            { [style["d-none"]]: !isDocumentReady },
            { [style["disable"]]: playingPageIdx !== null }
          )}
          style={{
            top: metadata.pageController.style.top,
          }}>
          <PageBlockControllerGroup {...props} />
        </div>
      )}

      {pageSorterList && (
        <>
          <li className="page-sorter-controller" style={{ top: pageSorterControllerStyle }}>
            <PageBlockControllerGroup pageSorterList={pageSorterList} />
          </li>
          <li id="sort-path-display" className={cx(style["sort-path-display"], style["d-none"])}></li>
        </>
      )}
    </>
  );
};
//Props type validation
EditorCanvasController.propTypes = {
  pageSorterList: PropTypes.bool,
  activePageListHover: PropTypes.bool,
};

export default EditorCanvasController;
