import React, { useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import global from "../../scss/dhp.scss";
import { Icon } from "../../components/ui/icon";

import { EditorContext } from "../../containers/editor/EditorLayout";
import useReplaceDuplicateShapeId from "../../hooks/useReplaceDuplicateShapeId";
import {
  ANIMATION,
  BRAND,
  COLLAGE,
  E,
  GIF,
  GROUP_WIDGET,
  ICON,
  ILLUSTRATION,
  JOT_FORM,
  LINE,
  MAP,
  PICTURE,
  QR_CODE,
  SHAPE,
  STICKER,
  STOCK,
  SVG,
  TABLE,
  TEXT,
  TEXT_FRAME,
  TYPE_FORM,
  UPLOAD,
  UPLOAD_VIDEO,
  VIDEO,
  W,
} from "../../constants/editor";
import SnapGrid from "./Widgets/SnapGrid";
import useSetActivePageBlock from "../../hooks/useSetActivePageBlock";
import {
  floatToFloatSec,
  getHMSToSec,
  getSecToHMS,
  getDocumentDuration,
  getCssTransformObj,
} from "../../_helpers/utils";
import { Popover, PopoverBody, UncontrolledTooltip } from "reactstrap";
import useUpdatePageDuration from "../../hooks/useUpdatePageDuration";
import useSnaptoGrid from "../../hooks/useSnaptoGrid";
import * as constant from "../../constants/editor";

let style = Object.assign({}, global);

const Widget = props => {
  const [sorterInnerHtml, setSorterInnerHtml] = useState();

  const { replaceDuplicateIdFromSVG } = useReplaceDuplicateShapeId();

  useEffect(() => {
    let targetDOM = document.createElement("div");
    targetDOM.innerHTML = props.widget.innerHTML;

    targetDOM.querySelectorAll(".dhp-page-widget").forEach(element => {
      element.removeAttribute("id");
      element.classList.remove("child-selected");
    });

    replaceDuplicateIdFromSVG(); // Replace duplicate id for shape use in shape border

    setSorterInnerHtml(targetDOM.innerHTML);
  }, [props.widget]);

  return (
    <>
      <div
        style={{ ...props.widget.style, display: "block", transform: "" }}
        {...props.widget.data}
        {...(props.widget.classLists.includes("dhp-widget-hyperlinked") &&
          props.classNm && {
            className: `nav-widget ${props.classNm} dhp-widget-hyperlinked`,
          })}
        {...(!props.widget.classLists.includes("dhp-widget-hyperlinked") &&
          props.classNm && { className: `nav-widget ${props.classNm}` })}
        {...(props.widget.classLists.includes("dhp-widget-hyperlinked") &&
          !props.classNm && { className: `nav-widget dhp-widget-hyperlinked` })}
        {...(!props.widget.classLists.includes("dhp-widget-hyperlinked") &&
          !props.classNm && { className: `nav-widget` })}
        dangerouslySetInnerHTML={{ __html: sorterInnerHtml }}></div>
    </>
  );
};
Widget.propTypes = {
  widget: PropTypes.object,
  classNm: PropTypes.string,
};

const WidgetDurationBar = props => {
  let {
    metadata,
    pageNodes,
    blockNodes,
    widgets,
    updateWidgets,
    activeWidgetFromTimeLine,
    setActiveWidgetFromTimeLine,
    handleWidgetAction,
  } = useContext(EditorContext);

  const resizeContainerRef = useRef();
  const entranceAnimationRef = useRef();
  const exitAnimationRef = useRef();
  const tooltipRef = useRef();

  const [resizableContainerStyle, setResizableContainerStyle] = useState();
  const [activeWidgetInfo, setAactiveWidgetInfo] = useState();
  const [resizingTimeDuration, setResizingTimeDuration] = useState();
  const [popoverOpen, setPopoverOpen] = useState();
  const [widgetAutoscrolling, setWidgetAutoscrolling] = useState();
  const [targetForTolltip, setTargetForTolltip] = useState();

  useSetActivePageBlock(activeWidgetInfo); // For set active page block widget by Hook
  const { showWidgetBarSnappedGridLines, hideGrideLines } = useSnaptoGrid();
  const { widgetAnimationSpeedUpdateBasedOnWidgetDuration } = useUpdatePageDuration();

  // set widget container style based on scale(gape btw 0.00 to 0.01 timing)
  const setContainerStyleBasedOnScale = () => {
    // calculate container width based on scale width
    let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;
    let widgetDuration = props.widget?.duration?.totalTime;
    widgetDuration = widgetDuration ? widgetDuration : pageDuration;
    let preValue = parseInt(widgetDuration.split(".")[0]);
    let postValue = parseInt(widgetDuration.split(".")[1]);
    postValue = postValue ? postValue : 0;
    let targetContainerWidth = props.scaleWidth * preValue + (props.scaleWidth / 10) * postValue;

    // calculate container left based on scale width
    let widgetStartTime = props.widget?.duration?.startTime;
    widgetStartTime = widgetStartTime ? widgetStartTime : "0.0s";
    let startTimePreValue = parseInt(widgetStartTime.split(".")[0]);
    let startTimePostValue = parseInt(widgetStartTime.split(".")[1]);
    startTimePostValue = startTimePostValue ? startTimePostValue : 0;
    let targetContainerLeft = props.scaleWidth * startTimePreValue + (props.scaleWidth / 10) * startTimePostValue;

    setResizableContainerStyle({
      width: `${targetContainerWidth}px`,
      transform: `translateX(${targetContainerLeft}px)`,
    });
  };

  // set active widget
  const activeWdgetContainerOnClick = targetWidgetId => {
    setActiveWidgetFromTimeLine(true);
    setAactiveWidgetInfo({
      targetPageIdx: metadata.activePageIdx,
      targetBlockIdx: metadata.activeBlockIdx,
      activeWidgetId: [targetWidgetId],
      activeWidgetType: [document.getElementById(targetWidgetId).dataset.assetType],
      disableAutoScroll: !props.pageSorterList ? true : false,
    });
  };

  // disable full li for lock widget
  const DisableLockedWidgetContainer = () => {
    setTimeout(() => {
      document.querySelectorAll(".widget-animation .item").forEach(element => {
        let targetId = element.getAttribute("data-widget-bar-id");

        let isLocked = document.getElementById(targetId)?.getAttribute("data-layer-locked") === "true" ? true : false;

        element.closest(".widget").classList.remove("disable");
        if (isLocked) element.closest(".widget").classList.add("disable");
      });
    }, 10);
  };

  // handle click on animation in page timeline
  const handleAnimationClick = animationSchemeType => {
    if (document.getElementById("editorBody").classList.contains("hide-secondAside")) props.toggle();

    setTimeout(() => {
      handleWidgetAction(
        "widget-animation",
        "Widget Animation",
        "widget_animation",
        1,
        false,
        "graphics-media",
        animationSchemeType
      );
    }, 100);
  };

  const mouseDownSubscribers = (e, targetWidgetId) => {
    activeWdgetContainerOnClick(props.widget.id); // active  widget

    // animation icon clicked
    if (e.target.closest(".entrance-animation-icon") || e.target.closest(".exit-animation-icon")) return;
    // widget drag
    else if (e.target.classList.contains("item")) widgetEvents.drag.start({ widgetId: targetWidgetId }, e);
    // widget left resize
    else if (e.target.closest(".resizer").classList.contains("left-resizer")) {
      widgetEvents.resize.start({ resizer: W }, e);
      setTargetForTolltip(e.target.closest(".resizer").getAttribute("id"));
    }
    // widget right resize
    else if (e.target.closest(".resizer").classList.contains("right-resizer")) {
      widgetEvents.resize.start({ resizer: E }, e);
      setTargetForTolltip(e.target.closest(".resizer").getAttribute("id"));
    }
  };

  // widget resize and drag
  const widgetEvents = {
    meta: false,

    // widget container drag events
    drag: {
      updateReactDom: ({ client }) => {
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === client.id);

        // create new obj to update widget context (new start time and end time)
        const updatedWidgetArray = Object.assign([...widgets], {
          [targetWidgetIndex]: {
            ...widgets[targetWidgetIndex],
            duration: {
              ...widgets[targetWidgetIndex].duration,
              ...client.current.duration,
            },
          },
        });

        updateWidgets(updatedWidgetArray); // update context
        setResizableContainerStyle({
          ...resizableContainerStyle,
          transform: client.current.transform,
        }); // update local state for widget container, as left is changed
      },

      dragToScroll: ({ client, scrollContainer, mouse, reducableLeft }, e) => {
        const widgetContainerWidth = parseFloat(client.node.style.width); // get current wiget container width

        setTimeout(() => {
          if (
            mouse.initial.directionX === "RIGHT" &&
            scrollContainer.rect.right - (mouse.current.x + widgetContainerWidth - client.pointerOffset.x) <= 100
          ) {
            const scrollAmount = scrollContainer.node.scrollLeft + 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            widgetEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) + 10;

            if (newX + widgetContainerWidth <= 180 * props.scaleWidth) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.drag.width = newX + widgetContainerWidth;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
                duration: {
                  startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                  endTime: `${parseFloat((newX + widgetContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                },
              };

              let highestWidth = 0;
              document.querySelectorAll(".widget .widget-animation").forEach(item => {
                const transformValue = parseInt(item.querySelector(".item").style.transform.split("(")[1], 10);

                if (highestWidth < item.clientWidth + transformValue) {
                  highestWidth = item.clientWidth + transformValue;
                }
              });

              if (Math.abs(highestWidth - document.querySelector(".timeScale12").offsetWidth) < 100) {
                props.setIsResizingPageTimeLine({
                  value: newX + widgetContainerWidth,
                  event: "widget-draging",
                  isResizing: true,
                });
              }

              props.setScalePointerPosition({
                left: newX + constant.PAGE_TIMELINE_LEFT_SECTION,
                right: newX + constant.PAGE_TIMELINE_LEFT_SECTION + widgetContainerWidth,
              }); // show pointer on scale
              props.setIsWidgetContainerPositionChanging(true); // draging status true
            }
          }

          if (
            mouse.initial.directionX === "LEFT" &&
            mouse.current.x -
              client.pointerOffset.x -
              (scrollContainer.rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) <=
              15
          ) {
            const scrollAmount = scrollContainer.node.scrollLeft - 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            widgetEvents.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) - 10;
            newX = newX < 5 ? 0 : newX;

            if (newX >= 0) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
                duration: {
                  startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                  endTime: `${parseFloat((newX + widgetContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                },
              };

              props.setScalePointerPosition({
                left: newX + constant.PAGE_TIMELINE_LEFT_SECTION,
                right: newX + constant.PAGE_TIMELINE_LEFT_SECTION + widgetContainerWidth,
              }); // show pointer on scale
              props.setIsWidgetContainerPositionChanging(true); // draging status true
            }
          }
        }, 100);
      },

      start: (data, e) => {
        let targetDiv = document.querySelector(".widget-container");
        let rect = targetDiv.getBoundingClientRect();
        let widgetContainerLeft = parseFloat(e.target?.style.transform.split("(")[1]);
        let scrollContainer = document.querySelector(".timeline-body.customScroll");

        const {
          width: scrollContainerWidth,
          left: scrollContainerLeft,
          right: scrollContainerRight,
        } = scrollContainer.getBoundingClientRect();

        // fetch all details for active widget container
        let dataProps = {
          isDragging: true,
          isFirstEvent: true,
          reducableLeft: rect.left + constant.PAGE_TIMELINE_LEFT_SECTION,
          client: {
            node: e.target,
            id: data.widgetId,
            pointerOffset: {
              x: e.clientX - (rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) - widgetContainerLeft,
            },
            initial: {
              transform: widgetContainerLeft,
              duration: {
                startTime: props.widget?.duration?.startTime ?? "0.0s",
                endTime: props.widget?.duration?.endTime ?? `${pageNodes[metadata.activePageIdx]?.pageDuration}s`,
              },
            },
            current: {
              transform: false,
              duration: false,
            },
            drag: {
              width: null,
            },
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerLeft, right: scrollContainerRight },
            scrollFactor: scrollContainer.scrollWidth / scrollContainerWidth,
            scrollLeftMax: scrollContainer.scrollWidth - scrollContainerWidth,
            scrollType: false,
            initial: {
              scrollLeft: scrollContainer.scrollLeft,
            },
            current: {
              scrollLeft: false,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
              directionX: false,
            },
            current: {
              x: false,
              directionX: false,
            },
          },
        };

        widgetEvents.meta = dataProps;
        document.addEventListener("mousemove", widgetEvents.drag.drag);
        document.addEventListener("mouseup", widgetEvents.drag.stop);
      },

      drag: e => {
        let dragMeta = widgetEvents.meta;

        if (dragMeta?.isDragging) {
          if (dragMeta.isFirstEvent) dragMeta.isFirstEvent = false; // event operations

          let newX = e.clientX - dragMeta.reducableLeft - dragMeta.client.pointerOffset.x; // calculate current left during drag
          let widgetContainerWidth = parseFloat(dragMeta.client.node.style.width); // get current widget container width

          // Auto scroll during drag
          if (
            (e.pageX -
              dragMeta.client.pointerOffset.x -
              (dragMeta.scrollContainer.rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) <=
              15 &&
              e.movementX <= 0) ||
            (dragMeta.scrollContainer.rect.right - (e.pageX + widgetContainerWidth - dragMeta.client.pointerOffset.x) <=
              100 &&
              e.movementX >= 0)
          ) {
            dragMeta.mouse.current.x = e.pageX;

            if (!dragMeta.mouse.initial.directionX)
              dragMeta.mouse.initial.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";
            if (dragMeta.mouse.initial.directionX)
              dragMeta.mouse.current.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";

            widgetEvents.drag.dragToScroll({ ...dragMeta }, e);
          } else {
            // update current left in dom and update current datas which was sent from start events( new start time, end time) (min left 0 and max right 3 min)
            if (newX >= 0 && newX + widgetContainerWidth <= 180 * props.scaleWidth) {
              dragMeta.client.node.style.transform = `translateX(${newX}px)`;
              dragMeta.client.current = {
                transform: `translateX(${newX}px)`,
                duration: {
                  startTime: `${parseFloat(newX / props.scaleWidth).toFixed(1)}s`,
                  endTime: `${parseFloat((newX + widgetContainerWidth) / props.scaleWidth).toFixed(1)}s`,
                },
              };

              props.setScalePointerPosition({
                left: newX + constant.PAGE_TIMELINE_LEFT_SECTION,
                right: newX + constant.PAGE_TIMELINE_LEFT_SECTION + widgetContainerWidth,
              }); // show pointer on scale
              props.setIsWidgetContainerPositionChanging(true); // draging status true
            }
          }

          dragMeta.client.drag.width = newX + widgetContainerWidth;
          showWidgetBarSnappedGridLines(dragMeta.client.node); // show snap line
        }
      },

      stop: () => {
        let stopMeta = widgetEvents.meta;

        if (stopMeta?.isDragging) {
          if (!stopMeta.isFirstEvent) widgetEvents.drag.updateReactDom({ ...stopMeta });

          document.removeEventListener("mousemove", widgetEvents.drag.drag);
          document.removeEventListener("mouseup", widgetEvents.drag.stop);

          hideGrideLines(); // hide snap lines
          props.setScalePointerPosition(); // hide pointer on scale
          props.setIsWidgetContainerPositionChanging(false); // draging status false

          setTimeout(() => {
            props.setIsResizingPageTimeLine({
              value:
                parseFloat(stopMeta.client.node.style.transform?.split("(")[1]) +
                parseFloat(stopMeta.client.node.style.width),
              event: "widget-draging",
              isResizing: false,
            });
            stopMeta = false;
          }, 10);
        }
      },
    },

    // widget container resize events
    resize: {
      updateReactDom: ({ client }) => {
        // When update the page duration check if any widget has applied widget animation and if their entrance or exit speed is greater than new widget duration update that widget animation speed accordingly
        let { newEntraceAnimationSpeed, newExitAnimationSpeed } = widgetAnimationSpeedUpdateBasedOnWidgetDuration(
          widgets[client.idx],
          parseFloat(client.current.duration.totalTime)
        );

        let updatedWidgetsArray = Object.assign([...widgets], {
          [client.idx]: {
            ...widgets[client.idx],
            duration: {
              ...widgets[client.idx].duration,
              ...client.current.duration,
            },
            data: {
              ...widgets[client.idx].data,
              "data-entrance-animation-speed": newEntraceAnimationSpeed,
              "data-exit-animation-speed": newExitAnimationSpeed,
            },
          },
        });

        updateWidgets(updatedWidgetsArray); // update context
        setResizableContainerStyle({
          ...resizableContainerStyle,
          width: `${client.current.width}px`,
          transform: `translateX(${client.current.left}px)`,
        }); // update local state for widget container, as left and right are changed
      },

      resizeOnAutoScroll: ({ delta }) => {
        const meta = widgetEvents.meta;
        const {
          client: { node: container, minWidth: cminw, maxWidth: cmaxw },
        } = meta;
        const {
          resizer: { type: resizer },
        } = meta;
        const { width: containerWidth } = container.getBoundingClientRect();
        const containerLeft = parseFloat(meta.client.node.style.transform.split("(")[1]);

        let left;
        let resizeData = { width: containerWidth, left: containerLeft };

        // West Resizer | Left
        if (resizer === W) {
          let width = containerWidth - delta;

          if (width < cminw) {
            resizeData = { ...resizeData, width: cminw, left: containerLeft + (containerWidth - cminw) };
          } else if (width > cmaxw) {
            resizeData = { ...resizeData, width: cmaxw, left: containerLeft + (containerWidth - cmaxw) };
          } else if (containerLeft + delta >= 0)
            resizeData = { ...resizeData, left, width, left: containerLeft + delta };
          else resizeData = { ...resizeData, width: resizeData.left + containerWidth, left: 0 };
        }
        // West Resizer | Right
        else if (resizer === E) {
          let width = containerWidth + delta;

          if (width < cminw) resizeData = { ...resizeData, width: cminw };
          else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
          else resizeData = { ...resizeData, width };
        }

        let newStime = floatToFloatSec(resizeData.left / props.scaleWidth);
        let newEtime = floatToFloatSec((resizeData.left + resizeData.width) / props.scaleWidth);

        meta.client.node.style.width = `${resizeData.width}px`;
        meta.client.node.style.transform = `translateX(${resizeData.left}px)`;

        meta.client.initial.width = containerWidth;
        meta.mouse.initial.x = meta.mouse.current.x;
        meta.client.current = {
          ...resizeData,
          duration: {
            startTime: newStime + "s",
            endTime: newEtime + "s",
            totalTime: floatToFloatSec(resizeData.width / props.scaleWidth) + "s",
          },
        };

        if (meta.client.current.width > meta.client.initial.width)
          props.setIsResizingPageTimeLine({ value: meta.client.current, event: "widget-resizing", isResizing: true });
        widgetEvents.resize.updateTooltip({ ...meta }, newStime, newEtime, true); // update duration tooltip
        props.setScalePointerPosition({
          left: resizeData.left + constant.PAGE_TIMELINE_LEFT_SECTION,
          right: resizeData.left + constant.PAGE_TIMELINE_LEFT_SECTION + resizeData.width,
        }); // show pointer on scale
        setWidgetAutoscrolling(true); // set auto scroll true
      },

      autoScroll: ({
        client: { node: client },
        mouse: {
          current: { x: mcx },
          intervalId,
        },
        scrollContainer: {
          node: scrollContainer,
          rect: { left: scrollContainerLeft, right: scrollContainerRight },
          autoScrollDx: dX,
        },
      }) => {
        widgetEvents.meta.mouse.intervalId = setInterval(() => {
          if (scrollContainerRight - mcx <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft + 10;
            widgetEvents.resize.resizeOnAutoScroll({ delta: 10 });
          } else if (mcx - (scrollContainerLeft + constant.PAGE_TIMELINE_LEFT_SECTION) <= 15) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft - 10;
            widgetEvents.resize.resizeOnAutoScroll({ delta: -10 });
          } else clearInterval(intervalId);
        }, 30);
      },

      updateTooltip: ({ client, resizer }, newStime, newEtime, autoscroll) => {
        setPopoverOpen(true);

        // set duration in tooltip
        let duration = parseFloat(newEtime) - parseFloat(newStime);
        setResizingTimeDuration(getSecToHMS({ sec: duration }));

        // set tooltip left when autoscrool is not performing
        if (document.querySelector(".widget-duration-tooltip .popover") && !autoscroll) {
          let timelineBoxRect = document.getElementById("timeline-wraper").getBoundingClientRect();
          let currentClientRect = client.node.getBoundingClientRect();
          let tolltipLeft =
            resizer.type === W
              ? currentClientRect.left - timelineBoxRect.left
              : currentClientRect.left - timelineBoxRect.left + currentClientRect.width - 59;
          let popOverTransform = document
            .querySelector(".widget-duration-tooltip .popover")
            ?.style.transform.split("(")[1];
          popOverTransform = popOverTransform?.split(",");

          if (popOverTransform) {
            let newPopOverTransform = `translate3d(${tolltipLeft}px,${parseInt(popOverTransform[1])}px, ${parseInt(
              popOverTransform[2]
            )}px)`;
            document.querySelector(".widget-duration-tooltip .popover").style.transform = newPopOverTransform;
          }
        }
      },

      toggleResizeActions: ({ action }) => {
        const entranceAnimation = entranceAnimationRef.current;
        const exitAnimation = exitAnimationRef.current;
        [entranceAnimation, exitAnimation].forEach(item => {
          if (item) item.classList[action === "HIDE" ? "add" : "remove"]("d-none");
        });
      },

      toggleEventCursorToDefault: ({ action }) => {
        const config = {
          SHOW: "ew-resize",
          HIDE: "",
        };
        document.getElementsByTagName("body")[0].style.cursor = config[action];
      },

      start: (data, e) => {
        if (e.button !== 0) return; // only applicable for left mouse click
        const container = resizeContainerRef.current;
        // const scrollContainer = container.closest(".widget");
        const scrollContainer = document.querySelector(".timeline-body.customScroll");
        const containerRect = container?.getBoundingClientRect();
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        const scrollFactor = scrollContainer.scrollWidth / scrollContainerRect.width;

        const pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;
        let widgetDuration = props.widget?.duration?.totalTime;
        let videoDuration =
          props.widget.data["data-asset-type"] === VIDEO &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(props.widget.data["data-source"])
            ? getHMSToSec({
                hms:
                  props.widget.data["data-trimmed-duration"] === ""
                    ? props.widget.data["data-duration"]
                    : props.widget.data["data-trimmed-duration"],
              }).toString()
            : false;
        widgetDuration = videoDuration ? videoDuration : widgetDuration ? widgetDuration : pageDuration;
        let videoinLoop =
          props.widget.data["data-asset-type"] === VIDEO &&
          [STOCK, BRAND, UPLOAD_VIDEO].includes(props.widget.data["data-source"]) &&
          props.widget.data["data-loop"]
            ? true
            : false;

        const dataProps = {
          isResizing: true,
          isFirstEvent: true,
          client: {
            idx: widgets.findIndex(w => w.id === container.getAttribute("data-widget-bar-id")),
            node: container,
            minWidth: videoDuration ? props.scaleWidth * parseFloat(videoDuration) : props.scaleWidth,
            maxWidth:
              videoDuration && !videoinLoop ? props.scaleWidth * parseFloat(videoDuration) : props.scaleWidth * 180,
            initial: {
              left: parseFloat(container.style.transform.match(/\d+/g)[0]),
              width: containerRect.width,
              duration: widgetDuration,
            },
            current: {
              left: false,
              width: false,
              duration: false,
            },
          },
          resizer: {
            node: e.target,
            type: data.resizer,
          },
          mouse: {
            initial: {
              x: e.pageX,
            },
            current: {
              x: false,
            },
            intervalId: false,
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerRect.left, right: scrollContainerRect.right },
            autoScrollDx: scrollFactor * 10,
          },
        };

        widgetEvents.meta = dataProps;
        document.addEventListener("mousemove", widgetEvents.resize.resizing);
        document.addEventListener("mouseup", widgetEvents.resize.stop);
      },

      resizing: e => {
        const meta = widgetEvents.meta;
        if (meta?.isResizing) {
          const {
            client: {
              node: client,
              minWidth: cminw,
              maxWidth: cmaxw,
              initial: { left: cil = 0, width: ciw = 0 },
            },
            resizer: { type: resizer },
            mouse: {
              initial: { x: mix },
              current: { x: mcx },
              intervalId,
            },
            scrollContainer: {
              rect: { left: scrollContainerLeft, right: scrollContainerRight },
            },
          } = meta;

          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
            widgetEvents.resize.toggleResizeActions({ action: "HIDE" });
            widgetEvents.resize.toggleEventCursorToDefault({ action: "SHOW" });
          }

          if (intervalId) {
            clearInterval(intervalId);
            meta.mouse.intervalId = false;
          }

          meta.mouse.current.x = e.pageX;

          // determine size of client after resize
          const dX = e.pageX - mix;

          let left, width;
          let resizeData = { left: cil, width: ciw };

          // West Resizer | Left
          if (resizer === W) {
            const delta = { l: dX, w: -dX };
            width = ciw + delta.w;

            if (width < cminw) resizeData = { ...resizeData, width: cminw, left: cil + (ciw - cminw) };
            else if (width > cmaxw) resizeData = { ...resizeData, width: cmaxw, left: cil + (ciw - cmaxw) };
            else if (cil + delta.l >= 0) resizeData = { ...resizeData, left, width, left: cil + delta.l };
            else resizeData = { ...resizeData, width: resizeData.left + ciw, left: 0 };
          }

          // East Resizer | Right
          else if (resizer === E) {
            const delta = { w: dX };
            width = ciw + delta.w;

            if (width < cminw) resizeData = { ...resizeData, width: cminw };
            else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
            else resizeData = { ...resizeData, width };
          }

          // auto scroll during resize
          if (
            scrollContainerRight - mcx <= 100 ||
            mcx - (scrollContainerLeft + constant.PAGE_TIMELINE_LEFT_SECTION) <= 15
          ) {
            widgetEvents.resize.autoScroll({ ...meta });
          }
          // apply size of client after resize
          else {
            client.style.width = `${resizeData.width}px`;
            client.style.transform = `translateX(${resizeData.left}px)`;
            let newStime = floatToFloatSec(resizeData.left / props.scaleWidth);
            let newEtime = floatToFloatSec((resizeData.left + resizeData.width) / props.scaleWidth);

            meta.client.current = {
              ...resizeData,
              duration: {
                startTime: newStime + "s",
                endTime: newEtime + "s",
                totalTime: floatToFloatSec(resizeData.width / props.scaleWidth) + "s",
              },
            };

            widgetEvents.resize.updateTooltip({ ...meta }, newStime, newEtime, false); // update duration tooltip
            showWidgetBarSnappedGridLines(client); // show snap lines
            props.setScalePointerPosition({
              left: resizeData.left + constant.PAGE_TIMELINE_LEFT_SECTION,
              right: resizeData.left + constant.PAGE_TIMELINE_LEFT_SECTION + resizeData.width,
            }); // show pointer on scale
            setWidgetAutoscrolling(false); // set auto scroll false
          }

          props.setIsWidgetContainerPositionChanging(true); // resizing status true
        }
      },

      stop: () => {
        let meta = widgetEvents.meta;

        let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;

        document.querySelectorAll(".widget-animation .item").forEach(element => {
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === element.getAttribute("data-widget-bar-id"));

          let WidgetDurationEndTime = widgets[targetWidgetIndex].duration?.endTime;

          let targetId = widgets[meta.client.idx].id;

          if (element.getAttribute("data-widget-bar-id") === targetId) {
            if (
              parseFloat(pageDuration) !== parseFloat(WidgetDurationEndTime) &&
              props.resizeDurationRef.current.includes(targetId)
            )
              props.resizeDurationRef.current = props.resizeDurationRef.current.filter(value => value !== targetId);
            else if (
              parseFloat(pageDuration) === parseFloat(WidgetDurationEndTime) &&
              !props.resizeDurationRef.current.includes(targetId)
            )
              props.resizeDurationRef.current = [...props.resizeDurationRef.current, targetId];
          }
        });

        props.setIsResizingPageTimeLine({ value: meta?.client?.current, event: "widget-resizing", isResizing: false });
        if (meta?.isResizing) {
          if (!meta.isFirstEvent) {
            widgetEvents.resize.updateReactDom({ ...meta });
          }
          clearInterval(meta.mouse.intervalId);
          widgetEvents.resize.toggleResizeActions({ action: "SHOW" });
          widgetEvents.resize.toggleEventCursorToDefault({ action: "HIDE" });
          document.removeEventListener("mousemove", widgetEvents.resize.resizing);
          document.removeEventListener("mouseup", widgetEvents.resize.stop);
          meta = false;
          hideGrideLines(); // hide snap lines
          props.setScalePointerPosition(); // hide pointer on scale
          props.setIsWidgetContainerPositionChanging(false); // resizing status false
          setPopoverOpen(false);
          setWidgetAutoscrolling(false); // set auto scroll false
        }
      },
    },
  };

  const disableResizerFunc = (hover, targetId) => {
    let idx = widgets.findIndex(w => w.id === targetId);
    let videoinLoop;

    if (
      widgets[idx].data["data-asset-type"] === VIDEO &&
      [STOCK, BRAND, UPLOAD_VIDEO].includes(widgets[idx].data["data-source"])
    )
      videoinLoop = widgets[idx].data["data-loop"] ? true : false;
    else videoinLoop = true;

    if (!videoinLoop && hover === "hoverIn") {
      document.querySelector(`#page-timeline-${targetId} .left-resizer`).classList.add("disable-resizer");
      document.querySelector(`#page-timeline-${targetId} .right-resizer`).classList.add("disable-resizer");
    } else {
      document.querySelector(`#page-timeline-${targetId} .left-resizer`).classList.remove("disable-resizer");
      document.querySelector(`#page-timeline-${targetId} .right-resizer`).classList.remove("disable-resizer");
    }
  };

  useEffect(() => {
    setContainerStyleBasedOnScale();
  }, [props.scaleWidth, metadata.activePageIdx, widgets]);

  useEffect(() => {
    DisableLockedWidgetContainer();
  }, [props.widget]);

  useEffect(() => {
    // add or remove active class on li in page timeline
    if (metadata.activeWidgetId.length === 1) {
      document.querySelectorAll(".widget-animation .item").forEach(element => {
        let isGroupWidget = document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-page-group");
        let targetId = isGroupWidget
          ? document.getElementById(metadata.activeWidgetId[0]).closest(".dhp-root-widget").getAttribute("id")
          : metadata.activeWidgetId[0];

        element.closest(".widget").classList.remove("active");
        if (element.getAttribute("data-widget-bar-id") === targetId) {
          element.closest(".widget").classList.add("active");
        }
      });

      // scroll to active widget bar when active any widget from canvas
      if (!activeWidgetFromTimeLine) {
        document.querySelector(".timeline-body").scrollTop = 0;
        let parentPos = document.querySelector(".timeline-body").getBoundingClientRect().top;
        let element = document.querySelector(".widget-container .widget.active");
        let childPos = element?.getBoundingClientRect().top;
        let targetOffset = childPos - parentPos;

        document.querySelector(".timeline-body").scrollTop = targetOffset - 30;
      }
    } else {
      document.querySelectorAll(".widget-animation .item").forEach(element => {
        element.closest(".widget").classList.remove("active");

        if (
          metadata.activeWidgetId.length > 1 &&
          metadata.activeWidgetId.includes(element.getAttribute("data-widget-bar-id"))
        ) {
          element.closest(".widget").classList.add("active");
        }
      });
    }
  }, [metadata.activeWidgetId]);

  useEffect(() => {
    setContainerStyleBasedOnScale();
    DisableLockedWidgetContainer();
  }, []);

  return (
    <div className={cx(style["px-3"], style["widget-animation"], "page-timeline-widget-contailner")}>
      {/* Widget Bar in right */}
      <div
        id={`page-timeline-${props.widget.id}`}
        ref={resizeContainerRef}
        onMouseDown={e => mouseDownSubscribers(e, props.widget.id)}
        data-widget-bar-id={props.widget.id}
        className={cx(
          style["custom-tooltip"],
          style["item"],
          style[
            `asset-${
              props.widget.data["data-widget"] === "LINE-SHAPE"
                ? "line"
                : props.widget.data["data-widget"] === "QRCODE"
                ? "qr-code"
                : props.widget.data["data-widget"] === "GROUP"
                ? "illustration"
                : props.widget.data["data-widget"] === "MEDIA"
                ? "video"
                : props.widget.data["data-widget"] === "UPLOAD" && props.widget.data["data-file-type"] === SVG
                ? "illustration"
                : props.widget.data["data-widget"] === "UPLOAD" && props.widget.data["data-file-type"] === "gif"
                ? "animation"
                : props.widget.data["data-widget"] === "UPLOAD" &&
                  ["jpg", "png", "jpeg"].includes(props.widget.data["data-file-type"])
                ? "picture"
                : props.widget.data["data-widget"].toLowerCase()
            }`
          ]
        )}
        style={resizableContainerStyle}>
        {/* left resizer */}
        <div
          className={cx(style["left-resizer"], style["position-absolute"], "resizer")}
          id={`l-resizer-${props.widget.id}`}
          onMouseDown={e => mouseDownSubscribers(e, props.widget.id)}
          onMouseOver={() => disableResizerFunc("hoverIn", props.widget.id)}
          onMouseOut={() => disableResizerFunc("hoverOut", props.widget.id)}>
          <Icon icon="ui-pause" />
        </div>

        {/* entrance animation */}
        <div
          ref={entranceAnimationRef}
          className={cx(style["entrance-animation-icon"], style["position-absolute"])}
          style={{ backgroundColor: props.widget.data["data-entrance-animation"] ? "#47b972" : "black" }}
          onClick={() => handleAnimationClick("entrance")}
          id={`page-timeline-entrance-animation-icon-${props.widget.id}`}>
          <Icon icon="ui-animate" />
          <UncontrolledTooltip
            placement="top"
            target={`page-timeline-entrance-animation-icon-${props.widget.id}`}
            boundariesElement={document.getElementById("app")}>
            Animate
          </UncontrolledTooltip>
        </div>

        {/* exit animation */}
        <div
          ref={exitAnimationRef}
          className={cx(style["exit-animation-icon"], style["position-absolute"])}
          style={{ backgroundColor: props.widget.data["data-exit-animation"] ? "#47b972" : "black" }}
          onClick={() => handleAnimationClick("exit")}
          id={`page-timeline-exit-animation-icon-${props.widget.id}`}>
          <Icon icon="ui-animate" />
          <UncontrolledTooltip
            placement="top"
            target={`page-timeline-exit-animation-icon-${props.widget.id}`}
            boundariesElement={document.getElementById("app")}>
            Animate
          </UncontrolledTooltip>
        </div>

        {/* right resizer */}
        <div
          className={cx(style["right-resizer"], style["position-absolute"], "resizer")}
          id={`r-resizer-${props.widget.id}`}
          onMouseDown={e => mouseDownSubscribers(e, props.widget.id)}
          onMouseOver={() => disableResizerFunc("hoverIn", props.widget.id)}
          onMouseOut={() => disableResizerFunc("hoverOut", props.widget.id)}>
          <Icon icon="ui-pause" />
        </div>

        {/* show widget duration tooltip when resize */}
        <Popover
          className="widget-duration-tooltip"
          placement={props.widgetIdx === 0 ? "bottom" : "top"}
          isOpen={popoverOpen}
          target={targetForTolltip ? targetForTolltip : `r-resizer-${props.widget.id}`}
          container={document.getElementById("timeline-wraper")}
          popperClassName={
            widgetAutoscrolling && targetForTolltip === `r-resizer-${props.widget.id}`
              ? "ml-n5"
              : widgetAutoscrolling && targetForTolltip === `l-resizer-${props.widget.id}`
              ? "mr-n5"
              : ""
          }>
          <PopoverBody>{resizingTimeDuration}</PopoverBody>
        </Popover>
      </div>
    </div>
  );
};
WidgetDurationBar.propTypes = {
  widgetIdx: PropTypes.number,
  widget: PropTypes.object,
  scaleWidth: PropTypes.number,
  scalePointerPosition: PropTypes.object,
  setScalePointerPosition: PropTypes.func,
  setIsWidgetContainerPositionChanging: PropTypes.func,
  isWidgetContainerPositionChanging: PropTypes.bool,
};

const PageTimeLineGenaretor = props => {
  let {
    metadata,
    widgets,
    pageNodes,
    snapGrid,
    updateWidgets,
    updateMetadata,
    changeBySocket,
    pagePlayData,
    setPagePlayData,
    playingPageIdx,
    whichPagePlaying,
    setWhichPagePlaying,
    doubleClickActive,
    isTimeLineViewOpen,
  } = useContext(EditorContext);
  let activeBlockWidgetList = widgets.filter(widget => widget.blockId === metadata.activeBlockId);

  const videoDurationLineRef = useRef();
  const videoSpecificRef = useRef([]);
  const resizeDurationRef = useRef([]);
  const leftStaticAreaWidth = constant.PAGE_TIMELINE_LEFT_SECTION;

  const [pageDurationLineLeft, setPageDurationLineLeft] = useState();
  const [durationLineDetection, setDurationLineDetection] = useState(false);
  const [playHeadLeft, setPlayHeadLeft] = useState(0);
  const [pageDurationToolTip, setPageDurationToolTip] = useState();

  const { updatePageDuration } = useUpdatePageDuration();
  const [scalePointerPosition, setScalePointerPosition] = useState();
  const [isWidgetContainerPositionChanging, setIsWidgetContainerPositionChanging] = useState();
  const [showPageDurationTooltip, setShowPageDurationTooltip] = useState(true);

  // show/hide widget
  const handleWidgetDispaly = (e, targetId) => {
    let newClassList;
    let newArray = Object.assign([...widgets]);
    let targetWidgetIndex = widgets.findIndex(widget => widget.id === targetId);

    //Add disable class for hide widget
    if (!widgets[targetWidgetIndex].data["data-layer-hidden"]) {
      newClassList = [...widgets[targetWidgetIndex].classLists, "draggable-disabled"];
      updateMetadata({ ...metadata, activeWidgetId: false });
    }
    //remove disable class for show widget
    else {
      newClassList = widgets[targetWidgetIndex].classLists.filter(className => className !== "draggable-disabled");
    }

    newArray = Object.assign([...newArray], {
      [targetWidgetIndex]: {
        ...newArray[targetWidgetIndex],
        style: {
          ...newArray[targetWidgetIndex].style,
          display: newArray[targetWidgetIndex].style.display === "block" ? "none" : "block",
        },
        data: {
          ...newArray[targetWidgetIndex].data,
          "data-layer-hidden": !newArray[targetWidgetIndex].data["data-layer-hidden"],
        },
        classLists: newClassList,
      },
    });

    updateWidgets(newArray);
  };

  const videoDurationLine = {
    meta: false,

    resize: {
      updateReactDom: ({ client }) => {
        updatePageDuration(client.current.duration);
      },

      resizeOnAutoScroll: ({ delta }) => {
        const meta = videoDurationLine.meta;
        if (meta?.isResizing) {
          const {
            client: { node: container, minWidth: cminw, maxWidth: cmaxw },
          } = meta;
          const contianerLeft = container.offsetLeft;

          let width = contianerLeft + delta;
          let resizeData = { width: contianerLeft };

          if (width < cminw) resizeData = { ...resizeData, width: cminw };
          else if (width > cmaxw) resizeData = resizeData = { ...resizeData, width: cmaxw };
          else resizeData = { ...resizeData, width };

          meta.client.current = {
            ...resizeData,
            duration: parseFloat(((resizeData.width - leftStaticAreaWidth) / props.scaleWidth).toFixed(1)),
          };

          setDurationLineDetection(true);
          setPageDurationLineLeft(resizeData.width);

          meta.client.initial.width = contianerLeft;
          meta.mouse.initial.x = meta.mouse.current.x;

          widgetUpdate(resizeData.width);
          meta.client.durationContainer.style.left = `${resizeData.width}px`;

          if (meta.client.current.width > meta.client.initial.width)
            props.setIsResizingPageTimeLine({
              value: meta.client.current,
              event: "videoDurationLine-resizing",
              isResizing: true,
            });
        }
      },

      autoScroll: ({
        mouse: {
          current: { x: mcx },
          intervalId,
        },
        scrollContainer: {
          node: scrollContainer,
          rect: { left: scrollContainerLeft, right: scrollContainerRight },
        },
      }) => {
        setDurationLineDetection(false);
        videoDurationLine.meta.mouse.intervalId = setInterval(() => {
          setShowPageDurationTooltip(false);
          if (scrollContainerRight - mcx <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft + 10;
            videoDurationLine.resize.resizeOnAutoScroll({ delta: 10 });
          } else if (mcx - (scrollContainerLeft + constant.PAGE_TIMELINE_LEFT_SECTION) <= 100) {
            scrollContainer.scrollLeft = scrollContainer.scrollLeft - 10;
            videoDurationLine.resize.resizeOnAutoScroll({ delta: -10 });
          } else clearInterval(intervalId);
        }, 30);
      },

      start: e => {
        if (e?.button !== 0) return;
        const container = videoDurationLineRef.current;
        const scrollContainer = document.querySelector(".timeline-body.customScroll");
        const durationContainer = document.getElementById("page_duration_line");
        const containerRect = container?.getBoundingClientRect();
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        const scrollFactor = scrollContainer.scrollWidth / scrollContainerRect.width;

        let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;

        let time = (pageDurationLineLeft - leftStaticAreaWidth) / props.scaleWidth;
        setPageDurationToolTip(getSecToHMS({ sec: time }));

        document.querySelectorAll(".widget-animation .item").forEach(element => {
          let targetWidgetIndex = widgets.findIndex(widget => widget.id === element.getAttribute("data-widget-bar-id"));

          let WidgetDurationEndTime = widgets[targetWidgetIndex].duration?.endTime
            ? widgets[targetWidgetIndex].duration.endTime
            : pageDuration;

          if (parseFloat(pageDuration) === parseFloat(WidgetDurationEndTime)) {
            if (!resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")))
              resizeDurationRef.current = [...resizeDurationRef.current, element.getAttribute("data-widget-bar-id")];

            let elementType = widgets[targetWidgetIndex].data["data-asset-type"];
            let obj = {};

            if (elementType === "videos") {
              let exists = videoSpecificRef.current.find(
                item => item.id === element.getAttribute("data-widget-bar-id")
              );

              if (!exists) {
                obj.id = element.getAttribute("data-widget-bar-id");

                let videoDuration = parseDurationToSeconds(
                  document
                    .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                    ?.getAttribute("data-duration")
                );

                let [startPoint, endPoint] = document
                  .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                  .getAttribute("data-trim")
                  ?.split(",");

                let videoTrimmedStartPoint = parseFloat(startPoint ? startPoint : 0);
                let videoTrimmedEndPoint = parseFloat(endPoint ? endPoint : 0);

                let videoExtendTime = videoDuration - videoTrimmedEndPoint;

                obj.maxWidth = calculateWidthOnPageDuration(
                  videoTrimmedEndPoint - videoTrimmedStartPoint + videoExtendTime
                );

                obj.trimmedDurationLength = calculateWidthOnPageDuration(
                  parseDurationToSeconds(
                    document
                      .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                      .getAttribute("data-trimmed-duration")
                  )
                );

                obj.isInLoop = document
                  .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                  .getAttribute("data-loop");

                videoSpecificRef.current = [...videoSpecificRef.current, obj];
              } else {
                exists.isInLoop = document
                  .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                  .getAttribute("data-loop");

                exists.trimmedDurationLength = calculateWidthOnPageDuration(
                  parseDurationToSeconds(
                    document
                      .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                      .getAttribute("data-trimmed-duration")
                  )
                );

                let videoDuration = parseDurationToSeconds(
                  document.getElementById(`${element.getAttribute("data-widget-bar-id")}`).getAttribute("data-duration")
                );

                let [startPoint, endPoint] = document
                  .getElementById(`${element.getAttribute("data-widget-bar-id")}`)
                  .getAttribute("data-trim")
                  ?.split(",");

                let videoTrimmedStartPoint = parseFloat(startPoint ? startPoint : 0);

                let videoTrimmedEndPoint = parseFloat(endPoint ? endPoint : 0);
                let videoExtendTime = videoDuration - videoTrimmedEndPoint;

                exists.maxWidth = calculateWidthOnPageDuration(
                  videoTrimmedEndPoint - videoTrimmedStartPoint + videoExtendTime
                );
              }
            }
          } else {
            if (resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")))
              resizeDurationRef.current = resizeDurationRef.current.filter(
                item => item !== element.getAttribute("data-widget-bar-id")
              );

            videoSpecificRef.current = videoSpecificRef.current.filter(
              item => item.id !== element.getAttribute("data-widget-bar-id")
            );
          }
        });

        const {
          activePage: {
            current: activePageDuration,
            scaledWidth: { min: minWidth, max: maxWidth },
          },
        } = getDocumentDuration(pageNodes, metadata.activePageIdx, props.scaleWidth);

        const data = {
          isResizing: true,
          isFirstEvent: true,
          client: {
            node: container,
            durationContainer: durationContainer,
            minWidth: props.scaleWidth * 1 + leftStaticAreaWidth,
            maxWidth: props.scaleWidth * 180 + leftStaticAreaWidth,
            initial: {
              width: pageDurationLineLeft,
              duration: activePageDuration,
            },
            current: {
              width: false,
              duration: false,
              left: pageDurationLineLeft,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
            },
            current: {
              x: false,
            },
            intervalId: false,
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerRect.left, right: scrollContainerRect.right },
            autoScrollDx: scrollFactor * 10,
          },
        };

        videoDurationLine.meta = data;
        setShowPageDurationTooltip(false);
        document.addEventListener("mousemove", videoDurationLine.resize.resizing);
        document.addEventListener("mouseup", videoDurationLine.resize.stop);
      },
      resizing: e => {
        const meta = videoDurationLine.meta;
        if (meta?.isResizing) {
          const {
            client: {
              // node: client,
              minWidth: cminw,
              maxWidth: cmaxw,
              initial: { width: ciw },
            },
            mouse: {
              initial: { x: mix },
              current: { x: mcx },
              intervalId,
            },
            scrollContainer: {
              rect: { left: scrollContainerLeft, right: scrollContainerRight },
            },
          } = meta;
          setDurationLineDetection(false);
          // event operations
          if (meta.isFirstEvent) {
            meta.isFirstEvent = false;
          }

          if (intervalId) {
            clearInterval(intervalId);
            meta.mouse.intervalId = false;
          }

          meta.mouse.current.x = e.pageX;

          // determine size of client after resize
          const dX = e.pageX - mix;

          let width;
          let resizeData = { width: ciw };

          // East Resizer | Right
          const delta = { w: dX };
          width = ciw + delta.w;

          if (width < cminw) resizeData = { ...resizeData, width: cminw };
          else if (width > cmaxw) resizeData = { ...resizeData, width: cmaxw };
          else resizeData = { ...resizeData, width };

          if (
            scrollContainerRight - mcx <= 100 ||
            mcx - (scrollContainerLeft + constant.PAGE_TIMELINE_LEFT_SECTION) <= 100
          ) {
            meta.client.current.left = resizeData.width;
            videoDurationLine.resize.autoScroll({ ...meta });
          }
          // apply size of client after resize
          else {
            meta.client.current.left = resizeData.width;

            setPageDurationLineLeft(
              parseFloat(((resizeData.width - leftStaticAreaWidth) / props.scaleWidth).toFixed(1)) * 160 +
                leftStaticAreaWidth
            );
            meta.client.current = {
              ...resizeData,
              duration: parseFloat(((resizeData.width - leftStaticAreaWidth) / props.scaleWidth).toFixed(1)),
            };
          }
        }
      },

      stop: () => {
        let meta = videoDurationLine.meta;

        if (meta?.isResizing) {
          if (!meta.isFirstEvent) {
            videoDurationLine.resize.updateReactDom({ ...meta });
          }
          setDurationLineDetection(false);

          let duration = !meta.client.current.duration ? meta.client.initial.duration : meta.client.current.duration;

          setPageDurationLineLeft(duration * props.scaleWidth + leftStaticAreaWidth);
          setShowPageDurationTooltip(true);
          props.setIsResizingPageTimeLine({
            value: meta.client.current,
            event: "videoDurationLine-resizing",
            isResizing: false,
          });
          clearInterval(meta.mouse.intervalId);
          document.removeEventListener("mousemove", videoDurationLine.resize.resizing);
          document.removeEventListener("mouseup", videoDurationLine.resize.stop);
          meta = false;
        }
      },
    },
  };

  const calculateWidthOnPageDuration = pageDuration => {
    pageDuration = pageDuration.toString();
    let leftStaticAreaWidth = constant.PAGE_TIMELINE_LEFT_SECTION;
    let bValue = parseInt(pageDuration?.split(".")[0]);
    let aValue = parseInt(pageDuration?.split(".")[1]);
    aValue = aValue ? aValue : 0;
    return props.scaleWidth * bValue + (props.scaleWidth / 10) * aValue + leftStaticAreaWidth;
  };

  // calculate the left of page duration line
  const calculatePageDurationLineLeft = () => {
    if (!pageNodes[metadata.activePageIdx]) return;
    let leftStaticAreaWidth = constant.PAGE_TIMELINE_LEFT_SECTION;
    let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;
    let bValue = parseInt(pageDuration.split(".")[0]);
    let aValue = parseInt(pageDuration.split(".")[1]);
    aValue = aValue ? aValue : 0;
    let targetLineLeft = props.scaleWidth * bValue + (props.scaleWidth / 10) * aValue + leftStaticAreaWidth;
    setPageDurationLineLeft(targetLineLeft);
  };

  const parseDurationToSeconds = time => {
    let [minute, secondsMilli] = time?.split(":");
    let [second, milliSecond] = secondsMilli ? secondsMilli.split(".") : [0, 0];

    let totalSeconds = (minute ? parseInt(minute, 10) * 60 : 0) + (second ? parseFloat(second) : 0);

    if (milliSecond) totalSeconds += parseFloat(`0.${milliSecond.charAt(0)}`) || 0;

    return totalSeconds;
  };

  const playHeadDragEvent = {
    meta: false,

    drag: {
      dragToScroll: ({ client, scrollContainer, mouse, reducableLeft }, e) => {
        setTimeout(() => {
          if (mouse.initial.directionX === "RIGHT" && scrollContainer.rect.right - mouse.current.x <= 100) {
            const activePageDuration = parseFloat(pageNodes[metadata.activePageIdx]?.pageDuration) ?? 5.0;
            const scrollAmount = scrollContainer.node.scrollLeft + 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            playHeadDragEvent.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) + 10;

            if (newX <= activePageDuration * props.scaleWidth) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
              };
            }
          }

          if (
            mouse.initial.directionX === "LEFT" &&
            mouse.current.x - (scrollContainer.rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) <= 15
          ) {
            const scrollAmount = scrollContainer.node.scrollLeft - 10;
            const newScrollLeft = scrollAmount >= 0 ? scrollAmount : 0;

            scrollContainer.node.scrollLeft = newScrollLeft;
            playHeadDragEvent.meta.scrollContainer.current.scrollLeft = newScrollLeft;

            let newX = parseFloat(client.node.style.transform.split("(")[1]) - 10;
            newX = newX < 5 ? 0 : newX;

            if (newX >= 0) {
              client.node.style.transform = `translateX(${newX}px)`;
              client.pointerOffset.x =
                e.clientX - reducableLeft - parseFloat(client.node.style.transform.split("(")[1]);
              client.current = {
                transform: `translateX(${newX}px)`,
              };
            }
          }
        }, 100);
      },

      start: e => {
        let targetDiv = document.querySelector(".widget-container");
        let rect = targetDiv.getBoundingClientRect();
        let pHleft = parseFloat(e.target.closest(".timeHandler-container")?.style.transform.split("(")[1]);
        let scrollContainer = document.querySelector(".timeline-body.customScroll");

        const {
          width: scrollContainerWidth,
          left: scrollContainerLeft,
          right: scrollContainerRight,
        } = scrollContainer.getBoundingClientRect();

        // fetch all details for active playhead
        let dataProps = {
          isDragging: true,
          isFirstEvent: true,
          reducableLeft: rect.left + constant.PAGE_TIMELINE_LEFT_SECTION,
          client: {
            node: e.target.closest(".timeHandler-container"),
            pointerOffset: {
              x: e.clientX - (rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) - pHleft,
            },
            initial: {
              transform: pHleft,
            },
            current: {
              transform: false,
            },
            drag: {
              width: null,
            },
          },
          scrollContainer: {
            node: scrollContainer,
            rect: { left: scrollContainerLeft, right: scrollContainerRight },
            scrollFactor: scrollContainer.scrollWidth / scrollContainerWidth,
            scrollLeftMax: scrollContainer.scrollWidth - scrollContainerWidth,
            scrollType: false,
            initial: {
              scrollLeft: scrollContainer.scrollLeft,
            },
            current: {
              scrollLeft: false,
            },
          },
          mouse: {
            initial: {
              x: e.pageX,
              directionX: false,
            },
            current: {
              x: false,
              directionX: false,
            },
          },
        };

        playHeadDragEvent.meta = dataProps;
        document.addEventListener("mousemove", playHeadDragEvent.drag.drag);
        document.addEventListener("mouseup", playHeadDragEvent.drag.stop);
      },

      drag: e => {
        let dragMeta = playHeadDragEvent.meta;

        if (dragMeta?.isDragging) {
          if (dragMeta.isFirstEvent) dragMeta.isFirstEvent = false; // event operations

          let activePageDuration = parseFloat(pageNodes[metadata.activePageIdx]?.pageDuration) ?? 5.0;
          let newX = e.clientX - dragMeta.reducableLeft - dragMeta.client.pointerOffset.x; // calculate current left during drag

          // Auto scroll during drag
          if (
            e.pageX - (dragMeta.scrollContainer.rect.left + constant.PAGE_TIMELINE_LEFT_SECTION) <= 15 ||
            dragMeta.scrollContainer.rect.right - e.pageX <= 100
          ) {
            dragMeta.mouse.current.x = e.pageX;

            if (!dragMeta.mouse.initial.directionX)
              dragMeta.mouse.initial.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";
            if (dragMeta.mouse.initial.directionX)
              dragMeta.mouse.current.directionX = e.movementX === 0 ? false : e.movementX > 0 ? "RIGHT" : "LEFT";

            playHeadDragEvent.drag.dragToScroll({ ...dragMeta }, e);
          }
          // update current left in dom (min left 0 and max right upto active page duration)
          else if (newX >= 0 && newX <= activePageDuration * props.scaleWidth) {
            dragMeta.client.node.style.transform = `translateX(${newX}px)`;
            dragMeta.client.current = {
              transform: `translateX(${newX}px)`,
            };
          }
        }
      },

      stop: () => {
        let stopMeta = playHeadDragEvent.meta;

        if (stopMeta?.isDragging) {
          if (stopMeta.client.current.transform) {
            const transX = parseFloat(stopMeta.client.current.transform.split("(")[1]);
            setPlayHeadLeft(transX);
            pagePlayHeadHandler(transX);
          }
          document.removeEventListener("mousemove", playHeadDragEvent.drag.drag);
          document.removeEventListener("mouseup", playHeadDragEvent.drag.stop);
          stopMeta = false;
        }
      },
    },
  };

  const pagePlayHeadHandler = transX => {
    const trackWidth = parseFloat(pageDurationLineLeft) - constant.PAGE_TIMELINE_LEFT_SECTION;
    const progress = Math.round((transX * 100) / trackWidth);

    if (progress >= 1 && progress <= 99) {
      const timer = (progress * pagePlayData.pageDurationMs) / 100;
      setPagePlayData({
        ...pagePlayData,
        action: "pause",
        pageTimer: timer - (timer % 100),
        pageProgress: progress,
      });
      setWhichPagePlaying({
        action: "pause",
        playingPageIdx: pagePlayData.pageIdx,
        triggerPoint: "slider",
      });
    } else {
      // exit play mode if progress is 0 or 100
      setWhichPagePlaying({
        action: "force_stop",
        playingPageIdx: pagePlayData.pageIdx,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isTimeLineViewOpen === "page-timeline") {
        document.querySelectorAll(".widget").forEach(element => {
          element.style.width = `${
            document.querySelector(".timeScale12").offsetWidth + constant.PAGE_TIMELINE_LEFT_SECTION
          }px`;

          element.style.minWidth = `${document.querySelector(".time-container").offsetWidth}px`;
        });
      }
    }, 100);
  }, [widgets, metadata.activePageIdx, pageNodes[metadata.activePageIdx]?.pageDuration, props.scaleWidth]);

  useEffect(() => {
    if (parseFloat(pageDurationLineLeft) - constant.PAGE_TIMELINE_LEFT_SECTION < parseFloat(playHeadLeft)) {
      setPlayHeadLeft(parseFloat(pageDurationLineLeft) - constant.PAGE_TIMELINE_LEFT_SECTION);
    }
  }, [pageNodes[metadata.activePageIdx]?.pageDuration]);

  // auto-slide play-head for page-timeline
  useEffect(() => {
    if (pagePlayData?.pageProgress >= 0 && whichPagePlaying?.triggerPoint !== "slider") {
      const pageDuration = pagePlayData?.pageDurationMs / 1000;
      const trackWidth = pageDuration * props.scaleWidth;
      const playheadTransX = pagePlayData.pageProgress * (trackWidth / 100);
      setPlayHeadLeft(playheadTransX);

      // auto scroll page scene along with page playhead (keep the playhead visible in viewport)
      const scrollContainer = document.getElementById("timeline-body");
      pagePlayData?.scrollData?.rangePoints?.forEach(range => {
        if (
          playheadTransX >= range.min &&
          playheadTransX <= range.max &&
          parseInt(scrollContainer.dataset.scrollLeft ?? 0) !== range.min
        ) {
          scrollContainer.scrollLeft = range.min;
          scrollContainer.dataset.scrollLeft = range.min;
        }
      });
    }
  }, [pagePlayData?.pageProgress]);

  useEffect(() => {
    calculatePageDurationLineLeft();
  }, [props.scaleWidth, metadata.activePageIdx]);

  // Handle collaboration
  useEffect(() => {
    if (pageNodes && changeBySocket) calculatePageDurationLineLeft();
  }, [pageNodes, changeBySocket]);

  const widgetUpdate = pageDurationLineLeft => {
    let durationLeft = pageDurationLineLeft - leftStaticAreaWidth;
    let scaledPageDuration = Math.round((durationLeft / props.scaleWidth).toFixed(1) * 10) / 10;
    setPageDurationToolTip(getSecToHMS({ sec: scaledPageDuration }));

    document.querySelectorAll(".widget-animation .item").forEach(element => {
      let targetWidgetIndex = widgets.findIndex(widget => widget.id === element.getAttribute("data-widget-bar-id"));
      let elementType = widgets[targetWidgetIndex].data["data-asset-type"];
      // let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;

      let widgetDurationStartTime = widgets[targetWidgetIndex].duration?.startTime
        ? widgets[targetWidgetIndex].duration?.startTime
        : 0;

      let timeDifference = Math.abs((parseFloat(scaledPageDuration) - parseFloat(widgetDurationStartTime)).toFixed(1));

      if (elementType === "videos") {
        let filterObj = videoSpecificRef.current.find(item => item.id === element.getAttribute("data-widget-bar-id"));

        const transform = parseInt(
          document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`)?.style.transform.split("(")[1],
          10
        );

        if (filterObj?.isInLoop == "false") {
          if (parseInt(element.style.width, 10) >= durationLeft && resizeDurationRef.current.includes(filterObj?.id)) {
            document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
              durationLeft - transform
            }px`;
          } else if (
            filterObj?.maxWidth >= pageDurationLineLeft &&
            resizeDurationRef.current.includes(filterObj?.id) &&
            parseInt(element.style.width, 10) <= durationLeft
          ) {
            document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
              durationLeft - transform
            }px`;
          }
        } else {
          if (
            filterObj?.trimmedDurationLength <= pageDurationLineLeft &&
            parseInt(element.style.width, 10) >= durationLeft &&
            resizeDurationRef.current.includes(filterObj?.id)
          ) {
            document.querySelector(`[data-widget-bar-id="${filterObj.id}"]`).style.width = `${
              durationLeft - transform
            }px`;
          } else if (
            resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
            parseInt(element.style.width, 10) <= durationLeft
          ) {
            document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
              durationLeft - transform
            }px`;
          }
        }
      } else {
        const transform = parseInt(
          document
            .querySelector(`[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`)
            ?.style.transform.split("(")[1],
          10
        );

        if (
          parseInt(element.style.width, 10) >= durationLeft &&
          resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
          timeDifference >= 1
        ) {
          document.querySelector(
            `[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`
          ).style.width = `${durationLeft - transform}px`;
        }

        if (
          resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
          parseInt(element.style.width, 10) <= durationLeft &&
          timeDifference >= 1
        ) {
          document.querySelector(
            `[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`
          ).style.width = `${durationLeft - transform}px`;
        }
      }
    });
  };

  useEffect(() => {
    if (!durationLineDetection) {
      let durationLeft = pageDurationLineLeft - leftStaticAreaWidth;
      let scaledPageDuration = Math.round((durationLeft / props.scaleWidth).toFixed(1) * 10) / 10;
      setPageDurationToolTip(getSecToHMS({ sec: scaledPageDuration }));

      document.querySelectorAll(".widget-animation .item").forEach(element => {
        let targetWidgetIndex = widgets.findIndex(widget => widget.id === element.getAttribute("data-widget-bar-id"));
        let elementType = widgets[targetWidgetIndex].data["data-asset-type"];
        let pageDuration = pageNodes[metadata.activePageIdx]?.pageDuration;

        let widgetDurationStartTime = widgets[targetWidgetIndex].duration?.startTime
          ? widgets[targetWidgetIndex].duration?.startTime
          : 0;

        let timeDifference = Math.abs(
          (parseFloat(scaledPageDuration) - parseFloat(widgetDurationStartTime)).toFixed(1)
        );

        if (elementType === "videos") {
          let filterObj = videoSpecificRef.current.find(item => item.id === element.getAttribute("data-widget-bar-id"));

          const transform = parseInt(
            document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`)?.style.transform.split("(")[1],
            10
          );

          if (filterObj?.isInLoop == "false") {
            if (
              parseInt(element.style.width, 10) >= durationLeft &&
              resizeDurationRef.current.includes(filterObj?.id)
            ) {
              document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
                durationLeft - transform
              }px`;
            } else if (
              filterObj?.maxWidth >= pageDurationLineLeft &&
              resizeDurationRef.current.includes(filterObj?.id) &&
              parseInt(element.style.width, 10) <= durationLeft
            ) {
              document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
                durationLeft - transform
              }px`;
            }
          } else if(filterObj?.isInLoop == "true"){
            if (
              filterObj?.trimmedDurationLength <= pageDurationLineLeft &&
              parseInt(element.style.width, 10) >= durationLeft &&
              resizeDurationRef.current.includes(filterObj?.id)
            ) {
              document.querySelector(`[data-widget-bar-id="${filterObj.id}"]`).style.width = `${
                durationLeft - transform
              }px`;
            } else if (
              resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
              parseInt(element.style.width, 10) <= durationLeft
            ) {
              document.querySelector(`[data-widget-bar-id="${filterObj?.id}"]`).style.width = `${
                durationLeft - transform
              }px`;
            }
          }
        } else {
          const transform = parseInt(
            document
              .querySelector(`[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`)
              ?.style.transform.split("(")[1],
            10
          );

          if (
            parseInt(element.style.width, 10) >= durationLeft &&
            resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
            timeDifference >= 1
          ) {
            document.querySelector(
              `[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`
            ).style.width = `${durationLeft - transform}px`;
          }

          if (
            resizeDurationRef.current.includes(element.getAttribute("data-widget-bar-id")) &&
            parseInt(element.style.width, 10) <= durationLeft &&
            timeDifference >= 1
          ) {
            document.querySelector(
              `[data-widget-bar-id="${element.getAttribute("data-widget-bar-id")}"]`
            ).style.width = `${durationLeft - transform}px`;
          }
        }
      });
    }
  }, [pageDurationLineLeft]);

  useEffect(() => {
    if (pageDurationLineLeft) document.getElementById("page_duration_line").style.left = `${pageDurationLineLeft}px`;
  }, [pageDurationLineLeft]);

  useEffect(() => {
    calculatePageDurationLineLeft();
  }, []);

  useEffect(() => {
    // conditional set disable class on timeline widgets while playing or playing end
    document.querySelectorAll(".widget-animation .item").forEach(element => {
      const targetId = element.getAttribute("data-widget-bar-id");
      const isLocked = document.getElementById(targetId)?.getAttribute("data-layer-locked") === "true" ? true : false;
      if (playingPageIdx !== null || (playingPageIdx === null && isLocked)) {
        element.closest(".widget").classList.add("disable");
      } else element.closest(".widget").classList.remove("disable");
    });
  }, [playingPageIdx]);

  return (
    <>
      <div className={style["divider"]}></div>
      <ul>
        {/* Widget list of active page */}
        {activeBlockWidgetList.length > 0 &&
          activeBlockWidgetList.toReversed().map(
            (widget, index) =>
              widget.blockId == metadata.activeBlockId && (
                <React.Fragment key={`active-bloack-widget-${index}`}>
                  <li
                    className={"widget"}
                    data-li-idx={index}
                    style={{
                      width: `${
                        document.querySelector(".timeScale12").offsetWidth + constant.PAGE_TIMELINE_LEFT_SECTION
                      }px`,
                      minWidth: `${document.querySelector(".time-container").offsetWidth}px`,
                    }}>
                    {/* widget left section (widget display and lebel) */}
                    {[TEXT].includes(widget.data["data-asset-type"]) && (
                      <div className={style["layer-item"]}>
                        {/* Widget Icon display */}
                        <div className={style["layer-widget"]}>
                          <Icon icon="ui-text" />
                        </div>

                        {/* Widget Lebels */}
                        {!doubleClickActive && (
                          <span className={style["text-truncate"]}>
                            {document.getElementById(widget.id)?.innerText}
                          </span>
                        )}
                        {doubleClickActive &&
                          (!document.getElementById(widget.id).style.visibility ||
                            document.getElementById(widget.id).style.visibility !== "hidden") && (
                            <span className={style["text-truncate"]}>
                              {document.getElementById(widget.id)?.innerText}
                            </span>
                          )}
                        {doubleClickActive && document.getElementById(widget.id).style.visibility === "hidden" && (
                          <span className={style["text-truncate"]}>
                            {document.querySelector(".dhp-content-editable-true-text")?.innerText}
                          </span>
                        )}

                        {/* Show hide icon */}
                        <div
                          className={cx(style["show-hide"])}
                          onClick={e => handleWidgetDispaly(e, widget.id)}
                          id={`display-icon-${widget.id}`}>
                          {widget.style.display === "block" && (
                            <>
                              <Icon icon="eye-cross" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Hide Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                          {widget.style.display === "none" && (
                            <>
                              <Icon icon="eye2" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Show Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {[
                      TEXT_FRAME,
                      LINE,
                      SHAPE,
                      ICON,
                      STICKER,
                      ILLUSTRATION,
                      ANIMATION,
                      QR_CODE,
                      PICTURE,
                      COLLAGE,
                      VIDEO,
                      UPLOAD,
                    ].includes(widget.data["data-asset-type"]) && (
                      <div className={style["layer-item"]}>
                        {/* Widget display */}
                        <div className={style["layer-widget"]}>
                          {[PICTURE, COLLAGE, VIDEO].includes(widget.data["data-asset-type"]) && (
                            <div
                              style={{
                                transform:
                                  parseFloat(widget.style.width) > parseFloat(widget.style.height)
                                    ? `scale(${36 / parseFloat(widget.style.height)})`
                                    : `scale(${36 / parseFloat(widget.style.width)})`,
                                height: "36px",
                                width: "36px",
                                transformOrigin: "left center",
                              }}>
                              <Widget key={widget.id} widget={widget} classNm={"img-video"} />
                            </div>
                          )}

                          {[UPLOAD].includes(widget.data["data-asset-type"]) &&
                            ["jpg", "png", "jpeg", "gif"].includes(widget.data["data-file-type"]) && (
                              <div
                                style={{
                                  transform:
                                    parseFloat(widget.style.width) > parseFloat(widget.style.height)
                                      ? `scale(${36 / parseFloat(widget.style.height)})`
                                      : `scale(${36 / parseFloat(widget.style.width)})`,
                                  height: "36px",
                                  width: "36px",
                                  transformOrigin: "left center",
                                }}>
                                <Widget key={widget.id} widget={widget} classNm={"img-video"} />
                              </div>
                            )}

                          {[UPLOAD].includes(widget.data["data-asset-type"]) &&
                            ["svg"].includes(widget.data["data-file-type"]) && (
                              <div
                                style={{
                                  transform: `scale(${28 / parseFloat(widget.style.width)})`,
                                  height: "36px",
                                  width: "36px",
                                  transformOrigin: "center center",
                                }}>
                                <Widget key={widget.id} widget={widget} />
                              </div>
                            )}

                          {[TEXT_FRAME, LINE, SHAPE, ICON, STICKER, ILLUSTRATION, ANIMATION, QR_CODE].includes(
                            widget.data["data-asset-type"]
                          ) && (
                            <div
                              style={{
                                transform: `scale(${28 / parseFloat(widget.style.width)})`,
                                height: "36px",
                                width: "36px",
                                transformOrigin: "center center",
                              }}>
                              <Widget key={widget.id} widget={widget} />
                            </div>
                          )}
                        </div>

                        {/* Widget Lebels */}
                        {widget.data["data-widget"] === "TEXT-FRAME" && <span>Text Frame</span>}
                        {widget.data["data-widget"] === "LINE-SHAPE" && <span>Line</span>}
                        {widget.data["data-widget"] === "UPLOAD" &&
                          ["jpg", "png", "jpeg"].includes(widget.data["data-file-type"]) && (
                            <span className={style["text-truncate"]}>Picture</span>
                          )}
                        {widget.data["data-widget"] === "UPLOAD" && widget.data["data-file-type"] === SVG && (
                          <span className={style["text-truncate"]}>Illustration</span>
                        )}
                        {widget.data["data-widget"] === "UPLOAD" && widget.data["data-file-type"] === GIF && (
                          <span className={style["text-truncate"]}>Animation</span>
                        )}
                        {widget.data["data-widget"] === "QRCODE" && <span>QR Code</span>}
                        {widget.data["data-widget"] === "MEDIA" && <span>Video</span>}

                        {!["TEXT-FRAME", "LINE-SHAPE", "UPLOAD", "QRCODE", "MEDIA"].includes(
                          widget.data["data-widget"]
                        ) && (
                          <span className={style["text-truncate"]}>
                            {widget.data["data-widget"].toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                          </span>
                        )}

                        {/* Show hide icon */}
                        <div
                          className={cx(style["show-hide"])}
                          onClick={e => handleWidgetDispaly(e, widget.id)}
                          id={`display-icon-${widget.id}`}>
                          {widget.style.display === "block" && (
                            <>
                              <Icon icon="eye-cross" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Hide Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                          {widget.style.display === "none" && (
                            <>
                              <Icon icon="eye2" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Show Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                        </div>
                      </div>
                    )}

                    {[MAP, TABLE, TYPE_FORM, JOT_FORM, GROUP_WIDGET].includes(widget.data["data-asset-type"]) && (
                      <div className={style["layer-item"]}>
                        {/* Widget Icon display */}
                        <div className={style["layer-widget"]}>
                          <Icon
                            icon={
                              widget.data["data-asset-type"] === MAP
                                ? `ui-maps`
                                : `ui-${widget.data["data-widget"].toLowerCase()}`
                            }
                          />
                        </div>

                        {/* Widget Lebels */}
                        <span className={style["text-truncate"]}>
                          {widget.data["data-widget"].toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                        </span>

                        {/* Show hide icon */}
                        <div
                          className={cx(style["show-hide"])}
                          onClick={e => handleWidgetDispaly(e, widget.id)}
                          id={`display-icon-${widget.id}`}>
                          {widget.style.display === "block" && (
                            <>
                              <Icon icon="eye-cross" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Hide Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                          {widget.style.display === "none" && (
                            <>
                              <Icon icon="eye2" />
                              <UncontrolledTooltip
                                placement="top"
                                target={`display-icon-${widget.id}`}
                                boundariesElement={document.getElementById("app")}>
                                Show Layer
                              </UncontrolledTooltip>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {/* ***************** END ******************** */}

                    {/* Widget duration bar */}
                    <WidgetDurationBar
                      widgetIdx={index}
                      widget={widget}
                      scaleWidth={props.scaleWidth}
                      resizeDurationRef={resizeDurationRef}
                      pageDurationLineLeft={pageDurationLineLeft - leftStaticAreaWidth}
                      scalePointerPosition={scalePointerPosition}
                      setScalePointerPosition={setScalePointerPosition}
                      setIsWidgetContainerPositionChanging={setIsWidgetContainerPositionChanging}
                      isWidgetContainerPositionChanging={isWidgetContainerPositionChanging}
                      setIsResizingPageTimeLine={props.setIsResizingPageTimeLine}
                    />
                  </li>

                  {/* widget bar Grid Lines for snap to grid */}
                  {snapGrid?.widgetContainerGrid?.map(
                    (grid, idx) =>
                      grid.widgetContainerIdx == index && (
                        <SnapGrid key={idx} grid={grid} component={"widget-container"} />
                      )
                  )}
                </React.Fragment>
              )
          )}

        {/* Page duration max limit line */}
        <div
          ref={videoDurationLineRef}
          onMouseDown={videoDurationLine.resize.start}
          id="page_duration_line"
          className={cx(style["page-duration-line"], { [style["disable"]]: playingPageIdx !== null })}>
          <div className={style["duration-line"]}></div>
          <div className={style["duration-pointer"]}></div>
        </div>

        {/* page duration tootip  */}
        {showPageDurationTooltip && (
          <UncontrolledTooltip
            placement="top"
            target="page_duration_line"
            boundariesElement={document.getElementById("app")}>
            {pageDurationToolTip}
          </UncontrolledTooltip>
        )}

        {/* Play head line */}
        {pagePlayData.pageProgress > 0 && pagePlayData.pageProgress < 100 && (
          <div
            onMouseDown={e => playHeadDragEvent.drag.start(e)}
            className={cx(style["timeHandler-container"], "page-timeline-playhead")}
            style={{ transform: `translateX(${playHeadLeft}px)` }}>
            <div className={style["timeHandler-line"]}></div>
            <div className={style["timeHandler-pointer"]}></div>
          </div>
        )}

        {/* snap pointer on scale durine resize and drag widget containers */}
        {isWidgetContainerPositionChanging && (
          <>
            <div className={cx(style["scale-snap-line"])} style={{ left: `${scalePointerPosition?.left}px` }}>
              <div className={style["scale-pointer"]}></div>
            </div>
            <div className={cx(style["scale-snap-line"])} style={{ left: `${scalePointerPosition?.right}px` }}>
              <div className={style["scale-pointer"]}></div>
            </div>
          </>
        )}
      </ul>
    </>
  );
};
PageTimeLineGenaretor.propTypes = {
  scaleWidth: PropTypes.number,
  widgetContainerLiWidth: PropTypes.number,
};

export default PageTimeLineGenaretor;
