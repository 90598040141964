import cx from "classnames";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import global from "../../../scss/dhp.scss";

import { Icon } from "../../ui/icon";
import { EditorContext } from "../../../containers/editor/EditorLayout";
import * as constant from "../../../constants/editor";

let style = Object.assign({}, global);

const PageMoveArrows = props => {
  let { documentType, metadata, blockNodes, pageNodes, updateMetadata, updateScrollEvent } = useContext(EditorContext);
  let currentPageIndexNumber = metadata.activePageIdx + 1;

  const pageMove = direction => {
    // To prevent auto scroll
    updateScrollEvent(true);

    if (
      (direction == "prev" && metadata.activeBlockIdx - 1 != -1) ||
      (direction == "next" && metadata.activeBlockIdx + 1 < blockNodes.length)
    ) {
      console.log(metadata);
      let targetPageIdx = direction == "prev" ? metadata.activePageIdx - 1 : metadata.activePageIdx + 1;
      let targetBlockIdx = direction == "prev" ? metadata.activeBlockIdx - 1 : metadata.activeBlockIdx + 1;

      if (![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType)) {
        updateMetadata({
          ...metadata,
          activePageId: pageNodes[targetPageIdx].pageId,
          activePageIdx: targetPageIdx,
          activeBlockId: blockNodes[targetBlockIdx].blockId,
          activeBlockIdx: targetBlockIdx,
          activeWidgetId: false,
          activeWidgetType: false,
          activeOutSideCanvasArea: false,
          disableAutoScroll: false,
        });
      } else {
        updateMetadata({
          ...metadata,
          activeBlockId: blockNodes[targetBlockIdx].blockId,
          activeBlockIdx: targetBlockIdx,
          activeWidgetId: false,
          activeWidgetType: false,
          activeOutSideCanvasArea: false,
          disableAutoScroll: false,
        });
      }
    }
  };

  return (
    <>
      <li className={cx(style["custom-tooltip"], style["page-up"])}>
        <span
          className={cx(style["prev"], {
            [style["disabled"]]: 0 === metadata.activeBlockIdx,
          })}
          onClick={() => pageMove("prev")}>
          <Icon icon="chevron-up" />
        </span>
        <div className={cx(style["custom-tooltip-content"], style["right"])}>Previous {props.pageType}</div>
      </li>

      {![constant.TYPE_INFOGRAPHIC, constant.TYPE_PROPOSAL].includes(documentType) && (
        <li className={cx(style["custom-tooltip"], style["p-0"])}>
          <span className={style["current-page"]}>{currentPageIndexNumber}</span>
          <div className={cx(style["custom-tooltip-content"], style["right"])}>Page Number</div>
        </li>
      )}

      <li className={cx(style["custom-tooltip"], style["page-next"])}>
        <span
          to="#"
          className={cx(style["next"], {
            [style["disabled"]]: blockNodes.length - 1 === metadata.activeBlockIdx,
          })}
          onClick={() => pageMove("next")}>
          <Icon icon="chevron-down" />
        </span>
        <div className={cx(style["custom-tooltip-content"], style["right"])}>Next {props.pageType}</div>
      </li>
    </>
  );
};

//Props type validation
PageMoveArrows.propTypes = {
  pageType: PropTypes.string.isRequired,
};

export default PageMoveArrows;
